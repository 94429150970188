import { useAppSelector } from "../../../app/store";
import {
  selectActiveEvents,
  selectEventsNotAcknowledged,
} from "../../devices/devices-slice";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Text } from "../../../components/metrics/text";
import { AnimatedNumber } from "../../../components/metrics/animated-number";
import { Legend } from "../../../components/metrics/legend";
import { useTheme } from "../../../app/theme-provider";
import { ParentSize } from "@visx/responsive";
import { PercentBar } from "../../../components/metrics/graph/percent-bar";

export function EventsStats() {
  const { t } = useTranslation();

  const historicEvents = useAppSelector((state) =>
    selectEventsNotAcknowledged(state)
  ).length;
  const activeEvents = useAppSelector((state) =>
    selectActiveEvents(state)
  ).length;

  const totalEvents = historicEvents + activeEvents;

  const { semanticColors } = useTheme();

  const legendItems = [
    ...(activeEvents
      ? [
          {
            value: activeEvents,
            label: t("stats.notifications.active", "active"),
            color: semanticColors.bad,
          },
        ]
      : []),
    ...(historicEvents
      ? [
          {
            value: historicEvents,
            label: t("stats.notifications.historic", "historic"),
            color: semanticColors.warning,
          },
        ]
      : []),
  ];

  return (
    <NavLink to={"/notifications"}>
      <div className="flex h-full grow flex-col gap-1">
        <Text>{t("stats.notifications.title", "notifications")}</Text>

        <AnimatedNumber
          unit={""}
          value={totalEvents}
          decimalPoints={0}
          className={"text-gray-900 lg:text-2xl"}
        />

        <div className="flex grow flex-col justify-end gap-1">
          <Legend values={legendItems} />

          <div className={"mt-2 h-1 w-full"}>
            <ParentSize>
              {({ width, height }) => {
                return (
                  <PercentBar
                    key={"deviceBar"}
                    height={height}
                    width={width}
                    colors={[semanticColors.bad, semanticColors.warning]}
                    values={[
                      {
                        value: activeEvents,
                        label: "online",
                      },
                      { value: historicEvents, label: "offline" },
                    ]}
                  />
                );
              }}
            </ParentSize>
          </div>
        </div>
      </div>
    </NavLink>
  );
}
