import { useTranslation } from "react-i18next";
import React from "react";

export function DeviceIdInput({
  index,
  serial,
  onSerialChange,
}: {
  index: number;
  serial: string;
  onSerialChange: (index: number, serial: string) => void;
}) {
  const { t } = useTranslation();

  return (
    <div>
      <label htmlFor="id" className="block text-sm">
        {t("device.pair.id", "id of the device")}
      </label>
      <div className="mb-2 mt-1">
        <input
          value={serial}
          onChange={(event) => {
            onSerialChange(index, event.target.value);
          }}
          type="text"
          name="id"
          id="id"
          className="block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
          placeholder=""
          aria-describedby="id-description"
        />
      </div>
    </div>
  );
}
