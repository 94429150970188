import { useAppSelector } from "../../../app/store";
import {
  selectAllDeviceStates,
  selectDeviceConfigurationByDeviceId,
} from "../../devices/devices-slice";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTime } from "../../../components/time/time-context";
import { getDeviceConnectionState } from "../../../models/device";
import { NavLink } from "react-router-dom";
import { Text } from "../../../components/metrics/text";
import { AnimatedNumber } from "../../../components/metrics/animated-number";
import { PercentBar } from "../../../components/metrics/graph/percent-bar";
import { ParentSize } from "@visx/responsive";
import { useTheme } from "../../../app/theme-provider";
import { Legend } from "../../../components/metrics/legend";

export function DevicesStats() {
  const { t } = useTranslation();

  const { now } = useTime();

  const devicesStates = useAppSelector(selectAllDeviceStates);
  const { semanticColors } = useTheme();

  const devicesStats = devicesStates.reduce(
    (prev, deviceState) => {
      const deviceConfig = useAppSelector((state) =>
        selectDeviceConfigurationByDeviceId(state, deviceState.id)
      );
      const connectionState = getDeviceConnectionState(
        now,
        deviceState.time ? new Date(deviceState.time) : undefined,
        deviceConfig
      );
      const isOnline = connectionState.status === "online";
      return {
        total: ++prev.total,
        online: prev.online + (isOnline ? 1 : 0),
        offline: prev.offline + (isOnline ? 0 : 1),
      };
    },
    {
      total: 0,
      online: 0,
      offline: 0,
    }
  );

  const legendValues = [
    ...(devicesStats.online
      ? [
          {
            value: devicesStats.online,
            label: t("stats.devices.online", "online"),
            color: semanticColors.good,
          },
        ]
      : []),
    ...(devicesStats.offline
      ? [
          {
            value: devicesStats.offline,
            label: t("stats.devices.offline", "offline"),
            color: semanticColors.bad,
          },
        ]
      : []),
  ];

  return (
    <NavLink to={"/devices"}>
      <div className="flex h-full grow flex-col gap-1">
        <Text>{t("stats.devices.title", "devices")}</Text>

        <AnimatedNumber
          unit={""}
          value={devicesStats.total}
          decimalPoints={0}
          className={"text-gray-900 lg:text-2xl"}
        />

        <div className="flex grow flex-col justify-end gap-1">
          <Legend values={legendValues} />

          <div className={"mt-2 h-1 w-full"}>
            <ParentSize>
              {({ width, height }) => {
                return (
                  <PercentBar
                    key={"deviceBar"}
                    height={height}
                    width={width}
                    colors={[semanticColors.good, semanticColors.bad]}
                    values={[
                      { value: devicesStats.online, label: "online" },
                      { value: devicesStats.offline, label: "offline" },
                    ]}
                  />
                );
              }}
            </ParentSize>
          </div>
        </div>
      </div>
    </NavLink>
  );
}
