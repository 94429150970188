import { Column } from "@tanstack/react-table";
import { cn } from "../ng";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/solid";
import React from "react";

interface DataTableColumnHeaderProps<TData, TValue>
  extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>;
  title: string;
}

export function DataTableColumnHeader<TData, TValue>({
  column,
  title,
  className,
}: DataTableColumnHeaderProps<TData, TValue>) {
  if (!column.getCanSort()) {
    return <div className={cn(className)}>{title}</div>;
  }

  return (
    <div
      className="flex cursor-pointer flex-row items-center"
      onClick={() => {
        if (column.getIsSorted() === "asc") {
          column.toggleSorting(true);
        } else if (column.getIsSorted() === "desc") {
          column.clearSorting();
        } else {
          column.toggleSorting(false);
        }
      }}
    >
      {title}

      {column.getIsSorted() === "asc" && (
        <ArrowUpIcon className="ml-2 h-4 w-4" />
      )}

      {column.getIsSorted() === "desc" && (
        <ArrowDownIcon className="ml-2 h-4 w-4" />
      )}
    </div>
  );
}
