import { useTranslation } from "react-i18next";
import { PageWidth } from "../../components/containers/page-width";
import { FlexContainer } from "../../components/containers/flex-container";
import { PageHeadline } from "../../components/text/page-headline";
import React from "react";
import { DeviceEvents } from "./device-events";

export function Events() {
  const { t } = useTranslation();

  return (
    <PageWidth>
      <FlexContainer className={"mb-4"}>
        <PageHeadline>{t("notifications.title", "Notifications")}</PageHeadline>

        <PageHeadline>
          {t("notifications.deviceNotifications.title", "Devices with alerts")}
        </PageHeadline>
        <DeviceEvents />
      </FlexContainer>
    </PageWidth>
  );
}
