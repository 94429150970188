import { Text } from "./text";
import { AnimatedNumber } from "./animated-number";
import React from "react";

export function MetricStat({
  value,
  unit,
  label,
  decimals = 0,
}: {
  value?: number;
  unit: string;
  label: string;
  decimals?: number;
}) {
  return (
    <div className="flex h-full grow flex-col gap-1">
      <Text>{label}</Text>

      <div className="flex grow flex-col justify-between gap-1">
        {
          <AnimatedNumber
            className={"text-gray-900 md:text-xl lg:text-2xl"}
            unit={unit}
            value={value}
            decimalPoints={decimals}
          />
        }
      </div>
    </div>
  );
}
