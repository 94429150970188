import { XCircleIcon } from "@heroicons/react/24/outline";
import {
  noValidDeviceIdError,
  selectPairDevicesLoadingState,
} from "../devices-slice";
import React from "react";
import { useAppSelector } from "../../../app/store";
import { useTranslation } from "react-i18next";
import { DeviceIdInput } from "./device-id-input";

export function PairDeviceWidget({
  deviceIds,
  onSerialChanged,
}: {
  deviceIds: string[];
  onSerialChanged: (deviceIds: string[]) => void;
}) {
  const { t } = useTranslation();
  const pairDeviceLoadingState = useAppSelector(selectPairDevicesLoadingState);

  const onSerialChange = (index: number, serial: string): void => {
    const newDeviceSerials =
      deviceIds.map((t, i) => {
        if (i !== index) {
          return t;
        } else {
          return serial.toUpperCase().trim();
        }
      }) ?? [];

    onSerialChanged(newDeviceSerials);
  };

  return (
    <div>
      {deviceIds.map((value, index) => {
        return (
          <div key={index}>
            <DeviceIdInput
              index={index}
              onSerialChange={onSerialChange}
              serial={value}
            />
          </div>
        );
      })}

      <p className="mt-2 text-sm text-gray-700" id="id-description">
        {t(
          "device.pair.idHint",
          "you will find the serial on the device or in the packaging"
        )}
      </p>

      <p className="mt-2 text-sm italic text-gray-700">
        {t(
          "device.pair.qrCodeHint",
          "tip: you can also scan the qr-code with your smartphone camera"
        )}
      </p>

      {/*<div className='mt-4'>*/}
      {/*    <RoundActionButton onClick={addSerial} innerElement={<PlusIcon className='h5 w-5'/>}/>*/}
      {/*</div>*/}

      {pairDeviceLoadingState.status === "rejected" && (
        <div className="bg-red-50 mt-4 rounded-md p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon className="h-5 w-5 text-red" />
            </div>
            <div className="ml-3">
              <h3 className="text-red-800 text-sm">
                {t(
                  "device.pair.problem",
                  "There was a problem adding the device to your account - possible reasons could be"
                )}
              </h3>
              <div className="text-red-700 mt-2 text-sm">
                <ul className="list-disc space-y-1 pl-5">
                  {pairDeviceLoadingState.error === noValidDeviceIdError && (
                    <li>
                      {t(
                        "device.pair.reasonNoValidId",
                        "No valid device id was entered"
                      )}
                    </li>
                  )}

                  {pairDeviceLoadingState.error !== noValidDeviceIdError && (
                    <>
                      <li>
                        {t(
                          "device.pair.reasonPaired",
                          "The device is paired with another account"
                        )}
                      </li>
                      <li>
                        {t(
                          "device.pair.reasonNeverOn",
                          "The device was never powered on"
                        )}
                      </li>
                      <li>
                        {t(
                          "device.pair.reasonNoNetwork",
                          "The device was not able to establish a connection"
                        )}
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
