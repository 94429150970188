import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

// // Not exported properly from pubsub, but copied in here
export enum ConnectionState {
  /*
   * The connection is alive and healthy
   */
  Connected = "Connected",
  /*
   * The connection is alive, but the connection is offline
   */
  ConnectedPendingNetwork = "ConnectedPendingNetwork",
  /*
   * The connection has been disconnected while in use
   */
  ConnectionDisrupted = "ConnectionDisrupted",
  /*
   * The connection has been disconnected and the network is offline
   */
  ConnectionDisruptedPendingNetwork = "ConnectionDisruptedPendingNetwork",
  /*
   * The connection is in the process of connecting
   */
  Connecting = "Connecting",
  /*
   * The connection is not in use and is being disconnected
   */
  ConnectedPendingDisconnect = "ConnectedPendingDisconnect",
  /*
   * The connection is not in use and has been disconnected
   */
  Disconnected = "Disconnected",
  /*
   * The connection is alive, but a keep alive message has been missed
   */
  ConnectedPendingKeepAlive = "ConnectedPendingKeepAlive",
}

const initialState: {
  connectionState: ConnectionState;
} = {
  connectionState: ConnectionState.Disconnected,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setConnectionState(state, action: PayloadAction<ConnectionState>) {
      state.connectionState = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setConnectionState } = appSlice.actions;
export const reducer = appSlice.reducer;

export const selectConnectionState = (state: RootState) =>
  state.app.connectionState;
