import React, { HTMLAttributes } from "react";
import { useAppDispatch, useAppSelector } from "../app/store";
import {
  completeNewPassword,
  loginUser,
  selectAuthState,
  selectCurrentCognitoUser,
} from "./auth-slice";
import { LoginForm } from "./forms/login-form";
import { Link as RouterLink } from "react-router-dom";
import { GridknightLogo } from "../assets/gridknight-logo";
import { Card } from "../components/containers/card";
import { useTranslation } from "react-i18next";
import { CompleteNewPasswordForm } from "./forms/complete-new-password-form";
import { LanguageSelector } from "../components/language/language-selector";

export function Authentication(
  props: HTMLAttributes<HTMLDivElement> & {
    hideSignUp: boolean;
  }
) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const authState = useAppSelector((state) => selectAuthState(state));
  const cognitoUser = useAppSelector((state) =>
    selectCurrentCognitoUser(state)
  );

  const onLogin = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    dispatch(
      loginUser({
        email,
        password,
      })
    );
  };

  const onCompleteNewPassword = ({ password }: { password: string }) => {
    if (cognitoUser) {
      dispatch(
        completeNewPassword({
          user: cognitoUser,
          password: password,
        })
      );
    }
  };
  if (authState === "firstfetching") {
    return null;
  }

  if (authState === "loggedIn") {
    return <>{props.children}</>;
  } else {
    return (
      <div className="flex min-h-screen flex-col justify-center bg-gray-800 py-12 sm:px-6 lg:px-8">
        <div className="mt-8 p-2 sm:mx-auto sm:w-full sm:max-w-md">
          <RouterLink to={"/"}>
            <GridknightLogo className="mx-auto mb-4 mr-2 h-16 md:m-4" />
          </RouterLink>

          {authState === "completeNewPassword" && (
            <Card>
              <h1 className="my-4 text-center text-2xl font-bold text-gray-700">
                {t("auth.completeNewPassword.title", "Enter new password")}
              </h1>

              <CompleteNewPasswordForm
                onCompleteNewPassword={onCompleteNewPassword}
              />
            </Card>
          )}

          {!(authState === "completeNewPassword") && (
            <Card>
              <h1 className="my-4 text-center text-2xl font-bold text-gray-700">
                {t("auth.login.title", "Log in")}
              </h1>

              <LoginForm onLogin={onLogin} />

              <div className="mt-6 flex flex-col justify-center gap-1 text-center text-sm text-gray-600">
                <span>
                  <RouterLink to={"/reset"} className="text-gray-700 underline">
                    {t("auth.login.resetPassword", "Reset Password")}
                  </RouterLink>
                </span>
                {!props.hideSignUp && (
                  <>
                    <span>{t("auth.login.or", "or")} </span>
                    <span>
                      <RouterLink to={"/signup"} className="text-blue">
                        {t("auth.login.signupInstead", "create a new account")}
                      </RouterLink>
                    </span>
                  </>
                )}
              </div>
            </Card>
          )}

          <div className={"mt-8 text-center"}>
            <LanguageSelector />
          </div>
        </div>
      </div>
    );
  }
}
