import React, { Fragment, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link as RouterLink, useMatch, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  selectActiveEvents,
  selectEventsNotAcknowledged,
} from "../../devices/devices-slice";
import { GridknightLogo } from "../../../assets/gridknight-logo";
import { classNames } from "../../../app/util";
import { Can } from "../../../auth/user-ability-provider";
import { ViolationIndicator } from "./violation-indicator";
import { createAnalyticsParamsWithDefaultProperties } from "../../analytics/analytics-dashboard-page";
import { logoutUser } from "../../../auth/auth-slice";
import { LanguageSelector } from "../../../components/language/language-selector";
import {
  CustomerSupportHelpModalProps,
  CustomerSupportModal,
} from "../../tenants/support/customer-support-modal";

export type NavigationProps = {
  showNavigationLinks?: boolean;
  showUserLinks?: boolean;
  showNotifications?: boolean;
};

export default function Navigation({
  showNavigationLinks = true,
  showUserLinks = true,
  showNotifications = true,
}: NavigationProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const matcher = useMatch;

  const [openHelpModal, setOpenHelpModal] = useState(false);
  const [navOpen, setNavOpen] = React.useState<boolean>(false);

  const historicEvents = useAppSelector((state) =>
    selectEventsNotAcknowledged(state)
  ).length;

  const activeEvents = useAppSelector((state) =>
    selectActiveEvents(state)
  ).length;

  const totalEvents = historicEvents + activeEvents;

  const onOpenHelpModal = () => {
    setNavOpen(false);
    setOpenHelpModal(true);
  };

  const signOut = async () => {
    setNavOpen(false);
    dispatch(logoutUser());
    navigate("/", { replace: true });
  };

  const getRoute = ({
    route,
    title,
    includeChildRoutes,
  }: {
    route: string;
    title: string;
    includeChildRoutes: boolean;
  }): {
    route: string;
    title: string;
    isActive: boolean;
  } => {
    // See https://github.com/ReactTraining/react-router/issues/7133
    let basePath = route.includes("?") ? route.split("?")[0] : route;
    let pathCompare = `${basePath.toString()}`;
    pathCompare += includeChildRoutes ? "/*" : "";

    return {
      route: route,
      title: title,
      isActive: !!matcher({
        path: pathCompare,
      }),
    };
  };

  const analyticsDefaultParams = createAnalyticsParamsWithDefaultProperties(
    "tenant",
    "tenant"
  );

  const topNavigationItems: {
    route: string;
    title: string;
    isActive: boolean;
  }[] = [
    {
      route: "/",
      title: t("navigation.items.dashboard", "Dashboard"),
      includeChildRoutes: false,
    },
    {
      route: "/devices",
      title: t("navigation.items.devices", "Devices"),
      includeChildRoutes: true,
    },
    {
      route: "/tags",
      title: t("navigation.items.tags", "Groups"),
      includeChildRoutes: true,
    },
    {
      route: "/analytics" + analyticsDefaultParams,
      title: t("navigation.items.analytics", "Analytics"),
      includeChildRoutes: true,
    },
  ].map(getRoute);

  const userNavigationItems: {
    route: string;
    title: string;
    isActive: boolean;
  }[] = [
    {
      route: "/settings",
      title: t("navigation.items.settings", "Settings"),
      includeChildRoutes: true,
    },
  ].map(getRoute);

  const helpModalProps: CustomerSupportHelpModalProps = {
    open: openHelpModal,
    close: () => {
      setOpenHelpModal(false);
    },
  };

  return (
    <>
      <CustomerSupportModal {...helpModalProps} key="helpModal" />
      <Disclosure as="nav" className="bg-gray-800 print:hidden">
        <>
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex h-12 items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <RouterLink to="/">
                    <GridknightLogo className="mr-2 inline-block h-8 w-auto" />
                  </RouterLink>
                </div>

                <div className="hidden sm:ml-6 md:block">
                  <div className="flex space-x-4">
                    {(showNavigationLinks ? topNavigationItems : []).map(
                      (item) => (
                        <RouterLink
                          key={item.route}
                          title={item.title}
                          to={item.route}
                          className={`${
                            item.isActive ? "text-white" : "text-gray-300"
                          }  rounded-md px-3 py-2 text-sm hover:bg-gray-700 hover:text-white`}
                        >
                          {item.title}
                        </RouterLink>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="hidden md:ml-6 md:block">
                <div className="flex items-center">
                  <Can I="use" a="events">
                    {showNotifications && (
                      <RouterLink
                        to={"/notifications"}
                        className="relative mr-6 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none"
                      >
                        <span className="sr-only">
                          {t(
                            "navigation.actions.viewNotifications",
                            "View notifications"
                          )}
                        </span>

                        <ViolationIndicator totalEvents={totalEvents} />
                      </RouterLink>
                    )}
                  </Can>

                  <LanguageSelector />

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button className="flex text-sm text-gray-400 hover:text-white focus:outline-none">
                            <span className="sr-only">
                              {t(
                                "navigation.actions.openUserMenu",
                                "Open user menu"
                              )}
                            </span>
                            <Bars3Icon className="h-8 w-8" />
                          </Menu.Button>
                        </div>
                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                          >
                            {(showUserLinks ? userNavigationItems : []).map(
                              (item) => {
                                return (
                                  <Menu.Item key={item.route}>
                                    {({ active }) => (
                                      <RouterLink
                                        className={classNames(
                                          active ? "bg-gray-100" : "",
                                          "block px-4 py-2 text-sm text-gray-700"
                                        )}
                                        to={item.route}
                                      >
                                        {item.title}
                                      </RouterLink>
                                    )}
                                  </Menu.Item>
                                );
                              }
                            )}

                            <Menu.Item>
                              {({ active }) => (
                                <RouterLink
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                  to={"/docs"}
                                >
                                  {t(
                                    "navigation.items.documentation",
                                    "Documentation"
                                  )}
                                </RouterLink>
                              )}
                            </Menu.Item>

                            <Menu.Item>
                              {({ active }) => (
                                <div
                                  onClick={onOpenHelpModal}
                                  title={t(
                                    "navigation.items.support",
                                    "Open help"
                                  )}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block w-auto cursor-pointer px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  {t(
                                    "navigation.items.support",
                                    "Customer support"
                                  )}
                                </div>
                              )}
                            </Menu.Item>

                            <Menu.Item>
                              {({ active }) => (
                                <div
                                  onClick={signOut}
                                  title={t(
                                    "navigation.actions.signOut",
                                    "Sign out"
                                  )}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block w-auto cursor-pointer px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  {t("navigation.actions.signOut", "Sign out")}
                                </div>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
              </div>
              <div className="-mr-2 flex items-center justify-center gap-2 md:hidden">
                <Can I="use" a="events">
                  {showNotifications && (
                    <RouterLink
                      onClick={() => setNavOpen(false)}
                      to={"/notifications"}
                      className="relative mr-3 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none"
                    >
                      <span className="sr-only">
                        {t(
                          "navigation.actions.viewNotifications",
                          "View notifications"
                        )}
                      </span>

                      <ViolationIndicator totalEvents={totalEvents} />
                    </RouterLink>
                  )}
                </Can>

                <LanguageSelector />
                {/* Mobile menu button */}

                <button
                  onClick={() => setNavOpen(!navOpen)}
                  className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                >
                  <span className="sr-only">
                    {t("navigation.actions.openMainMenu", "Open main menu")}
                  </span>
                  {navOpen ? (
                    <XMarkIcon className="block h-8 w-8" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-8 w-8" aria-hidden="true" />
                  )}
                </button>
              </div>
            </div>
          </div>

          <Disclosure.Panel static={navOpen} className="md:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {topNavigationItems.map((item) => (
                <RouterLink
                  onClick={() => setNavOpen(false)}
                  key={item.route}
                  title={item.title}
                  to={item.route}
                  className={`${
                    item.isActive ? "bg-gray-900 text-white" : "text-gray-300"
                  } block rounded-md px-3 py-2 text-base hover:bg-gray-700 hover:text-white`}
                >
                  {item.title}
                </RouterLink>
              ))}
            </div>

            <div className="border-t border-gray-700 pb-3 pt-4">
              <div className="mt-1 flex flex-row justify-between space-y-0.5 px-2">
                <div>
                  <RouterLink
                    onClick={() => setNavOpen(false)}
                    className={classNames(
                      !!matcher("/docs/*")
                        ? "bg-gray-900 text-white"
                        : "text-gray-300",
                      "block rounded-md px-3 py-2 text-base hover:bg-gray-700 hover:text-white"
                    )}
                    to={"/docs"}
                  >
                    {t("navigation.items.documentation", "Documentation")}
                  </RouterLink>
                </div>
                <div
                  onClick={onOpenHelpModal}
                  className="block rounded-md px-3 py-2 text-base text-gray-300 hover:bg-gray-700 hover:text-white"
                >
                  {t("navigation.items.support", "customer support")}
                </div>
              </div>
            </div>

            <div className="border-t border-gray-700 pb-3 pt-4">
              <div className="mt-3 space-y-1 px-2">
                {userNavigationItems.map((item) => {
                  return (
                    <RouterLink
                      onClick={() => setNavOpen(false)}
                      key={item.route}
                      className={classNames(
                        item.isActive
                          ? "bg-gray-900 text-white"
                          : "text-gray-300",
                        "block rounded-md px-3 py-2 text-base hover:bg-gray-700 hover:text-white"
                      )}
                      to={item.route}
                    >
                      {item.title}
                    </RouterLink>
                  );
                })}

                <div
                  onClick={signOut}
                  title={t("navigation.actions.signOut", "Sign out")}
                  className="block cursor-pointer rounded-md px-3 py-2 text-base text-gray-400 hover:bg-gray-700 hover:text-white"
                >
                  {t("navigation.actions.signOut", "Sign out")}
                </div>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      </Disclosure>
    </>
  );
}
