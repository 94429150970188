import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { Application } from "@nantis/gridknight-core";
import { AtLeast, FetchStatus } from "../../models/types";
import { API, graphqlOperation } from "aws-amplify";
import { RootState } from "../../app/store";
import { isAfter, subSeconds } from "date-fns";
import { Entity } from "./analytics-data-loader-context";

export type DataSetQueryParams = {
  entity: Entity;
  properties: string[];
  timeRange: Application.TimeRange;
};

export type Dataset = DataSetQueryParams & {
  status: FetchStatus;
  lastUpdateTime: Date;
  data: Application.Analytics.TimeseriesDatum[];
};

/**
 * To build a working cache we need to normalize the data in our cache by building a unique key
 */
const keyDelimiter = "|";
const valueDelimiter = "-";

export const encodeDatasetKey = ({
  entity,
  properties = [],
  timeRange,
}: AtLeast<DataSetQueryParams, "entity">): string => {
  const scopeKey = `scope:${entity.scope}`;
  const entityKey = `id:${entity.id}`;
  const propertyKey = `props:${[...properties].sort().join(valueDelimiter)}`;
  const timeRangeKey = `timerange:${
    timeRange
      ? `${timeRange.range}${valueDelimiter}${timeRange.from}${valueDelimiter}${timeRange.to}`
      : ""
  }`;

  return [scopeKey, entityKey, propertyKey, timeRangeKey].join(keyDelimiter);
};

const splitLabelAndKey = (s: string): { label: string; value: string } => {
  const parts = s.split(":");
  if (parts && parts.length > 1) {
    return {
      label: parts[0],
      value: parts[1],
    };
  }
  return {
    label: "",
    value: "",
  };
};

const getTimeRangeFromString = (
  timeRangeString: string
): Application.TimeRange => {
  const [range, fromString, toString] = timeRangeString.split(valueDelimiter);

  const from = new Date(fromString);
  const to = new Date(toString);

  return {
    range: range as Application.TimeRange["range"],
    from,
    to,
    raw: { from, to },
  };
};

export const decodeDatasetKey = (
  datasetKey: string
): AtLeast<DataSetQueryParams, "entity"> => {
  const [scopeKey, idKey, propertyKey, timeRangeKey] =
    datasetKey.split(keyDelimiter);

  const scope = splitLabelAndKey(scopeKey).value;
  const id = splitLabelAndKey(idKey).value;
  const properties = propertyKey
    ? splitLabelAndKey(propertyKey).value.split(valueDelimiter)
    : [];
  const timeRange = timeRangeKey
    ? getTimeRangeFromString(splitLabelAndKey(timeRangeKey).value)
    : undefined;

  return {
    entity: {
      scope: scope as Application.Analytics.TimeseriesScope,
      id: id,
    },
    properties,
    timeRange,
  };
};

const analyticsEntityAdapter = createEntityAdapter<Dataset>({
  selectId: (dataset) => encodeDatasetKey(dataset),
  sortComparer: (a, b) =>
    b.timeRange.from
      .toISOString()
      .localeCompare(a.timeRange.from.toISOString()),
});

export const determineInterval = (
  interval: Application.TimeRange
): Application.Analytics.TimerangeInterval => {
  switch (interval.range) {
    case "day":
      return "quarterHour";
    case "month":
      return "day";
    case "year":
      return "month";
    case "custom":
      if (
        interval.to.getTime() - interval.from.getTime() >
        6 * 31 * 24 * 60 * 60 * 1000
      ) {
        return "month";
      } else if (
        interval.to.getTime() - interval.from.getTime() >
        2 * 24 * 60 * 60 * 1000
      ) {
        return "day";
      }
      return "quarterHour";
  }
};

export const fetchAnalyticsData = createAsyncThunk(
  "analytics/fetchData",
  async (
    fetchAnalyticsDataArgs: DataSetQueryParams & {
      limit?: number;
    }
  ) => {
    const {
      limit = 1000,
      entity,
      timeRange,
      properties,
    } = fetchAnalyticsDataArgs;

    const queryProperties = [...properties, "time"];

    const params: Application.Analytics.TimeseriesQueryInput = {
      limit,
      scope: entity.scope,
      entity: entity.id,
      timerange: {
        from: timeRange.from.toISOString(),
        to: timeRange.to.toISOString(),
        interval: determineInterval(timeRange),
      },
    };

    const result = (await API.graphql(
      graphqlOperation(
        `
           query AnalyticsQuery($scope: AnalyticsScope, $entity: String, $timerange: Timerange, $limit: Int) {
              analyticsTimeseriesData(limit: $limit, timerange: $timerange, scope: $scope, entity: $entity) {
                data { ${queryProperties.join(" ")} }
              }
            }
           `,
        params
      )
    )) as {
      data: {
        analyticsTimeseriesData: {
          data: Application.Analytics.TimeseriesDatum[];
        };
      };
    };

    return result.data.analyticsTimeseriesData.data.map(
      (datum: Application.Analytics.TimeseriesDatum) => {
        return {
          ...datum,
          time: new Date(datum.time),
          er_t_d_cost:
            datum.er_t_d_cost != null ? BigInt(datum.er_t_d_cost) : null,
          ea_fwd_t_d_cost:
            datum.ea_fwd_t_d_cost != null
              ? BigInt(datum.ea_fwd_t_d_cost)
              : null,
        };
      }
    );
  }
);

// This won't work if we use the function multiple time in a series
const initialState = analyticsEntityAdapter.getInitialState();

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAnalyticsData.pending, (state, action) => {
      const { meta } = action;
      const { arg } = meta;
      const { entity, properties, timeRange } = arg;
      analyticsEntityAdapter.upsertOne(state, {
        entity,
        properties,
        timeRange,
        data: [],
        status: {
          status: "pending",
          error: null,
        },
        lastUpdateTime: new Date(),
      });
    });

    builder.addCase(fetchAnalyticsData.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const { arg } = meta;
      const { entity, properties, timeRange } = arg;
      analyticsEntityAdapter.upsertOne(state, {
        entity: entity,
        properties,
        timeRange,
        data: payload,
        status: {
          status: "fulfilled",
          error: null,
        },
        lastUpdateTime: new Date(),
      });
    });

    builder.addCase(fetchAnalyticsData.rejected, (state, action) => {
      const { meta, error } = action;
      const { arg } = meta;
      const { entity, properties, timeRange } = arg;
      analyticsEntityAdapter.upsertOne(state, {
        entity,
        properties,
        timeRange,
        data: [],
        status: {
          status: "rejected",
          error: error?.name ?? "",
        },
        lastUpdateTime: new Date(),
      });
    });
  },
});

export const reducer = analyticsSlice.reducer;

export const {
  selectAll: selectAllAnalytics,
  selectById: selectAnalyticsByDatum,
} = analyticsEntityAdapter.getSelectors<RootState>((state) => state.analytics);

export const selectAnalyticsByQueryParams = createSelector(
  [
    selectAllAnalytics,
    (state: RootState, datasetQueryParams: DataSetQueryParams[]) =>
      datasetQueryParams,
  ],
  (analytics, dataSetKeys) =>
    analytics.filter((analytics) =>
      dataSetKeys
        .map((d) => encodeDatasetKey(d))
        .includes(encodeDatasetKey(analytics))
    )
);

/**
 * The reloadDatasetsAfter parameter is to debounce a dataset
 */
export const selectAnalyticsMissingQueries = createSelector(
  [
    selectAllAnalytics,
    (
      state: RootState,
      datasetQueryParams: DataSetQueryParams[],
      now = new Date(),
      reloadDatasetsAfterSeconds = 60
    ) => {
      return { datasetQueryParams, now, reloadDatasetsAfterSeconds };
    },
  ],
  (analytics, params) =>
    params.datasetQueryParams.filter((dsp) => {
      const ds = analytics.find(
        (a) => encodeDatasetKey(a) === encodeDatasetKey(dsp)
      );
      if (ds) {
        // Avoid reloading currently pending data
        if (ds.status.status !== "fulfilled") {
          return false;
        }
        // If the end of the selected dataset is in the future we might want to reload the data
        if (isAfter(ds.timeRange.to, params.now)) {
          // Check if we want to reload because the data might be old

          const reload = isAfter(
            subSeconds(params.now, params.reloadDatasetsAfterSeconds),
            ds.lastUpdateTime
          );
          //console.log("reload", reload, new Date());
          return reload;
        } else {
          return false;
        }
      } else {
        return true;
      }
    })
);
