import { useAppDispatch, useAppSelector } from "../app/store";
import { selectCurrentUserCognitoProps } from "./auth-slice";
import { fetchTenant, selectTenant } from "../features/tenants/tenants-slice";
import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
} from "react";
import defineAbilityFor, { AppAbility } from "./user-ability";
import { createContextualCan } from "@casl/react";

// See https://casl.js.org/v5/en/package/casl-react
export const AbilityContext = createContext<AppAbility>(defineAbilityFor());
export const Can = createContextualCan(AbilityContext.Consumer);

export const useUserAbility = () => {
  return React.useContext(AbilityContext);
};

export function UserAbilityProvider(props: PropsWithChildren<{}>) {
  const user = useAppSelector(selectCurrentUserCognitoProps);
  const tenant = useAppSelector(selectTenant);

  const ability = useMemo(() => {
    return defineAbilityFor(user, tenant);
  }, [user, tenant]);

  // There might be a lot of rerenders, so memoize the ability
  return (
    <AbilityContext.Provider value={ability}>
      {tenant && ability && props.children}
    </AbilityContext.Provider>
  );
}
