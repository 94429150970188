import React, { useState } from "react";
import { TariffForm } from "./tariff-form";
import { Card } from "../../components/containers/card";
import { PageHeadline } from "../../components/text/page-headline";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Application,
  calculateEnergyCostRateForBaseCurrencyUnitPerKiloWattHour,
} from "@nantis/gridknight-core";
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
  createTariffWithRate,
  selectCreateTariffWithRateLoadingState,
} from "./tariff-slice";
import { selectTenantCurrency } from "../tenants/tenants-slice";
import { currencySymbols } from "../../app/util";
import { EnergyPriceInputField } from "../../components/inputs/energy-price-input-field";
import { LoadingButton } from "../../components/ng";

export function TariffCreatePage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const createTariffState = useAppSelector((state) =>
    selectCreateTariffWithRateLoadingState(state)
  );
  const currency = useAppSelector((state) => selectTenantCurrency(state));
  const currencySymbol = currencySymbols[currency];

  const rateActive: Application.Rate =
    calculateEnergyCostRateForBaseCurrencyUnitPerKiloWattHour(0);

  const [activeRate, setActiveRate] = useState<Application.Rate>(rateActive);

  const onChangeActiveEnergyPrice = (rate: number) => {
    const costsPerKWh =
      calculateEnergyCostRateForBaseCurrencyUnitPerKiloWattHour(rate);

    setActiveRate({
      ...costsPerKWh,
    });
  };

  const onSave = async (tariff: Pick<Application.Tariff, "name">) => {
    dispatch(
      createTariffWithRate({
        name: tariff.name,
        hourRate: {
          start: 0,
          metrics: {
            ea_fwd_t_d: activeRate,
          },
        },
      })
    ).then(() => {
      navigate({
        pathname: "../..",
      });
    });
  };

  return (
    <div>
      <PageHeadline>
        {t("tariffs.create.title", "Create energy tariff")}
      </PageHeadline>

      <div className={"mb-4 text-sm"}>
        <RouterLink to={"../.."}>
          <span aria-hidden="true">&larr;</span>{" "}
          {t("tariffs.create.backToTariffs", "back to tags")}
        </RouterLink>
      </div>

      <Card>
        <TariffForm onSave={onSave}>
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
            <label
              htmlFor="activeEnergyPrice"
              className="block text-sm text-gray-700"
            >
              {t("tariffRates.activeEnergyPrice", "Active Energy price")}
            </label>

            <div>
              <EnergyPriceInputField
                rate={activeRate}
                onChange={onChangeActiveEnergyPrice}
                currencySymbol={currencySymbol}
                unit={"kWh"}
              />

              <p className="mt-2 text-sm text-gray-500">
                {t(
                  "tariffRates.create.hint6Digits",
                  "you can enter the tariff rate with up to 6 digits after the decimal point"
                )}
              </p>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <LoadingButton
                type="submit"
                variant={"success"}
                isLoading={createTariffState.status === "pending"}
              >
                {t("tariffs.create.save", "save energy tariff")}
              </LoadingButton>
            </div>
          </div>
        </TariffForm>
      </Card>
    </div>
  );
}
