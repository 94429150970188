import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import {
  PlusIcon,
  ArrowPathIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { Application } from "@nantis/gridknight-core";
import { Can } from "../../../auth/user-ability-provider";
import {
  inviteUser,
  selectInviteUserLoadingState,
  removeUser,
  selectRemoveUserLoadingState,
  resendUserInvitation,
  selectResendUserInvitationState,
  setInviteUserName,
  selectUpdateUserRoleLoadingState,
  updateUserRole,
} from "../users/users-slice";
import { useAppDispatch, useAppSelector } from "../../../app/store";

export function TenantUserList({
  users,
  currentUser,
}: {
  users: Application.User[];
  currentUser: Application.User;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const resendInvitationLoadingState = useAppSelector(
    selectResendUserInvitationState
  );
  const inviteUserLoadingState = useAppSelector(selectInviteUserLoadingState);
  const removeUserLoadingState = useAppSelector(selectRemoveUserLoadingState);
  const updateUserLoadingState = useAppSelector(
    selectUpdateUserRoleLoadingState
  );

  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setInviteUserName(e.target.value));
  };

  const onInviteUser = (email: string) => {
    if (inviteUserLoadingState.status !== "pending") {
      dispatch(inviteUser(email));
    }
  };

  const onResendInvitation = (user: Application.User) => {
    if (resendInvitationLoadingState.status !== "pending") {
      dispatch(resendUserInvitation(user.id));
    }
  };

  const onRemoveUser = (user: Application.User) => {
    if (removeUserLoadingState.status !== "pending") {
      dispatch(removeUser(user.id));
    }
  };

  const handleChangeRole = (
    e: ChangeEvent<HTMLSelectElement>,
    user: Application.User
  ) => {
    if (updateUserLoadingState.status !== "pending") {
      dispatch(
        updateUserRole({
          ...user,
          role: e.target.value,
        })
      );
    }
  };

  return (
    <div>
      <Can I="invite" a="user">
        <div className="mb-6 flex flex-wrap items-end gap-y-4 gap-x-4">
          <div className="flex flex-grow flex-col space-y-2">
            <label
              htmlFor="add-team-members"
              className="block text-sm text-gray-700"
            >
              {t("users.inviteUserByEmail", "Invite user by email")}
            </label>

            <p id="add-team-members-helper" className="sr-only">
              {t("users.inviteUserByEmail", "Invite user by email address")}
            </p>

            <div className="flex-grow">
              <input
                onChange={onChangeEmail}
                disabled={inviteUserLoadingState.status === "pending"}
                value={inviteUserLoadingState.email}
                type="text"
                name="add-team-members"
                id="add-team-members"
                className="block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                placeholder={t("users.emailAddress", "Email address")}
                aria-describedby="add-team-members-helper"
              />

              {inviteUserLoadingState.status === "rejected" && (
                <div className="mt-4 rounded-md p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <XCircleIcon className="h-5 w-5 text-red" />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm text-red">
                        {t(
                          "users.errorInvitingUser",
                          "There was an error adding the user to this account. Possible reasons might be:"
                        )}
                      </h3>
                      <div className="mt-2 text-sm text-red">
                        <ul className="list-disc space-y-1 pl-5">
                          {inviteUserLoadingState.error && (
                            <>
                              <li>
                                {t(
                                  "users.reasonUsersExists",
                                  "The user already exists or is in another account"
                                )}
                              </li>
                              <li>
                                {t(
                                  "users.reasonEmailWrong",
                                  "The entered email address is not correct"
                                )}
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <button
            onClick={() => onInviteUser(inviteUserLoadingState.email ?? "")}
            disabled={inviteUserLoadingState.status === "pending"}
            type="button"
            className="focus:ring-sky-500 inline-flex h-10 flex-none items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2"
          >
            {inviteUserLoadingState.status === "pending" ? (
              <ArrowPathIcon
                className="-ml-2 mr-1 h-5 w-5 animate-spin text-gray-400"
                aria-hidden="true"
              />
            ) : (
              <PlusIcon
                className="-ml-2 mr-1 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            )}

            <span>{t("users.inviteUser", "Add user")}</span>
          </button>
        </div>
      </Can>

      <div>
        <ul className="divide-y divide-gray-200">
          {users
            .filter((user) => user.id !== currentUser.id)
            .map((user) => (
              <li
                key={user.email}
                className="grid grid-cols-1 justify-items-center gap-2 py-2 sm:grid-cols-2 lg:grid-cols-4"
              >
                <div className="mb-2 flex flex-row content-center gap-2 lg:justify-self-start">
                  <div className="flex flex-col content-center">
                    <span className="text-sm text-gray-900">
                      {user.name ?? ""}
                    </span>
                    <span className="text-sm text-gray-500">{user.email}</span>
                  </div>
                </div>

                <div>
                  <Can I="update" a="user">
                    <select
                      className="focus:ring-sky-500 focus:border-sky-500 block rounded-md border-gray-300 shadow-sm sm:text-sm"
                      value={user.role}
                      onChange={(event) => {
                        handleChangeRole(event, user);
                      }}
                    >
                      <option value="admin">
                        {t("users.roles.admin", "admin")}
                      </option>
                      <option value="user">
                        {t("users.roles.user", "user")}
                      </option>
                    </select>
                  </Can>

                  <Can not I="update" a="user">
                    <span className="text-sm text-gray-500">
                      {t(`users.roles.${user.role}`, "admin")}
                    </span>
                  </Can>
                </div>

                <div className="flex flex-col">
                  {user.last_login_at && (
                    <span className="text-sm text-gray-500">
                      {t(
                        "users.lastLogin",
                        "{{date, date(hour: 2-digit; minute: 2-digit)}}",
                        { date: new Date(user.last_login_at) }
                      )}
                    </span>
                  )}

                  {!user.last_login_at && (
                    <Can I="resendInvitation" a="user">
                      {!user.active && (
                        <button
                          onClick={() => onResendInvitation(user)}
                          disabled={
                            resendInvitationLoadingState.status === "pending"
                          }
                          type="button"
                          className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm text-gray-700 shadow-sm hover:bg-gray-50 disabled:text-gray-200"
                        >
                          {resendInvitationLoadingState.status === "pending" &&
                          user.id === resendInvitationLoadingState.user_id ? (
                            <ArrowPathIcon
                              className="-ml-2 mr-1 h-5 w-5 animate-spin text-gray-400"
                              aria-hidden="true"
                            />
                          ) : (
                            <ArrowPathIcon
                              className="-ml-2 mr-1 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          )}

                          <span>
                            {t("users.resendInvitation", "Resend invitation")}
                          </span>
                        </button>
                      )}
                    </Can>
                  )}
                </div>

                <div className="lg:justify-self-end">
                  <Can I="remove" a="user">
                    <button
                      onClick={() => onRemoveUser(user)}
                      disabled={removeUserLoadingState.status === "pending"}
                      type="button"
                      className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm text-gray-700 shadow-sm hover:bg-gray-50"
                    >
                      {removeUserLoadingState.status === "pending" &&
                      user.id === removeUserLoadingState.user_id ? (
                        <ArrowPathIcon
                          className="-ml-2 mr-1 h-5 w-5 animate-spin text-gray-400"
                          aria-hidden="true"
                        />
                      ) : (
                        <TrashIcon
                          className="-ml-2 mr-1 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      )}

                      <span>{t("users.removeUser", "Remove user")}</span>
                    </button>
                  </Can>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
