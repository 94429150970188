import React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/store";
import { selectCompleteNewUserPasswordState } from "../auth-slice";
import { LoadingButton } from "../../components/ng";

export type CompleteNewPasswordFormData = {
  password: string;
};

export type CompleteNewPasswordFormProps = {
  onCompleteNewPassword: ({ password }: CompleteNewPasswordFormData) => void;
};

const schema = yup.object().shape({
  password: yup.string().required(),
});

export function CompleteNewPasswordForm({
  onCompleteNewPassword,
}: CompleteNewPasswordFormProps) {
  const { t } = useTranslation();

  const { register, handleSubmit } = useForm<CompleteNewPasswordFormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit((data: CompleteNewPasswordFormData) => {
    onCompleteNewPassword({
      password: data.password,
    });
  });

  const completeNewPasswordState = useAppSelector((state) =>
    selectCompleteNewUserPasswordState(state)
  );

  return (
    <form onSubmit={onSubmit} className="space-y-6" action="#">
      <div>
        <label htmlFor="password" className="block text-sm text-gray-700">
          {t("auth.completeNewPassword.password", "New Password")}
        </label>
        <div className="mt-1">
          <input
            id="password"
            type="password"
            minLength={8}
            autoComplete="new-password"
            required
            {...register("password")}
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
          />
        </div>
      </div>

      <div className="text-center text-sm">
        <p className="text-gray-700">
          {t(
            "auth.completeNewPassword.passwordHint",
            "Please enter a new password to replace the temporary password."
          )}
        </p>
      </div>

      {completeNewPasswordState.status === "rejected" &&
        completeNewPasswordState.error === "passwordInvalid" && (
          <div className="text-center text-sm">
            <p className="text-red">
              {t(
                "auth.completeNewPassword.errors.invalidPassword",
                "Too many requests to reset the password. Please try again at a later time"
              )}
            </p>
          </div>
        )}

      <LoadingButton
        isLoading={completeNewPasswordState.status === "pending"}
        type="submit"
        variant="accent"
        className="w-full justify-center text-sm"
      >
        {t("auth.completeNewPassword.completeNewPassword", "Save new password")}
      </LoadingButton>
    </form>
  );
}
