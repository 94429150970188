import React, {ChangeEvent} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../app/store";
import {
    fetchDeviceRulesLoadingState,
    selectDeviceRulesByDeviceId,
    setDeviceRules,
    setDeviceRulesLoadingState,
    DeviceRuleAdded,
    deviceRuleRemove,
    deviceRuleUpdate,
} from "../../devices-slice";
import {PlusIcon, TrashIcon, EnvelopeIcon, QuestionMarkCircleIcon, FlagIcon} from "@heroicons/react/24/solid";
import {ElectricMetricKey, getMetricHelper} from "@nantis/gridknight-core";
import {Application} from "@nantis/gridknight-core";
import cuid from "cuid";
import {LoadingButton} from "../../../../components/ng";
import {classNames} from "../../../../app/util";
import {InfoIcon} from "../../../../components/help/info-icon";
import {LoadingSpinner} from "../../../../components/loading/loading-spinner";

export const availableTypes: Application.RuleType[] = [
    "de",
    "dt"
]

export const availableProperties: ElectricMetricKey[] = [
    "tplug",
    "urms_1",
    "urms_2",
    "urms_3",
    "irms_1",
    "irms_2",
    "irms_3",
    "irms_n",
    "ic",
    "p_t",
];

export const availableComparators: Application.ValueThresholdComparator[] = [
    "gt",
    "lt",
];

function DeviceRule({
                        threshold,
                        onChange,
                        onRemove,
                    }: {
    threshold: Application.DeviceRule<Application.RuleType>;
    onChange: (threshold: Application.DeviceRule<Application.RuleType>) => void;
    onRemove: (threshold: Application.DeviceRule<Application.RuleType>) => void;
}) {
    const {t} = useTranslation();

    const onChangeType = (e: ChangeEvent<HTMLSelectElement>) => {
        const type = e.target.value as Application.RuleType;

      const property = availableProperties[0];
      const mh = getMetricHelper(property);

        const data = (() => {
          switch (type) {
            case "de": return {
              type: "offline",
                t: 10 * 60
            }
            case "dt": return {
              prop: property,
              comp: "gt",
              lim: mh.range.nominal,
              dt: 0,
            }
          }
        })()

        onChange({
          ...threshold,
          type: type,
          data: data as any,
        });
    }

    const ruleComponent = (() => {
        switch (threshold.type) {
            case "dt":
                return <DeviceDatumRuleSettings threshold={threshold as Application.DeviceRule<"dt">}
                                                onChange={onChange}/>;
            case "de":
                return <DeviceEventRuleSettings threshold={threshold as Application.DeviceRule<"de">}
                                                onChange={onChange}/>;
        }
    })()

    const removeThreshold = () => {
        onRemove(threshold);
    };

    return (
        <div className="flex items-center justify-between rounded-lg border border-gray-200 p-2 text-gray-900 md:px-4">

            <div>
                <ul role="list" className="-mb-8">
                    <li>
                        <div className="relative pb-6">
                            <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"/>

                          <div className="relative flex items-center space-x-3">
                            <div>
                  <span
                      className={classNames(
                          'bg-blue flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white'
                      )}
                  >
                    <FlagIcon className="h-5 w-5 text-white" aria-hidden="true"/>
                  </span>
                            </div>
                            <div className="flex items-center gap-2 w-full p-2 md:px-4">
                                {t('device.events.settings.if', 'if') }

                              <label htmlFor="type" className="sr-only text-sm text-gray-700">
                                {t('device.events.settings.thresholdType', 'type of event rule')}
                              </label>

                              <select
                                  onChange={onChangeType}
                                  value={threshold.type.toLowerCase()}
                                  id="type"
                                  name="type"
                                  className="mt-1  block rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green focus:outline-none sm:text-sm"
                              >
                                {availableTypes.map((value) => {
                                  return (
                                      <option value={value} key={value}>
                                        {t(`device.events.settings.ruleTypes.${value.toLowerCase()}`, value)}
                                      </option>
                                  );
                                })}
                              </select>
                            </div>

                          </div>
                        </div>
                    </li>

                  <li>
                    <div className="relative pb-6">
                      <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"/>

                      <div className="relative flex items-center space-x-3">
                        <div>
                  <span
                      className={classNames(
                          'bg-blue flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white'
                      )}
                  >
                    <QuestionMarkCircleIcon className="h-5 w-5 text-white" aria-hidden="true"/>
                  </span>
                        </div>
                          {ruleComponent}
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="relative pb-6">

                      <div className="relative flex space-x-3">
                        <div className={"h-full flex items-center "}>
                  <span
                      className={classNames(
                          'bg-blue flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white'
                      )}
                  >
                    <EnvelopeIcon className="h-5 w-5 text-white" aria-hidden="true"/>
                  </span>
                                </div>
                                <div className="flex min-w-0 flex-1 justify-between p-2 md:px-4">
<span>{t('device.events.settings.sendANotification', 'send a message by e-mail or push notification (depending on your notification settings)')}</span>
                                </div>

                            </div>
                        </div>
                    </li>
                </ul>
            </div>


            <div
                title={t(
                    "device.events.settings.removeThreshold",
                    "remove the threshold"
                )}
                className="self-start ml-4 rounded-full p-2 bg-red text-white cursor-pointer align-middle flex justify-start"
            >
                <TrashIcon onClick={removeThreshold} className="h-5 w-5"/>
            </div>
        </div>

    );
}

export function DeviceEventRuleSettings({threshold, onChange} : {
    threshold: Application.DeviceRule<"de">;
    onChange: (threshold: Application.DeviceRule<any>) => void;
}) {
    const {t} = useTranslation();

    const onChangeTime = (e: ChangeEvent<HTMLSelectElement>) => {
        onChange({
            ...threshold,
            data: {
                ...threshold.data,
                t: parseInt(e.target.value),
            },
        });
    };

    return (
        <div className={"flex flex-wrap gap-2 md:px-4 p-2"}>
            <div className="flex items-center justify-between  text-gray-900 ">
                {t("device.events.settings.isOfflinePrefix", "is offline for more than")}
            </div>

            <div>
                <label htmlFor="delay" className="sr-only text-sm text-gray-700">
                    {t("device.events.settings.thresholdMetric", "metric")}
                </label>
                <select
                    onChange={onChangeTime}
                    value={threshold.data.t}
                    id="delay"
                    name="delay"
                    className="mt-1  block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green focus:outline-none sm:text-sm"
                >

                    <option value={10 * 60}>
                        {t("device.events.settings.offlineDurations.tenMinutes", "10 minutes")}
                    </option>
                    <option value={30 * 60}>
                        {t("device.events.settings.offlineDurations.thirtyMinutes", "30 minutes")}
                    </option>
                    <option value={60 * 60}>
                        {t("device.events.settings.offlineDurations.sixtyMinutes", "60 minutes")}
                    </option>
                    <option value={24 * 60 * 60}>
                        {t("device.events.settings.offlineDurations.twentyFourHours", "24 hours")}
                    </option>
                </select>
            </div>

            <div className="flex items-center justify-between  text-gray-900 ">
                {t("device.events.settings.isOfflineSuffix", "")}
            </div>

            <InfoIcon>
                {t('device.events.settings.offlineHint', 'If the plug was connected and then loses the connection, this rule is triggered. Reasons for a loss of connection can be: Unplugging, power failure, but also connection problems.')}
            </InfoIcon>
        </div>
    )
}

export function DeviceDatumRuleSettings({
                                            threshold,
                                            onChange,
                                        }: {
    threshold: Application.DeviceRule<"dt">;
    onChange: (threshold: Application.DeviceRule<any>) => void;
}) {
    const {t} = useTranslation();

    const mh = getMetricHelper(threshold.data.prop as ElectricMetricKey);

    const thresholdData = threshold.data;

    const onChangeProperty = (e: ChangeEvent<HTMLSelectElement>) => {
        const newProperty = e.target.value as ElectricMetricKey;
        const mh = getMetricHelper(newProperty);

        onChange({
            ...threshold,
            data: {
                ...threshold.data,
                prop: e.target.value,
                lim: mh.range.nominal,
            },
        });
    };


    const onChangeComparator = (e: ChangeEvent<HTMLSelectElement>) => {
        onChange({
            ...threshold,
            data: {
                ...threshold.data,
                comp: e.target.value as Application.ValueThresholdComparator,
            },
        });
    };

    const onChangeLimit = (e: ChangeEvent<HTMLInputElement>) => {
        onChange({
            ...threshold,
            data: {
                ...threshold.data,
                lim: parseFloat(e.target.value),
            },
        });
    };

    const onChangeDeadtime = (e: ChangeEvent<HTMLInputElement>) => {
        onChange({
            ...threshold,
            data: {
                ...threshold.data,
                dt: parseInt(e.target.value),
            },
        });
    };

    return (
        <div className="flex flex-wrap items-center gap-2 md:justify-between p-2 text-gray-900 md:px-4">

        <span>
          {t("device.events.settings.thresholdPrefix", "of")}
        </span>

                <div>
                    <label htmlFor="location" className="sr-only text-sm text-gray-700">
                        {t("device.events.settings.thresholdMetric", "metric")}
                    </label>
                    <select
                        onChange={onChangeProperty}
                        value={thresholdData.prop.toLowerCase()}
                        id="location"
                        name="location"
                        className="mt-1  block rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green focus:outline-none sm:text-sm"
                    >
                        {availableProperties.map((value) => {
                            return (
                                <option value={value} key={value}>
                                    {t(`datum:metrics.${value.toLowerCase()}.label`, value)}
                                </option>
                            );
                        })}

                        {!availableProperties.find(
                            (element) =>
                                element.toLowerCase() === thresholdData.prop.toLowerCase()
                        ) && (
                            <option key={thresholdData.prop}>
                                {t(
                                    `datum:metrics.${thresholdData.prop.toLowerCase()}.label`,
                                    thresholdData.prop
                                )}
                            </option>
                        )}
                    </select>
                </div>

                <div>
                    <span>{t("device.events.settings.for", "for")}</span>
                </div>

                <div>
                    <label htmlFor="deadtime" className="sr-only text-sm text-gray-700">
                        {t("device.events.settings.deadtime", "deadtime")}
                    </label>
                    <input
                        value={thresholdData.dt}
                        onChange={onChangeDeadtime}
                        type="number"
                        id="deadtime"
                        name="deadtime"
                        className="mt-1 mr-1 w-24 block rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green focus:outline-none sm:text-sm"
                    />
                </div>

                <span>{t("device.events.settings.seconds", "seconds")}</span>

                <div>
                    <label htmlFor="comparator" className="sr-only text-sm text-gray-700">
                        {t("device.events.settings.comparator", "comparator")}
                    </label>
                    <select
                        onChange={onChangeComparator}
                        value={thresholdData.comp}
                        id="comparator"
                        name="comparator"
                        className="mt-1 block rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green focus:outline-none sm:text-sm"
                    >
                        {availableComparators.map((value) => {
                            return (
                                <option value={value} key={value}>
                                    {t(`device.events.comparators.${value}`, value)}
                                </option>
                            );
                        })}
                    </select>
                </div>

            <div className={"flex flex-row gap-1 items-center"}>
                <label htmlFor="limit" className="sr-only text-sm text-gray-700">
                    {t("device.events.settings.limit", "limit")}
                </label>
                <input
                    value={thresholdData.lim}
                    onChange={onChangeLimit}
                    type="number"
                    id="limit"
                    name="limit"
                    className="mt-1 block w-28 rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green focus:outline-none sm:text-sm"
                />

                <span>{mh.unit.name}</span>
            </div>


            <div className="m-2 inline-block">
                    <span>{t("device.events.settings.is", "")}</span>
                </div>

        </div>
    )
}

export function DeviceRuleSettings({
                                       device_id,
                                       maxRules = 5,
                                   }: {
    device_id: string;
    maxRules?: number;
}) {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const rules = useAppSelector((state) =>
        selectDeviceRulesByDeviceId(state, device_id)
    );
    const setDeviceRulesState = useAppSelector((state) =>
        setDeviceRulesLoadingState(state)
    );
    const fetchDeviceRulesState = useAppSelector((state) =>
        fetchDeviceRulesLoadingState(state)
    );

    const onAddRule = () => {
        const property = availableProperties[0];
        const mh = getMetricHelper(property);

        dispatch(
            DeviceRuleAdded({
                device_id: device_id,
                id: cuid(),
                type: "dt",
                data: {
                    prop: property,
                    comp: "gt",
                    lim: mh.range.nominal,
                    dt: 0,
                },
            } as Application.DeviceRule<"dt">)
        );
    };

    const onRemoveRule = (rule: Application.DeviceRule<any>): void => {
        dispatch(deviceRuleRemove(rule.id));
    };

    const onRuleChange = (rule: Application.DeviceRule<any>): void => {
        dispatch(deviceRuleUpdate(rule));
    };

    const onSaveRules = async () => {
        if (rules) {
            await dispatch(
                setDeviceRules({
                    device_id: device_id,
                    rules,
                })
            );
        }
    };

    if (fetchDeviceRulesState.status === "pending") {
        return (
            <div
                className={
                    "flex h-full w-full items-center justify-center text-lg text-gray-700"
                }
            >
                <LoadingSpinner/>
                {t("device.events.settings.loadingData", "Loading rules")}
            </div>
        );
    }

    return (
        <div>
            {fetchDeviceRulesState.status === "fulfilled" && (
                <div>
                    <div>
                        {rules?.length !== undefined && (
                            <div className="">
                                <p>
                                    {t(
                                        "device.events.settings.sendAlerts",
                                        "Receive notifications by e-mail or push notification for certain events"
                                    )}
                                </p>

                                {rules.map((threshold) => {
                                    return (
                                        <div className="mt-5" key={threshold.id}>
                                            <DeviceRule
                                                onRemove={onRemoveRule}
                                                onChange={onRuleChange}
                                                threshold={threshold}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        )}

                        <div className={"flex flex-col gap-2 items-center justify-center"}>
                            {rules?.length !== undefined && rules?.length < maxRules && (
                                <>
                                    <button
                                        onClick={onAddRule}
                                        title={t(
                                            "device.events.settings.addThreshold",
                                            "add a new threshold"
                                        )}
                                        type="button"
                                        className="mt-5 inline-flex items-center rounded-full border border-transparent bg-blue p-2 text-white shadow-sm focus:outline-none md:ml-4"
                                    >
                                        <PlusIcon className="h-5 w-5"/>
                                    </button>
                                    <span className={"text-sm"}>{t(
                                        "device.events.settings.addThreshold",
                                        "add a new threshold"
                                    )}</span>
                                </>
                            )}</div>
                    </div>

                    <div className="mt-3 text-right">
                        <LoadingButton
                            onClick={onSaveRules}
                            isLoading={setDeviceRulesState.status === "pending"}
                            form="deviveSettingsForm"
                            variant="success"
                            type="button"
                        >
                            {t("device.events.settings.save", "save rule")}
                        </LoadingButton>
                    </div>
                </div>
            )}
        </div>
    );
}
