import { useAppSelector } from "../../app/store";
import { selectDeviceIdsByTagIds } from "./tag-on-device-slice";
import { selectDevicesByDeviceIds } from "../devices/devices-slice";
import { Card } from "../../components/containers/card";
import React from "react";
import { useTranslation } from "react-i18next";
import { PageHeadline } from "../../components/text/page-headline";
import { Button, ButtonGroup } from "../../components/buttons/button-group";
import { Link as RouterLink } from "react-router-dom";
import { AnalyticsIcon } from "../../assets/icons";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { createAnalyticsParamsWithDefaultProperties } from "../analytics/analytics-dashboard-page";
import { selectTagById } from "./tags-slice";
import { TagBadge } from "./tagBadge";
import { Can } from "../../auth/user-ability-provider";
import {
  AnalyticsDataLoaderContextProvider,
  Entity,
} from "../analytics/analytics-data-loader-context";
import { FlexContainer } from "../../components/containers/flex-container";
import { TagHistoricData } from "./tag-historic-data";
import { PageWidth } from "../../components/containers/page-width";
import { MetricKey } from "@nantis/gridknight-core";
import { ParentSize } from "@visx/responsive";
import { DevicesTable } from "../devices/table/devices-table";

export function TagDetailPage({ id }: { id: string }) {
  const { t } = useTranslation();

  const entities: Entity[] = [
    {
      scope: "tag",
      id: id,
    },
  ];

  const properties: MetricKey[] = ["ea_fwd_t_d", "ea_fwd_t_d_cost", "er_t_d"];

  const tag = useAppSelector((state) => selectTagById(state, id));

  const tagAnalyticsParams = createAnalyticsParamsWithDefaultProperties(
    id,
    "tag"
  );

  return (
    <AnalyticsDataLoaderContextProvider
      loadComparisonData={true}
      properties={properties}
      entities={entities}
    >
      <PageWidth>
        <FlexContainer>
          <div className="flex flex-wrap items-start justify-start gap-2 sm:justify-between">
            <PageHeadline>
              {t("tags.detail.title", "Group")} <TagBadge tag={tag} />
            </PageHeadline>

            <ButtonGroup>
              <Can I="use" a="historicalData">
                <Button>
                  <RouterLink
                    key="goToSettings"
                    to={`/analytics/${tagAnalyticsParams}`}
                  >
                    <div
                      className={"flex justify-items-center gap-1"}
                      title={t(
                        "tags.detail.analyticsTitle",
                        "go to analytics for groups"
                      )}
                    >
                      <AnalyticsIcon className="h-5 w-5" />
                      {t("tags.detail.analytics", "settings")}
                    </div>
                  </RouterLink>
                </Button>
              </Can>
              <Button>
                <RouterLink key="goToSettings" to={"settings"}>
                  <div className={"flex justify-items-center gap-1"}>
                    <Cog6ToothIcon className="h-5 w-5" />
                    {t("tags.detail.settings", "settings")}
                  </div>
                </RouterLink>
              </Button>
            </ButtonGroup>
          </div>

          <Can I="use" a="historicalData">
            <TagHistoricData />
          </Can>

          <h2 className="mb-2 mt-4 text-lg text-gray-700">
            {t("tags.detail.devicesInTag", "Devices in this group")}
          </h2>

          <TagDeviceList id={id} />
        </FlexContainer>
      </PageWidth>
    </AnalyticsDataLoaderContextProvider>
  );
}

function TagDeviceList({ id }: { id: string }) {
  const { t } = useTranslation();

  const tagOnDevices = useAppSelector((state) =>
    selectDeviceIdsByTagIds(state, [id])
  );
  const devices = useAppSelector((state) =>
    selectDevicesByDeviceIds(
      state,
      tagOnDevices.map((tod) => tod.device_id)
    )
  );

  return (
    <>
      {devices.length === 0 && (
        <Card>
          <div className="mx-auto max-w-2xl px-4 py-4 text-center sm:px-6 sm:py-20 md:py-16 lg:px-8">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              <span className="block">
                {t(
                  "tags.noDevicesInTag",
                  "There are no devices assigned to this group"
                )}
              </span>
            </h2>
          </div>
        </Card>
      )}

      {devices.length > 0 && (
        <ParentSize>
          {({ width }) => <DevicesTable width={width} devices={devices} />}
        </ParentSize>
      )}
    </>
  );
}
