import React from "react";
import { useTranslation } from "react-i18next";
import { selectTotalMetricByDeviceState } from "../../devices/devices-slice";
import { useAppSelector } from "../../../app/store";
import { MetricStat } from "../../../components/metrics/metric-stat";

export function TotalActiveEnergyConsumptionByCounter() {
  const { t } = useTranslation();

  const totalConsumption = useAppSelector((state) =>
    selectTotalMetricByDeviceState(state, "ea_fwd_t")
  );

  return (
    <MetricStat
      unit="watth"
      value={totalConsumption}
      decimals={2}
      label={t(
        "stats.totalActiveEnergyByCounter.title",
        "total energy consumption"
      )}
    />
  );
}
