import { useAppSelector } from "../../../app/store";
import {
  selectDeviceStateByDeviceId,
  selectOnlineDevicesByDeviceStateMetric,
} from "../../devices/devices-slice";
import { Text } from "../../../components/metrics/text";
import React from "react";
import { useTranslation } from "react-i18next";
import { formattedValueToString, getValueFormat } from "@nantis/grafana-data";
import { Application } from "@nantis/gridknight-core";
import { useNavigate } from "react-router-dom";

export function MostPowerConsumersList() {
  const { t } = useTranslation();

  const consumers = useAppSelector((state) =>
    selectOnlineDevicesByDeviceStateMetric(state, {
      metric: "p_t",
      now: new Date(),
      limit: 3,
    })
  );

  return (
    <div className="flex h-full grow flex-col gap-1">
      <Text>{t("stats.mostPowerConsumerList.title", "Biggest consumers")}</Text>

      <div>
        <table className="w-full table-auto text-sm">
          <thead className={"text-left"}>
            <tr className="border-b border-gray-200 font-normal">
              <th></th>
              <th className={"font-normal"}>
                {t("stats.mostPowerConsumerList.totalPower", "Total power")}
              </th>
            </tr>
          </thead>
          <tbody>
            {consumers.map((c) => (
              <TableItem key={c.id} consumer={c} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function TableItem({ consumer }: { consumer: Application.DeviceWithTags }) {
  let navigate = useNavigate();
  const wattFormat = getValueFormat("watt");

  const wattFormatter = (value: number): string => {
    const res = wattFormat(value, 1);
    return formattedValueToString(res);
  };

  const status = useAppSelector((state) =>
    selectDeviceStateByDeviceId(state, consumer.id)
  );

  return (
    <tr
      onClick={() => {
        navigate(`/devices/${consumer.id}`);
      }}
      className="cursor-pointer border-b border-gray-200"
    >
      <td>{consumer.name}</td>
      <td>{wattFormatter(status?.metrics.p_t ?? 0)}</td>
    </tr>
  );
}
