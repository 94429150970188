import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import type { CognitoUser } from "amazon-cognito-identity-js";

import { Auth } from "aws-amplify";
import { Application } from "@nantis/gridknight-core";
import { FetchStatus } from "../models/types";

export type AuthState =
  | "initializing"
  | "firstfetching"
  | "resetPassword"
  | "completeNewPassword"
  | "confirmResetPassword"
  | "loggedOut"
  | "loggedIn";
export type CognitoUserProps = Pick<
  Application.User,
  "id" | "tenant_id" | "email" | "role" | "locale" | "timezone" | "name"
>;

const initialState: {
  authState: AuthState;
  loginUser: FetchStatus;
  resetPassword: FetchStatus;
  completeNewUserPassword: FetchStatus;
  confirmResetPassword: FetchStatus;
  currentUserCognitoProps: CognitoUserProps | null;
  currentCognitoUser: CognitoUser | null;
} = {
  authState: "initializing",
  loginUser: {
    status: "idle",
    error: null,
  },
  completeNewUserPassword: {
    status: "idle",
    error: null,
  },
  resetPassword: {
    status: "idle",
    error: null,
  },
  currentUserCognitoProps: null,
  currentCognitoUser: null,
  confirmResetPassword: {
    status: "idle",
    error: null,
  },
};

/**
 * We might not be so sure if the groups array contains additional groups
 * So safeguard the groups and return the "highest" role
 * @param cognitoGroups Array of cognito groups
 */
const extractRole = (cognitoGroups: string[]): string => {
  if (cognitoGroups && cognitoGroups.length) {
    if (cognitoGroups.includes("admin")) {
      return "admin";
    }

    if (cognitoGroups.includes("user")) {
      return "user";
    }

    if (cognitoGroups.includes("viewer")) {
      return "viewer";
    }
  }

  return "viewer";
};

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<Application.User | any> => {
    return Auth.signIn({
      username: email,
      password: password,
    });
  }
);

export const logoutUser = createAsyncThunk(
  "auth/logoutUser",
  async (): Promise<any> => {
    return Auth.signOut();
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ email }: { email: string }): Promise<any> => {
    return Auth.forgotPassword(email);
  }
);

export const confirmResetPassword = createAsyncThunk(
  "auth/confirmResetPassword",
  async ({
    email,
    code,
    password,
  }: {
    email: string;
    code: string;
    password: string;
  }): Promise<string> => {
    return Auth.forgotPasswordSubmit(email, code, password);
  }
);

export const completeNewPassword = createAsyncThunk(
  "auth/completeNewPassword",
  async ({
    user,
    password,
  }: {
    user: CognitoUser;
    password: string;
  }): Promise<CognitoUser | any> => {
    return Auth.completeNewPassword(user, password);
  }
);

// We can extract certain properties from the cognito token
// We get the locale faster than from the api since it does not take another round trip
export const getUserInfoFromAuth = createAsyncThunk(
  "auth/authUserInfo",
  async (): Promise<CognitoUserProps | null> => {
    const user = await Auth.currentAuthenticatedUser();

    if (user && user.attributes) {
      const id = user.attributes["sub"];
      const tenant_id = user.attributes["custom:tenant"];
      const email = user.attributes["email"];
      const name = user.attributes["name"];
      const locale = user.attributes["locale"];
      const zoneinfo = user.attributes["zoneinfo"];
      // the array of groups that the user belongs to
      const cognitoGroups =
        user.signInUserSession.accessToken.payload["cognito:groups"];

      return {
        id: id,
        tenant_id: tenant_id,
        locale: locale,
        timezone: zoneinfo,
        email: email,
        name: name,
        role: extractRole(cognitoGroups),
      };
    }

    return null;
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.loginUser = {
        status: "pending",
        error: null,
      };
    });

    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loginUser = {
        status: "fulfilled",
        error: null,
      };

      state.resetPassword.error = null;

      if (action.payload) {
        const user = action.payload;
        state.currentCognitoUser = user;

        if (user.challengeName) {
          const challenge = user.challengeName;

          switch (challenge) {
            case "NEW_PASSWORD_REQUIRED": {
              state.authState = "completeNewPassword";
            }
          }
        } else {
          // User info gets requested after login through amplify bridge
          state.authState = "loggedIn";
        }
      }
    });

    builder.addCase(loginUser.rejected, (state, action) => {
      state.loginUser = {
        status: "rejected",
        error: null,
      };

      if (action.error) {
        console.error(action.error);
        state.authState = "loggedOut";
        const { code } = action.error;

        state.loginUser = {
          status: "rejected",
          error: code ?? null,
        };

        if (code === "UserNotConfirmedException") {
          state.loginUser.error = "userNotConfirmed";
        } else if (
          code === "NotAuthorizedException" ||
          "UserNotFoundException"
        ) {
          state.loginUser.error = "emailOrPasswordWrong";
        } else if (code === "PasswordResetRequiredException") {
          state.loginUser.error = "passwordResetRequired";
        } else {
          state.loginUser.error = code ?? "Unknown Error";
        }
      }
    });

    builder.addCase(completeNewPassword.pending, (state) => {
      state.completeNewUserPassword = {
        status: "pending",
        error: null,
      };

      state.loginUser = {
        status: "idle",
        error: null,
      };
    });

    builder.addCase(completeNewPassword.fulfilled, (state, action) => {
      state.authState = "loggedIn";

      state.completeNewUserPassword = {
        status: "fulfilled",
        error: null,
      };

      if (action.payload) {
        state.currentCognitoUser = action.payload;
      }
    });

    builder.addCase(completeNewPassword.rejected, (state, action) => {
      state.completeNewUserPassword = {
        status: "rejected",
        error: null,
      };

      if (action.error) {
        console.log(action.error);
        const { code } = action.error;

        if (code === "InvalidPasswordException") {
          state.completeNewUserPassword.error = "passwordInvalid";
        }
      }
    });

    builder.addCase(resetPassword.pending, (state) => {
      state.resetPassword = {
        status: "pending",
        error: null,
      };
    });

    builder.addCase(resetPassword.fulfilled, (state) => {
      state.authState = "confirmResetPassword";

      state.resetPassword = {
        status: "fulfilled",
        error: null,
      };
    });

    builder.addCase(resetPassword.rejected, (state, action) => {
      state.authState = "resetPassword";

      state.resetPassword = {
        status: "rejected",
        error: null,
      };

      if (action.error) {
        const { code } = action.error;

        switch (code) {
          case "LimitExceededException": {
            state.resetPassword = {
              status: "rejected",
              error: "limitExceeded",
            };
            break;
          }
          case "UserNotFoundException": {
            state.resetPassword = {
              status: "rejected",
              error: "userNotFound",
            };
            break;
          }
          case "InvalidParameterException": {
            state.resetPassword = {
              status: "rejected",
              error: "invalidParameters",
            };
            break;
          }
          case "NotAuthorizedException": {
            state.resetPassword = {
              status: "rejected",
              error: "userStateInvalid",
            };
            break;
          }
          default:
            break;
        }
      }
    });

    builder.addCase(confirmResetPassword.pending, (state) => {
      state.confirmResetPassword = {
        status: "pending",
        error: null,
      };
    });

    builder.addCase(confirmResetPassword.rejected, (state, action) => {
      state.authState = "confirmResetPassword";

      state.confirmResetPassword = {
        status: "rejected",
        error: null,
      };

      if (action.error) {
        const { code } = action.error;

        switch (code) {
          case "CodeMismatchException": {
            state.confirmResetPassword.error = "codeMismatch";
            break;
          }
          default:
            break;
        }
      }
    });

    builder.addCase(confirmResetPassword.fulfilled, (state) => {
      state.confirmResetPassword = {
        status: "fulfilled",
        error: null,
      };
    });

    // Has not have much effect upon state - see root reducer in store.tsx
    builder.addCase(logoutUser.fulfilled, (state) => {
      state.currentUserCognitoProps = null;
      state.currentCognitoUser = null;
      state.authState = "loggedOut";
    });

    builder.addCase(logoutUser.rejected, (state) => {
      state.currentUserCognitoProps = null;
      state.currentCognitoUser = null;
      state.authState = "loggedOut";
    });

    builder.addCase(getUserInfoFromAuth.pending, (state) => {
      if (state.authState === "initializing") {
        state.authState = "firstfetching";
      }
    });

    builder.addCase(getUserInfoFromAuth.fulfilled, (state, action) => {
      if (action.payload) {
        state.currentUserCognitoProps = action.payload;
        state.authState = "loggedIn";
      }
    });

    builder.addCase(getUserInfoFromAuth.rejected, (state) => {
      state.currentUserCognitoProps = null;
      state.authState = "loggedOut";
    });
  },
});

export const {} = authSlice.actions;
export const reducer = authSlice.reducer;
export const selectCurrentUserCognitoProps = (state: RootState) =>
  state.auth.currentUserCognitoProps;
export const selectCurrentCognitoUser = (state: RootState) =>
  state.auth.currentCognitoUser;
export const selectAuthState = (state: RootState) => state.auth.authState;
export const selectConfirmResetPasswordState = (state: RootState) =>
  state.auth.confirmResetPassword;
export const selectLoginUserLoadingState = (state: RootState) =>
  state.auth.loginUser;
export const selectCompleteNewUserPasswordState = (state: RootState) =>
  state.auth.completeNewUserPassword;
export const selectResetPasswordState = (state: RootState) =>
  state.auth.resetPassword;
