import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { selectDeviceStateByDeviceId } from "../devices-slice";
import  type { Device } from "@nantis/gridknight-core";
import { NotificationCard } from "../../../components/containers/notification-card";
import { TFunction } from "i18next";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Cog6ToothIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { DeviceEventsList } from "../events/device-events-list";
import {
  acknowledgeAllDeviceEvents,
  selectEventsByDevice,
} from "../devices-slice";
import { Card } from "../../../components/containers/card";

export function DeviceEvents({ deviceId, collapsed = false }: {collapsed?: boolean, deviceId: string }) {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const acknowledgeDeviceEvents = () => {
    dispatch(acknowledgeAllDeviceEvents({ device_id: deviceId }));
  };

  const historicEvents = useAppSelector((state) =>
    selectEventsByDevice(state, deviceId)
  );

  const status = useAppSelector((state) =>
    selectDeviceStateByDeviceId(state, deviceId)
  );
  const events: Device.Event<any>[] = status?.events ?? [];

  const activeEvents: Device.Event<any>[] = [];

  events.forEach((v) => {
    if (status && v.type === "dt") {
      activeEvents.push({
        ...v,
        data: {
          ...v.data,
          val: status.metrics[v.data.prop as keyof Device.StatusMetrics],
        },
      });
    }
  });

  const hasActiveEvents = status && activeEvents.length > 0;
  const hasHistoricalDeviceEvents = historicEvents.length > 0;

  if (!hasHistoricalDeviceEvents && !hasActiveEvents) {
    return <></>;
  }

  return (
    <Card>
      <div className="flex justify-between">
        <h2 className={"mb-2 text-lg"}>{t("device.events.title", "Alerts")}</h2>
        <RouterLink className="text-gray-700" to={"settings"}>
          <Cog6ToothIcon className="h-5 w-5" />
        </RouterLink>
      </div>
      {hasActiveEvents && (
        <>
          <h3 className={"mb-1 text-base"}>
            {t("device.events.activeAlerts", "currently active")}
          </h3>
          <CurrentDeviceEvents events={activeEvents} />
        </>
      )}

      {hasHistoricalDeviceEvents && (
        <div>
          <div className="mt-4 mb-1 flex justify-between">
            <h3 className={"text-base"}>
              {t("device.events.historicalAlerts", "historic alerts")}
            </h3>
            <div className="flex flex-row gap-1">
              {events.length > 0 && (
                <div
                  onClick={acknowledgeDeviceEvents}
                  title={t(
                    "device.events.acknowledgeAllDeviceAlerts",
                    "acknowledge all violation for device"
                  )}
                >
                  <XMarkIcon className="h-5 w-5 cursor-pointer text-gray-700" />
                </div>
              )}
            </div>
          </div>

          <DeviceEventsList
              collapsed={collapsed}
            showDeviceLink={false}
            deviceId={deviceId}
          />
        </div>
      )}
    </Card>
  );
}

function CurrentDeviceEvents({ events }: { events: Device.Event<any>[] }) {
  const { t } = useTranslation();

  return (
    <div>
      {events.map((v) => {
        return (
          <NotificationCard
            key={v.id}
            variant={"error"}
            className={"rounded-none shadow-none"}
          >
            {buildEventMessage(t, v)}
          </NotificationCard>
        );
      })}
    </div>
  );
}

function buildEventMessage(
  t: TFunction,
  event: Device.Event<any>
): string {
  if (event.type == "dt") {
    const eventData = event.data;

    // gen3 remove toLowerCase
    const propertyLabel = t(
      `datum:metrics.${eventData.prop.toLowerCase()}.label`,
      eventData.prop
    );

    return t("device.events.messageActive.event", {
      context: eventData.comp,
      propertyLabel: propertyLabel,
      datum: {
        value: eventData.val,
        property: eventData.prop.toLowerCase(),
      },
      limit: {
        value: eventData.lim,
        property: eventData.prop.toLowerCase(),
      },
    });
  }



  return "";
}
