type LegendItem = {
  value?: number;
  color: string;
  label: string;
};

export type LegendProps = {
  values: LegendItem[];
};

export function Legend({ values }: LegendProps) {
  return (
    <div className={"flex flex-row gap-2"}>
      {values.map((v, i) => {
        return <LegendItemBadge key={v.label + "" + i} item={v} />;
      })}
    </div>
  );
}

function LegendItemBadge({ item }: { item: LegendItem }) {
  return (
    <div
      className={
        "flex flex-row content-center items-center gap-1 text-xs text-gray-600"
      }
    >
      <div
        className={"h-2 w-2 rounded-full"}
        style={{ backgroundColor: item.color }}
      />
      <span>{`${item.label}${item.value ? ` (${item.value})` : ""}`}</span>
    </div>
  );
}
