import { Text } from "../../../components/metrics/text";
import React from "react";
import { Can } from "../../../auth/user-ability-provider";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function UpgradeSubscriptionStat() {
  const { t } = useTranslation();

  return (
    <div className="flex h-full grow flex-col gap-1">
      <Text>{t("stats.upgradeSubscription.title", "Subscription")}</Text>

      <p className={"text-sm text-gray-700"}>
        {t(
          "stats.upgradeSubscription.upgradeSubscription",
          "With a premium subscription you will receive more features like alerts or historical data."
        )}
      </p>

      <Can I="update" a="payment">
        <RouterLink
          to={"/settings/account"}
          className="mt-1 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-blue p-1 text-sm text-white sm:w-auto"
        >
          {t("stats.upgradeSubscription.changeSubscription", "Upgrade plan")}
        </RouterLink>
      </Can>

      <Can not I="update" a="payment">
        <p className={"mt-4 text-sm text-gray-700"}>
          {t(
            "stats.upgradeSubscription.askAdminToUpgradePlan",
            "Ask an administrator for an upgrade"
          )}
        </p>
      </Can>
    </div>
  );
}
