import React, { ReactElement, useEffect, useState } from "react";
import {
  MapContainer,
  FeatureGroup,
  TileLayer,
  Marker,
  Popup,
} from "react-leaflet";
import { LatLngBounds } from "leaflet";
import { Link as RouterLink } from "react-router-dom";
import { useAppSelector } from "../../../app/store";
import { ThemeContext } from "../../../app/theme-provider";
import { selectDeviceById, selectAllDeviceStates } from "../devices-slice";
import { Application } from "@nantis/gridknight-core";

function MapPopup({ deviceId }: { deviceId: string }) {
  const device = useAppSelector((state) => selectDeviceById(state, deviceId));

  return (
    <>
      {device && (
        <RouterLink to={`devices/${device.id}`}>
          {device.name ?? device.id}
        </RouterLink>
      )}
    </>
  );
}

export function DevicesLocationMap() {
  const [bounds, setBounds] = useState<LatLngBounds>();

  const deviceStates = useAppSelector((state) => selectAllDeviceStates(state));

  const { theme } = React.useContext(ThemeContext);

  // See https://leaflet-extras.github.io/leaflet-providers/preview/ for map tiles urls
  // e.g. https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png
  const tilesUrl =
    theme === "dark"
      ? "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      : "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

  useEffect(() => {
    const deviceLocations: Application.Location[] = [];

    deviceStates.forEach((deviceState) => {
      if (
        deviceState.location !== undefined &&
        deviceState.location.lng &&
        deviceState.location.lat
      ) {
        deviceLocations.push(deviceState.location);
      }
    });

    if (deviceLocations.length) {
      const newBounds = new LatLngBounds(
        deviceLocations[0],
        deviceLocations[0]
      );
      deviceLocations.forEach((value) => {
        if (newBounds) {
          newBounds.extend(value);
        }
      });

      setBounds(newBounds.pad(0.1));
    }
  }, [deviceStates]);

  return (
    <>
      {bounds && (
        <MapContainer bounds={bounds} zoom={11} scrollWheelZoom={false}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url={tilesUrl}
          />

          <FeatureGroup>
            {deviceStates
              .filter((state) => state.location != null)
              .map((deviceState): ReactElement | null => {
                if (
                  deviceState &&
                  deviceState.location?.resolved &&
                  deviceState.location.lat &&
                  deviceState.location.lng
                ) {
                  return (
                    <Marker
                      key={deviceState.id}
                      position={[
                        deviceState.location.lat,
                        deviceState.location.lng,
                      ]}
                    >
                      <Popup>
                        <MapPopup deviceId={deviceState.id} />
                      </Popup>
                    </Marker>
                  );
                } else {
                  return null;
                }
              })}
          </FeatureGroup>
        </MapContainer>
      )}
    </>
  );
}
