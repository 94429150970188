import { Application } from "@nantis/gridknight-core";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/solid";
import React, { HTMLAttributes, useState } from "react";
import { useTranslation } from "react-i18next";
import { classNames } from "../../../app/util";
import { Tab, Transition } from "@headlessui/react";
import { TagBadge } from "../../tags/tagBadge";
import { useAppSelector } from "../../../app/store";
import { selectTagById, selectTagsByNotInIds } from "../../tags/tags-slice";
import {
  selectDeviceById,
  selectDevicesByNotInDeviceIds,
} from "../../devices/devices-slice";
import { Entity } from "../analytics-data-loader-context";
import { Pill } from "../../../components";
import { RoundActionButton } from "../../../components/ng";

export type AnalyticsEntityWithColor = Entity & {
  color: string;
};

export function EntitySelectionLabel({
  entity,
  onRemove,
}: {
  entity: AnalyticsEntityWithColor;
  onRemove: (entity: Entity) => void;
}) {
  const { color = "#a100ff", id, scope } = entity;

  const ring = `rgb(255, 255, 255) 0px 0px 0px 2px, ${color} 0px 0px 0px 4px, rgba(0, 0, 0, 0) 0px 0px 0px 0px`;

  return (
    <div className={"font-semibold "}>
      {scope === "device" && (
        <DeviceSelectionPill style={{ boxShadow: ring }} key={id} deviceId={id}>
          <div
            onClick={() => {
              onRemove(entity);
            }}
            className="ml-2 inline-block cursor-pointer fill-gray-200"
          >
            <XMarkIcon className="color-white h-5 w-5" />
          </div>
        </DeviceSelectionPill>
      )}

      {scope === "tag" && (
        <TagSelectionPill style={{ boxShadow: ring }} key={id} tagId={id}>
          <div
            onClick={() => {
              onRemove(entity);
            }}
            className="ml-2 inline-block cursor-pointer fill-gray-200"
          >
            <XMarkIcon className="color-white h-5 w-5" />
          </div>
        </TagSelectionPill>
      )}

      {scope === "tenant" && (
        <TenantSelectionPill style={{ boxShadow: ring }} key={id}>
          <div
            onClick={() => {
              onRemove(entity);
            }}
            className="ml-2 inline-block cursor-pointer fill-gray-200"
          >
            <XMarkIcon className="color-white h-5 w-5" />
          </div>
        </TenantSelectionPill>
      )}
    </div>
  );
}

export function AddEntitySelectionButton({
  color,
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  color?: string;
}) {
  const { t } = useTranslation();

  return (
    <div {...props} className={classNames(className)}>
      <RoundActionButton
        backgroundColor={color}
        title={t("analytics.addEntityToAnalysis", "add entity to analytics")}
      >
        <PlusIcon className="h-5 w-5" />
      </RoundActionButton>
    </div>
  );
}

export function EntitySelector({
  open,
  selectedEntities = [],
  maxEntities = 3,
  onRemoveEntity,
  onAddEntity,
  nextColor,
}: {
  open: boolean;
  maxEntities?: number;
  nextColor: string;
  selectedEntities?: AnalyticsEntityWithColor[];
  onRemoveEntity: (entity: Entity) => void;
  onAddEntity: (entity: Entity) => void;
}) {
  const { t } = useTranslation();

  const [selectionOpen, setSelectionOpen] = useState<boolean>(open);

  const isBelowMaxEntities = selectedEntities.length < maxEntities;

  const selectedTagIds = selectedEntities
    .filter((e) => e.scope === "tag")
    .map((t) => t.id);
  const selectedDeviceIds = selectedEntities
    .filter((e) => e.scope === "device")
    .map((d) => d.id);
  const tenantSelectable = !selectedEntities.find((e) => e.scope === "tenant");

  const addEntity = (entity: Entity) => {
    onAddEntity({
      ...entity,
    });
    setSelectionOpen(false);
  };

  const devicesNotSelected = useAppSelector((state) =>
    selectDevicesByNotInDeviceIds(state, selectedDeviceIds)
  );
  const tagsNotSelected = useAppSelector((state) =>
    selectTagsByNotInIds(state, selectedTagIds)
  );

  return (
    <div className="flex flex-col">
      <div className="flex flex-col-reverse content-center items-center justify-around gap-4 md:flex-row md:justify-between">
        <div className="flex w-full flex-grow flex-row justify-between gap-2 py-1 sm:items-center">
          <div className="hidden sm:block">
            {t("analytics.entitySelection", "Analytics selection")}
          </div>

          <div
            aria-hidden="true"
            className="hidden h-5 w-px bg-gray-300 sm:mx-4  sm:block"
          />

          <div className="flex flex-grow flex-col flex-wrap items-center gap-4 md:flex-row">
            {selectedEntities.map((entity) => {
              return (
                <EntitySelectionLabel
                  onRemove={onRemoveEntity}
                  entity={entity}
                  key={entity.id}
                />
              );
            })}

            {isBelowMaxEntities && (
              <AddEntitySelectionButton
                onClick={() => {
                  setSelectionOpen(!selectionOpen);
                }}
                color={nextColor}
                className={classNames(
                  "transition-transform duration-200 sm:ml-4",
                  selectionOpen ? "rotate-45" : ""
                )}
              />
            )}
          </div>
        </div>
      </div>

      <div className="w-full">
        <Transition
          show={selectionOpen}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Tab.Group>
            <Tab.List className="mt-2 mb-6 flex gap-2 space-x-1 border-b border-gray-200 px-2 sm:px-0 md:mt-4">
              <Tab
                className={({ selected }) =>
                  classNames(
                    "flex flex-row items-center gap-2 border-b-2 p-2",
                    selected
                      ? "border-blue"
                      : "border-transparent hover:border-gray-200"
                  )
                }
              >
                <>
                  {t("analytics.devices", "devices")}
                  <Pill textColor={"#000"} color={"#ababab"} className={"h-4"}>
                    {devicesNotSelected.length}
                  </Pill>
                </>
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames(
                    "flex flex-row items-center gap-2 border-b-2 p-2",
                    selected
                      ? "border-blue"
                      : "border-transparent hover:border-gray-200"
                  )
                }
              >
                <>
                  {t("analytics.tags", "groups")}
                  <Pill textColor={"#000"} color={"#ababab"} className={"h-4"}>
                    {tagsNotSelected.length}
                  </Pill>
                </>
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames(
                    "flex flex-row items-center gap-2 border-b-2 p-2",
                    selected
                      ? "border-blue"
                      : "border-transparent hover:border-gray-200"
                  )
                }
              >
                <>
                  {t("analytics.account", "total")}
                  <Pill textColor={"#000"} color={"#ababab"} className={"h-4"}>
                    {tenantSelectable ? "1" : "0"}
                  </Pill>
                </>
              </Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <DeviceSelection
                  devicesNotSelected={devicesNotSelected}
                  onAddEntity={addEntity}
                />
              </Tab.Panel>
              <Tab.Panel>
                <TagSelection
                  tagsNotSelected={tagsNotSelected}
                  onAddEntity={addEntity}
                />
              </Tab.Panel>
              <Tab.Panel>
                {tenantSelectable && (
                  <TenantSelection onAddEntity={addEntity} />
                )}
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Transition>
      </div>
    </div>
  );
}

function TenantSelection({
  onAddEntity,
}: {
  onAddEntity: (entity: Entity) => void;
}) {
  const { t } = useTranslation();

  return (
    <div className={"mt-4 flex flex-wrap gap-2 fill-white text-white"}>
      <Pill
        color={"#75e3e5"}
        className="cursor-pointer"
        variant="large"
        onClick={() => {
          onAddEntity({ scope: "tenant", id: "entity" });
        }}
      >
        {t("analytics.tenantConsumption", "total consumption")}
      </Pill>
    </div>
  );
}

function TenantSelectionPill({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  const { t } = useTranslation();

  return (
    <Pill {...props} color={"#75e3e5"} variant="large">
      {t("analytics.tenantConsumption", "total consumption")} {children}
    </Pill>
  );
}

function DeviceSelectionPill({
  deviceId,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  deviceId: string;
}) {
  const device = useAppSelector((state) => selectDeviceById(state, deviceId));

  return (
    <Pill {...props} color={"#525252"} variant="large">
      {device?.name}
      {children}
    </Pill>
  );
}

function DeviceSelection({
  devicesNotSelected,
  onAddEntity,
}: {
  devicesNotSelected: Application.Device[];
  onAddEntity: (entity: Entity) => void;
}) {
  const addDevice = (device: Application.Device) => {
    onAddEntity({
      scope: "device",
      id: device.id,
    });
  };

  return (
    <div className={"mt-4 flex flex-wrap gap-2 fill-white text-white"}>
      {devicesNotSelected.map((device) => {
        return (
          <DeviceSelectionPill
            className="cursor-pointer"
            deviceId={device.id}
            key={device.id}
            onClick={() => {
              addDevice(device);
            }}
          />
        );
      })}
    </div>
  );
}

function TagSelectionPill({
  tagId,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  tagId: string;
}) {
  const tag = useAppSelector((state) => selectTagById(state, tagId));

  return (
    <TagBadge
      {...props}
      className={"cursor-pointer"}
      variant={"large"}
      tag={tag}
    >
      {children}
    </TagBadge>
  );
}

function TagSelection({
  tagsNotSelected,
  onAddEntity,
}: {
  tagsNotSelected: Application.Tag[];
  onAddEntity: (entity: Entity) => void;
}) {
  const addEntity = (tag: Application.Tag) => {
    onAddEntity({
      scope: "tag",
      id: tag.id,
    });
  };

  return (
    <div className={"mt-4 flex flex-wrap gap-2 fill-white text-white"}>
      {tagsNotSelected.map((tag) => {
        return (
          <TagSelectionPill
            tagId={tag.id}
            key={tag.id}
            onClick={() => {
              addEntity(tag);
            }}
          />
        );
      })}
    </div>
  );
}
