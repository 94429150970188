import React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/store";
import { selectConfirmResetPasswordState } from "../auth-slice";
import { LoadingButton } from "../../components/ng";

export type ConfirmResetPasswordFormData = {
  email: string;
  password: string;
  code: string;
};

export type ConfirmResetPasswordFormProps = {
  onConfirmResetPassword: ({
    email,
    password,
    code,
  }: ConfirmResetPasswordFormData) => void;
};

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
  code: yup.string().required(),
});

export function ConfirmResetPasswordForm({
  onConfirmResetPassword,
}: ConfirmResetPasswordFormProps) {
  const { t } = useTranslation();
  const confirmResetPasswordState = useAppSelector((state) =>
    selectConfirmResetPasswordState(state)
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ConfirmResetPasswordFormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit((data: ConfirmResetPasswordFormData) => {
    onConfirmResetPassword({
      email: data.email,
      password: data.password,
      code: data.code,
    });
  });

  return (
    <form
      id="confirmResetPasswordForm"
      onSubmit={onSubmit}
      className="space-y-6"
      action="#"
    >
      <div>
        <label htmlFor="companyName" className="block text-sm text-gray-700">
          {t("auth.confirmResetPassword.email", "Email address")}
        </label>
        <div className="mt-1">
          <input
            id="email"
            type="text"
            autoComplete="email"
            required
            {...register("email")}
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
          />
        </div>
      </div>

      <div>
        <label htmlFor="password" className="block text-sm text-gray-700">
          {t("auth.confirmResetPassword.password", "New Password")}
        </label>
        <div className="mt-1">
          <input
            id="password"
            type="password"
            autoComplete="new-password"
            required
            {...register("password")}
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
          />
        </div>
      </div>

      <div>
        <label htmlFor="code" className="block text-sm text-gray-700">
          {t("auth.confirmResetPassword.code", "Code")}
        </label>
        <div className="mt-1">
          <input
            id="code"
            type="text"
            autoComplete="one-time-code"
            required
            {...register("code")}
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
          />
        </div>
      </div>

      {confirmResetPasswordState.status === "rejected" &&
        confirmResetPasswordState.error === "codeMismatch" && (
          <div className="text-center text-sm">
            <p className="text-red">
              {t(
                "auth.confirmResetPassword.errors.codeMismatch",
                "The code is not correct. Please check if you entered it correctly."
              )}
            </p>
          </div>
        )}

      <LoadingButton
        form="confirmResetPasswordForm"
        isLoading={confirmResetPasswordState.status === "pending"}
        type="submit"
        variant="accent"
        className="w-full justify-center text-sm"
      >
        {t("auth.confirmResetPassword.savePassword", "Save new password")}
      </LoadingButton>
    </form>
  );
}
