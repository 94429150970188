import { PageHeadline } from "../../../components/text/page-headline";
import React, { useState } from "react";
import { FlexContainer } from "../../../components/containers/flex-container";
import { PageWidth } from "../../../components/containers/page-width";
import { useTranslation } from "react-i18next";
import { Card } from "../../../components/containers/card";
import { CustomerSupportDetails } from "../../../components/customer-support/customer-support-details";

import { Button } from "../../../components/ng";
import {
  CustomerSupportHelpModalProps,
  CustomerSupportModal,
} from "../../tenants/support/customer-support-modal";

export function DocumentationPage() {
  const { t } = useTranslation();

  const [openHelpModal, setOpenHelpModal] = useState(false);

  const onOpenHelpModal = () => {
    setOpenHelpModal(true);
  };

  const customerSupportModalProps: CustomerSupportHelpModalProps = {
    open: openHelpModal,
    close: () => {
      setOpenHelpModal(false);
    },
  };

  return (
    <>
      <CustomerSupportModal
        {...customerSupportModalProps}
        key="helpModalDocs"
      />
      <PageWidth>
        <FlexContainer>
          <PageHeadline>
            {t("documentation.title", "Gridknight Documentation")}
          </PageHeadline>
          <Card className={"flex flex-col items-center justify-center"}>
            <CustomerSupportDetails />
            <Button variant={"accent"} onClick={onOpenHelpModal}>
              {t("support.contactSupport", "Contact Support")}
            </Button>
          </Card>
        </FlexContainer>
      </PageWidth>
    </>
  );
}
