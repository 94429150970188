import React from "react";
import { useTranslation } from "react-i18next";
import { PageHeadline } from "../../../components/text/page-headline";
import { Application } from "@nantis/gridknight-core";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  deleteTag,
  selectDeleteTagLoadingState,
  selectTagById,
  selectUpdateTagLoadingState,
  updateTag,
} from "../tags-slice";
import { Card } from "../../../components/containers/card";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { TagForm } from "../tag-form";
import { PageWidth } from "../../../components/containers/page-width";
import { LoadingButton } from "../../../components/ng";

export function TagUpdatePage({ id }: { id: string }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const tag = useAppSelector((state) => selectTagById(state, id));
  const updateTagState = useAppSelector((state) =>
    selectUpdateTagLoadingState(state)
  );
  const deleteTagState = useAppSelector((state) =>
    selectDeleteTagLoadingState(state)
  );

  const onSave = async ({
    label,
    description = "",
    color,
  }: Omit<Application.Tag, "id" | "tenant_id">) => {
    if (tag) {
      const updatedTag: Application.Tag = {
        ...tag,
        label,
        description,
        color,
      };

      await dispatch(updateTag(updatedTag)).then(() => {
        navigate({
          pathname: "../..",
        });
      });
    }
  };

  const onDeleteTag = async () => {
    if (tag) {
      await dispatch(deleteTag(tag.id)).then(() => {
        navigate({
          pathname: "../..",
        });
      });
    }
  };

  return (
    <PageWidth>
      <div>
        <PageHeadline>{t("tags.update.title", "Update group")}</PageHeadline>

        <div className={"mb-4 text-sm"}>
          <RouterLink to={"../.."}>
            <span aria-hidden="true">&larr;</span>{" "}
            {t("tags.create.backToTags", "back to tags")}
          </RouterLink>
        </div>

        <Card>
          {tag && (
            <TagForm existingTag={tag} onSave={onSave}>
              <div className="pt-5">
                <div className="flex justify-end gap-4">
                  <LoadingButton
                    onClick={onDeleteTag}
                    isLoading={deleteTagState.status === "pending"}
                    variant="destructive"
                    type="button"
                  >
                    {t("tags.delete", "delete group")}
                  </LoadingButton>

                  <LoadingButton
                    isLoading={updateTagState.status === "pending"}
                    form="tagForm"
                    variant="success"
                    type="submit"
                  >
                    {t("tags.update.save", "save group")}
                  </LoadingButton>
                </div>
              </div>
            </TagForm>
          )}
        </Card>
      </div>
    </PageWidth>
  );
}
