import React from "react";
import {
  useSearchParams,
  createSearchParams,
  useNavigate,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PageHeadline } from "../../../components/text/page-headline";
import { Card } from "../../../components/containers/card";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  fetchDevice,
  fetchDeviceRules,
  fetchDevices,
  pairDevices,
  selectPairDevicesLoadingState,
} from "../devices-slice";
import { PairDeviceWidget } from "./pair-device-widget";
import { PageWidth } from "../../../components/containers/page-width";
import { FlexContainer } from "../../../components/containers/flex-container";
import { LoadingButton } from "../../../components/ng";

export const deviceKey = "d";

export function PairDevicePage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  let [searchParams, setSearchParams] = useSearchParams();
  const deviceIdParams = searchParams.getAll(deviceKey);
  const deviceIds = deviceIdParams.length
    ? deviceIdParams.map((p) => p.toUpperCase())
    : [""];

  const pairDeviceLoadingState = useAppSelector(selectPairDevicesLoadingState);

  const onPairDevices = () => {
    dispatch(pairDevices(deviceIds)).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        const payload = result.payload as {
          id: string;
        }[];

        if (payload && payload.length) {
          const firstDeviceId = payload[0].id;

          dispatch(fetchDeviceRules(firstDeviceId));

          if (payload.length === 1) {
            dispatch(fetchDevice(firstDeviceId));
          } else {
            dispatch(fetchDevices({}));
          }

          navigate({
            pathname: `/devices/${firstDeviceId}/settings`,
          });
        }
      }
    });
  };

  const onSerialChanged = (deviceIds: string[]) => {
    setSearchParams(
      createSearchParams({
        d: deviceIds,
      }),
      {
        replace: true,
      }
    );
  };

  return (
    <PageWidth>
      <FlexContainer>
        <PageHeadline>{t("device.pair.title", "Add devices")}</PageHeadline>

        <Card>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onPairDevices();
            }}
          >
            <div className={"ml-8 mr-8 mb-4"}>
              <PairDeviceWidget
                deviceIds={deviceIds}
                onSerialChanged={onSerialChanged}
              />
            </div>

            <div className="flex justify-end">
              <LoadingButton
                onClick={onPairDevices}
                isLoading={pairDeviceLoadingState.status === "pending"}
                form="deviveSettingsForm"
                variant="success"
                type="submit"
              >
                {t("device.pair.pairDevice", { count: deviceIds.length })}
              </LoadingButton>
            </div>
          </form>
        </Card>
      </FlexContainer>
    </PageWidth>
  );
}
