import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import {
  InformationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { classNames } from "../../app/util";

export type ConfirmationModalProps = {
  variant: "danger" | "warning" | "info";
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  description?: string;
  cancelButtonText?: string;
  confirmationButtonText?: string;
};

export default function ConfirmationModal({
  open,
  onCancel,
  onConfirm,
  variant = "info",
  ...labels
}: ConfirmationModalProps) {
  const cancelButtonRef = useRef(null);

  const { t } = useTranslation();

  const {
    title,
    description = "",
    cancelButtonText = t("modal.cancel", "cancel"),
    confirmationButtonText = t("modal.confirm", "confirm"),
  } = labels;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[1001]"
        initialFocus={cancelButtonRef}
        onClose={onCancel}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 z-[1002] bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-[1003] overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div
                      className={classNames(
                        "mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10",
                        variant === "danger" ? "bg-red/20" : null,
                        variant === "info" ? "bg-blue/20" : null,
                        variant === "warning" ? "bg-orange/20" : null
                      )}
                    >
                      {variant === "danger" && (
                        <ExclamationTriangleIcon
                          className="h-6 w-6 text-red"
                          aria-hidden="true"
                        />
                      )}
                      {variant === "warning" && (
                        <ExclamationTriangleIcon
                          className="h-6 w-6 text-orange"
                          aria-hidden="true"
                        />
                      )}
                      {variant === "info" && (
                        <InformationCircleIcon
                          className="h-6 w-6 text-blue"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-700"
                      >
                        {title}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">{description}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className={classNames(
                      variant === "danger" ? "bg-red" : null,
                      variant === "info" ? "bg-blue" : null,
                      variant === "warning" ? "bg-orange" : null,
                      "focus:ring-red-500 inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2  text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    )}
                    onClick={onConfirm}
                  >
                    {confirmationButtonText}
                  </button>
                  <button
                    type="button"
                    className="focus:ring- indigo-500 mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2               focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={onCancel}
                    ref={cancelButtonRef}
                  >
                    {cancelButtonText}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
