import React, { ChangeEvent, PropsWithChildren, useState } from "react";
import { Application } from "@nantis/gridknight-core";
import { useTranslation } from "react-i18next";

export function TariffForm({
  existingTariff,
  onSave,
  ...props
}: PropsWithChildren<{
  existingTariff?: Application.Tariff;
  onSave: (tariff: Pick<Application.Tariff, "name">) => Promise<void>;
}>) {
  const { t } = useTranslation();

  const initialState = {
    name: "",
  };

  const [tariff, setTariff] = useState<Pick<Application.Tariff, "name">>(
    existingTariff ?? initialState
  );

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setTariff({
      ...tariff,
      name: e.target.value,
    });
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    await onSave(tariff);
  };

  return (
    <>
      <form id="tariffForm" className={"mb-6"} onSubmit={onSubmit}>
        <div className="space-y-6 sm:space-y-5">
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
            <label htmlFor="label" className="block text-sm text-gray-700">
              {t("tariffs.create.name", "Name of the tariff")}
            </label>
            <div className="flex flex-col">
              <input
                type="text"
                maxLength={30}
                value={tariff.name ?? ""}
                name="label"
                id="label"
                onChange={onChangeName}
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
              />
            </div>
          </div>
        </div>

        {props.children}
      </form>
    </>
  );
}
