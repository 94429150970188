import { Route, Routes, useParams } from "react-router-dom";
import React from "react";
import { TariffUpdatePage } from "./tariff-update-page";
import { TariffRateCreatePage } from "./tariffRates/tariff-rate-create-page";

export function Tariff() {
  const { id } = useParams();

  return (
    <Routes>
      <Route
        path="tariff-rates/create"
        element={<>{id && <TariffRateCreatePage tariffId={id} />}</>}
      />

      <Route path="/" element={<>{id && <TariffUpdatePage id={id} />}</>} />
    </Routes>
  );
}
