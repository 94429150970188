import React, { useEffect } from "react";
import { GridknightLogo } from "../../assets/gridknight-logo";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { SignupForm } from "./signup-form";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
  createTenant,
  selectCreateTenantLoadingState,
  resetCreateTenant,
} from "../../features/tenants/tenants-slice";
import { Card } from "../../components/containers/card";
import { LanguageSelector } from "../../components/language/language-selector";
import { selectCurrentUserCognitoProps } from "../auth-slice";

export type CreateTenantData = {
  companyName: string;
  email: string;
  locale: string;
  timezone: string;
};

export function SignupPage() {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const user = useAppSelector(selectCurrentUserCognitoProps);
  const navigate = useNavigate();
  const loadingState = useAppSelector(selectCreateTenantLoadingState);

  const onSignup = ({
    companyName,
    email,
    locale,
    timezone,
  }: CreateTenantData) => {
    dispatch(
      createTenant({
        name: companyName,
        email: email,
        locale: locale,
        timezone: timezone,
      })
    );
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }

    return () => {
      dispatch(resetCreateTenant());
    };
  }, [dispatch]);

  return (
    <div className="flex min-h-screen flex-col justify-center bg-gray-800 py-12 sm:px-6 lg:px-8">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <RouterLink to={"/"}>
          <GridknightLogo className="mx-auto mb-4 mr-2 h-16 md:m-4" />
        </RouterLink>

        <Card>
          {loadingState.status !== "fulfilled" && (
            <>
              <h1 className="my-4 text-center text-2xl font-bold text-gray-700">
                {t("auth.signup.createANewAccount", "Create a new account")}
              </h1>

              <SignupForm onSignup={onSignup} />

              {loadingState.status === "rejected" && (
                <p className="mt-6 text-red">
                  {t(
                    "auth.signup.error",
                    "An error occurred during signup. Make sure the email address is not yet registered"
                  )}
                </p>
              )}

              <div className="mt-6">
                <p className="text-center text-sm text-gray-600">
                  {t("auth.signup.or", "or")}{" "}
                  <RouterLink to={"/"} className="text-blue">
                    {t(
                      "auth.signup.loginInstead",
                      "login to your existing account"
                    )}
                  </RouterLink>
                </p>
              </div>
            </>
          )}

          {loadingState.status === "fulfilled" && (
            <>
              {loadingState.status === "fulfilled" && (
                <>
                  <h1 className="my-4 text-center text-2xl font-bold text-gray-700">
                    {t("auth.signup.thankYou", "Thank you for signing up!")}
                  </h1>

                  <div className="mt-6">
                    <p>
                      {t(
                        "auth.signup.receivedEmailWithActivationCode",
                        "You will receive a mail with a one time password. Please login using the password"
                      )}
                    </p>

                    <p className="mt-6 text-center text-sm text-gray-600">
                      <RouterLink to={"/"} className="text-blue">
                        {t(
                          "auth.signup.loginWithOneTimePassword",
                          "Login to you account using your one time password"
                        )}
                      </RouterLink>
                    </p>
                  </div>
                </>
              )}
            </>
          )}
        </Card>

        <div className={"mt-8 text-center"}>
          <LanguageSelector />
        </div>
      </div>
    </div>
  );
}
