import React from "react";
import { classNames } from "../util";

type state = "good" | "bad" | "neutral";

export function GoodBadStat({
  state,
  labelGood = "okay",
  labelBad = "error",
  labelNeutral = "-",
}: {
  state: state | undefined;
  labelGood: string;
  labelBad: string;
  labelNeutral?: string;
}) {
  const fontColorClass = ((): string => {
    switch (state) {
      case "good":
        return " text-green";
      case "bad":
        return " text-red";
      case "neutral":
        return " text-gray";
    }
    return "";
  })();

  return (
    <span className={classNames(fontColorClass, "font-mono")}>
      {state === "good" ? labelGood : state === "bad" ? labelBad : labelNeutral}
    </span>
  );
}
