import { classNames } from "../../app/util";
import React from "react";

export type BadeProps = {
  label: string;
  Icon?: React.ElementType;
  variant?: "small" | "normal" | "large";
  color?: string;
  textColor?: string;
};

export function Badge({
  label,
  variant = "normal",
  color = "#000000",
  textColor = "#ffffff",
  Icon,
}: BadeProps) {
  const sizeStyles = (): string => {
    switch (variant) {
      case "small":
        return "px-2 py-0.5 text-xs";
      case "large":
        return "px-4 py-2  text-base";
      default:
        return "px-2 py-1 text-sm";
    }
  };

  return (
    <div
      style={{ backgroundColor: color, color: textColor }}
      className={classNames(
        sizeStyles(),
        "inline-flex items-center justify-items-center gap-1 whitespace-nowrap rounded-full leading-5"
      )}
    >
      <span className="">{label}</span>

      {Icon ? <Icon className={classNames("h-4 w-4")} /> : null}
    </div>
  );
}
