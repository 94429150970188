import { Badge } from "./badge";
import React from "react";
import { useTheme } from "../../app/theme-provider";
import {
  ArrowDownRightIcon,
  ArrowDownIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  ArrowUpRightIcon,
} from "@heroicons/react/24/solid";
type Trend =
  | "side"
  | "up-strong"
  | "up-moderate"
  | "down-moderate"
  | "down-strong";

/**
 * Calculate a trend based on an increase decrease
 * @param value
 * @param moderateThreshold Acceptable
 * @param strongThreshold
 */
export function calculateTrend(
  value: number | undefined,
  moderateThreshold = 5,
  strongThreshold = 10
): Trend {
  if (value === undefined) {
    return "side";
  }

  if (value > moderateThreshold) {
    if (value > strongThreshold) {
      return "up-strong";
    } else {
      return "up-moderate";
    }
  } else if (value < -moderateThreshold) {
    if (value < -strongThreshold) {
      return "down-strong";
    } else {
      return "down-moderate";
    }
  } else {
    return "side";
  }
}

export type TrendBadgeProps = {
  trend: Trend;
  label: string;
};

export function TrendBadge({ trend, label }: TrendBadgeProps) {
  const { semanticColors } = useTheme();

  const t = (() => {
    switch (trend) {
      case "side":
        return {
          icon: ArrowRightIcon,
          backgroundColor: semanticColors.neutral,
        };
      case "up-moderate":
        return {
          icon: ArrowUpRightIcon,
          backgroundColor: semanticColors.warning,
        };
      case "up-strong":
        return {
          icon: ArrowUpIcon,
          backgroundColor: semanticColors.bad,
        };
      case "down-strong":
        return {
          icon: ArrowDownIcon,
          backgroundColor: semanticColors.good,
        };
      case "down-moderate":
        return {
          icon: ArrowDownRightIcon,
          backgroundColor: semanticColors.good,
        };
    }
  })();

  return (
    <Badge
      variant={"small"}
      color={t.backgroundColor}
      label={label}
      Icon={t.icon}
    />
  );
}
