import { useTranslation } from "react-i18next";
import {
  Totals,
  useAnalytics,
} from "../../features/analytics/analytics-data-loader-context";
import { formattedValueToString, getValueFormat } from "@nantis/grafana-data";
import { calculateTotalsComparison } from "./utils";
import { calculateTrend, TrendBadge } from "./trend-badge";
import { Text } from "./text";
import { AnimatedNumber } from "./animated-number";
import React from "react";
import { getMetricHelper } from "@nantis/gridknight-core";
import { useAppSelector } from "../../app/store";
import { selectTenantCurrencySymbol } from "../../features/tenants/tenants-slice";

export type TotalMetricStatsProps = {
  metricKey: keyof Totals;
  label: string;
  decimals?: number;
  showTrend?: boolean;
};

export function TotalMetricStat({
  metricKey,
  label,
  decimals = 0,
  showTrend = true,
}: TotalMetricStatsProps) {
  const { t } = useTranslation();
  const currencySymbol = useAppSelector((state) =>
    selectTenantCurrencySymbol(state)
  );
  const { data } = useAnalytics();

  let comparisonString: string;
  let unit;

  const firstDataSet = data.length ? data[0] : undefined;

  const percentFormatter = getValueFormat("percent");

  const totalsComparison = calculateTotalsComparison(firstDataSet, metricKey);

  if (metricKey === "ea_fwd_t_d_cost" || metricKey === "er_t_d_cost") {
    const currencyFormatter = getValueFormat(currencySymbol);
    unit = currencySymbol;
    comparisonString = formattedValueToString(
      currencyFormatter(totalsComparison.previousTotal ?? 0, decimals)
    );
  } else {
    const metricHelper = getMetricHelper(metricKey);
    const metricFormatter = getValueFormat(metricHelper.unit.id);
    unit = metricHelper.unit.id;
    comparisonString = formattedValueToString(
      metricFormatter(totalsComparison.previousTotal ?? 0, decimals)
    );
  }

  const trend = calculateTrend(totalsComparison.percentChange, 5, 10);

  return (
    <div className="flex h-full grow flex-col gap-1">
      <div className={"flex flex-wrap justify-between gap-1"}>
        <Text>{label}</Text>

        {showTrend && (
          <TrendBadge
            trend={trend}
            label={formattedValueToString(
              percentFormatter(totalsComparison.percentChange ?? 0, 0)
            )}
          />
        )}
      </div>

      <div className="flex grow flex-col justify-between gap-1">
        {totalsComparison.currentTotal !== undefined ? (
          <AnimatedNumber
            unit={unit}
            value={totalsComparison.currentTotal}
            decimalPoints={decimals}
            className={"text-gray-900 lg:text-2xl"}
          />
        ) : (
          "-"
        )}

        <Text variant="small">
          {t("stats.inCurrentTimerange", "in current period")}
        </Text>
        <Text variant={"small"}>
          {t("stats.previousValue", "previously {{value}}", {
            value:
              totalsComparison.previousTotal !== undefined
                ? comparisonString
                : "-",
          })}
        </Text>
      </div>
    </div>
  );
}
