import React, { PropsWithChildren, useState } from "react";
import { useInterval } from "./use-interval";

const TimeContext = React.createContext<{
  now: Date;
}>({
  now: new Date(),
});

export const useTime = () => {
  return React.useContext(TimeContext);
};

export function TimeProvider({ children }: PropsWithChildren) {
  const [now, setNow] = useState<Date>(new Date());

  useInterval(() => {
    setNow(new Date());
  }, 1000);

  return (
    <TimeContext.Provider value={{ now }}>{children}</TimeContext.Provider>
  );
}
