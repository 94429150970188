import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  deleteTariffRate,
  selectDeleteTariffRateLoadingState,
} from "../tariff-slice";
import {
  TableCell,
  TableHeadCell,
  TableRow,
} from "../../../components/table/table";
import React from "react";
import { useTranslation } from "react-i18next";
import { Application, getRateFromTariffRates } from "@nantis/gridknight-core";
import { selectTenantCurrency } from "../../tenants/tenants-slice";
import { currencySymbols } from "../../../app/util";
import { LoadingButton } from "../../../components/ng";

export function TariffRateTable({
  tariffRates,
}: {
  tariffRates: Application.TariffRate[];
}) {
  const { t } = useTranslation();

  const activeRate = getRateFromTariffRates(tariffRates, new Date());

  return (
    <table className="min-w-full table-auto divide-y text-left">
      <thead className="bg-gray-50 text-gray-700">
        <tr>
          <TableHeadCell className="table-cell">
            {t("tariffRates.from", "Valid from")}
          </TableHeadCell>
          <TableHeadCell>
            {t("tariffs.currentRate", "current rate")}
          </TableHeadCell>
          <TableHeadCell>
            <span className="sr-only">{t("tariffs.edit", "edit tariff")}</span>
          </TableHeadCell>
        </tr>
      </thead>

      <tbody className="divide-y bg-white">
        {tariffRates.map((tariffRate) => (
          <TariffRateRow
            key={tariffRate.id}
            tariffRate={tariffRate}
            activeRate={activeRate}
          />
        ))}
        {!tariffRates.length && (
          <tr>
            <td colSpan={3} className={"p-4 italic"}>
              {t(
                "tariffRates.noTariffRatesYet",
                "you do not have any energy prices, you can create one by using the plus button on the top right"
              )}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

function TariffRateRow({
  tariffRate,
  activeRate,
}: {
  tariffRate: Application.TariffRate;
  activeRate: Application.TariffRate | null;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const deleteTariffState = useAppSelector((state) =>
    selectDeleteTariffRateLoadingState(state)
  );

  const isCurrentlyActive = activeRate && activeRate === tariffRate;

  const onDeleteTariffRate = () => {
    dispatch(
      deleteTariffRate({
        id: tariffRate.id,
      })
    );
  };

  return (
    <TableRow>
      <TableCell className={isCurrentlyActive ? "" : ""}>
        <span>
          {t(
            "tariffRates.fromDate",
            "{{date, date(day: numeric; month: short; year: numeric)}}",
            { date: tariffRate.from }
          )}
        </span>
        {isCurrentlyActive && (
          <span
            className={
              "ml-2 mr-1 flex inline-flex items-center justify-items-center whitespace-nowrap  rounded-full bg-green px-2 text-xs font-semibold leading-5 text-white"
            }
          >
            {t("tariffRates.currentTariffRate", "currently active")}
          </span>
        )}
      </TableCell>
      <TableCell>
        <HourRates hourRates={tariffRate.rate as Application.HourRate[]} />
      </TableCell>
      <TableCell>
        <div className="flex justify-end">
          <LoadingButton
            onClick={onDeleteTariffRate}
            isLoading={deleteTariffState.status === "pending"}
            variant="destructive"
            type="button"
          >
            {t("tariffRates.delete", "delete energy price")}
          </LoadingButton>
        </div>
      </TableCell>
    </TableRow>
  );
}

function HourRates({ hourRates }: { hourRates: Application.HourRate[] }) {
  const currency = useAppSelector((state) => selectTenantCurrency(state));
  const currencySymbol = currencySymbols[currency];

  return (
    <div className="flex flex-col">
      {hourRates.map((hourRate) => {
        return (
          <div key={hourRate.start}>
            <div>
              {hourRate.metrics.ea_fwd_t_d?.originalRate} {currencySymbol} / kWh
            </div>
          </div>
        );
      })}
    </div>
  );
}
