export interface ScreenBreakpoints {
  name: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
  min: number;
}

export const points: ScreenBreakpoints[] = [
  {
    name: "sm",
    min: 640,
  },
  {
    name: 'md',
    min: 768,
  },
  {
    name: 'lg',
    min: 1024,
  },
  {
    name: 'xl',
    min: 1200,
  },
  {
    name: '2xl',
    min: 1400,
  }
]

export const isVisible = (width: number, breakpoint: ScreenBreakpoints["name"] | undefined | null): boolean => {

  if(!breakpoint) {
    return true;
  }

  for (const breakpointElement of points) {
    if (breakpointElement.name == breakpoint) {
      return width >= breakpointElement.min;
    }
  }

  return false;
}

