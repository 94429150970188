import awsConfig from "../aws-config.json";
import { Amplify, Hub, Auth } from "aws-amplify";

import { Store } from "../app/store";
import { getUserInfoFromAuth } from "./auth-slice";
import { fetchCurrentUser } from "../features/tenants/users/users-slice";
import { unwrapResult } from "@reduxjs/toolkit";
import { setConnectionState } from "../app/app-slice";

// Not exported properly from pubsub
const CONNECTION_STATE_CHANGE = "ConnectionStateChange";

// Import this info from a json file (no secrets in there), could also work with react env variables, but I prefer this, must be available at build time
const { region, userPoolClientId, userPoolId, identityPoolRef, graphqlUrl } =
  awsConfig;

/**
 * Pass in the determined auth method and configure accordingly
 */
const configureAmplify = () => {
  // @ts-ignore
  // window.LOG_LEVEL = "DEBUG";

  // https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js
  // Scoped configuration is bullshit, there are no examples and the syntax is some snake_case camelcase
  // https://github.com/dabit3/appsync-auth-and-unauth
  Amplify.configure({
    // AUTH
    aws_cognito_region: region, // (required) - Region where Amazon Cognito project was created
    aws_user_pools_id: userPoolId, // (optional) -  Amazon Cognito User Pool ID
    aws_user_pools_web_client_id: userPoolClientId, // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
    aws_cognito_identity_pool_id: identityPoolRef, // (optional) - Amazon Cognito Identity Pool ID
    aws_mandatory_sign_in: "disabled", // (optional) - Users are not allowed to get the aws credentials unless they are signed in

    // API
    aws_appsync_region: region, // (optional) - AWS AppSync region
    aws_appsync_graphqlEndpoint: graphqlUrl, // (optional) - AWS AppSync endpoint
    // Seems to be more a kind of default auth type, you can override it per query, see create tenant
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS", // (optional) - Primary AWS AppSync authentication type

    // Usually we send only the access key which does not contain the username and other things encoded in the JWT
    // So we send our "own" header with the id token
    API: {
      graphql_headers: async () => {
        try {
          const session = await Auth.currentSession();
          if (session) {
            const idToken = session.getIdToken();
            if (idToken) {
              return {
                Authorization: idToken.getJwtToken(),
              };
            }
          }
        } catch (e) {
          console.warn(e);
          return {};
        }
      },
    },
  });
};

/**
 * This class connects our redux store and the amplify library
 *
 * https://richardzcode.github.io/Journal-AWS-Amplify-Tutorial/step-05/
 */
export class AmplifyBridge {
  private store: Store;

  constructor(store: Store) {
    this.store = store;

    // We can look up if there are credentials stored passed in by playwright
    // Check if we have IAM Credentials present in localstorage

    configureAmplify();

    Hub.listen("api", (data) => {
      const { payload } = data;
      this.onApiEvent(payload);
    });

    Hub.listen("auth", (data) => {
      const { payload } = data;
      this.onAuthEvent(payload);
    });

    // Initial sign in if we have credentials
    this.store
      .dispatch(getUserInfoFromAuth())
      .then(unwrapResult)
      .then(() => {
        this.store.dispatch(fetchCurrentUser());
      })
      .catch((e) => {
        console.warn(e);
      });
  }

  onApiEvent(payload: any) {
    const { event, data } = payload;
    if (event === CONNECTION_STATE_CHANGE) {
      this.store.dispatch(setConnectionState(data.connectionState));
    }
  }

  onAuthEvent(payload: any) {
    const { event } = payload;

    if (event === "signIn") {
      this.store
        .dispatch(getUserInfoFromAuth())
        .then(unwrapResult)
        .then(() => {
          this.store.dispatch(fetchCurrentUser());
        });
    }
  }
}
