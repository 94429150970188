import React from "react";
import { PageWidth } from "../../../components/containers/page-width";
import { useTranslation } from "react-i18next";
import { fetchTenant, selectFetchTenantLoadingState } from "../tenants-slice";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { CustomerSupportDetails } from "../../../components/customer-support/customer-support-details";
import { LoadingButton } from "../../../components/ng";

/**
 * Ideally this component never shows up as the backend should be fast enough, so that when the stripe webhook is called, the tenant is already updated.
 * The user gets redirected to the webapp after the checkout and the tenant can be fetched again by pressing a button.
 * @constructor
 */
export function SubscriptionPending() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const fetchTenantLoadingState = useAppSelector(selectFetchTenantLoadingState);

  const onRetry = () => {
    dispatch(fetchTenant());
  };

  return (
    <PageWidth className={"flex flex-col justify-center"}>
      <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
        {t("subscriptionPending.title", "Thank you for your order")}
      </h2>

      <p className={"mt-6 text-center"}>
        {t(
          "subscriptionPending.description",
          "We are processing the changes in the background. Please wait a moment and then reload to see the changes in effect."
        )}
      </p>

      <LoadingButton
        isLoading={fetchTenantLoadingState.status === "pending"}
        onClick={onRetry}
        className={"mt-4 mb-12 self-center"}
      >
        {t("subscriptionPending.reload", "Reload")}
      </LoadingButton>

      <CustomerSupportDetails />
    </PageWidth>
  );
}
