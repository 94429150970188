import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { Application } from "@nantis/gridknight-core";
import { FetchStatus } from "../../models/types";
import { API, graphqlOperation } from "aws-amplify";
import { RootState } from "../../app/store";
import { EntityState } from "@reduxjs/toolkit/src/entities/models";

const tariffEntityAdapter = createEntityAdapter<Application.Tariff>({
  selectId: (tariff) => tariff.id,
  sortComparer: (a, b) => b.id.localeCompare(a.id),
});

const tariffRateEntityAdapter = createEntityAdapter<Application.TariffRate>({
  selectId: (tariffRate) => tariffRate.id,
  sortComparer: (a, b) => b.id.localeCompare(a.id),
});

const initialState = tariffEntityAdapter.getInitialState<{
  fetchTariffs: FetchStatus;
  createTariffWithRate: FetchStatus;
  updateTariff: FetchStatus;
  deleteTariff: FetchStatus;
  tariffRates: EntityState<Application.TariffRate> & {
    fetchTariffRates: FetchStatus;
    createTariffRate: FetchStatus;
    deleteTariffRate: FetchStatus;
  };
}>({
  fetchTariffs: {
    status: "idle",
    error: null,
  },
  createTariffWithRate: {
    status: "idle",
    error: null,
  },
  updateTariff: {
    status: "idle",
    error: null,
  },
  deleteTariff: {
    status: "idle",
    error: null,
  },
  tariffRates: tariffRateEntityAdapter.getInitialState<{
    fetchTariffRates: FetchStatus;
    createTariffRate: FetchStatus;
    deleteTariffRate: FetchStatus;
  }>({
    fetchTariffRates: {
      status: "idle",
      error: null,
    },
    createTariffRate: {
      status: "idle",
      error: null,
    },
    deleteTariffRate: {
      status: "idle",
      error: null,
    },
  }),
});

export const fetchTariffs = createAsyncThunk(
  "tariffs/fetchTariffs",
  async (paginationArgs: Application.PaginationArguments) => {
    const { limit = 100, cursor = "" } = paginationArgs;

    const result = (await API.graphql(
      graphqlOperation(
        `
            query AllTariffsQuery($cursor: String, $limit: Int) {
              tariffs(cursor: $cursor, limit: $limit) {
                cursor
                items {
                  created_at
                  deleted_at
                  id
                  name
                  tenant_id
                  type
                }
              }
            }`,
        {
          limit,
          cursor,
        }
      )
    )) as {
      data: {
        tariffs: {
          cursor?: string;
          items: Application.Tariff[];
        };
      };
    };
    return result.data.tariffs.items;
  }
);

export const createTariffWithRate = createAsyncThunk(
  "tariffs/createTariffWithRate",
  async ({
    name,
    hourRate,
  }: {
    name: string | null;
    hourRate: Application.HourRate;
  }) => {
    const result = (await API.graphql(
      graphqlOperation(
        `
            mutation CreateTariffWithRateMutation($rate: AWSJSON, $name: String) {
              createTariffWithRate(rate: $rate, name: $name) {
                name
                id
                deleted_at
                created_at
                tenant_id
                type
                rates {
                  tenant_id
                  tariff_id
                  rate
                  id
                  from
                }
              }
            }
            `,
        {
          name,
          rate: JSON.stringify([hourRate]),
        }
      )
    )) as {
      data: {
        createTariffWithRate: Application.Tariff & {
          rates: Application.TariffRate[];
        };
      };
    };
    return {
      ...result.data.createTariffWithRate,
      rates: result.data.createTariffWithRate.rates.map((rate) => {
        return {
          ...rate,
          from: new Date(rate.from),
          rate: JSON.parse((rate?.rate as string) ?? "{}"),
        };
      }),
    };
  }
);

export const createTariffRate = createAsyncThunk(
  "tariffs/tariffRates/createTariffRate",
  async ({
    tariffId,
    from,
    hourRates,
  }: {
    tariffId: string;
    from: Date;
    hourRates: Application.HourRate[];
  }) => {
    const data = {
      tariff_id: tariffId,
      from,
      rate: JSON.stringify(hourRates),
    };

    const result = (await API.graphql(
      graphqlOperation(
        `
                mutation CreateTariffRateMutation($from: String, $rate: AWSJSON, $tariff_id: ID!) {
                  createTariffRate(tariff_id: $tariff_id, from: $from, rate: $rate) {
                    from
                    id
                    rate
                    tariff_id
                    tenant_id
                  }
                }
            `,
        data
      )
    )) as {
      data: {
        createTariffRate: Application.TariffRate;
      };
    };
    return {
      ...result.data.createTariffRate,
      from: new Date(result.data.createTariffRate.from),
      rate: JSON.parse((result.data.createTariffRate?.rate as string) ?? "{}"),
    };
  }
);

export const updateTariff = createAsyncThunk(
  "tariffs/updateTariff",
  async ({ id, name }: { id: string; name: string | null }) => {
    const result = (await API.graphql(
      graphqlOperation(
        `
            mutation UpdateTariffMutation($id: ID!, $name: String) {
                updateTariff(id: $id, name: $name) {
                    created_at
                    deleted_at
                    id
                    name
                    tenant_id
                    type
                }
            }
            `,
        {
          id,
          name,
        }
      )
    )) as {
      data: {
        updateTariff: Application.Tariff;
      };
    };

    return result.data.updateTariff;
  }
);

export const deleteTariff = createAsyncThunk(
  "tariffs/deleteTariff",
  async ({ id }: { id: string }) => {
    const result = (await API.graphql(
      graphqlOperation(
        `
            mutation DeleteTariffMutation($id: ID!) {
                deleteTariff(id: $id) {
                    created_at
                    deleted_at
                    id
                    name
                    tenant_id
                    type
                }
            }
            `,
        {
          id,
        }
      )
    )) as {
      data: {
        deleteTariff: Application.Tariff;
      };
    };

    return result.data.deleteTariff;
  }
);
export const deleteTariffRate = createAsyncThunk(
  "tariffs/tariffRates/deleteTariff",
  async ({ id }: { id: string }) => {
    const result = (await API.graphql(
      graphqlOperation(
        `
            mutation DeleteTariffRateMutation($id: ID!) {
              deleteTariffRate(id: $id) {
                id
                tenant_id
                tariff_id
                rate
                from
              }
            }

            `,
        {
          id,
        }
      )
    )) as {
      data: {
        deleteTariffRate: Application.TariffRate;
      };
    };

    return result.data.deleteTariffRate;
  }
);

export const fetchTariffsRates = createAsyncThunk(
  "tariffs/tariffRates/fetchTariffRatesQuery",
  async (paginationArgs: Application.PaginationArguments) => {
    const { limit = 100, cursor = "" } = paginationArgs;

    const result = (await API.graphql(
      graphqlOperation(
        `
            query AllTariffRatesQuery($cursor: String, $limit: Int) {
              tariffRates(cursor: $cursor, limit: $limit) {
                cursor
                items {
                  from
                  id
                  rate
                  tariff_id
                  tenant_id
                }
              }
            }
            `,
        {
          limit,
          cursor,
        }
      )
    )) as {
      data: {
        tariffRates: {
          cursor?: string;
          items: Application.TariffRate[];
        };
      };
    };

    return result.data.tariffRates.items.map((tariff) => {
      return {
        ...tariff,
        from: new Date(tariff.from),
        rate: JSON.parse((tariff?.rate as string) ?? "{}"),
      };
    });
  }
);

const tariffsSlice = createSlice({
  name: "tariffs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTariffs.pending, (state) => {
      state.fetchTariffs.status = "pending";
    });

    builder.addCase(fetchTariffs.fulfilled, (state, action) => {
      state.fetchTariffs.status = "fulfilled";
      tariffEntityAdapter.setAll(state, action.payload);
    });

    builder.addCase(fetchTariffs.rejected, (state) => {
      state.fetchTariffs.status = "rejected";
      state.fetchTariffs.error = "ERROR";
    });

    builder.addCase(fetchTariffsRates.pending, (state) => {
      state.tariffRates.fetchTariffRates.status = "pending";
    });

    builder.addCase(fetchTariffsRates.fulfilled, (state, action) => {
      state.tariffRates.fetchTariffRates.status = "fulfilled";
      tariffRateEntityAdapter.setAll(state.tariffRates, action.payload);
    });

    builder.addCase(fetchTariffsRates.rejected, (state) => {
      state.tariffRates.fetchTariffRates.status = "rejected";
      state.tariffRates.fetchTariffRates.error = "ERROR";
    });

    builder.addCase(createTariffWithRate.pending, (state) => {
      state.createTariffWithRate.status = "pending";
    });

    builder.addCase(createTariffWithRate.fulfilled, (state, action) => {
      state.createTariffWithRate.status = "fulfilled";
      tariffEntityAdapter.setOne(state, action.payload);
      tariffRateEntityAdapter.upsertMany(
        state.tariffRates,
        action.payload.rates
      );
    });

    builder.addCase(createTariffWithRate.rejected, (state) => {
      state.createTariffWithRate.status = "rejected";
      state.createTariffWithRate.error = "ERROR";
    });

    builder.addCase(updateTariff.pending, (state) => {
      state.updateTariff.status = "pending";
    });

    builder.addCase(updateTariff.fulfilled, (state, action) => {
      state.updateTariff.status = "fulfilled";
      tariffEntityAdapter.upsertOne(state, action.payload);
    });

    builder.addCase(updateTariff.rejected, (state) => {
      state.updateTariff.status = "rejected";
      state.updateTariff.error = "ERROR";
    });

    builder.addCase(deleteTariff.pending, (state) => {
      state.deleteTariff.status = "pending";
    });

    builder.addCase(deleteTariff.fulfilled, (state, action) => {
      state.deleteTariff.status = "fulfilled";
      tariffEntityAdapter.upsertOne(state, action.payload);
    });

    builder.addCase(deleteTariff.rejected, (state) => {
      state.deleteTariff.status = "rejected";
      state.deleteTariff.error = "ERROR";
    });

    builder.addCase(createTariffRate.pending, (state) => {
      state.tariffRates.createTariffRate.status = "pending";
    });

    builder.addCase(createTariffRate.fulfilled, (state, action) => {
      state.tariffRates.createTariffRate.status = "fulfilled";
      tariffRateEntityAdapter.upsertOne(state.tariffRates, action.payload);
    });

    builder.addCase(createTariffRate.rejected, (state) => {
      state.tariffRates.createTariffRate.status = "rejected";
      state.createTariffWithRate.error = "ERROR";
    });

    builder.addCase(deleteTariffRate.pending, (state) => {
      state.tariffRates.deleteTariffRate.status = "pending";
    });

    builder.addCase(deleteTariffRate.fulfilled, (state, action) => {
      state.tariffRates.deleteTariffRate.status = "fulfilled";
      tariffRateEntityAdapter.removeOne(state.tariffRates, action.payload.id);
    });

    builder.addCase(deleteTariffRate.rejected, (state) => {
      state.tariffRates.deleteTariffRate.status = "rejected";
      state.deleteTariff.error = "ERROR";
    });
  },
});

export const reducer = tariffsSlice.reducer;

export const { selectAll: selectAllTariffs, selectById: selectTariffById } =
  tariffEntityAdapter.getSelectors<RootState>((state) => state.tariffs);

export const {
  selectAll: selectAllTariffRates,
  selectById: selectTariffRateById,
} = tariffRateEntityAdapter.getSelectors<RootState>(
  (state) => state.tariffs.tariffRates
);

export const selectAllNonDeletedTariffs = createSelector(
  [selectAllTariffs, (state: RootState) => state],
  (tariffs) => tariffs.filter((tariffs) => tariffs.deleted_at == null)
);

export const selectFetchTariffsLoadingState = (state: RootState) =>
  state.tariffs.fetchTariffs;
export const selectCreateTariffWithRateLoadingState = (state: RootState) =>
  state.tariffs.createTariffWithRate;
export const selectUpdateTariffLoadingState = (state: RootState) =>
  state.tariffs.updateTariff;
export const selectDeleteTariffLoadingState = (state: RootState) =>
  state.tariffs.deleteTariff;
export const selectFetchTariffRatesLoadingState = (state: RootState) =>
  state.tariffs.tariffRates.fetchTariffRates;
export const selectCreateTariffRateLoadingState = (state: RootState) =>
  state.tariffs.tariffRates.createTariffRate;
export const selectDeleteTariffRateLoadingState = (state: RootState) =>
  state.tariffs.tariffRates.deleteTariffRate;

export const selectTariffRatesByTariffId = createSelector(
  [selectAllTariffRates, (state: RootState, tariffId: string) => tariffId],
  (tariffRates, tariffId) =>
    tariffRates.filter((tariffRate) => tariffRate.tariff_id === tariffId)
);

export const selectTariffsByTenant = createSelector(
  [
    selectAllNonDeletedTariffs,
    (state: RootState, tenantId: string) => tenantId,
  ],
  (tariffs, tenantId) =>
    tariffs.filter((tariff) => tariff.tenant_id === tenantId)
);
