import React from "react";
import { MapContainer, TileLayer, Marker, Circle } from "react-leaflet";
import { getAccuracy } from "../../../models/device";
import { useAppSelector } from "../../../app/store";
import { selectDeviceStateByDeviceId } from "../devices-slice";

export function DeviceLocationMap({ deviceId }: { deviceId: string }) {
  const status = useAppSelector((state) =>
    selectDeviceStateByDeviceId(state, deviceId)
  );

  const markerColor = "#6ce4ec";

  if (status && status.cellular && status.location) {
    const location = status.location;
    const accuracy = getAccuracy(status.cellular, status.location);

    return (
      <>
        {location?.resolved && location.lat && location.lng && (
          <MapContainer
            center={[location.lat, location.lng]}
            zoom={12}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            <Marker position={[location.lat, location.lng]} />

            <Circle
              center={[location.lat, location.lng]}
              pathOptions={{ color: markerColor }}
              radius={accuracy}
            ></Circle>
          </MapContainer>
        )}
      </>
    );
  } else {
    return null;
  }
}
