import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  unpairDevices,
  selectUnpairDevicesLoadingState,
} from "../../devices-slice";
import { useNavigate } from "react-router-dom";
import ConfirmationModal, {
  ConfirmationModalProps,
} from "../../../../components/modals/confirmation-modal";
import { LoadingButton } from "../../../../components/ng";

export function UnpairDevice({ deviceId }: { deviceId: string }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [openUnpairModal, setOpenUnpairModal] = useState(false);

  const unpairLoadingState = useAppSelector((state) =>
    selectUnpairDevicesLoadingState(state)
  );

  const onUnpairDevice = () => {
    setOpenUnpairModal(true);
  };

  const onConfirmUnpairDevice = () => {
    dispatch(unpairDevices([deviceId])).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        navigate({
          pathname: "/devices",
        });
      }
    });
  };

  const onCancelUnpairDevice = () => {
    setOpenUnpairModal(false);
  };

  const modalProps: ConfirmationModalProps = {
    variant: "danger",
    open: openUnpairModal,
    onConfirm: onConfirmUnpairDevice,
    onCancel: onCancelUnpairDevice,
    title: t("device.unpair.title", "Remove device"),
    confirmationButtonText: t("device.unpair.unpair", "remove device"),
    description: t(
      "device.unpair.noAccessAfterRemove",
      "you will have no access to the device after removing. settings will be lost."
    ),
  };

  return (
    <div>
      <ConfirmationModal {...modalProps} />

      <p className="mb-2 text-sm">
        {t(
          "device.unpair.hint",
          "if you want to pair the device with another account you will need to remove it"
        )}
      </p>

      <p className="mt-2 text-sm italic">
        {t(
          "device.unpair.noAccessAfterRemove",
          "you will have no access to the device after removing. settings will be lost."
        )}
      </p>

      <div className="flex justify-end">
        <LoadingButton
          onClick={onUnpairDevice}
          isLoading={unpairLoadingState.status === "pending"}
          form="deviceSettingsForm"
          variant="destructive"
          type="button"
        >
          {t("device.unpair.unpair", "remove device")}
        </LoadingButton>
      </div>
    </div>
  );
}
