import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

export function CallToActionPairDevice() {
  const { t } = useTranslation();

  return (
    <div className="mx-auto max-w-2xl py-4 px-4 text-center sm:py-20 sm:px-6 md:py-16 lg:px-8">
      <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
        <span className="block">
          {t(
            "device.pair.noDevicesYet",
            "It looks like you have not registered any devices with your account yet"
          )}
        </span>
      </h2>

      <p className="mt-4 text-lg leading-6 text-gray-700">
        {t(
          "device.pair.ctaPairDevice",
          "Add your first device to your account"
        )}
      </p>
      <RouterLink
        to={"/devices/pair"}
        className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green px-5 py-3 text-base text-white sm:w-auto"
      >
        {t("device.pair.pairDevice", "Pair device")}
      </RouterLink>
    </div>
  );
}
