import {
  ExclamationCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import React, { PropsWithChildren } from "react";
import { Card } from "./card";
import { classNames } from "../../app/util";

export type NotificationContainerProps = {
  variant: "warning" | "info" | "error";
};

export function NotificationCard({
  children,
  className,
  variant = "info",
  noBackground = false,
}: PropsWithChildren<
  NotificationContainerProps & {
    className?: string;
    noBackground?: boolean;
  }
>) {
  let borderClass = "border-blue";
  let bgClass = "";

  switch (variant) {
    case "warning":
      borderClass = "border-orange";
      bgClass = "bg-orange/5";
      break;
    case "info":
      bgClass = "bg-blue/5";
      borderClass = "border-blue";
      break;
    case "error":
      bgClass = "bg-red/5";
      borderClass = "border-red";
      break;
  }

  return (
    <Card
      className={classNames(
        className,
        "border-l-4",
        borderClass,
        noBackground ? "" : bgClass
      )}
    >
      <Notification variant={variant}>{children}</Notification>
    </Card>
  );
}

export function Notification({
  children,
  variant = "info",
}: PropsWithChildren<NotificationContainerProps>) {
  const icon = (() => {
    switch (variant) {
      case "warning":
        return (
          <ExclamationCircleIcon className="-mt-1 mr-4 inline-block h-6 w-6 text-orange" />
        );
      case "info":
        return (
          <InformationCircleIcon className="-mt-1 mr-4 inline-block h-6 w-6 text-blue" />
        );
      case "error":
        return (
          <ExclamationCircleIcon className="-mt-1 mr-4 inline-block h-6 w-6 text-red" />
        );
      default:
        return (
          <InformationCircleIcon className="-mt-1 mr-4 inline-block h-6 w-6 text-orange" />
        );
    }
  })();

  return (
    <div
      className={
        "flex flex-grow flex-row items-center items-stretch text-sm text-gray-700"
      }
    >
      <div className={"flex content-start"}>{icon}</div>
      <div className="w-full">{children}</div>
    </div>
  );
}
