import React, { HTMLAttributes } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { Application } from "@nantis/gridknight-core";
import {
  selectAllTariffs,
  selectTariffById,
} from "../../../tariff/tariff-slice";
import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { classNames } from "../../../../app/util";
import { useTranslation } from "react-i18next";
import { updateDevice } from "../../devices-slice";

export function DeviceTariff({
  device,
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  device: Application.DeviceWithTags;
}) {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const tariff = useAppSelector((state) =>
    selectTariffById(state, device.tariff_id ?? "")
  );
  const tariffs = useAppSelector((state) => selectAllTariffs(state));

  const onChange = async (selectedTariff: Application.Tariff) => {
    if (selectedTariff) {
      await dispatch(
        updateDevice({
          ...device,
          tariff_id: selectedTariff.id,
        })
      );
    }
  };

  const removeEnergyTariff = async () => {
    if (device) {
      await dispatch(
        updateDevice({
          ...device,
          tariff_id: null,
        })
      );
    }
  };

  return (
    <>
      <div {...props} className="flex justify-between gap-2">
        <div className="flex-grow">
          <Listbox value={tariff} onChange={onChange}>
            {({ open }) => (
              <>
                <div className="relative">
                  <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm sm:text-sm">
                    <span className="block truncate">
                      {tariff?.name ?? "-"}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {tariffs.map((tariff) => (
                        <Listbox.Option
                          key={tariff.id}
                          className={({ active }) =>
                            classNames(
                              active ? "bg-gray-50" : "",
                              "relative cursor-default select-none py-2 pl-8 pr-4"
                            )
                          }
                          value={tariff}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "block truncate"
                                )}
                              >
                                {tariff.name}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? "" : "",
                                    "absolute inset-y-0 left-0 flex items-center pl-1.5 text-green"
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>

        <button
          onClick={removeEnergyTariff}
          type="button"
          title={t("device.settings.tariff.remove", "remove from tariff")}
          className="inline-flex justify-center rounded-md border border-transparent bg-red py-2 px-4 text-sm text-white shadow-sm focus:outline-none"
        >
          <TrashIcon
            onClick={removeEnergyTariff}
            className="h-5 w-5 cursor-pointer"
            aria-hidden="true"
          />
        </button>
      </div>
    </>
  );
}
