import { Application } from "@nantis/gridknight-core";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
  createWebPushSubscription,
  selectUpdateUserLoadingState,
  updateCurrentUser,
} from "../tenants/users/users-slice";
import React, { ChangeEvent, useState } from "react";
import { ToggleSwitch } from "../../components/inputs/toggle-switch";
import { LoadingButton } from "../../components/ng";
import { WebpushSubscriptionButton } from "./webpush-subscription-button";

export type UserWithNotificationConfiguration = Application.User & {
  notification_configuration: Application.Notifications.NotificationConfiguration;
};

export function UserSettings({
  currentUser,
}: {
  currentUser: Application.User;
}) {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const updateUserSettingsLoadingState = useAppSelector(
    selectUpdateUserLoadingState
  );

  const defaultConfiguration: Application.Notifications.NotificationConfiguration =
    {
      email: false,
      "web-push": false,
    };

  const userWithConfiguration: UserWithNotificationConfiguration = {
    ...currentUser,
    notification_configuration: JSON.parse(
      currentUser?.notification_configuration as string
    ) as Application.Notifications.NotificationConfiguration,
  };

  const [user, setUser] = useState<UserWithNotificationConfiguration>(
    userWithConfiguration as UserWithNotificationConfiguration
  );

  const submitForm = (e: any) => {
    e.preventDefault();
    if (user) {
      dispatch(updateCurrentUser(user));
    }
  };

  const onWebPushNotificationConfigurationChange = (webPush: boolean) => {
    setUser({
      ...user,
      notification_configuration: {
        ...(typeof user.notification_configuration === "object"
          ? user.notification_configuration
          : defaultConfiguration),
        "web-push": webPush,
      },
    });
  };

  const onEmailNotificationConfigurationChange = (email: boolean) => {
    setUser({
      ...user,
      notification_configuration: {
        ...(typeof user.notification_configuration === "object"
          ? user.notification_configuration
          : defaultConfiguration),
        email: email,
      },
    });
  };

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUser({
      ...user,
      name: e.target.value,
    });
  };

  const onSubscribeToDeviceNotifications = (subscription: PushSubscription) => {
    dispatch(createWebPushSubscription(subscription));
  };

  // The subscriptions will actually delete themselves if they are called and return a 410 or 401
  const onUnsubscribeToDeviceNotifications = (
    subscription: PushSubscription
  ) => {};

  return (
    <form id="userSettingsForm" onSubmit={submitForm}>
      {/* Profile section */}
      <div>
        <h2 className="text-lg leading-6 text-gray-900">
          {" "}
          {t("user.settings.userProfile", "User profile")}
        </h2>
        <p className="mt-1 text-sm text-gray-500">
          {t(
            "user.settings.onlyVisibleInAccount",
            "This information is only visible within your account"
          )}
        </p>
      </div>

      <div className="mt-6 flex flex-col lg:flex-row">
        <div className="flex-grow space-y-6">
          <div className="mt-6">
            <label htmlFor="name" className="block text-sm text-gray-700">
              {t("user.settings.name", "Name")}
            </label>
            <input
              type="text"
              value={user.name ?? ""}
              onChange={onNameChange}
              name="first_name"
              id="name"
              autoComplete="given-name"
              className="focus:ring-light-blue-500 focus:border-light-blue-500 mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:outline-none sm:text-sm"
            />
          </div>
        </div>
      </div>

      {/* Privacy section */}
      <div className="">
        <ul className="mt-2 divide-y divide-gray-200">
          <li className="flex items-center justify-between gap-1 py-4">
            <div className="flex flex-col">
              <p className="text-sm text-gray-900">
                {t(
                  "user.settings.notifications.email.label",
                  "Email notifications for Alerts"
                )}
              </p>
              <p className="text-sm text-gray-500">
                {t(
                  "user.settings.notifications.email.description",
                  "Get notifications via email when a device violates a threshold"
                )}
              </p>
            </div>
            <ToggleSwitch
              enabled={user?.notification_configuration?.email}
              setEnabled={onEmailNotificationConfigurationChange}
            />
          </li>

          <li className="flex items-center justify-between gap-1 py-4">
            <div className="flex flex-col">
              <p className="text-sm text-gray-900">
                {t(
                  "user.settings.notifications.webpush.label",
                  "Push notifications for Alerts"
                )}
              </p>
              <p className="text-sm text-gray-500">
                {t(
                  "user.settings.notifications.webpush.description",
                  "Get push notifications when a device violates a threshold"
                )}
              </p>
            </div>

            <div className="hidden md:block">
              <WebpushSubscriptionButton
                onSubscribeToDeviceNotifications={
                  onSubscribeToDeviceNotifications
                }
                onUnsubscribeToDeviceNotifications={
                  onUnsubscribeToDeviceNotifications
                }
              />
            </div>

            <ToggleSwitch
              enabled={user?.notification_configuration?.["web-push"] ?? false}
              setEnabled={onWebPushNotificationConfigurationChange}
            />
          </li>
          <li className="flex items-center justify-between gap-1 py-4">
            <div className="md:hidden">
              <WebpushSubscriptionButton
                onSubscribeToDeviceNotifications={
                  onSubscribeToDeviceNotifications
                }
                onUnsubscribeToDeviceNotifications={
                  onUnsubscribeToDeviceNotifications
                }
              />
            </div>
          </li>
        </ul>
      </div>
      <div className="mt-2 flex justify-end">
        <LoadingButton
          isLoading={updateUserSettingsLoadingState.status === "pending"}
          form="userSettingsForm"
          variant="success"
          type="submit"
        >
          {t("user.settings.save", "Save")}
        </LoadingButton>
      </div>
    </form>
  );
}
