import React, { PropsWithChildren, useState } from "react";
import {
  Application,
  calculateEnergyCostRateForBaseCurrencyUnitPerKiloWattHour,
} from "@nantis/gridknight-core";
import { useTranslation } from "react-i18next";
import { EnergyPriceInputField } from "../../../components/inputs/energy-price-input-field";
import { useAppSelector } from "../../../app/store";
import { selectTenantCurrency } from "../../tenants/tenants-slice";
import { currencySymbols } from "../../../app/util";
import { Calendar } from "../../../components/time-range-picker/calendar";

export function TariffRateForm({
  existingTariffRate,
  onSave,
  ...props
}: PropsWithChildren<{
  existingTariffRate?: Application.TariffRate;
  onSave: (
    tariffRate: Pick<Application.TariffRate, "from">,
    hourRates: Application.HourRate[]
  ) => Promise<void>;
}>) {
  const { t, i18n } = useTranslation();

  const currency = useAppSelector((state) => selectTenantCurrency(state));
  const currencySymbol = currencySymbols[currency];

  const today = new Date();

  const initialState = {
    from: new Date(),
  };

  const [tariffRate, setTariffRate] = useState<
    Pick<Application.TariffRate, "from">
  >(existingTariffRate ?? initialState);

  const onChangeFrom = (date: Date) => {
    setTariffRate({
      ...tariffRate,
      from: date,
    });
  };

  const rateActive: Application.Rate =
    calculateEnergyCostRateForBaseCurrencyUnitPerKiloWattHour(0);

  const [activeRate, setActiveRate] = useState<Application.Rate>(rateActive);

  const onChangeActiveEnergyPrice = (rate: number) => {
    const costsPerKWh =
      calculateEnergyCostRateForBaseCurrencyUnitPerKiloWattHour(rate);

    setActiveRate({
      ...costsPerKWh,
    });
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    await onSave(tariffRate, [
      {
        start: 0,
        metrics: {
          ea_fwd_t_d: activeRate,
        },
      },
    ]);
  };

  return (
    <form id="tariffRateForm" className={"mb-6"} onSubmit={onSubmit}>
      <div className="space-y-6 sm:space-y-5">
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
          <label htmlFor="from" className="block text-sm text-gray-700">
            {t("tariffRates.from", "Active from")}
          </label>
          <div id={"from"} className="relative mt-1 flex flex-col">
            {/*@ts-ignore*/}
            <Calendar
              minDate={today}
              locale={i18n.language}
              date={tariffRate.from}
              onDateSelected={onChangeFrom}
            />
          </div>
        </div>
      </div>

      <div className="mt-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="activeEnergyPrice"
          className="block text-sm text-gray-700"
        >
          {t("tariffRates.activeEnergyPrice", "Active Energy price")}
        </label>
        <div>
          <EnergyPriceInputField
            rate={activeRate}
            onChange={onChangeActiveEnergyPrice}
            currencySymbol={currencySymbol}
            unit={"kWh"}
          />

          <p className="mt-2 text-sm text-gray-500">
            {t(
              "tariffRates.create.hint6Digits",
              "you can enter the tariff rate with up to 6 digits after the decimal point"
            )}
          </p>
        </div>
      </div>

      {props.children}
    </form>
  );
}
