import { classNames } from "../../app/util";
import { useTranslation } from "react-i18next";
import { NavLink, useMatch, useNavigate } from "react-router-dom";
import { PlugIconBlank, NetworkIcon, SingleUserIcon } from "../../assets/icons";
import { HTMLAttributes } from "react";
import { Select } from "../../components/inputs/select";

type Tab = {
  name: string;
  to: string;
  icon: any;
  current: boolean;
};

export function SettingsTabNavigation(props: HTMLAttributes<HTMLDivElement>) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const tabs: Tab[] = [
    {
      name: t("settings.devices", "devices"),
      to: "/settings/devices",
      icon: PlugIconBlank,
    },
    {
      name: t("settings.account", "account"),
      to: "/settings/account",
      icon: NetworkIcon,
    },
    {
      name: t("settings.profile", "profile"),
      to: "/settings/profile",
      icon: SingleUserIcon,
    },
  ].map((t) => {
    return {
      ...t,
      current: !!useMatch(`${t.to}/*`),
    };
  });

  const currentTab = tabs.find((tab) => tab.current);

  const onSetTab = (tab: Tab) => {
    navigate({
      pathname: tab.to,
    });
  };

  return (
    <div {...props}>
      {/* Mobile */}
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          {t("settings.selectATab", "select a tab")}
        </label>

        <Select<Tab>
          getKey={(tab) => tab?.to ?? ""}
          getLabel={(tab) => tab?.name ?? "label"}
          options={tabs}
          selected={currentTab}
          setSelected={onSetTab}
        />
      </div>

      {/* Desktop */}
      <div className="hidden sm:block">
        <nav
          className="relative z-0 flex divide-x divide-gray-200 rounded-lg shadow"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => (
            <NavLink
              key={tab.name}
              to={tab.to}
              className={classNames(
                tab.current
                  ? "text-gray-900"
                  : "text-gray-500 hover:text-gray-700",
                tabIdx === 0 ? "rounded-l-lg" : "",
                tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                "text-md group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center hover:bg-gray-50 focus:z-10"
              )}
              aria-current={tab.current ? "page" : undefined}
            >
              <span className="inline-flex items-center justify-items-center">
                <tab.icon
                  className="mr-2 h-8 w-8 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                {tab.name}
              </span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.current ? "bg-blue" : "bg-white",
                  "absolute inset-x-0 bottom-0 h-0.5"
                )}
              />
            </NavLink>
          ))}
        </nav>
      </div>
    </div>
  );
}
