import { HTMLAttributes } from "react";
import { classNames } from "../../app/util";

export function PageWidth({
  children,
  className,
  variant = "normal",
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  variant?: "full" | "normal";
}) {
  return (
    <div
      className={classNames(
        variant === "normal" ? "container" : "",
        "mx-auto p-2 md:p-2 lg:p-4 lg:px-8",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}
