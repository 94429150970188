import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { Link as RouterLink } from "react-router-dom";
import { selectDeviceById, fetchDeviceRules } from "../../devices-slice";
import { useTranslation } from "react-i18next";
import { DeviceRuleSettings } from "./device-rules-settings";
import { DeviceSettings } from "./device-settings";
import { Card } from "../../../../components/containers/card";
import { UnpairDevice } from "./unpair-device";
import { PageHeadline } from "../../../../components/text/page-headline";
import { Can } from "../../../../auth/user-ability-provider";
import { DeviceTags } from "./device-tags";
import { DeviceTariff } from "./device-tariff";
import { PageWidth } from "../../../../components/containers/page-width";
import { FlexContainer } from "../../../../components/containers/flex-container";

export function DeviceSettingsPage({ deviceId }: { deviceId: string }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const device = useAppSelector((state) => selectDeviceById(state, deviceId));

  useEffect(() => {
    dispatch(fetchDeviceRules(deviceId));
  }, [dispatch, deviceId]);

  return (
    <PageWidth>
      <FlexContainer>
        <PageHeadline>{`${t(
          "device.settings.titleDeviceAndId",
          "Settings for device {{deviceName}} with id {{deviceId",
          {
            deviceId: deviceId,
            deviceName: device?.name,
          }
        )}`}</PageHeadline>

        <div className={"mb-4 text-sm"}>
          <RouterLink to={`..`}>
            <span aria-hidden="true">&larr;</span>{" "}
            {t("device.settings.backToDevice", "back to device")}
          </RouterLink>
        </div>

        <Card>
          <div className="md:p-2">
            <h2 className="mb-4 text-lg">
              {t("device.settings.deviceSettings.title", "Device Settings")}
            </h2>
            <DeviceSettings deviceId={deviceId} />
          </div>
        </Card>

        <Can I="use" a="events">
          <Card>
            <div className="md:p-2">
              <h2 className="mb-4 text-lg">
                {t("device.events.settings.title", "Settings Rules")}
              </h2>
              <DeviceRuleSettings maxRules={6} device_id={deviceId} />
            </div>
          </Card>
        </Can>

        <Can I="attach" a="tariff">
          <Card>
            <div className="md:p-2">
              <h2 className="mb-4 text-lg">
                {t("device.settings.tariff.title", "Energy tariff")}
              </h2>

              {device && <DeviceTariff device={device} />}
            </div>
          </Card>
        </Can>

        <Can I="attach" a="tag">
          <Card>
            <div className="md:p-2">
              <h2 className="mb-4 text-lg">
                {t("device.tags.title", "Device Groups")}
              </h2>

              {device && <DeviceTags device={device} />}
            </div>
          </Card>
        </Can>

        <Can I="unpair" a="device">
          <Card>
            <div className="md:p-2">
              <h2 className="mb-4 text-lg">
                {t("device.unpair.title", "Remove device")}
              </h2>
              <UnpairDevice deviceId={deviceId} />
            </div>
          </Card>
        </Can>
      </FlexContainer>
    </PageWidth>
  );
}
