import React, { ChangeEvent } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  deviceUpdated,
  selectDeviceById,
  updateDevice,
  selectUpdateDeviceLoadingState,
} from "../../devices-slice";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "../../../../components/ng";

export function DeviceSettings({ deviceId }: { deviceId: string }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const device = useAppSelector((state) => selectDeviceById(state, deviceId));
  const updateDeviceState = useAppSelector((state) =>
    selectUpdateDeviceLoadingState(state)
  );

  const onSave = async (event: any) => {
    event.preventDefault();

    if (device) {
      await dispatch(updateDevice(device));
    }
  };

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    if (device) {
      dispatch(
        deviceUpdated({
          ...device,
          name: e.target.value,
        })
      );
    }
  };

  return (
    <div>
      <form
        id="deviceSettingsForm"
        className="mb-4 divide-y divide-gray-200 lg:col-span-9"
        onSubmit={onSave}
      >
        <div className="flex flex-col lg:flex-row">
          <div className="flex-grow space-y-6">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12 sm:col-span-6">
                <label
                  htmlFor="first_name"
                  className="block text-sm text-gray-700"
                >
                  {t("device.settings.name", "Name")}
                </label>
                <input
                  value={device?.name ?? ""}
                  onChange={onChangeName}
                  type="text"
                  maxLength={30}
                  name="first_name"
                  id="first_name"
                  autoComplete="given-name"
                  className="focus:ring-light-blue-500 focus:border-light-blue-500 mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:outline-none sm:text-sm"
                />
              </div>
            </div>
          </div>
        </div>
      </form>

      <div className="flex justify-end text-sm">
        <LoadingButton
          isLoading={updateDeviceState.status === "pending"}
          form="deviveSettingsForm"
          onClick={onSave}
          variant="success"
          type="submit"
        >
          {t("device.settings.save", "save")}
        </LoadingButton>
      </div>
    </div>
  );
}
