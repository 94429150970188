import { Route, Routes, useParams } from "react-router-dom";
import React from "react";
import { TagDetailPage } from "./tag-detail-page";
import { TagUpdatePage } from "./update/tag-update-page";

export function Tag() {
  const { tagId } = useParams();

  return (
    <Routes>
      <Route
        path="/settings"
        element={<>{tagId && <TagUpdatePage id={tagId} />}</>}
      />
      <Route path="/*" element={<>{tagId && <TagDetailPage id={tagId} />}</>} />
    </Routes>
  );
}
