import React from "react";
import {
  AnalyticsGraph,
  AnalyticsGraphProps,
  createKeyMap,
  keyFormatterGenerator,
} from "../../components/graph/analytics-graph";
import { useAppSelector } from "../../app/store";
import { useAnalytics } from "../analytics/analytics-data-loader-context";

import { AnalyticsDataWithLabelAndColor } from "../analytics/analytics-dashboard-view";
import { useTheme } from "../../app/theme-provider";
import { TimeRangePicker } from "../../components/time-range-picker/time-range-picker";
import { selectTenantCurrencySymbol } from "./tenants-slice";
import { useTranslation } from "react-i18next";
import { useTimeRange } from "../analytics/time-range-context";
import { useTimeRangeFormatter } from "../../components/time/use-time-range-formatter";
import { AnalyticsGraphLegend } from "../../components/graph/analytics-graph-legend";

export function TenantHistoricData() {
  const { t } = useTranslation();
  const { timeRange } = useTimeRange();
  const timeRangeString = useTimeRangeFormatter(timeRange);
  const { properties, isLoading, data } = useAnalytics();

  const currencySymbol = useAppSelector((state) =>
    selectTenantCurrencySymbol(state)
  );
  const { colors } = useTheme();

  const tenantData = data.find((d) => d.entity.scope === "tenant");

  const tenantConsumption: AnalyticsDataWithLabelAndColor[] = tenantData
    ? [
        {
          ...tenantData,
          color: colors[0],
          label: t("analytics.tenantConsumption", "Total consumption"),
        },
      ]
    : [];

  const keyMap = createKeyMap(tenantConsumption);

  const graphProps: Omit<AnalyticsGraphProps, "unit"> = {
    measurementKey: "ea_fwd_t_d",
    costKey: "ea_fwd_t_d_cost",
    shape: "bar",
    timeRange,
    timeseries: tenantConsumption,
    currencySymbol,
    properties,
    keyFormatter: keyFormatterGenerator(keyMap),
  };

  return (
    <div>
      <div className="flex flex-wrap content-center items-start justify-around gap-2 md:justify-between">
        <h2 className="text-lg">
          {t(
            "tenant.dashboard.consumptionInTimeRange",
            "Consumption in period {{timeRange}}",
            { timeRange: timeRangeString }
          )}
        </h2>

        <div className="flex-grow sm:flex-grow-0 md:w-80">
          <TimeRangePicker isLoading={isLoading} />
        </div>
      </div>

      <div style={{ height: "240px" }} className="">
        <AnalyticsGraph {...graphProps} />
      </div>

      <div className={"mt-2"}>
        <AnalyticsGraphLegend {...graphProps} />
      </div>
    </div>
  );
}
