import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { GridknightGradient } from "../assets/gridknight-logo";
import { getColorFromCSSVariable } from "../components/util";
import {
  buildChartTheme,
  ThemeProvider as VisxThemeProvider,
} from "@visx/xychart";
type ThemeString = "dark" | "light";

export type Theme = {
  theme: ThemeString;
  setTheme: (theme: ThemeString) => void;
  colors: string[];
  getComparisonColor: (color: string) => string;
  semanticColors: {
    good: string;
    bad: string;
    warning: string;
    neutral: string;
  };
};

export const ThemeContext = createContext<Theme>({
  theme: "light",
  setTheme: () => {},
  colors: [],
  getComparisonColor: (color: string) => "#e0e0e0",
  semanticColors: {
    good: "#2ce02c",
    bad: "#fc1a1a",
    warning: "#ff7300",
    neutral: "#8c8c8c",
  },
});

export const useTheme = () => {
  return useContext(ThemeContext);
};

// themeContext.js

const getInitialTheme = (): ThemeString => {
  // TODO replace with light theme by default for now
  return "light";

  // if (typeof window !== "undefined" && window.localStorage) {
  //   const storedPrefs = window.localStorage.getItem("color-theme");
  //
  //   if (storedPrefs != null && storedPrefs?.length) {
  //     return storedPrefs as ThemeString;
  //   }
  //
  //   const userMedia = window.matchMedia("(prefers-color-scheme: dark)");
  //
  //   if (userMedia.matches) {
  //     return "dark";
  //   }
  // }
  //
  // return "light";
};

export const ThemeProvider = ({
  initialTheme,
  children,
}: {
  initialTheme?: ThemeString;
  children: ReactNode;
}) => {
  const [theme, setTheme] = useState<ThemeString>(getInitialTheme);

  const rawSetTheme = (theme: string) => {
    const root = window.document.documentElement;
    const isDark = theme === "dark";

    root.classList.remove(isDark ? "light" : "dark");
    root.classList.add(theme);

    localStorage.setItem("color-theme", theme);
  };

  if (initialTheme) {
    rawSetTheme(initialTheme);
  }

  const gray = getColorFromCSSVariable("--color-gray") ?? "#8c8c8c";
  const green = getColorFromCSSVariable("--color-green") ?? "#00db8b";
  const blue = getColorFromCSSVariable("--color-blue") ?? "#00d5d9";
  const red = getColorFromCSSVariable("--color-red") ?? "#ff4d4d";
  const orange = getColorFromCSSVariable("--color-orange") ?? "#f9cb28";

  const colors = [
    blue,
    green,
    orange,
    red,
    getColorFromCSSVariable("--color-pink") ?? "#ff0080",
    getColorFromCSSVariable("--color-violet") ?? "#7928ca",
    getColorFromCSSVariable("--color-blue-dark") ?? "#0713a1",
  ];

  const getComparisonColor = (color: string) => {
    return "#e0e0e0";
  };

  const semanticColors = {
    neutral: gray,
    good: green,
    warning: orange,
    bad: red,
  };

  // Graphs
  const graphTheme = buildChartTheme({
    backgroundColor: "#fff",
    colors,
    tickLength: 4,
    gridColor: "#7c7c7c",
    gridColorDark: "#1f1f1f",
  });

  useEffect(() => {
    rawSetTheme(theme);
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{ theme, setTheme, colors, getComparisonColor, semanticColors }}
    >
      <VisxThemeProvider theme={graphTheme}>
        <GridknightGradient />
        {children}
      </VisxThemeProvider>
    </ThemeContext.Provider>
  );
};
