import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  PayloadAction,
} from "@reduxjs/toolkit";
import { FetchStatus } from "../../../models/types";
import { API, graphqlOperation } from "aws-amplify";
import { RootState } from "../../../app/store";
import { Application } from "@nantis/gridknight-core";

const userEntityAdapter = createEntityAdapter<Application.User>({
  selectId: (user) => user.id,
  sortComparer: (a, b) => b.email.localeCompare(a.email),
});

type UserId = {
  user_id: string | null;
};

type UserEmail = {
  email: string;
};

const initialState = userEntityAdapter.getInitialState<{
  currentUser: Application.User | null;
  fetchCurrentUser: FetchStatus;
  fetchAllUsers: FetchStatus;
  updateUserRole: FetchStatus;
  updateCurrentUser: FetchStatus;
  inviteUser: FetchStatus & UserEmail;
  removeUser: FetchStatus & UserId;
  resendUserInvitation: FetchStatus & UserId;
}>({
  currentUser: null,
  fetchCurrentUser: {
    status: "idle",
    error: null,
  },
  fetchAllUsers: {
    status: "idle",
    error: null,
  },
  updateCurrentUser: {
    status: "idle",
    error: null,
  },
  updateUserRole: {
    status: "idle",
    error: null,
  },
  inviteUser: {
    status: "idle",
    error: null,
    email: "",
  },
  removeUser: {
    status: "idle",
    error: null,
    user_id: null,
  },
  resendUserInvitation: {
    status: "idle",
    error: null,
    user_id: null,
  },
});

export const fetchCurrentUser = createAsyncThunk(
  "users/fetchCurrentUser",
  async () => {
    const result = (await API.graphql(
      graphqlOperation(`
            query CurrentUserQuery {
              currentUser {
                active
                email
                id
                last_login_at
                locale
                name
                notification_configuration
                role
                timezone
                tenant_id
                webPushSubscriptions {
                  subscription
                  last_success
                }
              }
            }
        `)
    )) as {
      data: {
        currentUser: Application.User;
      };
    };
    return result.data.currentUser;
  }
);

export const fetchAllUsers = createAsyncThunk(
  "users/fetchUsers",
  async (paginationArgs: Application.PaginationArguments) => {
    const { limit = 100, cursor = "" } = paginationArgs;

    const result = (await API.graphql(
      graphqlOperation(
        `
            query allUsersQuery($cursor: String, $limit: Int) {
              users(cursor: $cursor, limit: $limit) {
                cursor
                items {
                  active
                  email
                  id
                  name
                  role
                  tenant_id
                  notification_configuration
                  locale
                  last_login_at
                }
              }
            }
        `,
        {
          limit,
          cursor,
        }
      )
    )) as {
      data: {
        users: {
          cursor?: string;
          items: Application.User[];
        };
      };
    };
    return result.data.users.items;
  }
);

export const inviteUser = createAsyncThunk(
  "users/inviteUser",
  async (email: string, { rejectWithValue }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(
          `
                mutation inviteUserMutation($email: AWSEmail!) {
                  inviteUser(email: $email) {
                    active
                    email
                    id
                    last_login_at
                    locale
                    notification_configuration
                    name
                    tenant_id
                    role
                  }
                }
            `,
          {
            email: email,
          }
        )
      )) as {
        data: {
          inviteUser: Application.User;
        };
        errors?: any;
      };

      return result.data.inviteUser;
    } catch (err: any) {
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue({
        message: err?.errors[0].message,
      });
    }
  }
);

export const resendUserInvitation = createAsyncThunk(
  "users/resendUserInvitation",
  async (user_id: string) => {
    const result = (await API.graphql(
      graphqlOperation(
        `
            mutation resendUserInvitationMutation($user_id: ID!) {
              resendUserInvitation(user_id: $user_id) {
                active
                email
                id
                last_login_at
                locale
                name
                notification_configuration
                role
                tenant_id
              }
            }
        `,
        {
          user_id: user_id,
        }
      )
    )) as {
      data: {
        resendUserInvitation: Application.User;
      };
      errors?: any;
    };
    return result.data.resendUserInvitation;
  }
);

export const removeUser = createAsyncThunk(
  "users/removeUser",
  async (user_id: string) => {
    const result = (await API.graphql(
      graphqlOperation(
        `
            mutation removeUserMutation($user_id: ID!) {
              removeUser(user_id: $user_id) {
                tenant_id
                user_id
              }
            }
        `,
        {
          user_id: user_id,
        }
      )
    )) as {
      data: {
        removeUser: {
          tenant_id: string;
          user_id: string;
        };
      };
      errors?: any;
    };
    return result.data.removeUser;
  }
);

export const updateCurrentUser = createAsyncThunk(
  "users/updateUser",
  async (user: Application.User) => {
    const result = (await API.graphql(
      graphqlOperation(
        `
            mutation UpdateUserMutation($locale: String, $name: String, $notification_configuration: AWSJSON, $timezone: String) {
              updateCurrentUser(locale: $locale, name: $name, timezone: $timezone, notification_configuration: $notification_configuration) {
                active
                email
                id
                last_login_at
                locale
                name
                notification_configuration
                role
                tenant_id
                timezone
              }
            }
`,
        {
          name: user.name,
          notification_configuration: JSON.stringify(
            user.notification_configuration
          ),
          locale: user.locale,
          timezone: user.timezone,
        }
      )
    )) as {
      data: {
        updateCurrentUser: Application.User;
      };
      errors?: any;
    };
    return result.data.updateCurrentUser;
  }
);

export const updateUserRole = createAsyncThunk(
  "users/updateUserRole",
  async (user: Application.User) => {
    const result = (await API.graphql(
      graphqlOperation(
        `
            mutation updateUserRoleMutation($role: UserRole, $user_id: ID!) {
              updateUserRole(user_id: $user_id, role: $role) {
                active
                email
                id
                last_login_at
                locale
                name
                role
                notification_configuration
                tenant_id
              }
            }
        `,
        {
          user_id: user.id,
          role: user.role,
        }
      )
    )) as {
      data: {
        updateUserRole: Application.User;
      };
      errors?: any;
    };
    return result.data.updateUserRole;
  }
);

export const createWebPushSubscription = createAsyncThunk(
  "users/createWebPushSubscription",
  async (subscription: PushSubscription) => {
    const result = (await API.graphql(
      graphqlOperation(
        `
          mutation CreateWebPushSubscription($subscription: AWSJSON!) {
            createWebPushSubscription(subscription: $subscription) {
              subscription
              tenant_id
              user_id
            }
          }
        `,
        {
          subscription: JSON.stringify(subscription),
        }
      )
    )) as {
      data: {
        createWebPushSubscription: Application.WebPushSubscription;
      };
      errors?: any;
    };
    return result.data.createWebPushSubscription;
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setInviteUserName: (state, action: PayloadAction<string>) => {
      state.inviteUser.email = action.payload;
    },
  },
  // https://redux-toolkit.js.org/API/createAsyncThunk#promise-lifecycle-actions
  extraReducers: (builder) => {
    builder.addCase(updateCurrentUser.pending, (state) => {
      state.updateCurrentUser = {
        status: "pending",
        error: null,
      };
    });

    builder.addCase(updateCurrentUser.fulfilled, (state) => {
      state.updateCurrentUser = {
        status: "fulfilled",
        error: null,
      };
    });

    builder.addCase(updateCurrentUser.rejected, (state) => {
      state.updateCurrentUser = {
        status: "rejected",
        error: null,
      };
    });

    builder.addCase(fetchCurrentUser.pending, (state) => {
      state.fetchCurrentUser = {
        status: "pending",
        error: null,
      };
    });

    builder.addCase(fetchCurrentUser.fulfilled, (state, action) => {
      state.fetchCurrentUser.status = "fulfilled";
      state.fetchCurrentUser.error = null;
      userEntityAdapter.upsertOne(state, action.payload);
      state.currentUser = action.payload;
    });

    builder.addCase(fetchCurrentUser.rejected, (state, payload) => {
      console.error(payload);
      state.fetchCurrentUser.status = "rejected";
      state.fetchCurrentUser.error = "ERROR";
      state.currentUser = null;
    });

    builder.addCase(fetchAllUsers.pending, (state) => {
      state.fetchAllUsers.status = "pending";
      state.fetchAllUsers.error = null;
    });

    builder.addCase(fetchAllUsers.fulfilled, (state, action) => {
      state.fetchAllUsers.status = "fulfilled";
      state.fetchAllUsers.error = null;
      userEntityAdapter.setAll(state, action.payload);
    });

    builder.addCase(fetchAllUsers.rejected, (state) => {
      state.fetchAllUsers.status = "rejected";
      state.fetchAllUsers.error = "ERROR";
    });

    builder.addCase(inviteUser.pending, (state, action) => {
      state.inviteUser.status = "pending";
      state.inviteUser.email = action?.meta?.arg;
      state.inviteUser.error = null;
    });

    builder.addCase(inviteUser.fulfilled, (state, action) => {
      state.inviteUser.status = "fulfilled";
      state.inviteUser.email = "";
      state.inviteUser.error = null;
      const user = action.payload as Application.User;
      if (user) {
        userEntityAdapter.upsertOne(state, user);
      }
    });

    builder.addCase(inviteUser.rejected, (state, action) => {
      state.inviteUser.status = "rejected";
      state.inviteUser.error = action.payload as string;
      console.log(action);
    });

    builder.addCase(updateUserRole.pending, (state, action) => {
      const { id, role } = action.meta.arg;
      userEntityAdapter.updateOne(state, {
        id: id,
        changes: {
          role: role,
        },
      });
      state.updateUserRole.status = "pending";
      state.updateUserRole.error = null;
    });

    builder.addCase(updateUserRole.fulfilled, (state, action) => {
      const user = action.payload;
      if (user) {
        userEntityAdapter.upsertOne(state, user);
      }
      state.updateUserRole.status = "fulfilled";
      state.updateUserRole.error = null;
    });

    builder.addCase(updateUserRole.rejected, (state) => {
      state.updateUserRole.status = "rejected";
      state.updateUserRole.error = "ERROR";
    });

    builder.addCase(resendUserInvitation.pending, (state, action) => {
      state.resendUserInvitation.status = "pending";
      console.log("pen", action);
      state.resendUserInvitation.user_id = action?.meta?.arg;
      state.resendUserInvitation.error = null;
    });

    builder.addCase(resendUserInvitation.fulfilled, (state, action) => {
      state.resendUserInvitation.status = "fulfilled";
      state.resendUserInvitation.user_id = null;
      state.resendUserInvitation.error = null;
      const user = action.payload;
      if (user) {
        userEntityAdapter.upsertOne(state, user);
      }
    });

    builder.addCase(resendUserInvitation.rejected, (state, action) => {
      state.resendUserInvitation.status = "rejected";
      state.resendUserInvitation.error = action.payload as string;
    });

    builder.addCase(removeUser.pending, (state, action) => {
      state.removeUser.user_id = action?.meta?.arg;
      state.removeUser.status = "pending";
      state.removeUser.error = null;
    });

    builder.addCase(removeUser.fulfilled, (state, action) => {
      state.removeUser.status = "fulfilled";
      state.removeUser.user_id = null;
      state.removeUser.error = null;
      const user = action.payload;
      if (user) {
        userEntityAdapter.removeOne(state, user.user_id);
      }
    });

    builder.addCase(removeUser.rejected, (state, action) => {
      state.removeUser.status = "rejected";
      state.removeUser.error = action.payload as string;
    });

    // Optimistic update
    /*builder.addCase(.pending, (state, action) => {
            const { userId } = action.meta.arg;
            userEntityAdapter.updateOne(state, {
                id: userId,
                changes: {
                    acknowledged: true
                }
            })
        })*/

    // builder.addCase(acknowledgeDeviceViolation.fulfilled, (state, action) => {
    //     userEntityAdapter.upsertMany(state, action.payload)
    // })
  },
});

export const reducer = usersSlice.reducer;

export const { setInviteUserName } = usersSlice.actions;

export const { selectAll: selectAllUsers, selectById: selectUserById } =
  userEntityAdapter.getSelectors<RootState>((state) => state.users);

export const selectCurrentUser = (state: RootState) => state.users.currentUser;
export const selectFetchCurrentUserLoadingState = (state: RootState) =>
  state.users.fetchCurrentUser;
export const selectInviteUserLoadingState = (state: RootState) =>
  state.users.inviteUser;
export const selectRemoveUserLoadingState = (state: RootState) =>
  state.users.removeUser;
export const selectUpdateUserRoleLoadingState = (state: RootState) =>
  state.users.updateUserRole;
export const selectUpdateUserLoadingState = (state: RootState) =>
  state.users.updateCurrentUser;
export const selectResendUserInvitationState = (state: RootState) =>
  state.users.resendUserInvitation;
