import React, { HTMLAttributes } from "react";
import { classNames } from "../../app/util";

export function PageHeadline({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLHeadingElement>) {
  return (
    <h1
      {...props}
      className={classNames(
        className,
        "flex items-center gap-2 text-xl text-gray-700"
      )}
    >
      {children}
    </h1>
  );
}
