import {
  AnalyticsData,
  calculateTotals,
  Totals,
} from "../../features/analytics/analytics-data-loader-context";
import { differenceInSeconds, isAfter } from "date-fns";

export function calculateTotalsComparison(
  data: AnalyticsData | undefined,
  metric: keyof Totals
): {
  isNormalized: boolean;
  currentTotal?: number;
  percentChange?: number;
  previousTotal?: number;
} {
  let currentTotal;

  const totals = calculateTotals(data?.currentData.data ?? []);
  currentTotal = totals[metric.toLowerCase() as keyof Totals];

  let comparisonTotal = undefined;

  // We can only compare if we have comparison data
  if (data?.comparisonData?.data) {
    const previousTotals = calculateTotals(data?.comparisonData.data ?? []);
    comparisonTotal = previousTotals[metric.toLowerCase() as keyof Totals];

    const currentTimeRange = data.currentData.timeRange;

    const now = new Date();

    // Check if we need to normalize
    if (isAfter(currentTimeRange.to, now)) {
      const comparisonTimeRange = data.currentData.timeRange;

      const durationCurrent = differenceInSeconds(now, currentTimeRange.from);

      const durationComparison = differenceInSeconds(
        comparisonTimeRange.to,
        comparisonTimeRange.from
      );

      if (durationCurrent > 0 && durationComparison > 0) {
        let currentTotalNormalized = currentTotal / durationCurrent;
        let comparisonTotalNormalized = comparisonTotal / durationComparison;

        return {
          isNormalized: true,
          currentTotal: currentTotal,
          previousTotal: comparisonTotal,
          percentChange: calculatePercentChange(
            currentTotalNormalized,
            comparisonTotalNormalized
          ),
        };
      }
    }
  }

  return {
    previousTotal: comparisonTotal,
    currentTotal: currentTotal,
    percentChange: calculatePercentChange(currentTotal, comparisonTotal),
    isNormalized: false,
  };
}

function calculatePercentChange(newValue: number, oldValue: number = 0) {
  if (oldValue == 0) {
    if (newValue != 0) {
      return newValue > 0 ? Infinity : -Infinity;
    } else {
      return 0;
    }
  }
  return ((newValue - oldValue) / oldValue) * 100;
}
