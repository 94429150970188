import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {
  formatDate,
  formatDatum,
  formatNumber,
  translationsDe,
  translationsEn,
} from "@nantis/gridknight-core";

import { default as translationDE } from "./de/translation.json";
import { default as translationEN } from "./en/translation.json";

// Loading with namespace : https://jsfiddle.net/jamuhl/ferfywyf/525/
i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // the keys resolve to namespaces
    resources: {
      en: {
        translation: translationEN,
        datum: translationsEn,
      },
      de: {
        translation: translationDE,
        datum: translationsDe,
      },
    },
    fallbackLng: "en",
    defaultNS: "translation",
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: (value, format, lng) => {
        if (format && lng) {
          if (format.startsWith("date")) {
            return formatDate(value, format, lng);
          }
          if (format.startsWith("datum")) {
            return formatDatum(value, format);
          }

          if (format.startsWith("number")) {
            return formatNumber(value, format, lng);
          }
        }
        return value;
      },
    },
  });

export default i18n;
