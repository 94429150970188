import {
  Application,
  getMeasurementRateFromTariffRates,
} from "@nantis/gridknight-core";
import { useTranslation } from "react-i18next";
import React from "react";
import {
  TableCell,
  TableHeadCell,
  TableRow,
} from "../../components/table/table";
import { Can } from "../../auth/user-ability-provider";
import { Link as RouterLink } from "react-router-dom";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { useAppSelector } from "../../app/store";
import { selectNumberOfDevicesByTariffId } from "../devices/devices-slice";
import { selectTariffRatesByTariffId } from "./tariff-slice";
import { selectTenantCurrency } from "../tenants/tenants-slice";
import { currencySymbols } from "../../app/util";
import { RoundActionButton } from "../../components/ng";

export function TariffsTable({ tariffs }: { tariffs: Application.Tariff[] }) {
  const { t } = useTranslation();

  return (
    <table className="min-w-full table-auto divide-y text-left">
      <thead className="bg-gray-50 text-gray-700">
        <tr>
          <TableHeadCell>{t("tariffs.name", "name")}</TableHeadCell>

          <TableHeadCell className="hidden sm:table-cell">
            {t(
              "tariffs.numberOfDevicesWithTariff",
              "number of device in group"
            )}
          </TableHeadCell>

          <TableHeadCell>
            {t("tariffs.currentRate", "current rate")}
          </TableHeadCell>

          <TableHeadCell>
            <span className="sr-only">{t("tariffs.edit", "edit tariff")}</span>
          </TableHeadCell>
        </tr>
      </thead>
      <tbody className="divide-y bg-white">
        {tariffs.map((tariff) => (
          <TariffTableRow key={tariff.id} tariff={tariff} />
        ))}
        {!tariffs.length && (
          <tr>
            <td colSpan={4} className={"p-4 italic"}>
              {t(
                "tariffs.noTariffsYet",
                "you do not have any energy tariffs, you can create one by using the plus button on the top right"
              )}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

function TariffTableRow({ tariff }: { tariff: Application.Tariff }) {
  const { t } = useTranslation();
  const now = new Date();

  const numberOfDevicesWithTariff = useAppSelector((state) =>
    selectNumberOfDevicesByTariffId(state, tariff.id)
  );
  const tariffRates = useAppSelector((state) =>
    selectTariffRatesByTariffId(state, tariff.id)
  );

  const hourRateActive = getMeasurementRateFromTariffRates(
    "ea_fwd_t_d",
    tariffRates,
    now
  );

  const currency = useAppSelector((state) => selectTenantCurrency(state));
  const currencySymbol = currencySymbols[currency];

  return (
    <TableRow>
      <TableCell>{tariff.name}</TableCell>
      <TableCell className="hidden sm:table-cell">
        {numberOfDevicesWithTariff}
      </TableCell>
      <TableCell>
        {hourRateActive &&
          `${hourRateActive.originalRate} ${currencySymbol} / kWh`}
        {!hourRateActive && "-"}
      </TableCell>
      <TableCell className="text-right">
        <Can I="update" a="tariff">
          <RouterLink
            key={"addTag"}
            className={"text-sm"}
            to={`tariffs/${tariff.id}`}
          >
            <RoundActionButton
              onClick={() => {}}
              title={t("tariffs.edit", "edit tariff")}
            >
              <Cog6ToothIcon className="h-4 w-4" />
            </RoundActionButton>
          </RouterLink>
        </Can>
      </TableCell>
    </TableRow>
  );
}
