import {
  AnalyticsData,
  Entity,
  useAnalytics,
} from "./analytics-data-loader-context";
import React from "react";
import { EntitySelector } from "./selection/entity-selector";
import { Card } from "../../components/containers/card";
import { TimeRangePicker } from "../../components/time-range-picker/time-range-picker";
import { useAppSelector } from "../../app/store";
import { selectAllNonDeletedDevices } from "../devices/devices-slice";
import { selectAllTags } from "../tags/tags-slice";
import { useTranslation } from "react-i18next";
import { PropertySelector } from "./selection/property-selector";
import {
  AnalyticsGraph,
  AnalyticsGraphProps,
  createKeyMap,
  keyFormatterGenerator,
} from "../../components/graph/analytics-graph";
import { selectTenantCurrencySymbol } from "../tenants/tenants-slice";
import { TimeseriesEnergyStats } from "./stats/timeseries-energy-stats";
import { EventEmitterProvider } from "@visx/xychart";

export type AnalyticsDataWithLabelAndColor = AnalyticsData & {
  label: string;
  color: string;
};

export function AnalyticsDashboardView() {
  const { t } = useTranslation();

  // We get everything we need to build the dashboard from the context
  const {
    entities,
    setEntities,
    properties,
    setProperties,
    data,
    timeRange,
    nextColor,
    isLoading,
  } = useAnalytics();

  const currencySymbol = useAppSelector((state) =>
    selectTenantCurrencySymbol(state)
  );

  const onRemoveEntity = (entity: Entity) => {
    setEntities(entities.filter((e) => e.id !== entity.id));
  };

  const onAddEntity = (entity: Entity) => {
    setEntities([...entities, entity]);
  };

  const devices = useAppSelector(selectAllNonDeletedDevices);
  const tags = useAppSelector(selectAllTags);

  // Build our data series from the context
  const analyticsDataWithLabelAndColor = (() => {
    return data.map((d): AnalyticsDataWithLabelAndColor => {
      const { entity } = d;
      const { scope, id } = entity;

      // Reconstruct our correct objects from the selection
      const sE = entities.find((e) => e.scope === scope && e.id === id);
      const color: string = sE ? sE.color : "#56595d";

      const label = ((): string => {
        switch (scope) {
          case "tag": {
            const tag = tags.find((t) => t.id === id);
            if (tag) {
              return tag.label;
            } else {
              return id;
            }
          }
          case "device": {
            const device = devices.find((t) => t.id === id);
            if (device) {
              return device.name ?? id;
            } else {
              return id;
            }
          }
          case "tenant": {
            return t("analytics.tenantConsumption", "total consumption");
          }
          default:
            return id;
        }
      })();

      return {
        label,
        color,
        ...d,
      };
    });
  })();

  const keyMap = createKeyMap(analyticsDataWithLabelAndColor);

  const graphProps: Omit<AnalyticsGraphProps, "measurementKey" | "unit"> = {
    timeRange,
    timeseries: analyticsDataWithLabelAndColor,
    currencySymbol,
    properties,
    keyFormatter: keyFormatterGenerator(keyMap),
  };

  return (
    // The event emitter provides a common context for events e.g. tooltip within the contained graphs
    <>
      <Card>
        <TimeRangePicker isLoading={isLoading} />
      </Card>

      <Card className={"print:hidden"}>
        <div className="flex flex-col gap-3">
          <EntitySelector
            nextColor={nextColor}
            open={!entities.length}
            onAddEntity={onAddEntity}
            onRemoveEntity={onRemoveEntity}
            maxEntities={4}
            selectedEntities={entities}
          />
          <hr />
          <PropertySelector
            properties={properties}
            setProperties={setProperties}
          />
        </div>
      </Card>

      <EventEmitterProvider>
        {properties.includes("ea_fwd_t_d") && !!entities.length && (
          <Card>
            <div
              style={{ height: "240px" }}
              className="py-2 px-2 sm:p-2 lg:pb-4"
            >
              <h2>{t("datum:metrics.ea_t.label", "Active Energy")}</h2>
              <AnalyticsGraph
                measurementKey={"ea_fwd_t_d"}
                costKey={"ea_fwd_t_d_cost"}
                {...graphProps}
                shape={"bar"}
              />
            </div>

            {
              <TimeseriesEnergyStats
                measurementKey={"ea_fwd_t_d"}
                costKey={"ea_fwd_t_d_cost"}
                {...graphProps}
              />
            }
          </Card>
        )}

        {properties.includes("er_t_d") && !!entities.length && (
          <Card>
            <div
              style={{ height: "240px" }}
              className="py-2 px-2 sm:p-2 lg:pb-4"
            >
              <h2>{t("datum:metrics.er_t.label", "Reactive Energy")}</h2>
              <AnalyticsGraph
                measurementKey={"er_t_d"}
                {...graphProps}
                shape={"bar"}
              />
            </div>

            {
              <TimeseriesEnergyStats
                measurementKey={"er_t_d"}
                costKey={undefined}
                {...graphProps}
              />
            }
          </Card>
        )}

        {properties.includes("p_t") && !!entities.length && (
          <Card>
            <div
              style={{ height: "240px" }}
              className="py-2 px-2 sm:p-2 lg:pb-4"
            >
              <h2>{t("datum:metrics.p_t.label", "Active Power Total")}</h2>
              <AnalyticsGraph measurementKey={"p_t"} {...graphProps} />
            </div>
          </Card>
        )}

        {properties.includes("q_t") && !!entities.length && (
          <Card>
            <div
              style={{ height: "240px" }}
              className="py-2 px-2 sm:p-2 lg:pb-4"
            >
              <h2>{t("datum:metrics.q_t.label", "Rective Power Total")}</h2>
              <AnalyticsGraph measurementKey={"q_t"} {...graphProps} />
            </div>
          </Card>
        )}

        {properties.includes("uc") && !!entities.length && (
          <Card>
            <div
              style={{ height: "240px" }}
              className="py-2 px-2 sm:p-2 lg:pb-4"
            >
              <h2>{t("datum:metrics.uc.label", "Voltage total")}</h2>
              <AnalyticsGraph
                includeZero={false}
                measurementKey={"uc"}
                {...graphProps}
              />
            </div>
          </Card>
        )}

        {properties.includes("ic") && !!entities.length && (
          <Card>
            <div
              style={{ height: "240px" }}
              className="py-2 px-2 sm:p-2 lg:pb-4"
            >
              <h2>{t("datum:metrics.ic.label", "Voltage total")}</h2>
              <AnalyticsGraph measurementKey={"ic"} {...graphProps} />
            </div>
          </Card>
        )}

        {properties.includes("pf_t") && !!entities.length && (
          <Card>
            <div
              style={{ height: "240px" }}
              className="py-2 px-2 sm:p-2 lg:pb-4"
            >
              <h2>{t("datum:metrics.pf_t.label", "Power Factor Total")}</h2>
              <AnalyticsGraph measurementKey={"pf_t"} {...graphProps} />
            </div>
          </Card>
        )}

        {properties.includes("tplug") && !!entities.length && (
          <Card>
            <div
              style={{ height: "240px" }}
              className="py-2 px-2 sm:p-2 lg:pb-4"
            >
              <h2>{t("datum:metrics.tplug.label", "Plug temperature")}</h2>
              <AnalyticsGraph measurementKey={"tplug"} {...graphProps} />
            </div>
          </Card>
        )}
      </EventEmitterProvider>
    </>
  );
}
