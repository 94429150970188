import React, { HTMLAttributes, ReactNode } from "react";
import { Application } from "@nantis/gridknight-core";
import { Pill } from "../../components";
import { classNames } from "../../app/util";

export function TagActionIcon({
  id,
  icon,
  onAction,
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  icon?: ReactNode;
  id: string;
  onAction: (id: string) => void;
}) {
  return (
    <div
      onClick={() => {
        onAction(id);
      }}
      className="inline-block cursor-pointer fill-white"
      {...props}
    >
      {icon}
    </div>
  );
}

export function TagBadge({
  tag,
  variant = "normal",
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  tag: Pick<Application.Tag, "label" | "color" | "description"> | undefined;
  variant?: "normal" | "large";
}) {
  const color = tag?.color ?? "rgba(161,0,255,0.7)";

  return (
    <Pill
      {...props}
      title={tag?.description ?? ""}
      color={color}
      variant={variant}
      className={classNames(className, "font-semibold")}
    >
      {tag?.label}
      {children}
    </Pill>
  );
}
