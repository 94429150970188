import React from "react";
import { Route, Routes } from "react-router-dom";
import { DeviceListPage } from "./device-list-page";
import { PairDevicePage } from "./pair/pair-device-page";
import { Device } from "./device/device";
import { DevicesSettings } from "./settings/devices-settings";

export function Devices() {
  return (
    <Routes>
      <Route path="/" element={<DeviceListPage />} />
      <Route path="/pair" element={<PairDevicePage />} />
      <Route path="/settings/*" element={<DevicesSettings />} />
      <Route path="/:deviceId/*" element={<Device />} />
    </Routes>
  );
}
