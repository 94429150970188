import React from "react";
import { useTranslation } from "react-i18next";
import { DevicesLocationMap } from "../devices/location/devices-location-map";
import { useAppSelector } from "../../app/store";
import {
  selectFetchDevicesLoadingState,
  selectTotalDevices,
} from "../devices/devices-slice";
import { PageHeadline } from "../../components/text/page-headline";
import { Card } from "../../components/containers/card";
import { CallToActionPairDevice } from "../devices/pair/cta-pair-device";
import { Can } from "../../auth/user-ability-provider";
import { PageWidth } from "../../components/containers/page-width";
import { DevicesStats } from "../common/stats/devices-stats";
import { EventsStats } from "../common/stats/events-stats";
import {
  AnalyticsDataLoaderContextProvider,
  Entity,
} from "../analytics/analytics-data-loader-context";
import { FlexContainer } from "../../components/containers/flex-container";
import { TenantHistoricData } from "./tenant-historic-data";
import { selectTenant } from "./tenants-slice";
import { TotalMetricStat } from "../../components/metrics/total-metric-stat";
import { MetricKey } from "@nantis/gridknight-core";
import { TotalActiveEnergyConsumptionByCounter } from "../common/stats/total-active-energy-consumption-by-counter";
import { MostPowerConsumersList } from "../common/stats/most-power-consumers-list";
import { UpgradeSubscriptionStat } from "../common/stats/upgrade-subscription-stat";

export function TenantDashboardPage() {
  const { t } = useTranslation();

  const fetchDevicesState = useAppSelector((state) =>
    selectFetchDevicesLoadingState(state)
  );
  const totalDevices = useAppSelector((state) => selectTotalDevices(state));

  const tenant = useAppSelector(selectTenant);

  if (fetchDevicesState.status === "fulfilled" && totalDevices === 0) {
    return (
      <PageWidth>
        <FlexContainer>
          <PageHeadline>
            {t("tenant.dashboard.title", "Dashboard")} {tenant?.name}
          </PageHeadline>
          <Card>
            <CallToActionPairDevice />
          </Card>
        </FlexContainer>
      </PageWidth>
    );
  }

  const entities: Entity[] = [
    {
      scope: "tenant",
      id: "tenant",
    },
  ];

  const properties: MetricKey[] = ["ea_fwd_t_d", "ea_fwd_t_d_cost", "er_t_d"];

  return (
    <AnalyticsDataLoaderContextProvider
      loadComparisonData={true}
      properties={properties}
      entities={entities}
    >
      <PageWidth>
        <FlexContainer>
          <PageHeadline>
            {t("tenant.dashboard.title", "Dashboard")} {tenant?.name}
          </PageHeadline>

          <Can I="use" a="historicalData">
            <Card>
              <TenantHistoricData />
            </Card>
          </Can>

          <div className="grid grid-cols-2 gap-3 lg:grid-cols-3 xl:grid-cols-5">
            <Card>
              <DevicesStats />
            </Card>
            <Can I="use" a="events">
              <Card>
                <EventsStats />
              </Card>
            </Can>
            <Can not I="use" a="events">
              <Card>
                <TotalActiveEnergyConsumptionByCounter />
              </Card>
            </Can>
            <Can I="use" a="historicalData">
              <Card>
                <TotalMetricStat
                  decimals={2}
                  metricKey={"ea_fwd_t_d_cost"}
                  label={t("stats.costInTimeRange", "cost")}
                />
              </Card>

              <Card>
                <TotalMetricStat
                  decimals={2}
                  metricKey={"ea_fwd_t_d"}
                  label={t("stats.totalActiveEnergy", "active energy")}
                />
              </Card>

              <Card>
                <TotalMetricStat
                  decimals={2}
                  showTrend={false}
                  metricKey={"er_t_d"}
                  label={t("stats.totalReactiveEnergy", "reactive energy")}
                />
              </Card>
            </Can>
            <Can not I="use" a="historicalData">
              <Card className={"col-span-2"}>
                <MostPowerConsumersList />
              </Card>
              <Card>
                <UpgradeSubscriptionStat />
              </Card>
            </Can>
          </div>

          <Can I="use" a="location">
            <div className={"card rounded-lg"}>
              <DevicesLocationMap />
            </div>
          </Can>
        </FlexContainer>
      </PageWidth>
    </AnalyticsDataLoaderContextProvider>
  );
}
