import React, { ChangeEvent, PropsWithChildren, useState } from "react";
import { TagBadge } from "./tagBadge";
import { ColorSelector } from "./color-selector";
import { Application } from "@nantis/gridknight-core";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../app/theme-provider";
import { useAppSelector } from "../../app/store";
import { selectTagByLabel, selectTagByLabelAlreadyExists } from "./tags-slice";

export function TagForm({
  existingTag,
  onSave,
  ...props
}: PropsWithChildren<{
  existingTag?: Application.Tag;
  onSave: (tag: Omit<Application.Tag, "id" | "tenant_id">) => Promise<void>;
}>) {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const initialState = {
    label: "",
    description: "",
    color: colors[0],
  };

  const [tag, setTag] = useState<Omit<Application.Tag, "id" | "tenant_id">>(
    existingTag ?? initialState
  );

  const existingTagsWithLabel = useAppSelector((state) =>
    selectTagByLabel(state, tag.label)
  );
  const labelExists = useAppSelector((state) =>
    selectTagByLabelAlreadyExists(state, tag.label)
  );

  const tagLabelAlreadyExists = existingTag
    ? existingTagsWithLabel.filter((l) => l.id != existingTag.id).length > 0
    : labelExists;

  const onChangeLabel = (e: ChangeEvent<HTMLInputElement>) => {
    setTag({
      ...tag,
      label: e.target.value,
    });
  };

  const onChangeDescription = (e: ChangeEvent<HTMLInputElement>) => {
    setTag({
      ...tag,
      description: e.target.value,
    });
  };

  const onChangeColor = (color: string) => {
    setTag({
      ...tag,
      color: color,
    });
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    await onSave(tag);
  };

  return (
    <>
      <div className="space-y-6 sm:space-y-5">
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
          <div>
            <h2>{t("tags.create.preview", "Preview")}</h2>
          </div>
          <div className="flex items-center justify-items-center py-4 sm:py-2">
            {tag.label && <TagBadge tag={tag} />}
          </div>
        </div>
      </div>

      <form id="tagForm" className={"mb-6"} onSubmit={onSubmit}>
        <div className="space-y-6 sm:space-y-5">
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
            <label htmlFor="label" className="block text-sm text-gray-700">
              {t("tags.create.label", "Label")}
            </label>
            <div className="mt-1 flex flex-col">
              <input
                type="text"
                maxLength={20}
                value={tag.label}
                name="label"
                id="label"
                onChange={onChangeLabel}
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
              />

              {tagLabelAlreadyExists && (
                <span className={"pt-4 text-sm text-red"}>
                  {t(
                    "tags.create.labelTextExists",
                    "A group with this name already exists, are you sure you want to use this name?"
                  )}
                </span>
              )}

              <span className="mt-2 text-sm text-gray-600">
                {t("tags.create.tipEmoji", "Description")}
              </span>
            </div>
          </div>
        </div>

        <div className="mt-6 space-y-6 sm:mt-5 sm:space-y-5">
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="description"
              className="block text-sm text-gray-700"
            >
              {t("tags.create.description", "Description")}
            </label>
            <div className="mt-1">
              <input
                type="text"
                value={tag.description ?? ""}
                name="description"
                id="description"
                onChange={onChangeDescription}
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
              />
            </div>
          </div>
        </div>

        <div className="mt-6 space-y-6 sm:mt-5 sm:space-y-5">
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="color" className="block text-sm text-gray-700">
              {t("tags.create.color", "Description")}
            </label>
            <ColorSelector
              col={tag.color}
              id={"color"}
              onSelectColor={onChangeColor}
              colors={colors}
            />
          </div>
        </div>

        {props.children}
      </form>
    </>
  );
}
