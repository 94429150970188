import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
  acknowledgeAllTenantDeviceEvents,
  selectDevicesWithActiveOrHistoricEvents,
  selectEventsGroupedByDevice,
} from "../devices/devices-slice";
import { Card } from "../../components/containers/card";
import { useTranslation } from "react-i18next";
import { NotificationCard } from "../../components/containers/notification-card";
import { Can } from "../../auth/user-ability-provider";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { DeviceEventsList } from "../devices/events/device-events-list";
import { ParentSize } from "@visx/responsive";
import { DevicesTable } from "../devices/table/devices-table";

export function DeviceEvents() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const deviceWithEvents = useAppSelector((state) =>
    selectDevicesWithActiveOrHistoricEvents(state)
  );

  const groupedEvents = useAppSelector((state) =>
    selectEventsGroupedByDevice(state)
  );
  const groupedEventsDeviceIds = Array.from(groupedEvents.keys());

  const acknowledgeTenantEvents = () => {
    dispatch(acknowledgeAllTenantDeviceEvents());
  };

  if (deviceWithEvents.length === 0 && groupedEventsDeviceIds.length === 0) {
    return (
      <>
        <NotificationCard variant="info">
          {t(
            "notifications.deviceNotifications.noAlerts",
            "There are currently no device alerts."
          )}
        </NotificationCard>
      </>
    );
  }
  return (
    <>
      <Can I="use" a="events">
        {deviceWithEvents.length > 0 && (
          <ParentSize>
            {({ width }) => (
              <DevicesTable width={width} devices={deviceWithEvents} />
            )}
          </ParentSize>
        )}

        <Card>
          <div className="flex justify-between">
            <div>
              <h2 className={"mb-2 text-lg"}>
                {t(
                  "notifications.deviceNotifications.historicAlerts",
                  "Histoic alerts"
                )}
              </h2>
            </div>
            <div className="flex flex-row">
              {groupedEventsDeviceIds.length > 0 && (
                <span
                  onClick={acknowledgeTenantEvents}
                  title={t(
                    "device.events.acknowledgeAllTenantAlerts",
                    "acknowledge all violation"
                  )}
                >
                  <XMarkIcon className="h-5 w-5 cursor-pointer text-gray-400" />
                </span>
              )}
            </div>
          </div>

          {groupedEventsDeviceIds.length > 0 ? (
            groupedEventsDeviceIds.map((deviceId) => (
              <DeviceEventsList key={deviceId} deviceId={deviceId} />
            ))
          ) : (
            <span className="text-gray-400">
              {t(
                "device.events.noAlerts",
                "All is well! No active alerts present."
              )}
            </span>
          )}
        </Card>
      </Can>
    </>
  );
}
