import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  createCustomerPortalSession,
  selectCreateCustomerPortalSessionState,
  selectTenant,
  selectSetSubscriptionState,
  setSubscription,
  fetchTenant,
} from "../tenants-slice";
import { useLocation } from "react-router-dom";
import { LoadingButton } from "../../../components/ng";
import {
  SubscriptionPlan,
  SubscriptionSelector,
} from "../subscriptions/subscription-selector";
import stripeConfig from "../../../stripe-config.json";
const { tiers: stripeTiers } = stripeConfig as { tiers: SubscriptionPlan[] };

export function TenantPaymentSettings() {
  const { t } = useTranslation();
  const tenant = useAppSelector(selectTenant);
  const dispatch = useAppDispatch();
  const customerPortalSessionState = useAppSelector(
    selectCreateCustomerPortalSessionState
  );
  const subscriptionLoadingState = useAppSelector(selectSetSubscriptionState);
  const location = useLocation();

  const onGoToCustomerPortal = () => {
    let returnUrl = null;

    if (window?.location?.origin) {
      returnUrl = window.location.origin + location.pathname;
    }

    if (customerPortalSessionState.status !== "pending") {
      dispatch(
        createCustomerPortalSession({
          returnUrl: returnUrl,
        })
      );
    }
  };

  // Redirect to the customer portal session url if possible
  useEffect(() => {
    if (
      customerPortalSessionState.status === "fulfilled" &&
      customerPortalSessionState.sessionUrl != null
    ) {
      window.location.replace(customerPortalSessionState.sessionUrl);
    }
  }, [customerPortalSessionState]);

  // Redirect to the checkout session (if user needs to setup payment)
  useEffect(() => {
    if (
      subscriptionLoadingState.status === "fulfilled" &&
      subscriptionLoadingState.data?.status === "redirect"
    ) {
      window.location.replace(
        subscriptionLoadingState.data.sessionUrl as string
      );
    }
  }, [dispatch, subscriptionLoadingState]);

  const onSelectTier = (tier: string | null) => {
    // Undefined could mean any non-existent plan
    if (tier === null || tier === undefined) {
      // TODO show contact form
    } else {
      if (subscriptionLoadingState.status !== "pending") {
        let successUrl = null;
        let cancelUrl = null;

        if (window?.location?.origin) {
          successUrl =
            window.location.origin +
            location.pathname +
            "?stripe_session_id={CHECKOUT_SESSION_ID}";
          cancelUrl = window.location.origin + location.pathname;
        }

        // Init subscription
        dispatch(
          setSubscription({
            tier: tier,
            successUrl: successUrl,
            cancelUrl: cancelUrl,
          })
        ).then(() => {
          dispatch(fetchTenant());
        });
      }
    }
  };

  const tiers: SubscriptionPlan[] = stripeTiers;

  // TODO replace tenant subscription page
  return (
    <div className="text-center text-sm text-gray-700">
      {tenant && (
        <>
          <div className="mb-6 mt-4">
            <SubscriptionSelector
              onSelectTier={onSelectTier}
              tiers={tiers}
              currentTier={tenant?.tier}
            />
          </div>

          <p>
            {t(
              "tenant.settings.payment.workingWithStripe",
              "Gridknight works with Stripe to optimize payments"
            )}
          </p>

          <LoadingButton
            variant={"accent"}
            className={"my-4"}
            type={"button"}
            onClick={onGoToCustomerPortal}
            isLoading={customerPortalSessionState.status === "pending"}
          >
            {t(
              "tenant.settings.payment.goToCustomerPortal",
              "Manage my payment data"
            )}
          </LoadingButton>

          <p>
            {" "}
            {t(
              "tenant.settings.payment.manageDataInfo",
              "You can edit your payment data, manage your subscription or add an invoice address on the stripe customer portal."
            )}
          </p>
        </>
      )}
    </div>
  );
}
