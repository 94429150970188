import React, { HTMLAttributes } from "react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useTheme } from "../../../app/theme-provider";
import { classNames } from "../../../app/util";
import { useTranslation } from "react-i18next";
import { Pill } from "../../../components";
import { MetricKey } from "@nantis/gridknight-core";

export type PropertySelectorProps = {
  properties: string[];
  setProperties?: (properties: string[]) => void;
};

export function PropertySelector({
  properties,
  setProperties = () => {
    console.warn("no property setter present");
  },
}: PropertySelectorProps) {
  const propertySets: Partial<Record<MetricKey, string[]>> = {
    ea_fwd_t_d: ["ea_fwd_t_d", "ea_fwd_t_d_cost"],
    er_t_d: ["er_t_d", "er_t_d_cost"],
    p_t: ["p_t"],
    q_t: ["q_t"],
    tplug: ["tplug", "tplug_min", "tplug_max"],
    pf_t: ["pf_t"],
    uc: ["uc"],
    ic: ["ic"],
  };

  const availableProperties = Object.keys(propertySets) as MetricKey[];

  const displayProperties = availableProperties.map((p) => {
    return {
      property: p,
      isSelected: properties.includes(p),
    };
  });

  const onRemoveProperty = (property: MetricKey) => {
    setProperties(
      properties.filter((p) => {
        const propertySet = propertySets[property];
        if (propertySet) {
          return !propertySet.includes(p);
        }
        return true;
      })
    );
  };

  const onAddProperty = (property: MetricKey) => {
    const propertySet = propertySets[property];
    setProperties([...properties, ...(propertySet ? propertySet : [])]);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-grow flex-col flex-wrap content-center items-center  gap-2 md:flex-row">
        {displayProperties.map((property) => {
          return (
            <PropertyLabel
              onClick={() => {
                if (property.isSelected) {
                  onRemoveProperty(property.property);
                } else {
                  onAddProperty(property.property);
                }
              }}
              displayProperty={property}
              key={property.property}
            >
              {property.isSelected && <CheckIcon className={"h-4 w-4"} />}
            </PropertyLabel>
          );
        })}
      </div>
    </div>
  );
}

export function PropertyLabel({
  displayProperty,
  children,
  className,
  ...props
}: HTMLAttributes<HTMLSpanElement> & {
  displayProperty: {
    property: MetricKey;
    isSelected: boolean;
  };
}) {
  const { property, isSelected } = displayProperty;

  const { t } = useTranslation();

  const propertyLabel = t(`analytics.properties.${property}`, property);

  const { colors } = useTheme();
  const color = isSelected ? colors[0] : "#606060";

  return (
    <Pill
      variant="normal"
      color={color}
      className={classNames(className, "cursor-pointer gap-2")}
      {...props}
    >
      {propertyLabel}
      {children}
    </Pill>
  );
}
