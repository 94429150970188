import React from "react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { Application } from "@nantis/gridknight-core";
import { useAppSelector } from "../../../app/store";
import { selectSetSubscriptionState } from "../tenants-slice";
import { LoadingButton } from "../../../components/ng";

// TODO get a better throughout feature matching
export type SubscriptionPlan = {
  tier: Application.Subscription.Tier | null;
  features: string[];
  price: number | null;
  title: string;
  frequency: string | null;
  description: string;
  mostPopular: boolean;
  cta: string;
};

export interface SubscriptionSelectorProps {
  currentTier: Application.Tenant["tier"];
  tiers: SubscriptionPlan[];
  onSelectTier?: (tier: string | null) => void;
}

function SubscriptionSelector(props: SubscriptionSelectorProps) {
  const { t } = useTranslation();
  const currentTier = props.currentTier;

  const subscriptionLoadingState = useAppSelector(selectSetSubscriptionState);

  const onClick = (tier: string | null) => {
    if (props.onSelectTier) {
      props.onSelectTier(tier);
    }
  };

  return (
    <div>
      <main>
        {/* Pricing Section */}
        <section className="relative" aria-labelledby="pricing-heading">
          <h2 id="pricing-heading" className="sr-only">
            {t("subscriptions.title", "Plans and pricing")}
          </h2>

          {/* Tiers */}
          <div className="mx-auto my-8 max-w-2xl space-y-12 px-4 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-x-6 lg:space-y-0 lg:px-8">
            {props.tiers &&
              props.tiers.map((tier) => (
                <div
                  key={tier.title}
                  className="relative flex flex-col rounded-2xl border border-gray-200 bg-white p-6 shadow-md shadow-sm"
                >
                  <div className="flex-1">
                    <h3 className="text-2xl font-semibold text-gray-900">
                      {tier.title}
                    </h3>

                    {tier.mostPopular ? (
                      <p className="absolute top-0 -translate-y-1/2 transform rounded-full bg-green px-4 py-1.5 text-xs font-semibold uppercase tracking-wide text-white">
                        {t("subscriptions.mostPopular", "Most popular")}
                      </p>
                    ) : null}

                    {currentTier != null && tier.tier === currentTier && (
                      <p className="absolute top-0 -translate-y-1/2 transform rounded-full bg-blue px-4 py-3 text-xs font-semibold uppercase tracking-wide text-white">
                        {t("subscriptions.currentTier", "Your current plan")}
                      </p>
                    )}

                    <p className="min-h-10 mt-4 flex items-baseline text-gray-900">
                      <span className="text-5xl">
                        {tier.price !== null
                          ? "€" + Math.floor(tier.price / 100.0)
                          : ""}
                      </span>
                      <span className="ml-1 text-xl font-semibold">
                        {tier.frequency != null
                          ? tier.frequency
                          : t("subscriptions.customerSpecific", "on request")}
                      </span>
                    </p>

                    <p className="mt-6 text-gray-500">{tier.description}</p>

                    {/* Feature list */}
                    <ul className="mt-6 space-y-4">
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex">
                          <CheckIcon
                            className="text-green-600 h-6 w-6 flex-shrink-0"
                            aria-hidden="true"
                          />
                          <span className="ml-3 text-gray-500">{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <LoadingButton
                    disabled={currentTier != null && tier.tier === currentTier}
                    isLoading={subscriptionLoadingState.status === "pending"}
                    type="submit"
                    variant={tier.mostPopular ? "success" : "accent"}
                    className="mt-4 w-full justify-center text-sm"
                    onClick={() => onClick(tier.tier)}
                  >
                    {tier.cta}
                  </LoadingButton>
                </div>
              ))}

            {!props.tiers && (
              <span>No tiers available. Contact the administrator</span>
            )}
          </div>
        </section>
      </main>
    </div>
  );
}

export { SubscriptionSelector };
