import React from "react";
import { useTranslation } from "react-i18next";
import { BellIcon } from "../../../assets/icons";

export function ViolationIndicator({ totalEvents }: { totalEvents: number }) {
  const { t } = useTranslation();

  return (
    <>
      <BellIcon className="h-7 w-7 fill-white" aria-hidden="true" />

      {totalEvents > 0 && (
        <>
          <span className="sr-only">
            {t("navigation.actions.viewNotifications", "View notifications")}
          </span>

          <div
            aria-hidden="true"
            className="absolute top-1 -right-3 flex h-6 w-6 items-center justify-center "
          >
            <div className="absolute inline-flex h-full w-full animate-ping rounded-full bg-blue opacity-75" />
            <div className="absolute flex inline-flex h-5 w-8 items-center justify-center rounded-full bg-blue text-xs text-white">
              {totalEvents > 99 ? <span>99+</span> : <span>{totalEvents}</span>}
            </div>
          </div>
        </>
      )}
    </>
  );
}
