import { useTranslation } from "react-i18next";
import { AnalyticsGraphProps } from "../../../components/graph/analytics-graph";
import { calculateTotals } from "../analytics-data-loader-context";
import { formattedValueToString, getValueFormat } from "@nantis/grafana-data";
import React from "react";
import { ElectricMetricKey, metrics, Metric } from "@nantis/gridknight-core";
import { ArrowPathIcon } from "@heroicons/react/24/solid";

export function TimeseriesEnergyStats(
  analyticsGraphProps: Omit<AnalyticsGraphProps, "measurementKey"> & {
    measurementKey: ElectricMetricKey;
  }
) {
  const { t } = useTranslation();

  const totals = analyticsGraphProps.timeseries.map((t) => {
    return {
      label: t.label,
      color: t.color,
      totals: calculateTotals(t.currentData.data),
      isLoading: t.currentData.status.status === "pending",
    };
  });

  const currencyFormat = getValueFormat(
    `currency:${analyticsGraphProps.currencySymbol}`
  );

  const metric: Metric = metrics[analyticsGraphProps.measurementKey];
  const measurementFormat = getValueFormat(metric.unit.id);

  return (
    <div className="flex flex-col items-center justify-center gap-2">
      <span className="text-sm text-gray-700">
        {t("analytics.totalValuesInRange", "Total values in timerange")}
      </span>
      <div className="flex flex-row gap-2 text-sm">
        {totals.map((t) => {
          const costSpan = (() => {
            if (
              analyticsGraphProps.costKey &&
              analyticsGraphProps.costKey.toLowerCase() in t.totals
            ) {
              return (
                <span>
                  (
                  {formattedValueToString(
                    currencyFormat(
                      (t.totals as Record<string, any>)[
                        analyticsGraphProps.costKey.toLowerCase()
                      ],
                      2
                    )
                  )}
                  )
                </span>
              );
            } else return <></>;
          })();

          const totalSpan = (() => {
            if (
              analyticsGraphProps.measurementKey &&
              analyticsGraphProps.measurementKey.toLowerCase() in t.totals
            ) {
              return (
                <span>
                  {formattedValueToString(
                    measurementFormat(
                      (t.totals as Record<string, any>)[
                        analyticsGraphProps.measurementKey.toLowerCase()
                      ],
                      2
                    )
                  )}
                </span>
              );
            } else return <></>;
          })();

          return (
            <span className="flex h-4 items-center" key={t.label}>
              <span className="mr-2 flex items-center">
                {t.isLoading ? (
                  <ArrowPathIcon
                    style={{ color: t.color, fill: t.color }}
                    className="inline-block h-4 w-4 animate-spin"
                  />
                ) : (
                  <span
                    className="inline-block h-3 w-3 rounded-full"
                    style={{
                      backgroundColor: t.color,
                      WebkitPrintColorAdjust: "exact",
                    }}
                  />
                )}
              </span>
              <span>
                {t.label}: {totalSpan} {costSpan}
              </span>
            </span>
          );
        })}
      </div>
    </div>
  );
}
