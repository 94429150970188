import React from "react";
import { Card } from "../../components/containers/card";
import { useAppSelector } from "../../app/store";
import {
  selectCurrentUser,
  selectFetchCurrentUserLoadingState,
} from "../tenants/users/users-slice";
import { UserSettings } from "./user-settings";
import { FlexContainer } from "../../components/containers/flex-container";

export function UserSettingsPage() {
  const fetchCurrentUserLoadingState = useAppSelector(
    selectFetchCurrentUserLoadingState
  );
  const currentUser = useAppSelector(selectCurrentUser);

  return (
    <FlexContainer>
      {currentUser && fetchCurrentUserLoadingState.status === "fulfilled" && (
        <Card>
          <UserSettings currentUser={currentUser} />
        </Card>
      )}
    </FlexContainer>
  );
}
