import { GridknightLogo } from "../../assets/gridknight-logo";
import { useTheme } from "../../app/theme-provider";
import { useTranslation } from "react-i18next";
import { ParentSize } from "@visx/responsive";
import { SineWaveAnimation } from "../animations";

export function LoadingIndicatorFullScreen() {
  const { t } = useTranslation();
  const { colors } = useTheme();

  return (
    <div className="fixed flex h-screen w-screen items-center justify-center bg-gray-200">
      <div className="absolute h-full w-full">
        <ParentSize>
          {({ width, height }) => (
            <SineWaveAnimation
              height={height}
              width={width * 4}
              options={{}}
              animate={true}
              colors={colors}
            />
          )}
        </ParentSize>
      </div>

      <div className="z-50 flex flex-col items-center justify-center text-lg text-white">
        <GridknightLogo
          singleColor={true}
          fill={"#fff"}
          className="-mt-6 h-12 lg:h-24"
        />
        <h1>{t("app.loading", "Application loading")}</h1>
      </div>
    </div>
  );
}
