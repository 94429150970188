import { useTranslation } from "react-i18next";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Card } from "../../../components/containers/card";
import { TenantPaymentSettings } from "./tenant-payment-settings";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  selectTenant,
  selectUpdateTenantLoadingState,
  updateTenant,
} from "../tenants-slice";
import { TenantUserList } from "./tenant-user-list";
import {
  fetchAllUsers,
  selectAllUsers,
  selectCurrentUser,
} from "../users/users-slice";

import { Can } from "../../../auth/user-ability-provider";
import { FlexContainer } from "../../../components/containers/flex-container";
import { LoadingButton } from "../../../components/ng";

export function TenantSettingsPage() {
  const { t } = useTranslation();
  const tenant = useAppSelector(selectTenant);
  const updateTenantLoadingState = useAppSelector(
    selectUpdateTenantLoadingState
  );
  const users = useAppSelector(selectAllUsers);
  const currentUser = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();

  const [tenantData, setTenantData] = useState<{
    name?: string;
  }>({
    name: tenant?.name ?? "",
  });

  const submitForm = (e: any) => {
    e.preventDefault();
    dispatch(updateTenant(tenantData));
  };

  useEffect(() => {
    dispatch(fetchAllUsers({}));
  }, [dispatch, tenant]);

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTenantData({
      ...tenantData,
      name: e.target.value,
    });
  };

  return (
    <FlexContainer>
      {tenant && (
        <Can I="update" a="tenant">
          <Card>
            <h2
              id="plan_heading"
              className="mb-4 text-lg leading-6 text-gray-900"
            >
              {t("tenant.settings.account", "Account")}
            </h2>

            <form id={"tenantSettingsForm"} onSubmit={submitForm}>
              <div className=" flex flex-col lg:flex-row">
                <div className="flex-grow space-y-6">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12 sm:col-span-12">
                      <label
                        htmlFor="company"
                        className="block text-sm text-gray-700"
                      >
                        {t("tenant.settings.name", "Company name")}
                      </label>
                      <input
                        type="text"
                        name="company"
                        id="company"
                        value={tenantData.name ?? ""}
                        onChange={onNameChange}
                        autoComplete="organization"
                        className="focus:border-light-blue-500 mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:outline-none focus:ring-blue sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-2 flex justify-end">
                <LoadingButton
                  isLoading={updateTenantLoadingState.status === "pending"}
                  form="tenantSettingsForm"
                  variant="success"
                  type="submit"
                >
                  {t("tenant.settings.save", "Users")}
                </LoadingButton>
              </div>
            </form>
          </Card>
        </Can>
      )}

      <Card>
        <h2 className="mb-4 text-lg leading-6 text-gray-900">
          {t("users.title", "Users")}
        </h2>
        {users && currentUser && (
          <TenantUserList currentUser={currentUser} users={users} />
        )}
      </Card>

      <Can I="update" a="payment">
        <Card>
          <h2 className="mb-4 text-lg leading-6 text-gray-900">
            {t("tenant.settings.payment.title", "Payment data")}
          </h2>

          <TenantPaymentSettings />
        </Card>
      </Can>

      <Can not I="update" a="payment">
        <Card>
          <div className="text-center text-sm text-gray-700">
            {t(
              "tenant.settings.payment.managed",
              "Your account is managed by the software provider. You cannot change your payment settings."
            )}
          </div>
        </Card>
      </Can>
    </FlexContainer>
  );
}
