import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { PageHeadline } from "../../../components/text/page-headline";
import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Card } from "../../../components/containers/card";
import { TariffRateForm } from "./tariff-rate-form";
import { Application } from "@nantis/gridknight-core";
import {
  createTariffRate,
  selectCreateTariffRateLoadingState,
} from "../tariff-slice";
import { LoadingButton } from "../../../components/ng";

export function TariffRateCreatePage({ tariffId }: { tariffId: string }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const createTariffRateState = useAppSelector((state) =>
    selectCreateTariffRateLoadingState(state)
  );

  const onSave = async (
    tariffRate: Pick<Application.TariffRate, "from">,
    hourRates: Application.HourRate[]
  ) => {
    dispatch(
      createTariffRate({
        tariffId,
        from: tariffRate.from,
        hourRates,
      })
    ).then(() => {
      navigate({
        pathname: "..",
      });
    });
  };

  return (
    <div>
      <PageHeadline>
        {t("tariffRates.create.title", "Create energy price")}
      </PageHeadline>

      <div className={"mb-4 text-sm"}>
        <RouterLink to={".."}>
          <span aria-hidden="true">&larr;</span>{" "}
          {t("tariffRates.create.backToTariff", "back to tariff")}
        </RouterLink>
      </div>

      <Card>
        <TariffRateForm onSave={onSave}>
          <div className="pt-5">
            <div className="flex justify-end">
              <LoadingButton
                isLoading={createTariffRateState.status === "pending"}
                form="tariffRateForm"
                variant="success"
                type="submit"
              >
                {t("tariffRates.create.save", "save price")}
              </LoadingButton>
            </div>
          </div>
        </TariffRateForm>
      </Card>
    </div>
  );
}
