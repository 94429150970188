import React from "react";
import { GridknightLogo } from "../../assets/gridknight-logo";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { Card } from "../../components/containers/card";
import {
  ResetPasswordForm,
  ResetPasswordFormData,
} from "./reset-password-form";
import {
  confirmResetPassword,
  resetPassword,
  selectAuthState,
} from "../auth-slice";
import {
  ConfirmResetPasswordForm,
  ConfirmResetPasswordFormData,
} from "./confirm-reset-password-form";
import { LanguageSelector } from "../../components/language/language-selector";
import { unwrapResult } from "@reduxjs/toolkit";

export function ResetPasswordPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const authState = useAppSelector((state) => selectAuthState(state));

  const onResetPassword = ({ email }: ResetPasswordFormData) => {
    dispatch(
      resetPassword({
        email: email,
      })
    );
  };

  const onConfirmResetPassword = ({
    email,
    code,
    password,
  }: ConfirmResetPasswordFormData) => {
    dispatch(
      confirmResetPassword({
        email: email,
        code: code,
        password: password,
      })
    )
      .then(unwrapResult)
      .then(() => {
        navigate({
          pathname: "/",
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="flex min-h-screen flex-col justify-center bg-gray-800 py-12 sm:px-6 lg:px-8">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <RouterLink to={"/"}>
          <GridknightLogo className="mx-auto mb-4 mr-2 h-16 md:m-4" />
        </RouterLink>

        {authState === "confirmResetPassword" && (
          <>
            <Card>
              <h1 className="my-4 text-center text-2xl font-bold text-gray-700">
                {t("auth.confirmResetPassword.title", "Confirm Reset Password")}
              </h1>

              <p className="my-4 text-center text-base font-bold text-gray-700">
                {t(
                  "auth.confirmResetPassword.codeHint",
                  "We sent you a code, please enter it to set a new password"
                )}
              </p>

              <ConfirmResetPasswordForm
                onConfirmResetPassword={onConfirmResetPassword}
              />

              <div className="mt-6">
                <p className="text-center text-sm text-gray-600">
                  <RouterLink to={"/"} className="text-blue">
                    {t("auth.resetPassword.toLogin", "go to login")}
                  </RouterLink>
                </p>
              </div>
            </Card>
          </>
        )}

        {authState !== "confirmResetPassword" && (
          <>
            <Card>
              <h1 className="my-4 text-center text-2xl font-bold text-gray-700">
                {t("auth.resetPassword.title", "Reset Password")}
              </h1>

              <ResetPasswordForm onResetPassword={onResetPassword} />

              <div className="mt-6">
                <p className="text-center text-sm text-gray-600">
                  <RouterLink to={"/"} className="text-blue">
                    {t("auth.resetPassword.toLogin", "go to login")}
                  </RouterLink>
                </p>
              </div>
            </Card>
          </>
        )}

        <div className={"mt-8 text-center"}>
          <LanguageSelector />
        </div>
      </div>
    </div>
  );
}
