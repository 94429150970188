import { de, deAT, enGB, enUS } from "date-fns/locale";
import { Locale } from "date-fns";

export const mapLocale = (locale: string | null | undefined): Locale => {
  if (locale && locale.length >= 2) {
    const locales: Record<string, Locale> = {
      de: de,
      en: enGB,
      "en-US": enUS,
      "de-AT": deAT,
    };

    if (locales.hasOwnProperty(locale)) {
      return locales[locale];
    }

    const shortLocale = locale.substring(2);

    if (locales.hasOwnProperty(shortLocale)) {
      return locales[shortLocale];
    }
  }

  return de;
};
