import { TimeRangePicker } from "../../../components/time-range-picker/time-range-picker";
import {
  AnalyticsGraph,
  AnalyticsGraphProps,
  createKeyMap,
  keyFormatterGenerator,
} from "../../../components/graph/analytics-graph";
import React from "react";
import { useTimeRange } from "../../analytics/time-range-context";
import { useAnalytics } from "../../analytics/analytics-data-loader-context";
import { useAppSelector } from "../../../app/store";
import { selectTenantCurrencySymbol } from "../../tenants/tenants-slice";
import { useTranslation } from "react-i18next";
import { useTimeRangeFormatter } from "../../../components/time/use-time-range-formatter";
import { useTheme } from "../../../app/theme-provider";
import { AnalyticsDataWithLabelAndColor } from "../../analytics/analytics-dashboard-view";
import { selectDeviceById } from "../devices-slice";
import { Card } from "../../../components/containers/card";
import { TotalMetricStat } from "../../../components/metrics/total-metric-stat";
import { AnalyticsGraphLegend } from "../../../components/graph/analytics-graph-legend";

export function DeviceHistoricData({ deviceId }: { deviceId: string }) {
  const { t } = useTranslation();
  const device = useAppSelector((state) => selectDeviceById(state, deviceId));
  const { timeRange } = useTimeRange();
  const timeRangeString = useTimeRangeFormatter(timeRange);
  const { properties, isLoading, data } = useAnalytics();

  const currencySymbol = useAppSelector((state) =>
    selectTenantCurrencySymbol(state)
  );

  const { colors } = useTheme();

  const deviceData = data.find((d) => d.entity.scope === "device");

  const deviceConsumption: AnalyticsDataWithLabelAndColor[] = deviceData
    ? [
        {
          ...deviceData,
          color: colors[0],
          label: device?.name ?? "",
        },
      ]
    : [];

  const keyMap = createKeyMap(deviceConsumption);

  const graphProps: Omit<AnalyticsGraphProps, "unit"> = {
    measurementKey: "ea_fwd_t_d",
    costKey: "ea_fwd_t_d_cost",
    shape: "bar",
    timeRange,
    timeseries: deviceConsumption,
    currencySymbol,
    properties,
    keyFormatter: keyFormatterGenerator(keyMap),
  };

  return (
    <>
      <Card>
        <div className="flex flex-wrap content-center items-start justify-around gap-2 md:justify-between">
          <h2 className="text-lg">
            {t(
              "device.detail.consumptionInTimeRange",
              "Consumption in time period {{timeRange}}",
              { timeRange: timeRangeString }
            )}
          </h2>

          <div className="flex-grow sm:flex-grow-0 md:w-80">
            <TimeRangePicker isLoading={isLoading} />
          </div>
        </div>

        <div style={{ height: "260px" }}>
          <AnalyticsGraph {...graphProps} />
        </div>

        <div className={"mt-2"}>
          <AnalyticsGraphLegend {...graphProps} />
        </div>
      </Card>

      <div className="grid grid-cols-1 gap-3 lg:grid-cols-3">
        <Card>
          <TotalMetricStat
            decimals={2}
            metricKey={"ea_fwd_t_d_cost"}
            label={t("stats.costInTimeRange", "cost")}
          />
        </Card>

        <Card>
          <TotalMetricStat
            decimals={2}
            metricKey={"ea_fwd_t_d"}
            label={t("stats.totalActiveEnergy", "active energy")}
          />
        </Card>

        <Card>
          <TotalMetricStat
            decimals={2}
            showTrend={false}
            metricKey={"er_t_d"}
            label={t("stats.totalReactiveEnergy", "reactive energy")}
          />
        </Card>
      </div>
    </>
  );
}
