import React from "react";
function Errors({ error }: any) {
  return (
    <div role="alert">
      <p>Es gab ein Problem. Versuche die Seite neu zu laden.</p>
      <pre>{error.message}</pre>
    </div>
  );
}

function ErrorMessage() {
  return (
    <div>
      <p>Error</p>
    </div>
  );
}

export { Errors, ErrorMessage };
