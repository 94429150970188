import React, { HTMLAttributes } from "react";
import { CheckIcon } from "@heroicons/react/24/solid";

export function ColorSelector({
  colors,
  onSelectColor,
  col,
  ...props
}: {
  col: string | null;
  colors: string[];
  onSelectColor: (color: string) => void;
} & HTMLAttributes<HTMLElement>) {
  return (
    <div className="flex flex-row space-x-4 py-2">
      {colors.map((c) => {
        const isSelected = c === col;
        return (
          <div
            {...props}
            onClick={() => onSelectColor(c)}
            key={c}
            className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full"
            style={{ backgroundColor: c }}
          >
            {isSelected && <CheckIcon className="h-4 w-4 fill-white" />}
          </div>
        );
      })}
    </div>
  );
}
