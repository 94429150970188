import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { reducer as appReducer } from "./app-slice";
import { reducer as authReducer } from "../auth/auth-slice";
import { reducer as devicesReducer } from "../features/devices/devices-slice";
import { reducer as tenantReducer } from "../features/tenants/tenants-slice";
import { reducer as usersReducer } from "../features/tenants/users/users-slice";
import { reducer as tagsReducer } from "../features/tags/tags-slice";
import { reducer as tagOnDeviceReducer } from "../features/tags/tag-on-device-slice";
import { reducer as tariffsReducer } from "../features/tariff/tariff-slice";
import { reducer as analyticsReducer } from "../features/analytics/analytics-slice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

const rootReducer = (state: any, action: any) => {
  if (action) {
    // Reset the store if the user is logged out, setting undefined will ask reducers to return the initial state
    if (action.type === "auth/logoutUser/fulfilled") {
      state = undefined;
    }
  }

  return combineReducers({
    app: appReducer,
    auth: authReducer,
    tenant: tenantReducer,
    users: usersReducer,
    devices: devicesReducer,
    tags: tagsReducer,
    tagOnDevice: tagOnDeviceReducer,
    analytics: analyticsReducer,
    tariffs: tariffsReducer,
  })(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

// https://redux-toolkit.js.org/rtk-query/api/setupListeners
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type Store = typeof store;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
