import React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/store";
import { selectResetPasswordState } from "../auth-slice";
import { LoadingButton } from "../../components/ng";

export type ResetPasswordFormData = {
  email: string;
};

export type ResetPasswordFormProps = {
  onResetPassword: ({ email }: ResetPasswordFormData) => void;
};

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

export function ResetPasswordForm({ onResetPassword }: ResetPasswordFormProps) {
  const { t } = useTranslation();

  const resetPasswordState = useAppSelector((state) =>
    selectResetPasswordState(state)
  );

  const { register, handleSubmit } = useForm<ResetPasswordFormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit((data: ResetPasswordFormData) => {
    onResetPassword({
      email: data.email,
    });
  });

  return (
    <form
      id="resetPasswordForm"
      onSubmit={onSubmit}
      className="space-y-6"
      action="#"
    >
      <div>
        <label htmlFor="email" className="block text-sm text-gray-700">
          {t("auth.resetPassword.email", "Email address")}
        </label>
        <div className="mt-1">
          <input
            id="email"
            type="text"
            autoComplete="email"
            required
            {...register("email")}
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
          />
        </div>
      </div>

      {resetPasswordState.status === "rejected" &&
        resetPasswordState.error === "limitExceeded" && (
          <div className="text-center text-sm">
            <p className="text-red">
              {t(
                "auth.resetPassword.errors.limitExceeded",
                "Too many requests to reset the password. Please try again at a later time"
              )}
            </p>
          </div>
        )}

      {resetPasswordState.status === "rejected" &&
        resetPasswordState.error === "userNotFound" && (
          <div className="text-center text-sm">
            <p className="text-red">
              {t(
                "auth.resetPassword.errors.userNotFound",
                "We cannot find that email address. Please check if the email address is correct."
              )}
            </p>
          </div>
        )}

      {resetPasswordState.status === "rejected" &&
        resetPasswordState.error === "invalidParameters" && (
          <div className="text-center text-sm">
            <p className="text-red">
              {t(
                "auth.resetPassword.errors.invalidParameters",
                "Your email address is not verified yet. We cannot reset your password. Please contact customer support."
              )}
            </p>
          </div>
        )}

      {resetPasswordState.status === "rejected" &&
        resetPasswordState.error === "userStateInvalid" && (
          <div className="text-center text-sm">
            <p className="mb-2 text-red">
              {t(
                "auth.resetPassword.errors.userStateInvalid",
                "We cannot reset the password for your account. If an administrator invited you, please ask them to resend the invitation. Otherwise contact customer support"
              )}
            </p>
          </div>
        )}

      <LoadingButton
        form="resetPasswordForm"
        isLoading={resetPasswordState.status === "pending"}
        type="submit"
        variant="accent"
        className="w-full justify-center text-sm"
      >
        {t("auth.resetPassword.resetPassword", "Reset password")}
      </LoadingButton>
    </form>
  );
}
