import { Card } from "../../../components/containers/card";
import { ParentSize } from "@visx/responsive";
import { StackedRadialGauge } from "../../../components";
import React from "react";
import { useAppSelector } from "../../../app/store";
import { selectDeviceStateByDeviceId } from "../devices-slice";
import { toFixed } from "@nantis/grafana-data";
import { useTranslation } from "react-i18next";
import { DeviceCellularReceptionGraph } from "./device-cellular-reception-graph";
import { MetricStat } from "../../../components/metrics/metric-stat";
import { Text } from "../../../components/metrics/text";
import { FlexContainer } from "../../../components/containers/flex-container";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "../../tenants/tenant";
import { GoodBadStat } from "../../../components/metrics/good-bad-stat";
import { InfoIcon } from "../../../components/help/info-icon";

export type SinglePhaseValue = {
  label: string;
  labelSub?: string;
  value?: number;
  unit: string;
};

export function StackedRadialGaugeLabel(v: SinglePhaseValue) {
  return (
    <>
      <p className={"whitespace-nowrap text-2xl"}>{`${toFixed(
        v.value ?? 0,
        1
      )}${v.unit}`}</p>
      <p className={"mt-1 whitespace-nowrap font-sans text-lg text-gray-700"}>
        {v.label}
        {v.labelSub && <sub>{v.labelSub}</sub>}
      </p>
    </>
  );
}

export function DeviceStatusData({ deviceId }: { deviceId: string }) {
  const { t } = useTranslation();
  const status = useAppSelector((state) =>
    selectDeviceStateByDeviceId(state, deviceId)
  );

  const voltages = {
    data: [
      {
        label: "U",
        labelSub: "1",
        unit: " V",
        value: status?.metrics?.urms_1,
      },
      {
        label: "U",
        labelSub: "2",
        unit: " V",
        value: status?.metrics?.urms_2,
      },
      {
        label: "U",
        labelSub: "3",
        unit: " V",
        value: status?.metrics?.urms_3,
      },
    ],
    commonValue: {
      label: "U",
      labelSub: "RMS",
      unit: " V",
      value: status?.metrics?.uc,
    },
  };

  const currents = {
    data: [
      {
        label: "I",
        labelSub: "1",
        unit: " A",
        value: status?.metrics?.irms_1 ?? 0,
      },
      {
        label: "I",
        labelSub: "2",
        unit: " A",
        value: status?.metrics?.irms_2 ?? 0,
      },
      {
        label: "I",
        labelSub: "3",
        unit: " A",
        value: status?.metrics?.irms_3 ?? 0,
      },
    ],
    commonValue: {
      label: "I",
      labelSub: "RMS",
      unit: " A",
      value: status?.metrics?.ic ?? 0,
    },
  };

  const powers = {
    data: [
      {
        label: "P",
        labelSub: "1",
        unit: " W",
        value: status?.metrics?.p_1 ?? 0,
      },
      {
        label: "P",
        labelSub: "2",
        unit: " W",
        value: status?.metrics?.p_2 ?? 0,
      },
      {
        label: "P",
        labelSub: "3",
        unit: " W",
        value: status?.metrics?.p_3 ?? 0,
      },
    ],
    commonValue: {
      label: "P",
      unit: " W",
      value: status?.metrics?.p_t ?? 0,
    },
  };

  const accessor = (v: SinglePhaseValue) => v.value ?? 0;

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Card className="text-center">
        <FlexContainer>
          <div className={"grid max-w-full grid-cols-1 gap-2 lg:grid-cols-3"}>
            <div>
              <h2 className={"mb-2 text-lg"}>
                {t("datum:metrics.u.label", "voltage")}
              </h2>
              <ParentSize>
                {({ width }) => (
                  <StackedRadialGauge<SinglePhaseValue>
                    width={width}
                    height={200}
                    options={{ minValue: 0, maxValue: 253 }}
                    data={voltages}
                    accessor={accessor}
                    valueLabelGenerator={StackedRadialGaugeLabel}
                  />
                )}
              </ParentSize>
            </div>

            <div>
              <h2 className={"mb-2 text-lg"}>
                {t("datum:metrics.i.label", "current")}
              </h2>
              <ParentSize>
                {({ width }) => (
                  <StackedRadialGauge<SinglePhaseValue>
                    width={width}
                    height={200}
                    options={{ minValue: 0, maxValue: 16 }}
                    data={currents}
                    accessor={accessor}
                    valueLabelGenerator={StackedRadialGaugeLabel}
                  />
                )}
              </ParentSize>
            </div>

            <div>
              <h2 className={"mb-2 text-lg"}>
                {t("datum:metrics.p.label", "power")}
              </h2>
              <ParentSize>
                {({ width }) => (
                  <StackedRadialGauge<SinglePhaseValue>
                    width={width}
                    height={200}
                    options={{ minValue: 0, maxValue: 6000 }}
                    data={powers}
                    accessor={accessor}
                    valueLabelGenerator={StackedRadialGaugeLabel}
                  />
                )}
              </ParentSize>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-2 md:grid-cols-3 xl:grid-cols-6">
            <Card className="border-2 border-gray-100 shadow-none">
              <MetricStat
                unit={"Hz"}
                decimals={2}
                value={status?.metrics?.f}
                label={t("datum:metrics.f.label", "frequency")}
              />
            </Card>

            <Card className="border-2 border-gray-100 shadow-none">
              <MetricStat
                unit={""}
                decimals={2}
                value={status?.metrics?.pf_t}
                label={t("datum:metrics.pf_t.label", "powerfactor")}
              />
            </Card>

            <Card className="border-2 border-gray-100 shadow-none">
              <MetricStat
                unit={"voltampreact"}
                decimals={1}
                value={status?.metrics?.q_t}
                label={t("datum:metrics.q_t.label", "reactive power")}
              />
            </Card>

            <Card className="border-2 border-gray-100 shadow-none">
              <Text>
                {t("datum:metrics.phs.label", "phase order")}{" "}
                <InfoIcon>
                  {t(
                    "datum:metrics.phs.help",
                    "the phase order indicates if the phase order is right handed"
                  )}
                </InfoIcon>
              </Text>

              <div className={"mt-2 lg:text-2xl"}>
                <GoodBadStat
                  state={
                    status?.metrics?.phs !== undefined
                      ? status.metrics.phs
                        ? "good"
                        : "bad"
                      : undefined
                  }
                  labelGood={t("datum:metrics.phs.okay", "okay")}
                  labelBad={t("datum:metrics.phs.error", "error")}
                />
              </div>
            </Card>

            <Card className="border-2 border-gray-100 shadow-none">
              <MetricStat
                unit={"°C"}
                value={status?.metrics?.tplug}
                decimals={1}
                label={t("datum:metrics.tplug.label", "plug temperature")}
              />
            </Card>

            <Card className="border-2 border-gray-100 shadow-none">
              <Text>{t("device.cellular.reception", "mobile reception")}</Text>

              <div className="mt-2 h-6 lg:h-8">
                <DeviceCellularReceptionGraph deviceId={deviceId} />
              </div>
            </Card>
          </div>
        </FlexContainer>
      </Card>
    </ErrorBoundary>
  );
}
