import { HTMLAttributes } from "react";
import { classNames } from "../../app/util";

export function FlexContainer({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={classNames("flex flex-col gap-2", className)} {...props}>
      {children}
    </div>
  );
}
