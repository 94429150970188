import {
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  isWithinInterval,
  isAfter,
  isBefore,
  isSameDay,
  differenceInCalendarDays,
  addDays,
  setMonth,
  setYear,
} from "date-fns";
import React, { useState } from "react";
import { mapLocale } from "./locale-mapper";
import { classNames } from "../../app/util";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/solid";
import { Select } from "../inputs/select";
import { range } from "d3-array";

export function getMonthNames(
  locale = "en",
  format:
    | "long"
    | "numeric"
    | "2-digit"
    | "short"
    | "narrow"
    | undefined = "long"
): string[] {
  const formatter = new Intl.DateTimeFormat(locale, {
    month: format,
    timeZone: "UTC",
  });
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => {
    const mm = month < 10 ? `0${month}` : month;
    return new Date(`2022-${mm}-01T00:00:00+00:00`);
  });
  return months.map((date) => formatter.format(date));
}

export function getWeekdayNames(
  locale: string,
  format: "long" | "short" = "short"
): string[] {
  const now = new Date();

  const formatter = new Intl.DateTimeFormat(locale, { weekday: format });

  const loc = mapLocale(locale);

  return eachDayOfInterval({
    start: startOfWeek(now, { locale: loc }),
    end: endOfWeek(now, { locale: loc }),
  }).map((day) => formatter.format(day));
}

export type CalendarProps = {
  locale?: string;
  minDate?: Date;
  maxDate?: Date;
  today?: Date;
  date: Date;
  shownDate?: Date;
  onShownDateChange?: (date: Date) => void;
  onDateSelected: (date: Date) => void;
};

/**
 * Calendar component
 * https://github.com/hypeserver/react-date-range/blob/master/src/components/Month/index.js
 * @param locale
 * @param date
 * @param shownTimeRange
 * @param onShownDateChange
 * @param minDate
 * @param maxDate
 * @param onDateSelected
 * @constructor
 */
export function Calendar({
  locale = "en",
  today = new Date(),
  date,
  shownDate: initialShownDate,
  onShownDateChange,
  minDate,
  maxDate,
  onDateSelected,
}: CalendarProps) {
  const [shownDate, setShownDate] = useState<Date>(initialShownDate ?? date);

  const monthNames = getMonthNames(locale, "long");

  const onSelectDate = (date: Date) => {
    onDateSelected(date);
  };

  const weekDayNames = getWeekdayNames(locale, "short");

  const onChangeMonth = (month: number) => {
    const day = setMonth(shownDate ?? date, month);

    if (onShownDateChange) {
      onShownDateChange(day);
    }

    setShownDate(day);
  };

  const onChangeYear = (year: number) => {
    const day = setYear(shownDate ?? date, year);

    if (onShownDateChange) {
      onShownDateChange(day);
    }

    setShownDate(day);
  };

  return (
    <div className={"flex flex-col gap-1"}>
      {/*Year and Month Select Header*/}
      <div className="flex flex-col gap-1">
        <YearSelect
          minYear={minDate ? minDate.getFullYear() : undefined}
          maxYear={maxDate ? maxDate.getFullYear() : undefined}
          year={shownDate.getFullYear()}
          onSelectYear={onChangeYear}
        />
        <MonthSelect
          months={monthNames}
          month={shownDate.getMonth()}
          onSelectMonth={onChangeMonth}
        />
      </div>
      {/*Days */}
      <div className={"flex flex-col gap-1"}>
        <div className={"mt-4 grid grid-cols-7 gap-1 text-center"}>
          {/*Weekday names*/}
          {weekDayNames.map((d) => {
            return (
              <span className={"pb-2"} key={d}>
                {d}
              </span>
            );
          })}

          <CalendarDays
            minDate={minDate}
            maxDate={maxDate}
            onSelectDate={onSelectDate}
            locale={locale}
            shownDate={shownDate}
            date={date}
            today={today}
          />
        </div>
      </div>
    </div>
  );
}

export function DirectionButton({
  onClick,
  disabled = false,
  direction = "forward",
}: {
  direction: "back" | "forward" | "today";
  onClick: () => void;
  disabled?: boolean;
}) {
  const className = classNames(
    disabled ? "text-gray-400" : "text-blue",
    "h-4 w-4"
  );
  return (
    <button
      type="button"
      role="button"
      disabled={disabled}
      className={classNames(
        "flex w-8 items-center justify-center rounded",
        disabled ? "cursor-not-allowed" : "hover:bg-gray-200"
      )}
      onClick={onClick}
    >
      {direction === "forward" && <ChevronRightIcon className={className} />}
      {direction === "back" && <ChevronLeftIcon className={className} />}
      {direction === "today" && (
        <ChevronDoubleRightIcon className={className} />
      )}
    </button>
  );
}

export function YearSelect({
  year,
  onSelectYear,
  minYear = 2021,
  maxYear = new Date().getFullYear() + 2,
}: {
  year: number;
  maxYear?: number;
  minYear?: number;
  onSelectYear: (year: number) => void;
}) {
  const onBack = () => {
    onSelectYear(year - 1);
  };

  const onForward = () => {
    onSelectYear(year + 1);
  };

  const nextYearDisabled = year >= maxYear;
  const prevYearDisabled = year <= minYear;

  const availableYears: number[] = range(minYear, maxYear);

  return (
    <div className="flex items-stretch justify-between gap-2 font-semibold">
      <DirectionButton
        direction={"back"}
        onClick={onBack}
        disabled={prevYearDisabled}
      />
      <Select<number>
        setSelected={onSelectYear}
        selected={year}
        options={availableYears}
        getKey={(m) => `${m}`}
        getLabel={(m) => (m ? `${m}` : "-")}
      />
      <DirectionButton
        direction={"forward"}
        onClick={onForward}
        disabled={nextYearDisabled}
      />
    </div>
  );
}

function MonthSelect({
  onSelectMonth,
  month,
  months,
}: {
  month: number;
  months: string[];
  onSelectMonth: (month: number) => void;
}) {
  const onBack = () => {
    onSelectMonth(month > 0 ? Math.abs((month - 1) % 12) : 11);
  };

  const onForward = () => {
    onSelectMonth((month + 1) % 12);
  };

  const onSelect = ({ index }: { label: string; index: number }) => {
    onSelectMonth(index);
  };

  const monthsOptions = months.map((m, i) => {
    return { label: m, index: i };
  });
  const selectedMonth = monthsOptions[month];

  return (
    <div className="flex items-stretch justify-between gap-2 font-semibold">
      <DirectionButton direction={"back"} onClick={onBack} />
      <Select<{
        label: string;
        index: number;
      }>
        setSelected={onSelect}
        selected={selectedMonth}
        options={monthsOptions}
        getKey={(m) => `${m?.index}${m?.label}`}
        getLabel={(m) => m?.label ?? "-"}
      />
      <DirectionButton direction={"forward"} onClick={onForward} />
    </div>
  );
}

function CalendarDays({
  locale = "de",
  today,
  date,
  minDate,
  maxDate,
  shownDate,
  onSelectDate,
}: {
  locale: string;
  date: Date;
  today: Date;
  minDate?: Date;
  maxDate?: Date;
  shownDate: Date;
  onSelectDate: (date: Date) => void;
}) {
  const loc = mapLocale(locale);
  const opt = {
    locale: loc,
  };

  const monthStartDate = startOfMonth(shownDate);
  const monthEndDate = endOfMonth(shownDate);

  const calendarStartDate = startOfWeek(monthStartDate, opt);
  let calendarEndDate = endOfWeek(monthEndDate, opt);

  // We want always 6 rows (worst case) so we stretch the number of shown days to that range
  if (differenceInCalendarDays(calendarEndDate, calendarStartDate) <= 27) {
    calendarEndDate = addDays(calendarEndDate, 14);
  } else if (
    differenceInCalendarDays(calendarEndDate, calendarStartDate) <= 34
  ) {
    calendarEndDate = addDays(calendarEndDate, 7);
  }

  const days = eachDayOfInterval({
    start: calendarStartDate,
    end: calendarEndDate,
  }).map((day) => {
    return {
      day: day,
      isPassive: !isWithinInterval(day, {
        start: monthStartDate,
        end: monthEndDate,
      }),
      isAllowed:
        (minDate ? isAfter(day, minDate) || isSameDay(day, minDate) : true) &&
        (maxDate ? isBefore(day, maxDate) || isSameDay(day, maxDate) : true),
      isSelected: isSameDay(date, day),
    };
  });

  return (
    <>
      {days.map((day) => {
        return (
          <CalendarDay
            today={today}
            key={day.day.toISOString()}
            onSelect={onSelectDate}
            {...day}
          />
        );
      })}
    </>
  );
}

export function SelectionPill({
  label,
  onSelect,
  isSelected = false,
  isNow = false,
  isDisabled = false,
  isPassive = false,
}: {
  label: string;
  onSelect: () => void;
  isSelected?: boolean;
  isPassive?: boolean;
  isDisabled?: boolean;
  isNow?: boolean;
}) {
  return (
    <div
      className={classNames(
        "flex h-8 items-center justify-center rounded border border-gray-200 hover:border-blue",
        isNow ? "underline" : "",
        isSelected
          ? "cursor-pointer bg-blue text-white"
          : isPassive || isDisabled
          ? "cursor-not-allowed text-gray-300"
          : "cursor-pointer text-gray-700"
      )}
      aria-disabled={isPassive || isDisabled}
      onClick={() => {
        if (!isDisabled) {
          onSelect();
        }
      }}
    >
      {label}
    </div>
  );
}

function CalendarDay({
  isSelected,
  isAllowed,
  isPassive,
  day,
  today,
  onSelect,
}: {
  isSelected: boolean;
  isAllowed: boolean;
  isPassive: boolean;
  day: Date;
  today: Date;
  onSelect: (day: Date) => void;
}) {
  const onClick = () => {
    onSelect(day);
  };

  return (
    <SelectionPill
      isSelected={isSelected}
      isDisabled={!isAllowed}
      isPassive={isPassive}
      isNow={isSameDay(day, today)}
      label={`${day.getDate()}`}
      onSelect={onClick}
    />
  );
}
