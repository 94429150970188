import React, {
  PropsWithChildren,
  Dispatch,
  SetStateAction,
  useState,
} from "react";
import { Application } from "@nantis/gridknight-core";
import { getDefaultTimeRange } from "../../components/time/time-range-utils";

const TimeRangeContext = React.createContext<{
  timeRange: Application.TimeRange;
  setTimeRange: Dispatch<SetStateAction<Application.TimeRange>>;
}>({
  timeRange: getDefaultTimeRange(),
  setTimeRange: () => {
    console.warn("No TimeRange context provided");
  },
});

export const useTimeRange = () => {
  return React.useContext(TimeRangeContext);
};

export function TimeRangeProvider(props: PropsWithChildren<{}>) {
  const [timeRange, setTimeRange] = useState<Application.TimeRange>(
    getDefaultTimeRange()
  );

  return (
    <TimeRangeContext.Provider
      value={{
        timeRange,
        setTimeRange,
      }}
    >
      {props.children}
    </TimeRangeContext.Provider>
  );
}
