import { Route, Routes } from "react-router-dom";
import React from "react";
import { TagsListPage } from "./tags-list-page";
import { Tag } from "./tag";
import { TagCreatePage } from "./create/tag-create-page";

export function Tags() {
  return (
    <Routes>
      <Route path="/" element={<TagsListPage />} />
      <Route path="/create" element={<TagCreatePage />} />
      <Route path="/:tagId/*" element={<Tag />} />
    </Routes>
  );
}
