import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { PageHeadline } from "../../components/text/page-headline";
import { Card } from "../../components/containers/card";
import {
  updateTariff,
  deleteTariff,
  selectDeleteTariffLoadingState,
  selectFetchTariffRatesLoadingState,
  selectFetchTariffsLoadingState,
  selectTariffById,
  selectTariffRatesByTariffId,
  selectUpdateTariffLoadingState,
} from "./tariff-slice";
import { TariffForm } from "./tariff-form";
import { Application } from "@nantis/gridknight-core";
import { PlusIcon } from "@heroicons/react/24/outline";
import { TariffRateTable } from "./tariffRates/tariff-rate-table";
import { Can } from "../../auth/user-ability-provider";
import { LoadingButton, RoundActionButton } from "../../components/ng";

export function TariffUpdatePage({ id }: { id: string }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const tariff = useAppSelector((state) => selectTariffById(state, id));
  const tariffRates = useAppSelector((state) =>
    selectTariffRatesByTariffId(state, id)
  );
  const fetchTariffsState = useAppSelector((state) =>
    selectFetchTariffsLoadingState(state)
  );
  const fetchTariffRatesState = useAppSelector((state) =>
    selectFetchTariffRatesLoadingState(state)
  );
  const updateTariffState = useAppSelector((state) =>
    selectUpdateTariffLoadingState(state)
  );
  const deleteTariffState = useAppSelector((state) =>
    selectDeleteTariffLoadingState(state)
  );

  const onUpdateTariff = async ({ name }: Pick<Application.Tariff, "name">) => {
    dispatch(updateTariff({ id, name })).then(() => {
      navigate({
        pathname: "../../..",
      });
    });
  };

  const onDeleteTariff = async () => {
    dispatch(deleteTariff({ id })).then(() => {
      navigate({
        pathname: "../../..",
      });
    });
  };

  return (
    <div>
      <PageHeadline>{t("tariffs.update.title", "Update group")}</PageHeadline>

      <div className={"mb-4 text-sm"}>
        <RouterLink to={"../../.."}>
          <span aria-hidden="true">&larr;</span>{" "}
          {t("tariffs.update.backToTariffs", "back to tags")}
        </RouterLink>
      </div>

      <Card>
        {tariff && (
          <TariffForm existingTariff={tariff} onSave={onUpdateTariff}>
            <div className="pt-5">
              <div className="flex justify-end gap-4">
                <LoadingButton
                  onClick={onDeleteTariff}
                  isLoading={deleteTariffState.status === "pending"}
                  variant="destructive"
                  type="button"
                >
                  {t("tariffs.delete", "delete tariff")}
                </LoadingButton>

                <LoadingButton
                  isLoading={updateTariffState.status === "pending"}
                  form="tariffForm"
                  variant="success"
                  type="submit"
                >
                  {t("tariffs.update.save", "save tariff")}
                </LoadingButton>
              </div>
            </div>
          </TariffForm>
        )}
      </Card>

      {fetchTariffsState.status === "fulfilled" &&
        fetchTariffRatesState.status === "fulfilled" && (
          <div className="mt-4">
            <Can I="create" a="tariff">
              <div className="float-right">
                <RouterLink
                  key={"addTariff"}
                  className={"text-sm"}
                  to={"tariff-rates/create"}
                >
                  <RoundActionButton
                    variant={"success"}
                    onClick={() => {}}
                    title={t("tariffs.add", "create energy tariff")}
                  >
                    <PlusIcon className="h-5 w-5" />
                  </RoundActionButton>
                </RouterLink>
              </div>
            </Can>

            <PageHeadline>
              {t("tariffRates.title", "Energy prices")}
            </PageHeadline>

            <div className="mt-2 inline-block min-w-full align-middle">
              <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                <TariffRateTable tariffRates={tariffRates} />
              </div>
            </div>
          </div>
        )}
    </div>
  );
}
