import { sum } from "d3-array";
import { scaleLinear } from "d3-scale";
import { useMemo } from "react";
import { Bar } from "@visx/shape";
import { VisualizationProps } from "../../types";
import { Group } from "@visx/group";

export type PercentBarProps = VisualizationProps & {
  colors: string[];
  values: { value: number; label: string }[];
};

export function PercentBar({
  values,
  colors,
  width,
  height,
  margin = { top: 0, right: 0, bottom: 0, left: 0 },
}: PercentBarProps) {
  const { top = 0, right = 0, bottom = 0, left = 0 } = margin;

  const padding = height / 2;
  const xMax = width - left - right - (values.length - 1) * padding;
  const barHeight = height - top - bottom;

  const total = sum(values, (v) => v.value);

  const xScale = useMemo(() => {
    return scaleLinear([0, total], [0, xMax]);
  }, [width, values]);

  return (
    <svg width={width} height={height}>
      <Group top={top} left={left}>
        {total > 0 &&
          values.map((v, i) => {
            const x = (i > 0 ? xScale(values[i - 1].value) : 0) + i * padding;
            return (
              <Bar
                key={i}
                rx={barHeight / 2}
                x={x}
                width={xScale(v.value)}
                height={barHeight}
                fill={colors[i]}
              />
            );
          })}
        {total == 0 && (
          <Bar
            rx={barHeight / 2}
            x={0}
            width={xMax}
            height={barHeight}
            fill={"#d0d0d0"}
          />
        )}
      </Group>
    </svg>
  );
}
