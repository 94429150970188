import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import React from "react";
import { Can } from "../../auth/user-ability-provider";

export function FeatureNotAvailableBanner() {
  const { t } = useTranslation();

  return (
    <div className="mx-auto max-w-2xl py-4 px-4 text-center sm:py-20 sm:px-6 md:py-16 lg:px-8">
      <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
        <span className="block">
          {t(
            "features.notAvailableInPlan",
            "This feature is not included in your current plan."
          )}
        </span>
      </h2>
      <p className="mt-4 text-lg leading-6 text-gray-700">
        {t("features.upgradeToUse", "Please upgrade to use this feature")}
      </p>

      <Can I="update" a="payment">
        <RouterLink
          to={"/settings/account"}
          className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green px-5 py-3 text-base text-white sm:w-auto"
        >
          {t("features.upgradePlan", "Update plan")}
        </RouterLink>
      </Can>

      <Can not I="update" a="payment">
        <p className={"mt-4 text-lg text-gray-700 text-blue"}>
          {t(
            "features.askAdminToUpgradePlan",
            "Ask an administrator of this account to update your plan"
          )}
        </p>
      </Can>
    </div>
  );
}
