import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FetchStatus } from "../../models/types";
import { Application } from "@nantis/gridknight-core";
import { RootState } from "../../app/store";
import { API } from "aws-amplify";

export const createTenant = createAsyncThunk(
  "tenant/createTenant",
  async ({
    name,
    email,
    locale = "de-DE",
    timezone = "Europe/Berlin",
  }: {
    name: string;
    email: string;
    locale?: string;
    timezone?: string;
  }) => {
    const result = (await API.graphql({
      query: `
                mutation createTenantMutation($locale: String, $email: AWSEmail!, $name: String!, $timezone: String) {
                  createTenant(email: $email, name: $name, locale: $locale, timezone: $timezone)
                }
            `,
      variables: {
        name,
        email,
        locale,
        timezone,
      },
      authMode: "AWS_IAM",
    })) as { data: { createTenant: boolean } };

    // returning a boolean would mean abort fetch, so return object
    return result.data;
  }
);

export const updateTenant = createAsyncThunk(
  "tenant/updateTenant",
  async ({ name }: { name?: string }) => {
    const result = (await API.graphql({
      query: `
                mutation UpdateTenantMutation($name: String) {
                  updateTenant(name: $name) {
                    id
                    name
                    subscription
                    tier
                  }
                }
            `,
      variables: {
        name,
      },
    })) as { data: { updateTenant: Application.Tenant } };

    return result.data.updateTenant;
  }
);

export const fetchTenant = createAsyncThunk(
  "tenant/fetchTenant",
  async (_, { signal }) => {
    const promise = API.graphql({
      query: `
            query getTenant {
              tenant {
                id
                name
                subscription
                tier
              }
            }
        `,
    }) as Promise<any>;

    signal.addEventListener("abort", () => {
      API.cancel(promise, "Operation canceled by the user.");
    });

    const result = (await promise) as { data: { tenant: Application.Tenant } };

    return {
      ...result.data?.tenant,
      subscription: JSON.parse(result.data?.tenant.subscription as string),
    } as Application.Tenant;
  }
);

// {CHECKOUT_SESSION_ID} is a string literal; do not change it!
// the actual Session ID is returned in the query parameter when your customer
// is redirected to the success page.
//'https://example.com/success.html?session_id={CHECKOUT_SESSION_ID}'
export const setSubscription = createAsyncThunk(
  "tenant/setSubscription",
  async ({
    tier,
    successUrl,
    cancelUrl,
  }: {
    tier: string;
    successUrl: string | null;
    cancelUrl: string | null;
  }) => {
    const result = (await API.graphql({
      query: `
                mutation setSubscriptionMutation($cancelUrl: String, $successUrl: String, $tier: String!) {
                  setSubscription(tier: $tier, cancelUrl: $cancelUrl, successUrl: $successUrl) {
                    sessionUrl
                    status
                    tier
                  }
                }
            `,
      variables: {
        tier,
        successUrl,
        cancelUrl,
      },
    })) as {
      data: {
        setSubscription: {
          status: string;
          tier: Application.Subscription.Tier;
          sessionUrl?: string;
        };
      };
    };

    return result.data;
  }
);

export const createCustomerPortalSession = createAsyncThunk(
  "tenant/createSubscriptionSession",
  async ({ returnUrl }: { returnUrl: string | null }) => {
    const result = (await API.graphql({
      query: `
                mutation createCustomerPortalSession($returnUrl: String) {
                  createCustomerPortalSession(returnUrl: $returnUrl)
                }
            `,
      variables: {
        returnUrl,
      },
    })) as {
      data: {
        createCustomerPortalSession: string;
      };
    };

    return {
      sessionUrl: result.data.createCustomerPortalSession,
    };
  }
);

export const sendCustomerSupportMessage = createAsyncThunk(
  "tenant/sendCustomerSupportMessage",
  async ({
    message,
    meta,
  }: {
    message: string;
    meta: Record<string, string>;
  }) => {
    const result = (await API.graphql({
      query: `
              mutation SendCustomerSupportMessage($message: String!, $meta: AWSJSON) {
                sendCustomerSupportMessage(message: $message, meta: $meta)
              }
            `,
      variables: {
        message,
        meta: JSON.stringify(meta),
      },
    })) as {
      data: {
        sendCustomerSupportMessage: boolean;
      };
    };

    return result.data;
  }
);

const initialState: {
  tenant: Application.Tenant | null;
  createTenant: FetchStatus;
  fetchTenant: FetchStatus;
  updateTenant: FetchStatus;
  setSubscription: FetchStatus & {
    data: {
      status: string | null;
      tier: Application.Subscription.Tier | null;
      sessionUrl?: string | null;
    } | null;
  };
  createCustomerPortalSession: FetchStatus & {
    sessionUrl: string | null;
  };
  sendCustomerSupportMessage: FetchStatus;
} = {
  tenant: null,
  createTenant: {
    status: "idle",
    error: null,
  },
  updateTenant: {
    status: "idle",
    error: null,
  },
  fetchTenant: {
    status: "idle",
    error: null,
  },
  setSubscription: {
    status: "idle",
    error: null,
    data: null,
  },
  createCustomerPortalSession: {
    status: "idle",
    error: null,
    sessionUrl: null,
  },
  sendCustomerSupportMessage: {
    status: "idle",
    error: null,
  },
};

const tenantSlice = createSlice({
  name: "tenant",
  initialState,
  reducers: {
    resetCreateTenant(state) {
      state.createTenant = {
        status: "idle",
        error: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createTenant.pending, (state) => {
      state.createTenant.status = "pending";
      state.createTenant.error = null;
    });

    builder.addCase(createTenant.fulfilled, (state) => {
      state.createTenant.status = "fulfilled";
      state.createTenant.error = null;
    });

    builder.addCase(createTenant.rejected, (state) => {
      state.createTenant.status = "rejected";
      state.createTenant.error = "creating tenant errored";
    });

    builder.addCase(updateTenant.pending, (state) => {
      state.updateTenant.status = "pending";
      state.updateTenant.error = null;
    });

    builder.addCase(updateTenant.fulfilled, (state, action) => {
      state.updateTenant.status = "fulfilled";
      state.updateTenant.error = null;
      const tenant = action.payload;
      if (tenant !== undefined && state.tenant) {
        state.tenant.name = tenant.name;
      }
    });

    builder.addCase(updateTenant.rejected, (state) => {
      state.updateTenant.status = "rejected";
      state.updateTenant.error = "updating tenant errored";
    });

    builder.addCase(fetchTenant.pending, (state) => {
      state.fetchTenant.status = "pending";
    });

    builder.addCase(fetchTenant.fulfilled, (state, action) => {
      state.fetchTenant.status = "fulfilled";
      const tenant = action.payload;
      if (tenant !== undefined) {
        state.tenant = tenant;
      }
    });

    builder.addCase(fetchTenant.rejected, (state) => {
      state.fetchTenant.status = "rejected";
      state.fetchTenant.error = "Fetching tenant errored";
      state.tenant = null;
    });

    builder.addCase(setSubscription.pending, (state) => {
      state.setSubscription.status = "pending";
      state.setSubscription.error = null;
      state.setSubscription.data = null;
    });

    builder.addCase(setSubscription.fulfilled, (state, action) => {
      state.setSubscription.status = "fulfilled";
      state.setSubscription.data = action.payload.setSubscription;
      state.setSubscription.error = null;

      if (state.setSubscription.data.status === "success") {
        if (state.tenant != null) {
          state.tenant.tier = action.payload.setSubscription.tier;
        }
      }
    });

    builder.addCase(setSubscription.rejected, (state) => {
      state.setSubscription.status = "rejected";
      state.setSubscription.data = null;
      state.setSubscription.error = "creating subscription session errored";
    });

    builder.addCase(createCustomerPortalSession.pending, (state) => {
      state.createCustomerPortalSession.status = "pending";
      state.createCustomerPortalSession.error = null;
      state.createCustomerPortalSession.sessionUrl = null;
    });

    builder.addCase(createCustomerPortalSession.fulfilled, (state, action) => {
      state.createCustomerPortalSession.status = "fulfilled";
      state.createCustomerPortalSession.sessionUrl = action.payload.sessionUrl;
      state.createCustomerPortalSession.error = null;
    });

    builder.addCase(createCustomerPortalSession.rejected, (state) => {
      state.createCustomerPortalSession.status = "rejected";
      state.createCustomerPortalSession.sessionUrl = null;
      state.createCustomerPortalSession.error =
        "creating customer portal session errored";
    });

    builder.addCase(sendCustomerSupportMessage.pending, (state) => {
      state.sendCustomerSupportMessage.status = "pending";
      state.sendCustomerSupportMessage.error = null;
    });
    builder.addCase(sendCustomerSupportMessage.fulfilled, (state) => {
      state.sendCustomerSupportMessage.status = "fulfilled";
      state.sendCustomerSupportMessage.error = null;
    });
    builder.addCase(sendCustomerSupportMessage.rejected, (state) => {
      state.sendCustomerSupportMessage.status = "rejected";
      state.sendCustomerSupportMessage.error =
        "sending customer support message errored";
    });
  },
});

export const reducer = tenantSlice.reducer;

export const { resetCreateTenant } = tenantSlice.actions;

export const selectFetchTenantLoadingState = (state: RootState) =>
  state.tenant.fetchTenant;
export const selectCreateTenantLoadingState = (state: RootState) =>
  state.tenant.createTenant;
export const selectUpdateTenantLoadingState = (state: RootState) =>
  state.tenant.updateTenant;
export const selectSetSubscriptionState = (state: RootState) =>
  state.tenant.setSubscription;
export const selectCreateCustomerPortalSessionState = (state: RootState) =>
  state.tenant.createCustomerPortalSession;
export const selectSendCustomerMessageState = (state: RootState) =>
  state.tenant.sendCustomerSupportMessage;
export const selectTenant = (state: RootState) => state.tenant.tenant;
// TODO tenant settings e.g. currency and locale
export const selectTenantCurrency = (state: RootState) => "EUR";
export const selectTenantCurrencySymbol = (state: RootState) => "€";
