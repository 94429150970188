import { AbilityBuilder, Ability } from "@casl/ability";
import { Application, getTierPlan } from "@nantis/gridknight-core";
import { CognitoUserProps } from "./auth-slice";

type Actions =
  | "use"
  | "invite"
  | "resendInvitation"
  | "remove"
  | "update"
  | "pair"
  | "unpair"
  | "create"
  | "delete"
  | "attach";
type Subjects =
  | "historicalData"
  | "events"
  | "location"
  | "user"
  | "payment"
  | "tenant"
  | "device"
  | "tag"
  | "tariff";
export type AppAbility = Ability<[Actions, Subjects]>;

/**
 * We create a common ability set for both the tenant and the user to manage abilities
 *
 * @param user
 * @param tenant
 */
export default function defineAbilityFor(
  user?: CognitoUserProps | null,
  tenant?: Application.Tenant | null
) {
  const { can, build } = new AbilityBuilder<AppAbility>(Ability);

  // Tenant abilities
  can("use", "location");

  if (tenant?.tier) {
    getTierPlan(tenant.tier as Application.Subscription.Tier).features.map(
      (feature) => {
        can("use", feature);
      }
    );
  }

  // User / Admin abilities
  if (user?.role === "admin") {
    // Settings page
    can("invite", "user");
    can("resendInvitation", "user");
    can("remove", "user");
    can("update", "user");

    can("update", "tenant");

    // Devices
    can("pair", "device");
    can("unpair", "device");

    // Tags
    can("create", "tag");
    can("update", "tag");
    can("delete", "tag");
    can("attach", "tag");

    // Tariffs
    can("create", "tariff");
    can("update", "tariff");
    can("delete", "tariff");
    can("attach", "tariff");

    // Tier change
    if (!tenant?.settings?.managedAccount) {
      can("update", "payment");
    }
  }

  if (user?.role === "user") {
  }

  return build();
}
