import { useTranslation } from "react-i18next";
import { DEFlag, GBFlag } from "../../assets/flags";
import { Listbox } from "@headlessui/react";

function LanguageIcon({ language }: { language: string }) {
  const flag = (() => {
    switch (language) {
      case "de":
        return <DEFlag overflow="clip" />;
      case "en":
        return <GBFlag />;
      default:
        return <DEFlag />;
    }
  })();

  return (
    <div
      title={language}
      className={
        "h-7 w-7 cursor-pointer overflow-hidden rounded-full border-2 border-white"
      }
    >
      {flag}
    </div>
  );
}

export function LanguageSelector() {
  const { i18n } = useTranslation();

  const onChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  const languages = ["de", "en"];

  return (
    <div className="relative inline-block h-7 w-7">
      <Listbox value={i18n.language} onChange={onChangeLanguage}>
        <Listbox.Button>
          <LanguageIcon language={i18n.language} />
        </Listbox.Button>
        <Listbox.Options className="absolute flex flex-col gap-2 ">
          {languages
            .filter((l) => l != i18n.language)
            .map((language) => (
              <Listbox.Option key={language} value={language}>
                <LanguageIcon language={language} />
              </Listbox.Option>
            ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
}
