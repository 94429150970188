import { ParentSize } from "@visx/responsive";
import { CellularReceptionGraph } from "../../../components/cellular/cellular-reception-graph";
import React from "react";
import { Application, getReceptionStrength } from "@nantis/gridknight-core";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/store";
import { selectDeviceStateByDeviceId } from "../devices-slice";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../components/help/tooltip";

export function DeviceCellularReceptionGraph({
  deviceId,
}: {
  deviceId: string;
}) {
  const { t } = useTranslation();
  const status = useAppSelector((state) =>
    selectDeviceStateByDeviceId(state, deviceId)
  );

  let reception: Application.CellularReceptionQuality = "no-reception";

  let receptionMessage = <>-</>;

  if (status && status.cellular) {
    const { rssi, rat, rsrp, rsrq, band } = status.cellular;

    reception = getReceptionStrength(status?.cellular);

    receptionMessage = (
      <div>
        <p>
          {t("device.cellular.reception", "Mobile Reception")}:{" "}
          {t("device.cellular.receptionLevels." + reception, reception)}
        </p>
        <p>
          {t("device.cellular.technology", "Technology")}: {rat}
        </p>
        <p>
          {t("device.cellular.rssi", "RSSI")}: {rssi} dBm
        </p>
        <p>
          {t("device.cellular.rsrq", "RSRP")}: {rsrq ?? "-"} dB
        </p>
        <p>
          {t("device.cellular.rsrp", "RSRQ")}: {rsrp ?? "-"} dBm
        </p>
        <p>
          {t("device.cellular.band", "band")}: {band ?? "-"}
        </p>
      </div>
    );
  }

  return (
    <ParentSize>
      {({ width, height }) => (
        <Tooltip>
          <TooltipTrigger>
            <CellularReceptionGraph
              height={height}
              width={width}
              options={{
                reception: reception,
              }}
            />
            <TooltipContent>{receptionMessage}</TooltipContent>
          </TooltipTrigger>
        </Tooltip>
      )}
    </ParentSize>
  );
}
