import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { App } from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./translations/i18n";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { store } from "./app/store";
import { AmplifyBridge } from "./auth/AmplifyBridge";

// https://github.com/GoogleChromeLabs/jsbi/issues/30#issuecomment-953187833
// @ts-ignore
BigInt.prototype.toJSON = function () {
  return this.toString();
};

const root = createRoot(document.getElementById("root") as HTMLElement);

new AmplifyBridge(store);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    console.log("Updated service worker");
  },
  onSuccess: (registration) => {
    console.log("Service worker installed");
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
