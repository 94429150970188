import { Table } from "@tanstack/react-table";

import { XMarkIcon, MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { Button } from "../ng";
import { Input } from "../ng/input";
import { useTranslation } from "react-i18next";

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
}

export function DataTableToolbar<TData>({
  globalFilter,
  setGlobalFilter,
}: DataTableToolbarProps<TData>) {
  const { t } = useTranslation();

  const isFiltered = globalFilter.length > 0;

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-2">
        <div className="relative rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon className="mr-2 h-4 w-4" />
          </div>
          <Input
            placeholder={t("table.search", "search...")}
            value={globalFilter ?? ""}
            onChange={(event) => setGlobalFilter(event.target.value)}
            className="h-8 pl-10"
          />
        </div>

        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => setGlobalFilter("")}
            className="h-8 px-2 lg:px-3"
          >
            <XMarkIcon className="mr-2 h-4 w-4" />
            {t("table.reset", "reset")}
          </Button>
        )}
      </div>
    </div>
  );
}
