import React, { useState } from "react";
import {Application, Device} from "@nantis/gridknight-core";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { acknowledgeDeviceEvent, selectEventsByDevice } from "../devices-slice";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ArrowRightIcon,
  XMarkIcon,
  BoltIcon,
    EllipsisHorizontalIcon
} from "@heroicons/react/24/outline";
import { selectDeviceById } from "../devices-slice";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { NotificationCard } from "../../../components/containers/notification-card";
import type {Prettify} from "../../../app/util";

/**
 * We do want to show a list of events and limit the number to a certain size
 * @param deviceId
 * @param limit
 * @param collapsed
 * @param showDeviceLink
 * @constructor
 */
export function DeviceEventsList({
  deviceId,
  limit = 5,
   collapsed = true,
  showDeviceLink = true,
}: {
  deviceId: string;
  limit?: number;
  collapsed?: boolean;
  showDeviceLink?: boolean;
}) {
  const { t } = useTranslation();

  const device = useAppSelector((state) => selectDeviceById(state, deviceId));

  // Show the latest events first
  const deviceEvents = useAppSelector((state) =>
    selectEventsByDevice(state, deviceId)
  ).sort((a, b) => {
    const aV = a.time ? new Date(a.time).getTime() : 0;
    const bV = b.time ? new Date(b.time).getTime() : 0;
    return bV - aV;
  });

  const [open, setOpen] = useState(!collapsed);

  if (!device) {
    return <></>;
  }

  return (
    <NotificationCard variant="warning" className={"rounded-none shadow-none"}>
      <div
        onClick={() => setOpen(!open)}
        className={'flex cursor-pointer'}
      >
        <div className={"flex-grow text-sm text-gray-700"}>
          <p>
            {t("device.events.summary.historicEventsInDevice", {
              name: device?.name ?? device?.id,
              count: deviceEvents.length,
            })}
            {showDeviceLink && (
              <RouterLink
                className="ml-1 text-gray-700"
                to={`/devices/${deviceId}`}
              >
                <span className="whitespace-nowrap">
                  {t("device.events.goToDevice", "go to device")}{" "}
                </span>
                <ArrowRightIcon className="inline-block h-4 w-4" />
              </RouterLink>
            )}
          </p>
        </div>

          <div className="ml-4 whitespace-nowrap text-right text-sm">
            <span
              title={
                open
                  ? t("device.events.collapse", "collapse")
                  : t("device.events.expand", "expand")
              }
            >
              {open ? (
                <ArrowUpIcon className={"-mt-1 inline-block h-4 w-4"} />
              ) : (
                <ArrowDownIcon className={"-mt-1 inline-block h-4 w-4"} />
              )}
            </span>
          </div>

      </div>

      {open && (
        <div>
          <ul className="md:mt-6: ml-4 mt-4 md:ml-8">
            {deviceEvents.length > 0 &&
              deviceEvents.map((event, index) => {
                if (!showDeviceLink || index < limit) {
                  return (
                    <li key={event.id}>
                      <DeviceEventListItem
                        event={event}
                        isLastItem={index === deviceEvents.length - 1}
                      />
                    </li>
                  );
                }
                return null;
              })}
            {showDeviceLink && deviceEvents.length > limit && (
              <li>
                <div className="relative pb-2">
                  <div className="relative flex space-x-4">
                    <div>
                      <span className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-400">
                        <EllipsisHorizontalIcon className={"h-5 w-5 text-white"}/>
                      </span>
                    </div>
                    <div className="border-b-1 flex min-w-0 flex-1 justify-between border-gray-200">
                      <div>
                        <RouterLink to={`/devices/${deviceId}`}>
                          <p className="text-sm text-gray-900">
                            {t(
                              "device.events.viewMoreInDevice",
                              "see more in device detail view"
                            )}

                            <ArrowRightIcon className="inline-block ml-1 h-4 w-4" />
                          </p>
                        </RouterLink>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            )}
          </ul>
        </div>
      )}
    </NotificationCard>
  );
}

function buildDeviceEventMessage(
  t: TFunction,
  event: Application.Events.DatabaseEvent<any, any>
): string {
  if (event.source == "gridknight" && event.type === 'device.event') {

    const deviceEvent = event as Prettify<Application.Events.DatabaseDeviceEvent>;

    return deviceEvent.data?.events?.map((e) => {

      if(e.type == 'de') {
        return t("device.events.message.offline", "The device has lost the connection")
      }

      if(e.type == 'dt') {
        const eventData = e.data as unknown as Device.ValueThresholdEvent;
        const property = eventData.prop.toLowerCase();

        // gen3 remove
        const propertyLabel = t(`datum:metrics.${property}.label`, property);

        return t("device.events.message.event", {
          context: eventData.comp,
          propertyLabel: propertyLabel,
          datum: {
            value: eventData.val,
            property: property,
          },
          limit: {
            value: eventData.lim,
            property: property,
          },
        });

      }

    }).join(' - ');
  }

  return "";
}

function DeviceEventListItem({
  event,
  isLastItem,
}: {
  event: Application.Events.DatabaseEvent<any, any>;
  isLastItem: boolean;
}) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const acknowledgeViolation = (event: Application.Events.DatabaseEvent<any, any>) => {
    dispatch(acknowledgeDeviceEvent(event));
  };

  if(event) {

    const violationDate = event.data?.time ? new Date(event.data.time) : new Date(event.time);
    const eventString = buildDeviceEventMessage(t, event);

    return (
        <div className="relative pb-2">
          {!isLastItem && (
              <span
                  className="absolute top-4 left-3 -ml-px h-full w-0.5 bg-gray-400"
                  aria-hidden="true"
              />
          )}

          <div className="relative flex space-x-4">
            <div>
          <span className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-400">
            <BoltIcon className={"h-4 w-4 text-white"} />
          </span>
            </div>
            <div className="flex min-w-0 flex-1 justify-between">
              <div>
                <p className="text-sm text-gray-900">{eventString}</p>
                <time className="text-sm text-gray-700">
                  {t("device.events.date", { date: violationDate })}
                </time>
              </div>
              <div
                  title={t("device.events.acknowledgeAlert", "acknowledge violation")}
                  className="cursor-pointer whitespace-nowrap text-right text-sm text-gray-900"
              >
                <XMarkIcon
                    onClick={() => acknowledgeViolation(event)}
                    className={"h-5 w-5"}
                />
              </div>
            </div>
          </div>
        </div>
    );
  } else {
    return <div className="relative pb-2">No data</div>
  }
}
