import React, { FormEvent, Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../../app/util";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { selectCurrentUser } from "../users/users-slice";
import {
  selectSendCustomerMessageState,
  selectTenant,
  sendCustomerSupportMessage,
} from "../tenants-slice";
import { Button, LoadingButton } from "../../../components/ng";
import { encodeMailLink } from "./encodeMailLink";

export type CustomerSupportHelpModalProps = {
  open: boolean;
  close: () => void;
};

export function CustomerSupportModal({
  open,
  close,
}: CustomerSupportHelpModalProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const cancelButtonRef = useRef(null);

  const [showSuccess, setShowSuccess] = useState(false);

  const currentUser = useAppSelector(selectCurrentUser);
  const tenant = useAppSelector(selectTenant);
  const sendCustomerSupportMessageState = useAppSelector(
    selectSendCustomerMessageState
  );

  const url = window.location.href;

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    dispatch(
      sendCustomerSupportMessage({
        message: formData.get("message") as string,
        meta: {
          url,
        },
      })
    )
      .then(() => {
        setShowSuccess(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onClose = () => {
    setShowSuccess(false);
    close();
  };

  const onCancel = () => {
    close();
  };

  const recipient = t("support.emailAddress", "support@gridknight.com");

  const emailSubject = t(
    "support.emailSubject",
    "Customer Support Request from {{customerName}}",
    {
      customerName: tenant?.name,
    }
  );

  const emailBody = t(
    "support.emailBody",
    "Dear Gridknight Team,\n\nmy customer number is {{customerNumber}} and I have the following problem:\n\n\n\nBest regards,\n{{userName}}",
    {
      customerNumber: currentUser?.tenant_id,
      customerName: tenant?.name,
      userName: currentUser?.name,
    }
  );

  const emailLink = encodeMailLink({
    recipient: recipient,
    subject: emailSubject,
    body: emailBody,
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[1002]"
        initialFocus={cancelButtonRef}
        onClose={close}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 z-[1003] bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-[1004] overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-6 sm:max-w-4xl">
                {showSuccess && (
                  <div className="flex flex-col items-center gap-4 bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <h1 className={"text-center text-lg font-bold"}>
                      {t(
                        "support.thankYou",
                        "Thank you for submitting a message to our customer support. We will get back to you as soon as possible."
                      )}
                    </h1>

                    <div className={"text-center"}>
                      <p className={"mb-4"}>
                        {t(
                          "support.reachViaPhoneOrMail",
                          "You can also reach us via phone or mail:"
                        )}
                      </p>
                      <p>
                        <a
                          href={`tel:${t(
                            "support.phoneNumber",
                            "+49 (711) 34 20 200"
                          )}`}
                        >
                          {t("support.phone", "Telephone")}:{" "}
                          {t("support.phoneNumber", "+49 (711) 34 20 200")}
                        </a>
                      </p>
                      <p>
                        <a href={emailLink}>
                          {t("support.email", "Email:")}: {recipient}
                        </a>
                      </p>
                    </div>

                    <Button variant={"success"} onClick={onClose}>
                      {t("support.closeModal", "close")}
                    </Button>
                  </div>
                )}
                {!showSuccess && (
                  <form id="sendCustomerSupportMessage" onSubmit={onSubmit}>
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div
                          className={classNames(
                            "mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue/20 sm:mx-0 sm:h-10 sm:w-10"
                          )}
                        >
                          <QuestionMarkCircleIcon
                            className="h-6 w-6 text-blue"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="mt-3 w-full text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-700"
                          >
                            {t(
                              "support.contactSupport",
                              "Contact customer support"
                            )}
                          </Dialog.Title>

                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              {t("support.customer", "customer")} :{" "}
                              {tenant?.name}
                            </p>
                            <p className="text-sm text-gray-500">
                              {t("support.user", "user")} : {currentUser?.name}{" "}
                              ({currentUser?.email})
                            </p>

                            <label
                              htmlFor="message"
                              className="mt-4 block text-sm font-medium leading-6 text-gray-900"
                            >
                              {t("support.message", "your message for us")}
                              <textarea
                                required
                                rows={4}
                                name="message"
                                id="message"
                                className="focus:ring-indigo-600 mt-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col justify-end gap-2 bg-gray-50 px-4 py-3 sm:flex-row sm:px-6">
                      <Button
                        type={"button"}
                        variant={"outline"}
                        ref={cancelButtonRef}
                        onClick={onCancel}
                      >
                        {t("support.cancel", "cancel")}
                      </Button>

                      <LoadingButton
                        type="submit"
                        form="sendCustomerSupportMessage"
                        variant={"success"}
                        isLoading={
                          sendCustomerSupportMessageState.status === "pending"
                        }
                      >
                        {t("support.send", "send message")}
                      </LoadingButton>
                    </div>
                  </form>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
