import { Card } from "../../../components/containers/card";
import { Text } from "../../../components/metrics/text";
import { MetricStat } from "../../../components/metrics/metric-stat";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  resetDeviceUserCounter,
  resetDeviceUserCounterLoadingState,
  selectDeviceById,
  selectDeviceStateByDeviceId,
} from "../devices-slice";
import { Button, ButtonGroup } from "../../../components/buttons/button-group";
import { OperatingHourCounterIcon } from "../../../assets/icons";
import { AnimatedNumber } from "../../../components/metrics/animated-number";
import type { Application } from "@nantis/gridknight-core";
import ConfirmationModal, {
  ConfirmationModalProps,
} from "../../../components/modals/confirmation-modal";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { LoadingSpinner } from "../../../components/loading/loading-spinner";

function DeviceCounterStat({
  decimals = 2,
  value,
  tripValue,
  unit,
  label,
  start,
}: {
  value?: number;
  label: string;
  tripValue?: number;
  decimals?: number;
  unit: string;
  start: Date;
}) {
  const { t } = useTranslation();

  const showTripValue = tripValue != undefined && value != undefined;

  return (
    <Card className={"border-2 border-gray-100 shadow-none"}>
      <div className="flex h-full grow flex-col gap-1">
        <MetricStat
          unit={unit}
          decimals={decimals}
          value={showTripValue ? value - tripValue : value}
          label={label}
        />
        {showTripValue && (
          <Text variant={"small"}>
            {t(
              "device.counters.since",
              "since {{date, date(day: numeric; month: short; year: numeric; hour: 2-digit; minute: 2-digit)}}",
              { date: start }
            )}
          </Text>
        )}
        {showTripValue && (
          <Text variant={"small"}>
            <span className={"mr-1"}>
              {t("device.counters.total", "Total")}
            </span>
            <AnimatedNumber
              value={value}
              unit={unit}
              decimalPoints={decimals}
            />
          </Text>
        )}
      </div>
    </Card>
  );
}

export function DeviceCounters({ deviceId }: { deviceId: string }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const device = useAppSelector((state) => selectDeviceById(state, deviceId));

  const status = useAppSelector((state) =>
    selectDeviceStateByDeviceId(state, deviceId)
  );

  const resetUserCounterLoadingState = useAppSelector((state) =>
    resetDeviceUserCounterLoadingState(state)
  );

  const [openResetModal, setOpenResetModal] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);

  let tripCounters: Partial<Application.ResettableDeviceCounter> = {};

  if (device) {
    if (device.counters) {
      tripCounters = JSON.parse(
        device.counters as string
      ) as Application.ResettableDeviceCounter;
    }
  }

  const hasExistingUserCounter = !!tripCounters.reset_at;

  const onResetTripCounter = () => {
    if (hasExistingUserCounter) {
      setOpenResetModal(true);
    } else {
      dispatch(resetDeviceUserCounter({ deviceId }));
    }
  };

  const onRemoveTripCounter = () => {
    setOpenRemoveModal(true);
  };

  const onConfirmResetUserCounter = () => {
    dispatch(resetDeviceUserCounter({ deviceId })).finally(() => {
      setOpenResetModal(false);
    });
  };

  const onConfirmRemoveUserCounter = () => {
    dispatch(resetDeviceUserCounter({ deviceId, removeCounter: true })).finally(
      () => {
        setOpenRemoveModal(false);
      }
    );
  };

  const resetModalProps: ConfirmationModalProps = {
    variant: "info",
    open: openResetModal,
    onConfirm: onConfirmResetUserCounter,
    onCancel: () => {
      setOpenResetModal(false);
    },
    title: t("device.counters.resetModal.title", "Reset user counter"),
    confirmationButtonText: t(
      "device.counters.resetModal.resetCounter",
      "reset user counter"
    ),
    description: t(
      "device.counters.resetModal.description",
      "This function resets a resettable counter. A total counter value will always be kept."
    ),
  };

  const removeModalProps: ConfirmationModalProps = {
    variant: "info",
    open: openRemoveModal,
    onConfirm: onConfirmRemoveUserCounter,
    onCancel: () => {
      setOpenRemoveModal(false);
    },
    title: t("device.counters.removeModal.title", "Remove user counter"),
    confirmationButtonText: t(
      "device.counters.removeModal.removeCounter",
      "Remove user counter"
    ),
    description: t(
      "device.counters.removeModal.description",
      "This function removes a resettable counter. A total counter value will always be kept."
    ),
  };

  const startDate =
    tripCounters.reset_at != undefined
      ? new Date(tripCounters.reset_at)
      : new Date();

  return (
    <Card className={"flex flex-col gap-3"}>
      <ConfirmationModal key={"resetModal"} {...resetModalProps} />
      <ConfirmationModal key={"removeModal"} {...removeModalProps} />
      <div className={"md:flex md:justify-around"}>
        <h2 className="mb-4 text-lg">
          {t("device.counters.title", "Counters")}
        </h2>
        <ButtonGroup>
          <Button onClick={onResetTripCounter}>
            <div className={"flex items-center justify-items-center gap-1"}>
              {resetUserCounterLoadingState.status === "pending" ? (
                <LoadingSpinner />
              ) : (
                <OperatingHourCounterIcon className={"h-5 w-5"} />
              )}

              {!hasExistingUserCounter && (
                <span>
                  {t(
                    "device.counters.createUserCounter",
                    "Create user counter"
                  )}
                </span>
              )}

              {hasExistingUserCounter && (
                <span>
                  {t("device.counters.resetUserCounter", "Reset user counter")}
                </span>
              )}
            </div>
          </Button>
          {hasExistingUserCounter && (
            <Button onClick={onRemoveTripCounter}>
              <div className={"flex items-center justify-items-center gap-1"}>
                {resetUserCounterLoadingState.status === "pending" ? (
                  <LoadingSpinner />
                ) : (
                  <XMarkIcon className={"h-5 w-5"} />
                )}

                <span>
                  {t(
                    "device.counters.removeUserCounter",
                    "Remove user counter"
                  )}
                </span>
              </div>
            </Button>
          )}
        </ButtonGroup>
      </div>

      <div className={"flex flex-col gap-3"}>
        {status && status.metrics && (
          <div className="grid gap-2 md:grid-cols-4 ">
            <DeviceCounterStat
              start={startDate}
              unit={"watth"}
              decimals={2}
              tripValue={tripCounters.ea_fwd_t}
              value={status?.metrics.ea_fwd_t}
              label={t(
                "datum:metrics.ea_fwd_t.counter",
                "counter active energy"
              )}
            />

            <DeviceCounterStat
              start={startDate}
              unit={"voltampreacth"}
              decimals={2}
              tripValue={tripCounters.er_t}
              value={status?.metrics.er_t}
              label={t("datum:metrics.er_t.counter", "counter reactive energy")}
            />

            <DeviceCounterStat
              start={startDate}
              unit={"suffix:h"}
              decimals={0}
              tripValue={
                tripCounters.whc !== undefined
                  ? tripCounters.whc / (60 * 60)
                  : undefined
              }
              value={
                status?.metrics.whc !== undefined
                  ? status?.metrics.whc / (60 * 60)
                  : undefined
              }
              label={t("datum:metrics.whc.label", "operating hours active")}
            />

            <DeviceCounterStat
              start={startDate}
              unit={"suffix:h"}
              decimals={0}
              tripValue={
                tripCounters.whp !== undefined
                  ? tripCounters.whp / (60 * 60)
                  : undefined
              }
              value={
                status?.metrics.whp !== undefined
                  ? status?.metrics.whp / (60 * 60)
                  : undefined
              }
              label={t("datum:metrics.whp.label", "operating hours plugged")}
            />
          </div>
        )}
      </div>
    </Card>
  );
}
