import React from "react";
import { HTMLAttributes, ReactNode } from "react";
import { classNames } from "../util";

export function Pill({
  children,
  className,
  style,
  variant = "normal",
  color = "#000000",
  textColor = "#ffffff",
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  children?: ReactNode;
  variant?: "normal" | "large";
  color?: string;
  textColor?: string;
}) {
  const sizeStyles = (): string => {
    switch (variant) {
      case "large":
        return "px-4 py-2 mr-1 text-sm";
      default:
        return "px-2 py-1 mr-1 text-xs";
    }
  };

  return (
    <div
      {...props}
      style={{
        ...{ backgroundColor: color, color: textColor },
        ...style,
      }}
      className={classNames(
        className,
        sizeStyles(),
        "inline-flex items-center justify-items-center whitespace-nowrap rounded-full leading-5"
      )}
    >
      {children}
    </div>
  );
}
