import React from "react";
import { useTranslation } from "react-i18next";
import { PageHeadline } from "../../../components/text/page-headline";
import { useAppSelector } from "../../../app/store";
import { Can } from "../../../auth/user-ability-provider";
import { Link as RouterLink } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/24/outline";
import {
  selectAllNonDeletedTariffs,
  selectFetchTariffRatesLoadingState,
  selectFetchTariffsLoadingState,
} from "../../tariff/tariff-slice";
import { TariffsTable } from "../../tariff/tariffs-table";
import { FlexContainer } from "../../../components/containers/flex-container";
import { RoundActionButton } from "../../../components/ng";

export function DevicesSettingsPage() {
  const { t } = useTranslation();

  const tariffs = useAppSelector((state) => selectAllNonDeletedTariffs(state));
  const fetchTariffsState = useAppSelector((state) =>
    selectFetchTariffsLoadingState(state)
  );
  const fetchTariffRatesState = useAppSelector((state) =>
    selectFetchTariffRatesLoadingState(state)
  );

  return (
    <Can I="use" a="historicalData">
      <FlexContainer>
        <div className="">
          <Can I="create" a="tariff">
            <div className="float-right">
              <RouterLink
                key={"addTariff"}
                className={"text-sm"}
                to={"tariffs/create"}
              >
                <RoundActionButton
                  variant={"success"}
                  onClick={() => {}}
                  title={t("tariffs.add", "create energy tariff")}
                >
                  <PlusIcon className="h-5 w-5" />
                </RoundActionButton>
              </RouterLink>
            </div>
          </Can>

          <PageHeadline>{t("tariffs.title", "Energy tariffs")}</PageHeadline>

          {(fetchTariffsState.status !== "fulfilled" ||
            fetchTariffRatesState.status !== "fulfilled") && (
            <span>Loading</span>
          )}

          {fetchTariffsState.status === "fulfilled" &&
            fetchTariffRatesState.status === "fulfilled" && (
              <div className="mt-2 inline-block min-w-full align-middle">
                <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                  <TariffsTable tariffs={tariffs} />
                </div>
              </div>
            )}
        </div>
      </FlexContainer>
    </Can>
  );
}
