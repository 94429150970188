import { Card } from "../../components/containers/card";
import { TimeRangePicker } from "../../components/time-range-picker/time-range-picker";
import {
  AnalyticsGraph,
  AnalyticsGraphProps,
  createKeyMap,
  keyFormatterGenerator,
} from "../../components/graph/analytics-graph";
import { TotalMetricStat } from "../../components/metrics/total-metric-stat";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTimeRange } from "../analytics/time-range-context";
import { useTimeRangeFormatter } from "../../components/time/use-time-range-formatter";
import { useAnalytics } from "../analytics/analytics-data-loader-context";
import { useAppSelector } from "../../app/store";
import { selectTenantCurrencySymbol } from "../tenants/tenants-slice";
import { useTheme } from "../../app/theme-provider";
import { AnalyticsDataWithLabelAndColor } from "../analytics/analytics-dashboard-view";
import { AnalyticsGraphLegend } from "../../components/graph/analytics-graph-legend";

export function TagHistoricData() {
  const { t } = useTranslation();
  const { timeRange } = useTimeRange();
  const timeRangeString = useTimeRangeFormatter(timeRange);
  const { properties, isLoading, data } = useAnalytics();

  const currencySymbol = useAppSelector((state) =>
    selectTenantCurrencySymbol(state)
  );
  const { colors } = useTheme();

  const tagData = data.find((d) => d.entity.scope === "tag");

  const tagConsumption: AnalyticsDataWithLabelAndColor[] = tagData
    ? [
        {
          ...tagData,
          color: colors[0],
          label: t("analytics.tenantConsumption", "Total consumption"),
        },
      ]
    : [];

  const keyMap = createKeyMap(tagConsumption);

  const graphProps: Omit<AnalyticsGraphProps, "unit"> = {
    measurementKey: "ea_fwd_t_d",
    costKey: "ea_fwd_t_d_cost",
    shape: "bar",
    timeRange,
    timeseries: tagConsumption,
    currencySymbol,
    properties,
    keyFormatter: keyFormatterGenerator(keyMap),
  };

  return (
    <>
      <Card>
        <div className="flex flex-wrap content-center items-start justify-around gap-2 md:justify-between">
          <h2 className="text-lg">
            {t(
              "tags.detail.consumptionInTimeRange",
              "Consumption in timerange {{timeRange}}",
              { timeRange: timeRangeString }
            )}
          </h2>

          <div className="w-52 flex-grow sm:flex-grow-0 md:w-80">
            <TimeRangePicker
              isLoading={isLoading}
              className="w-full"
              key="timePicker"
            />
          </div>
        </div>

        <div style={{ height: "260px" }}>
          {tagConsumption && <AnalyticsGraph {...graphProps} />}
        </div>

        {!tagConsumption && (
          <div>
            <p>{t("tag.detail.noDataYet", "there is no data yet")}</p>
          </div>
        )}

        <div className={"mt-2"}>
          <AnalyticsGraphLegend {...graphProps} />
        </div>
      </Card>

      <div className="grid grid-cols-1 gap-3 lg:grid-cols-3">
        <Card>
          <TotalMetricStat
            decimals={2}
            metricKey={"ea_fwd_t_d_cost"}
            label={t("stats.costInTimeRange", "cost")}
          />
        </Card>

        <Card>
          <TotalMetricStat
            decimals={2}
            metricKey={"ea_fwd_t_d"}
            label={t("stats.totalActiveEnergy", "active energy")}
          />
        </Card>

        <Card>
          <TotalMetricStat
            decimals={2}
            showTrend={false}
            metricKey={"er_t_d"}
            label={t("stats.totalReactiveEnergy", "reactive energy")}
          />
        </Card>
      </div>
    </>
  );
}
