import React from "react";
import { useTranslation } from "react-i18next";
import { PageHeadline } from "../../../components/text/page-headline";
import { Application } from "@nantis/gridknight-core";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { createTag, selectCreateTagLoadingState } from "../tags-slice";
import { Card } from "../../../components/containers/card";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { TagForm } from "../tag-form";
import { PageWidth } from "../../../components/containers/page-width";
import { LoadingButton } from "../../../components/ng";

export function TagCreatePage() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const createTagState = useAppSelector((state) =>
    selectCreateTagLoadingState(state)
  );

  const onSave = async (tag: Omit<Application.Tag, "id" | "tenant_id">) => {
    if (tag) {
      await dispatch(createTag(tag)).then(() => {
        navigate({
          pathname: "..",
        });
      });
    }
  };

  return (
    <PageWidth>
      <div>
        <PageHeadline>
          {t("tags.create.title", "Create new group")}
        </PageHeadline>

        <div className={"mb-4 text-sm"}>
          <RouterLink to={".."}>
            <span aria-hidden="true">&larr;</span>{" "}
            {t("tags.create.backToTags", "back to tags")}
          </RouterLink>
        </div>

        <Card>
          <TagForm onSave={onSave}>
            <div className="pt-5">
              <div className="flex justify-end">
                <LoadingButton
                  isLoading={createTagState.status === "pending"}
                  form="tagForm"
                  variant="success"
                  type="submit"
                >
                  {t("tags.create.save", "save group")}
                </LoadingButton>
              </div>
            </div>
          </TagForm>
        </Card>
      </div>
    </PageWidth>
  );
}
