import {
  Application,
  calculateEnergyCostRateForBaseCurrencyUnitPerKiloWattHour,
  MetricKey,
} from "@nantis/gridknight-core";
import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { AnalyticsEntityWithColor } from "./selection/entity-selector";
import {
  Dataset,
  DataSetQueryParams,
  encodeDatasetKey,
  fetchAnalyticsData,
  selectAnalyticsByQueryParams,
  selectAnalyticsMissingQueries,
} from "./analytics-slice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { useTimeRange } from "./time-range-context";
import { useTheme } from "../../app/theme-provider";
import { FetchStatus } from "../../models/types";
import {
  differenceInSeconds,
  subDays,
  subMonths,
  subSeconds,
  subYears,
} from "date-fns";
import { getDefaultTimeRange } from "../../components/time/time-range-utils";

export type Entity = {
  scope: Application.Analytics.TimeseriesScope;
  id: string;
};

export type AnalyticsData = {
  entity: Entity;
  currentData: {
    timeRange: Application.TimeRange;
    data: Application.Analytics.TimeseriesDatum[];
    status: FetchStatus;
  };
  comparisonData?: {
    timeRange: Application.TimeRange;
    data: Application.Analytics.TimeseriesDatum[];
    status: FetchStatus;
  };
};

const AnalyticsDataLoaderContext = React.createContext<{
  timeRange: Application.TimeRange;
  comparisonTimeRange?: Application.TimeRange;
  entities: AnalyticsEntityWithColor[];
  setEntities: (entities: Entity[]) => void;
  properties: string[];
  setProperties: (properties: string[]) => void;
  loadComparisonData: boolean;
  setLoadComparisonData: (loadComparisonData: boolean) => void;
  data: AnalyticsData[];
  nextColor: string;
  isLoading: boolean;
}>({
  timeRange: getDefaultTimeRange(),
  nextColor: "#ff0000",
  isLoading: false,
  entities: [],
  setEntities: () => {
    console.warn("No analytics context provider");
  },
  properties: [],
  setProperties: () => {
    console.warn("No analytics context provider");
  },
  loadComparisonData: false,
  setLoadComparisonData: () => {
    console.warn("No analytics context provider");
  },
  data: [],
});

export const useAnalytics = () => {
  return React.useContext(AnalyticsDataLoaderContext);
};

export function AnalyticsDataLoaderContextProvider({
  entities,
  setEntities,
  properties,
  setProperties,
  loadComparisonData: initialLoadComparisonData = false,
  ...props
}: PropsWithChildren<{
  loadComparisonData?: boolean;
  entities: Entity[];
  setEntities?: (entities: Entity[]) => void;
  properties: MetricKey[];
  setProperties?: (properties: string[]) => void;
}>) {
  // This is an internal flag to avoid loading data when react.strict mode is enabled
  // See https://github.com/facebook/react/issues/24502#issuecomment-1118867879 ignore comment
  let currentlyLoading = false;

  //console.log("data loader");

  const dispatch = useAppDispatch();
  const { timeRange } = useTimeRange();
  const [loadComparisonData, setLoadComparisonData] = useState<boolean>(
    initialLoadComparisonData
  );

  const { colors } = useTheme();

  // The time range we are currently comparing our data to
  const comparisonTimeRange = loadComparisonData
    ? getComparisonTimeRange(timeRange)
    : undefined;

  // We want to load in comparison data (say the timerange before)
  // The data in the store has no role as that could change
  const currentQueryParams = entities.map((e) => {
    return {
      entity: e,
      timeRange,
      properties,
    };
  });

  const comparisonQueryParams = comparisonTimeRange
    ? entities.map((e) => {
        return {
          entity: e,
          timeRange: comparisonTimeRange,
          properties,
        };
      })
    : [];

  const currentDatasets: Dataset[] = useAppSelector((state) =>
    selectAnalyticsByQueryParams(state, currentQueryParams)
  );

  const comparisonDataSets: Dataset[] = useAppSelector((state) =>
    selectAnalyticsByQueryParams(state, comparisonQueryParams)
  );

  const missingData: DataSetQueryParams[] = useAppSelector((state) =>
    selectAnalyticsMissingQueries(state, [
      ...currentQueryParams,
      ...comparisonQueryParams,
    ])
  );

  const isLoading = useMemo<boolean>(() => {
    return [...currentDatasets, ...comparisonDataSets].reduce<boolean>(
      (prev, ds) => {
        return prev || ds.status.status === "pending";
      },
      false
    );
  }, [currentDatasets, comparisonTimeRange]);

  useEffect(() => {
    if (
      !currentlyLoading &&
      !isLoading &&
      entities.length &&
      properties.length
    ) {
      if (missingData.length) {
        //console.log(missingData, currentDatasets, comparisonDataSets)
        currentlyLoading = true;
      }

      Promise.all(
        missingData.map((q) => {
          return dispatch(fetchAnalyticsData(q));
        })
      ).finally(() => {
        currentlyLoading = false;
      });
    }
    return () => {};
  }, [entities, properties, timeRange]);

  const selectedEntitiesWithColor = useMemo<AnalyticsEntityWithColor[]>(() => {
    return entities.map((e, i) => {
      return {
        ...e,
        color: colors[i % (colors.length - 1)],
      };
    });
  }, [colors, entities]);

  const nextColor = colors[selectedEntitiesWithColor.length % colors.length];

  const data: AnalyticsData[] = useMemo(() => {
    return currentDatasets.map((currentDataSet) => {
      const key = encodeDatasetKey(currentDataSet);

      // Partial compare to get comparison data
      const comparisonData = comparisonDataSets.find((comparisonDataSet) => {
        return (
          encodeDatasetKey({
            entity: comparisonDataSet.entity,
            properties: comparisonDataSet.properties,
          }) ===
          encodeDatasetKey({
            entity: comparisonDataSet.entity,
            properties: currentDataSet.properties,
          })
        );
      });

      const compData =
        comparisonData && comparisonTimeRange
          ? {
              timeRange: comparisonTimeRange,
              data: comparisonData.data,
              status: comparisonData.status,
            }
          : undefined;

      return {
        key,
        entity: currentDataSet.entity,
        currentData: {
          timeRange: timeRange,
          data: currentDataSet.data,
          status: currentDataSet.status,
        },
        comparisonData: compData,
      };
    });
  }, [currentDatasets, comparisonDataSets]);

  return (
    <AnalyticsDataLoaderContext.Provider
      value={{
        timeRange: timeRange,
        comparisonTimeRange: comparisonTimeRange,
        entities: selectedEntitiesWithColor,
        nextColor: nextColor,
        isLoading,
        loadComparisonData,
        setLoadComparisonData,
        setEntities: setEntities
          ? setEntities
          : () => {
              console.warn("Cannot set entities");
            },
        properties,
        setProperties: setProperties
          ? setProperties
          : () => {
              console.warn("Cannot set properties");
            },
        data,
      }}
    >
      {props.children}
    </AnalyticsDataLoaderContext.Provider>
  );
}

function getComparisonTimeRange(
  timeRange: Application.TimeRange
): Application.TimeRange {
  let from = timeRange.from;
  let to = timeRange.to;

  switch (timeRange.range) {
    case "day":
      to = subDays(to, 1);
      from = subDays(from, 1);
      break;
    case "month":
      to = subMonths(to, 1);
      from = subMonths(from, 1);
      break;
    case "year":
      to = subYears(to, 1);
      from = subYears(from, 1);
      break;
    case "custom":
      console.warn(
        "Possibly cannot calculate comparison timerange for custom range correctly"
      );
      const differenceSeconds = differenceInSeconds(
        timeRange.from,
        timeRange.to
      );
      to = subSeconds(to, differenceSeconds);
      from = subDays(from, differenceSeconds);
      break;
  }

  return {
    range: timeRange.range,
    from,
    to,
    raw: {
      from,
      to,
    },
  };
}

export type Totals = Record<
  Extract<
    MetricKey,
    "ea_fwd_t_d_cost" | "ea_fwd_t_d" | "er_t_d" | "er_t_d_cost"
  >,
  number
>;

export const calculateTotals = (data: Record<string, any>[]): Totals => {
  const { factor: costFactor } =
    calculateEnergyCostRateForBaseCurrencyUnitPerKiloWattHour(0);

  const totals = data?.reduce(
    (cost, datum) => {
      return {
        ea_fwd_t_d:
          cost.ea_fwd_t_d + (datum?.ea_fwd_t_d ? datum.ea_fwd_t_d : 0),
        ea_fwd_t_d_cost:
          cost.ea_fwd_t_d_cost +
          (datum?.ea_fwd_t_d_cost ? BigInt(datum.ea_fwd_t_d_cost) : BigInt(0)),
        er_t_d: cost.er_t_d + (datum?.er_t_d ? datum.er_t_d : 0),
        er_t_d_cost:
          cost.er_t_d_cost +
          (datum?.er_t_d_cost ? BigInt(datum.er_t_d_cost) : BigInt(0)),
      };
    },
    {
      ea_fwd_t_d: 0,
      ea_fwd_t_d_cost: BigInt(0),
      er_t_d: 0,
      er_t_d_cost: BigInt(0),
    }
  ) ?? {
    ea_fwd_t_d: 0,
    ea_fwd_t_d_cost: BigInt(0),
    er_t_d: 0,
    er_t_d_cost: BigInt(0),
  };

  // We need to be able to still do fiscal rounding afterwards, so calculate with one more digit than needed
  return {
    ea_fwd_t_d: totals.ea_fwd_t_d,
    ea_fwd_t_d_cost:
      Number((totals.ea_fwd_t_d_cost * BigInt(1000)) / BigInt(costFactor)) /
      1000,
    er_t_d: totals.er_t_d,
    er_t_d_cost:
      Number((totals.er_t_d_cost * BigInt(1000)) / BigInt(costFactor)) / 1000,
  };
};
