import * as React from "react";

// Gradients must be referenced by id but cannot be there multiple times
// https://fvsch.com/svg-gradient-fill
export function GridknightGradient() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={0} height={0}>
      <defs>
        <linearGradient
          id="gridknight-gradient"
          gradientUnits="userSpaceOnUse"
          x1={11930.67}
          y1={1800.93}
          x2={15232.28}
          y2={5041.68}
        >
          <stop offset={0} stopColor="#00d5d9" />
          <stop offset={1} stopColor="#00db8b" />
        </linearGradient>
      </defs>
    </svg>
  );
}

/**
 * I had some issue keeping the gradient from the original logo svg, so we replaced it with a computed output from
 * https://react-svgr.com/
 * https://react-svgr.com/playground/?typescript=true
 */
export function GridknightLogo({
  fill = "#fff",
  singleColor = false,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  singleColor?: boolean;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 2929.117 721.012"
      clipRule="evenodd"
      fillRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      {...props}
    >
      <path
        className="prefix__fil0"
        d="M11165.32 4202.57c-160.48-594.25-190.22-1146.2-112.09-1642.29 97.55-619.51 362.6-1148.82 751.29-1563.48 387.19-413.06 895.83-710.86 1482.41-869.28 518.89-140.12 1141.68-170.58 1780.75-62.26l-140.87 456.45c-543.85-85.66-1058.74-58.96-1517.2 64.85-500.81 135.24-932.94 387.26-1259.39 735.5-324.95 346.66-546.73 790.34-628.66 1310.71-67.9 431.13-40.37 917.05 102.79 1447.14l68.69 237.47-527.72-114.79z"
        fill={singleColor ? fill : "url(#gridknight-gradient)"}
        transform="matrix(.12358 0 0 .12358 886.788 6.742)"
      />
      <path
        className="prefix__fil1"
        d="M13589.47 2662.59c277.71 196.55 573.15 357.37 897.48 458.73 319.95 99.99 669.48 141.75 1059.89 102.16l203.97-20.67c212.96 852.94 435.02 1705.23 636.22 2560.88-666.48-667.2-1622.38-943.46-2495.53-989.97-454.81-24.21-860.17-224-1151.62-531.43-292.13-308.16-470.53-723.64-470.53-1178.26 0-472.96 191.72-901.17 501.66-1211.11 309.95-309.94 738.19-501.7 1211.11-501.7 290.11 0 571.28 74.34 819.98 210.03 248.32 135.54 462.91 332.06 620.22 576.72l-.15.08 130.78 191.87-547.07 52.22c-45.45-74.98-89.6-129.45-118.68-161.95l.15-.12-8.57-9.13-.8-.87c-13.78-14.88-22.73-23.33-24.63-25.12l-.27-.27-.11-.08a1234.609 1234.609 0 00-277.63-207.02c-178.25-97.29-381.65-150.54-593.24-150.54-341.57 0-650.91 138.43-874.71 362.28-223.81 223.81-362.28 533.1-362.28 874.71 0 329.39 128.39 629.48 338.56 851.19 210.98 222.51 503.83 367.15 831.93 384.62 457.36 24.36 1063.69 134.24 1548.87 357.59 63.03 29 124.2 60.02 183.01 93.06l-259.7-1040.33c-377.24 15.3-721.93-35.36-1042.11-135.42-373.05-116.59-711.69-300.73-1029.4-525.6l-248.43-211.89 521.61-174.67z"
        fill={singleColor ? fill : "url(#gridknight-gradient)"}
        transform="matrix(.12358 0 0 .12358 886.788 6.742)"
      />
      <g>
        <path
          d="M153.081 517.471c44.582 0 75.23-13.005 95.193-39.938l10.216 34.365h48.296V336.367H163.761v59.438h69.655v8.828c0 39.47-35.29 52.47-69.192 52.47-48.29 0-80.796-36.217-80.796-101.23 0-65.474 35.291-99.376 79.41-99.376 32.036 0 52.469 13.468 62.685 29.721l63.156-26.006c-24.611-42.26-70.581-64.08-124.914-64.08-93.803 0-157.882 61.76-157.882 159.742 0 98.907 56.184 161.6 147.205 161.6zM393.985 511.898V395.805c0-36.686 18.575-51.543 52.94-51.543 6.5 0 11.605.463 19.037 1.395v-70.585c-35.29-1.859-59.901 11.612-74.296 48.759l-12.538-42.26H323.4V511.9h70.585z"
          className="prefix__fil2"
          fill={fill}
          fillRule="nonzero"
        />
        <path
          d="M547.125 243.496c23.222 0 41.796-19.044 41.796-41.797 0-23.215-18.572-41.79-41.796-41.79-23.218 0-41.79 18.575-41.79 41.79 0 22.753 18.574 41.797 41.79 41.797z"
          className="prefix__fil3"
          fill={singleColor ? fill : "#00d6cd"}
          fillRule="nonzero"
        />
        <path
          className="prefix__fil2"
          fill={fill}
          fillRule="nonzero"
          d="M467.716 335.44h43.655v176.458h71.05v-230.33H467.717zM692.376 517.471c25.074 0 52.474-10.216 71.974-36.686l9.29 31.11h67.334c-6.5-33.9-7.895-66.87-7.895-90.085V201.697h-70.585V313.15c-14.858-25.544-45.044-38.08-70.118-38.08-59.439 0-101.699 44.582-101.699 119.81 0 75.229 42.26 122.592 101.699 122.592zm22.752-187.606c27.402 0 50.618 19.507 50.618 66.401 0 47.835-23.216 66.408-50.618 66.408-27.856 0-51.08-25.074-51.08-67.797 0-42.26 23.224-65.012 51.08-65.012zM1094.417 511.898l-97.058-134.204 90.086-96.125h-83.58l-77.087 87.304V201.699h-70.585v310.199h70.585v-58.043l24.15-25.544 59.902 83.587zM1096.17 281.569v230.329h70.588V393.484c0-41.79 13.001-63.617 42.723-63.617 33.895 0 35.29 26.933 35.29 59.439v122.592h70.582v-140.24c0-62.224-18.106-96.589-77.544-96.589-35.297 0-60.834 19.97-71.05 37.15l-8.822-30.648h-61.767z"
        />
        <path
          d="M1398.835 243.496c23.222 0 41.796-19.044 41.796-41.797 0-23.215-18.574-41.79-41.796-41.79-23.218 0-41.79 18.575-41.79 41.79 0 22.753 18.572 41.797 41.79 41.797z"
          className="prefix__fil4"
          fill={singleColor ? fill : "#00db8b"}
          fillRule="nonzero"
        />
        <path
          className="prefix__fil2"
          fill={fill}
          fillRule="nonzero"
          d="M1319.428 335.44h43.653v176.458h71.05v-230.33h-114.703zM1442.387 391.625c0 70.118 49.229 111.915 99.377 111.915 26.006 0 52.94-11.612 72.907-36.223v41.327c0 31.58-20.9 43.655-49.222 43.655-16.253 0-34.365-5.11-42.723-21.827l-57.58 20.897c21.357 42.26 64.08 54.797 102.161 54.797 71.977 0 117.482-33.895 117.482-111.445v-213.15h-60.834l-9.284 31.58c-17.649-25.074-45.977-38.542-75.229-38.542-47.826 0-97.055 36.686-97.055 117.018zm73.373-3.717c0-40.395 23.221-58.043 51.08-58.043 27.402 0 50.618 14.857 50.618 59.902 0 45.044-23.216 58.975-50.618 58.975-27.859 0-51.08-19.97-51.08-60.834zM1778.96 511.898V393.484c0-41.79 13-63.617 42.722-63.617 33.895 0 35.29 26.933 35.29 59.439v122.592h70.58v-140.24c0-62.224-18.105-96.589-77.544-96.589-35.297 0-60.834 19.97-71.05 37.15v-110.52h-70.586v310.199h70.585zM1927.456 335.44h38.08v111.446c0 54.332 19.5 65.012 76.154 65.012h44.581v-57.58h-20.431c-22.759 0-29.721-4.178-29.721-26.007v-92.87h50.154v-53.872h-50.154v-57.58h-70.582v57.58h-38.08v53.872z"
        />
      </g>
    </svg>
  );
}
