import { Route, Routes, Navigate } from "react-router-dom";
import React from "react";
import { SettingsTabNavigation } from "./settings-tab-navigation";
import { UserSettingsPage } from "../profile/user-settings-page";
import { PageHeadline } from "../../components/text/page-headline";
import { useTranslation } from "react-i18next";
import { TenantSettingsPage } from "../tenants/settings/tenant-settings-page";
import { DevicesSettings } from "../devices/settings/devices-settings";
import { PageWidth } from "../../components/containers/page-width";
import { FlexContainer } from "../../components/containers/flex-container";

/**
 * Group all relevant settings in one page
 * @constructor
 */
export function Settings() {
  const { t } = useTranslation();

  return (
    <PageWidth>
      <FlexContainer className={"mb-4"}>
        <PageHeadline>{t("settings.title", "Settings")}</PageHeadline>

        <SettingsTabNavigation className="mb-4" />

        <Routes>
          <Route path="/account/*" element={<TenantSettingsPage />} />
          <Route path="/devices/*" element={<DevicesSettings />} />
          <Route path="/profile/*" element={<UserSettingsPage />} />
          <Route path="*" element={<Navigate to="devices" replace />} />
        </Routes>
      </FlexContainer>
    </PageWidth>
  );
}
