import React, { HTMLAttributes } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { selectTagById, selectTagsByNotInIds } from "../../../tags/tags-slice";
import { Application } from "@nantis/gridknight-core";
import { TagBadge, TagActionIcon } from "../../../tags/tagBadge";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import {
  assignTags,
  removeTags,
  selectTagsOnDeviceByDeviceId,
} from "../../../tags/tag-on-device-slice";
import { useTranslation } from "react-i18next";

export function DeviceTags({
  device,
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  device: Application.DeviceWithTags;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const ToDs = useAppSelector((state) =>
    selectTagsOnDeviceByDeviceId(state, device.id)
  );
  const tagIdsOnDevice = ToDs.map((t) => t.tag_id);

  const tagsNotOnDevice = useAppSelector((state) =>
    selectTagsByNotInIds(state, tagIdsOnDevice)
  );

  const onRemove = async (id: string) => {
    await dispatch(
      removeTags({
        device_id: device.id,
        tod_ids: [id],
      })
    );
  };

  const onAdd = async (id: string) => {
    await dispatch(
      assignTags({
        device_id: device.id,
        tags: [id],
      })
    );
  };

  return (
    <div {...props}>
      <div>
        <h2>{t("device.settings.tags.currentTags", "Current Groups")}</h2>
        <div className="mt-2 flex flex-wrap gap-2">
          {ToDs.map((toD) => {
            return (
              <RemoveTagBadge
                key={toD.id}
                onRemove={onRemove}
                tagId={toD.tag_id}
                todId={toD.id}
              />
            );
          })}
        </div>
      </div>

      <div className="mt-4">
        <h2>{t("device.settings.tags.availableTags", "Available Groups")}</h2>
        <div className="mt-2 flex flex-wrap gap-2">
          {tagsNotOnDevice.map((tag) => {
            return (
              <TagBadge variant={"large"} key={tag.id} tag={tag}>
                <TagActionIcon
                  id={tag.id}
                  onAction={onAdd}
                  icon={<PlusIcon className="ml-2 h-6 w-6 fill-white" />}
                />
              </TagBadge>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function RemoveTagBadge({
  todId,
  tagId,
  onRemove,
}: {
  todId: string;
  tagId: string;
  onRemove: (id: string) => void;
}) {
  const tag = useAppSelector((state) => selectTagById(state, tagId));

  if (!tag) {
    return <></>;
  }

  return (
    <TagBadge variant={"large"} tag={tag}>
      <TagActionIcon
        id={todId}
        onAction={onRemove}
        icon={<TrashIcon className="ml-2 inline-block h-6 w-6 fill-white" />}
      />
    </TagBadge>
  );
}
