import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Tenant } from "./features/tenants/tenant";
import { ThemeProvider } from "./app/theme-provider";

import { SignupPage } from "./auth/signup/signup-page";
import { TimeRangeProvider } from "./features/analytics/time-range-context";
import { Authentication } from "./auth/auth";

import { TimeProvider } from "./components/time/time-context";
import { ResetPasswordPage } from "./auth/reset-password/reset-password-page";
import { DataContextProvider } from "./app/data-context";
import { UserAbilityProvider } from "./auth/user-ability-provider";
import { BrowserContextProvider } from "./components/browser/browser-context";

export function App() {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <Routes>
          <Route path="signup" element={<SignupPage />} />
          <Route path="reset" element={<ResetPasswordPage />} />
          <Route
            path="*"
            element={
              <Authentication className="authenticator" hideSignUp={false}>
                <BrowserContextProvider>
                  <DataContextProvider>
                    <TimeProvider>
                      <UserAbilityProvider>
                        <TimeRangeProvider>
                          <Tenant />
                        </TimeRangeProvider>
                      </UserAbilityProvider>
                    </TimeProvider>
                  </DataContextProvider>
                </BrowserContextProvider>
              </Authentication>
            }
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
