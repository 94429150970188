import React from "react";
import { VisualizationPropsWithOptions } from "../types";
import { scaleLinear } from "d3-scale";
import { LinearGradient } from "@visx/gradient";
import { area } from "@visx/shape";

/**
 * Create a sine data series
 * @param count number of items to create
 * @param time
 * @param delta from 0 to 4 Pi
 * @param factor
 */
const getPath = (
  count: number,
  time: number,
  delta: number,
  factor: number
): [number, number, number][] => {
  return range(count).map((i) => {
    // normalize to [0, 4 Pi]
    const x = (i / (count - 1)) * 4 * Math.PI;

    const y0 = Math.sin(x + delta + time);

    const y1 = y0 - 1;

    return [x, (factor * (y0 + 1)) / 2, factor * (y1 / 2)];
  });
};

const range = (size: number): number[] => {
  return Array.from(new Array(size), (x, i) => i);
};

const createPaths = (time: number, length = 3, factor = 1) => {
  return range(length).map((i) => {
    return {
      data: getPath(200, time, (i / length) * 4 * Math.PI, factor),
    };
  });
};

export function SineWaveAnimation({
  width,
  height,
  colors,
}: VisualizationPropsWithOptions<{}> & {
  colors: string[];
}) {
  if (colors.length < 6) {
    console.warn("Not enough colors for loading indicator");
  }

  const factor = width == 0 || height == 0 ? 0 : 1;

  const series = createPaths(0, 3, factor);

  const scaleX = scaleLinear()
    .domain([0, Math.PI * 4])
    .range([0, width ?? 0]);

  const fullHeight = height ?? 0;
  const lowerBound = fullHeight * 0.8;
  const upperBound = fullHeight * 0.2;

  const scaleY = scaleLinear().domain([-1, 1]).range([lowerBound, upperBound]);

  const pathGenerator = area<[number, number, number]>({
    x: (s) => scaleX(s[0]),
    y0: (s) => scaleY(s[1]),
    y1: (s) => scaleY(s[2]),
  });

  const paths = series.map((s) => {
    return pathGenerator(s.data);
  });

  return (
    <svg
      style={{ overflow: "visible", position: "absolute" }}
      width={width}
      height={height}
      className="loadingAnimationFullScreen"
    >
      <LinearGradient
        id="onetwo"
        from={colors[0]}
        to={colors[1]}
        vertical={true}
      />
      <LinearGradient
        id="threefour"
        from={colors[2]}
        to={colors[3]}
        vertical={true}
      />
      <LinearGradient
        id="fivesix"
        from={colors[4]}
        to={colors[5]}
        vertical={true}
      />

      <path d={paths[0] ?? ""} fill={"url(#onetwo)"} />

      <path d={paths[1] ?? ""} fill={`url(#threefour)`} />

      <path d={paths[2] ?? ""} fill={`url(#fivesix)`} />
    </svg>
  );
}
