import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { Application } from "@nantis/gridknight-core";
import { FetchStatus } from "../../models/types";
import { API, graphqlOperation } from "aws-amplify";
import { RootState } from "../../app/store";
import { fetchDevice, fetchDevices } from "../devices/devices-slice";

const tagsOnDeviceEntityAdapter = createEntityAdapter<Application.TagOnDevices>(
  {
    selectId: (ToD) => ToD.id,
    sortComparer: (a, b) => b.id.localeCompare(a.id),
  }
);

const initialState = tagsOnDeviceEntityAdapter.getInitialState<{
  assignTags: FetchStatus;
  removeTags: FetchStatus;
}>({
  removeTags: {
    status: "idle",
    error: null,
  },
  assignTags: {
    status: "idle",
    error: null,
  },
});

export const assignTags = createAsyncThunk(
  "devices/assignTags",
  async ({ device_id, tags }: { device_id: string; tags: string[] }) => {
    const result = (await API.graphql(
      graphqlOperation(
        `
            mutation AssignTagsMutation($device_id: ID!, $tags: [ID]) {
              assignTags(device_id: $device_id, tags: $tags) {
                device_id
                tag_id
                tenant_id
                id
              }
            }
        `,
        {
          device_id,
          tags,
        }
      )
    )) as {
      data: {
        assignTags: Application.TagOnDevices[];
      };
      errors?: any;
    };
    return result.data.assignTags;
  }
);

export const removeTags = createAsyncThunk(
  "devices/removeTags",
  async ({ device_id, tod_ids }: { device_id: string; tod_ids: string[] }) => {
    const result = (await API.graphql(
      graphqlOperation(
        `
            mutation RemoveTagsMutation($device_id: ID!, $tod_ids: [ID]) {
              removeTags(device_id: $device_id, tod_ids: $tod_ids)
            }
        `,
        {
          device_id,
          tod_ids,
        }
      )
    )) as {
      data: {
        removeTags: string[];
      };
      errors?: any;
    };
    return result.data.removeTags;
  }
);

const tagOnDeviceSlice = createSlice({
  name: "tagOnDevice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDevices.fulfilled, (state, action) => {
      const tagsOnDevices = action.payload
        .map((device) => device.tags)
        .flat()
        .filter(Boolean);
      tagsOnDeviceEntityAdapter.setAll(
        state,
        tagsOnDevices as Application.TagOnDevices[]
      );
    });

    builder.addCase(fetchDevice.fulfilled, (state, action) => {
      const device = action.payload;

      if (device && device.tags) {
        tagsOnDeviceEntityAdapter.upsertMany(state, device.tags);
      }
    });

    builder.addCase(assignTags.pending, (state) => {
      state.assignTags.status = "pending";
    });

    builder.addCase(assignTags.fulfilled, (state, action) => {
      state.assignTags.status = "fulfilled";
      tagsOnDeviceEntityAdapter.upsertMany(state, action.payload);
    });

    builder.addCase(assignTags.rejected, (state) => {
      state.assignTags.status = "rejected";
    });

    builder.addCase(removeTags.pending, (state) => {
      state.removeTags.status = "pending";
    });

    builder.addCase(removeTags.fulfilled, (state, action) => {
      state.removeTags.status = "fulfilled";
      tagsOnDeviceEntityAdapter.removeMany(state, action.payload);
    });

    builder.addCase(removeTags.rejected, (state) => {
      state.removeTags.status = "rejected";
    });
  },
});

export const reducer = tagOnDeviceSlice.reducer;

export const {
  selectAll: selectAllTagOnDevice,
  selectById: selectTagOnDeviceById,
} = tagsOnDeviceEntityAdapter.getSelectors<RootState>(
  (state) => state.tagOnDevice
);

export const selectTagOnDeviceByTagId = createSelector(
  [selectAllTagOnDevice, (state: RootState, tag_id: string) => tag_id],
  (tagsOnDevices, tag_id) =>
    tagsOnDevices.filter((tagOnDevice) => {
      return tagOnDevice.tag_id === tag_id;
    })
);

export const selectTagsOnDeviceByDeviceId = createSelector(
  [selectAllTagOnDevice, (state: RootState, device_id: string) => device_id],
  (tagsOnDevices, device_id) =>
    tagsOnDevices.filter((tagOnDevice) => {
      return tagOnDevice.device_id === device_id;
    })
);

export const selectDeviceIdsByTagIds = createSelector(
  [selectAllTagOnDevice, (state: RootState, tag_ids: string[]) => tag_ids],
  (tagsOnDevices, tag_ids) =>
    tagsOnDevices.filter((tagOnDevice) => {
      return tag_ids.includes(tagOnDevice.tag_id);
    })
);

export const selectNumberOfDevicesByTag = createSelector(
  [selectTagOnDeviceByTagId, (state: RootState, tag_id: string) => tag_id],
  (tagsOnDevices) => tagsOnDevices.length
);

export const selectAssignTagsLoadingState = (state: RootState) =>
  state.tagOnDevice.assignTags;
export const selectRemoveTagsLoadingState = (state: RootState) =>
  state.tagOnDevice.removeTags;
