import { FindMeIcon } from "../../../assets/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { findMe, selectDeviceConfigurationByDeviceId } from "../devices-slice";
import { classNames } from "../../../app/util";
import { Button } from "../../../components/buttons/button-group";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

export function FindMeButton({ deviceId }: { deviceId: string }) {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const configuration = useAppSelector((state) =>
    selectDeviceConfigurationByDeviceId(state, deviceId)
  );
  // Could also be in the store but makes more sense here
  const [waitForResponse, setWaitForResponse] = useState<boolean>(false);

  const onClick = () => {
    // If we need to stop the blinking we need to rework the logic
    setWaitForResponse(true);
    dispatch(
      findMe({
        id: deviceId,
        findMe: configuration ? !configuration.findMe : true,
      })
    ).finally(() => {
      setTimeout(() => {
        setWaitForResponse(false);
      }, 5000);
    });
  };

  return (
    <Button
      onClick={() => {
        if (!waitForResponse) {
          onClick();
        }
      }}
      title={t(
        "device.findMe.title",
        "FindMe - activate find me LED on device"
      )}
      className={classNames(
        waitForResponse ? "cursor-default" : "cursor-pointer"
      )}
    >
      <div className={"flex items-center justify-items-center gap-1"}>
        {waitForResponse && !configuration?.findMe ? (
          <ArrowPathIcon className="h-5 w-5 animate-spin" />
        ) : (
          <FindMeIcon className="h-5 w-5" />
        )}

        {t("device.findMe.label", "findMe")}
        {configuration?.findMe && (
          <div className="inline-flex h-3 w-3 animate-blink rounded-full bg-red" />
        )}
      </div>
    </Button>
  );
}
