import * as React from "react";
import { cn } from "./utils";
import { cva, type VariantProps } from "class-variance-authority";
import { LoadingSpinner } from "../loading/loading-spinner";

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        success: "bg-success text-success-foreground hover:bg-success/90",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-input hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        accent: "bg-accent text-accent-foreground hover:bg-accent/90",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "underline-offset-4 hover:underline text-primary",
      },
      size: {
        default: "h-10 py-2 px-4",
        sm: "h-9 px-3 rounded-md",
        lg: "h-11 px-8 rounded-md",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, ...props }, ref) => {
    return (
      <button
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);

Button.displayName = "Button";

const LoadingButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    isLoading?: boolean;
  }
>(({ className, variant, size, isLoading, ...props }, ref) => {
  return (
    <button
      className={cn(buttonVariants({ variant, size, className }))}
      ref={ref}
      {...props}
    >
      {isLoading && <LoadingSpinner />}
      {props.children}
    </button>
  );
});

LoadingButton.displayName = "LoadingButton";

const actionButtonVariants = cva(
  "inline-flex items-center rounded-full border border-transparent bg-blue fill-blue  p-1 text-white shadow-sm focus:outline-none",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        success: "bg-success text-success-foreground hover:bg-success/90",
      },
    },
  }
);

export interface RoundActionButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof actionButtonVariants> {
  backgroundColor?: string;
}

const RoundActionButton = React.forwardRef<
  HTMLButtonElement,
  RoundActionButtonProps
>(({ className, backgroundColor, variant, ...props }, ref) => {
  return (
    <button
      style={{ backgroundColor }}
      className={cn(actionButtonVariants({ variant, className }))}
      ref={ref}
      {...props}
    />
  );
});

RoundActionButton.displayName = "RoundActionButton";

export {
  Button,
  LoadingButton,
  RoundActionButton,
  actionButtonVariants,
  buttonVariants,
};
