import { Route, Routes } from "react-router-dom";
import React from "react";
import { DocumentationPage } from "./documentation/documentation-page";

export function Documentation() {
  return (
    <Routes>
      <Route path="/" element={<DocumentationPage />} />
    </Routes>
  );
}
