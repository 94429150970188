export const calculateRGBAFromCSSColorVariable = (
  variableValue: string | undefined
): string | undefined => {
  if (variableValue) {
    return `hsl(${variableValue.trim().split(" ").join(",")},1)`;
  }

  return undefined;
};

export const getColorFromCSSVariable = (
  className: string
): string | undefined => {
  return calculateRGBAFromCSSColorVariable(
    (window as any)
      .getComputedStyle(window.document.documentElement)
      .getPropertyValue(className)
  );
};
