import React, { HTMLAttributes, ReactElement } from "react";
import { classNames } from "../../app/util";

export function TableHeadCell({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLTableHeaderCellElement>): ReactElement<HTMLTableHeaderCellElement> {
  return (
    <th
      {...props}
      scope="col"
      className={classNames(
        className,
        "py-2 pl-2 pr-3 text-sm font-semibold text-gray-900 sm:pl-6"
      )}
    >
      {children}
    </th>
  );
}

export function TableRow({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLTableRowElement>): ReactElement<HTMLTableRowElement> {
  return (
    <tr
      {...props}
      className={classNames(
        className,
        "py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-6"
      )}
    >
      {children}
    </tr>
  );
}

export function TableCell({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLTableCellElement>): ReactElement<HTMLTableCellElement> {
  return (
    <td
      {...props}
      className={classNames(
        className,
        "sm:text-md h-12 py-1 pl-2 pr-3 text-sm text-gray-900 sm:pl-6"
      )}
    >
      {children}
    </td>
  );
}
