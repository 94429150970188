import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";

export type InfoIconProps = {
  children: string;
};

export function InfoIcon(props: InfoIconProps) {
  return (
    <Tooltip {...props}>
      <TooltipTrigger>
        <InformationCircleIcon
          className={" inline-flex h-5 cursor-help fill-gray-400"}
        />
      </TooltipTrigger>

      <TooltipContent>{props.children}</TooltipContent>
    </Tooltip>
  );
}
