import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CreateTenantData } from "./signup-page";
import { useAppSelector } from "../../app/store";
import { selectCreateTenantLoadingState } from "../../features/tenants/tenants-slice";
import { LoadingButton } from "../../components/ng";

export type SignupFormData = {
  companyName: string;
  email: string;
  country: string;
};

export type SignupFormProps = {
  onSignup: ({ companyName, email, locale }: CreateTenantData) => void;
};

const schema = yup.object().shape({
  companyName: yup.string().required(),
  email: yup.string().email().required(),
  country: yup.string().required(),
});

function SignupForm({ onSignup }: SignupFormProps) {
  const { t, i18n } = useTranslation();

  const loadingState = useAppSelector(selectCreateTenantLoadingState);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignupFormData>({
    resolver: yupResolver(schema),
  });

  const getLocale = (countryCode: string): string => {
    const language = i18n.language;
    let lang = "de";
    if (language) {
      lang = language.split("-")[0];
    }

    return `${lang}-${countryCode}`;
  };

  const onSubmit = handleSubmit((data: SignupFormData) => {
    console.log(errors);
    const locale = getLocale(data.country);

    onSignup({
      companyName: data.companyName,
      email: data.email,
      locale: locale,
      timezone: "Europe/Berlin",
    });
  });

  return (
    <form onSubmit={onSubmit} className="space-y-6" action="#">
      <div>
        <label htmlFor="companyName" className="block text-sm text-gray-700">
          {t("auth.signup.companyName", "Company name")}
        </label>
        <div className="mt-1">
          <input
            id="companyName"
            type="text"
            autoComplete="organization"
            required
            {...register("companyName")}
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
          />
        </div>
      </div>

      <div>
        <label htmlFor="email" className="block text-sm text-gray-700">
          {t("auth.signup.email", "Email address")}
        </label>
        <div className="mt-1">
          <input
            id="email"
            type="email"
            autoComplete="email"
            required
            {...register("email")}
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
          />
        </div>
      </div>

      <div>
        <label htmlFor="country" className="block text-sm text-gray-700">
          {t("auth.signup.country", "Country")}
        </label>
        <div className="mt-1">
          <select
            id="country"
            autoComplete="country"
            required
            {...register("country")}
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
          >
            <option value="DE">{t("countries.DE", "Germany")}</option>
            <option value="CH">{t("countries.CH", "Switzerland")}</option>
            <option value="AT">{t("countries.AT", "Austria")}</option>
          </select>
        </div>
      </div>

      <LoadingButton
        type="submit"
        variant="accent"
        isLoading={loadingState.status === "pending"}
        className={"w-full justify-center text-sm"}
      >
        {t("auth.signup.createAccount", "Create Account")}
      </LoadingButton>
    </form>
  );
}

export { SignupForm };
