import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { FetchStatus } from "../../models/types";

import { API, graphqlOperation } from "aws-amplify";
import { RootState } from "../../app/store";
import { Application, Device } from "@nantis/gridknight-core";
import { EntityState } from "@reduxjs/toolkit/src/entities/models";
import { Observable, ZenObservable } from "zen-observable-ts";
import { getDeviceConnectionState } from "../../models/device";

export type DeviceStatusWithId = {
  id: string;
} & Application.DeviceStatus;

type DeviceConfigurationWithId = {
  id: string;
} & Application.DeviceConfiguration;

const deviceEntityAdapter = createEntityAdapter<Application.Device>({
  selectId: (device) => device.id,
  sortComparer: (a, b) => b.id.localeCompare(a.id),
});

const deviceStatusEntityAdapter = createEntityAdapter<DeviceStatusWithId>({
  selectId: (deviceStatus) => deviceStatus.id,
  sortComparer: (a, b) => b.id.localeCompare(a.id),
});

const deviceConfigurationEntityAdapter =
  createEntityAdapter<DeviceConfigurationWithId>({
    selectId: (deviceConfiguration) => deviceConfiguration.id,
    sortComparer: (a, b) => b.id.localeCompare(a.id),
  });

const deviceRulesEntityAdapter = createEntityAdapter<
  Application.DeviceRule<any>
>({
  selectId: (rule) => rule.id,
  sortComparer: (a, b) => b.id.localeCompare(a.id),
});

const deviceEventsEntityAdapter = createEntityAdapter<
    Application.Events.DatabaseEvent<any, any>
>({
  selectId: (deviceEvent) => deviceEvent.id,
  sortComparer: (a, b) => b.id.localeCompare(a.id),
});

const initialState = deviceEntityAdapter.getInitialState<{
  fetchDevices: FetchStatus;
  resetDeviceUserCounter: FetchStatus;
  fetchDevice: FetchStatus;
  pairDevices: FetchStatus;
  unpairDevices: FetchStatus;
  updateDevice: FetchStatus;
  states: EntityState<DeviceStatusWithId> & {};
  configurations: EntityState<DeviceConfigurationWithId> & {};
  rules: EntityState<Application.DeviceRule<any>> & {
    fetchDeviceRules: FetchStatus;
    setDeviceRules: FetchStatus;
  };
  events: EntityState< Application.Events.DatabaseEvent<any, any>> & {
    fetchEvents: FetchStatus;
  };
}>({
  states: deviceStatusEntityAdapter.getInitialState({}),
  configurations: deviceConfigurationEntityAdapter.getInitialState({}),
  rules: deviceRulesEntityAdapter.getInitialState<{
    fetchDeviceRules: FetchStatus;
    setDeviceRules: FetchStatus;
  }>({
    fetchDeviceRules: {
      status: "idle",
      error: null,
    },
    setDeviceRules: {
      status: "idle",
      error: null,
    },
  }),
  events: deviceEventsEntityAdapter.getInitialState<{
    fetchEvents: FetchStatus;
  }>({
    fetchEvents: {
      status: "idle",
      error: null,
    },
  }),
  fetchDevices: {
    status: "idle",
    error: null,
  },
  fetchDevice: {
    status: "idle",
    error: null,
  },
  pairDevices: {
    status: "idle",
    error: null,
  },
  unpairDevices: {
    status: "idle",
    error: null,
  },
  updateDevice: {
    status: "idle",
    error: null,
  },
  resetDeviceUserCounter: {
    status: "idle",
    error: null,
  },
});

export const noValidDeviceIdError = "no valid device id";

export const subscribeToDeviceStates = ({
  tenant_id,
  onDeviceStatus,
  onDeviceConfiguration,
  onError,
}: {
  tenant_id: string;
  onDeviceStatus: (status: DeviceStatusWithId) => void;
  onDeviceConfiguration: (configuration: DeviceConfigurationWithId) => void;
  onError: (error: any) => void;
}): ZenObservable.Subscription => {
  return (
    API.graphql(
      graphqlOperation(
        `
            subscription deviceStatusSubscription($tenant_id: ID!) {
              deviceStatusSet(tenant_id: $tenant_id) {
                id
                status
                configuration
                tenant_id
              }
            }
        `,
        {
          tenant_id: tenant_id,
        }
      )
    ) as Observable<object>
  ).subscribe({
    next: (data: any) => {
      const statusResponse = data.value.data.deviceStatusSet;

      if (statusResponse.status) {
        const status = {
          id: statusResponse.id,
          ...JSON.parse(statusResponse.status),
        } as DeviceStatusWithId;

        onDeviceStatus(status);
      }

      if (statusResponse.configuration) {
        const configuration = {
          id: statusResponse.id,
          ...JSON.parse(statusResponse.configuration),
        };
        onDeviceConfiguration(configuration);
      }
    },
    error(errorValue: any) {
      onError(errorValue);
    },
  });
};

export const findMe = createAsyncThunk(
  "devices/findMe",
  async ({ id, findMe = true }: { id: string; findMe?: boolean }) => {
    const result = (await API.graphql(
      graphqlOperation(
        `
            mutation FindMeDeviceMutation($device_id: ID!, $find_me: Boolean = true) {
                findMeDevice(device_id: $device_id, find_me: $find_me)
            }
        `,
        {
          device_id: id,
          find_me: findMe,
        }
      )
    )) as {
      data: {
        findMeDevice: boolean;
      };
    };
    return result.data.findMeDevice;
  }
);

export const pairDevices = createAsyncThunk(
  "devices/pairDevices",
  async (ids: string[], { rejectWithValue }) => {
    if (!ids.length || ids[0].length === 0) {
      return rejectWithValue(noValidDeviceIdError);
    }

    try {
      const result = (await API.graphql(
        graphqlOperation(
          `
                mutation pairDevicesMutation($ids: [String]) {
                  pairDevices(ids: $ids) {
                    configuration
                    id
                    info
                    name
                    deleted_at
                    tags {
                      device_id
                      id
                      tag_id
                      tenant_id
                    }
                    status
                    tariff_id
                    tenant_id
                    type
                  }
                }
            `,
          {
            ids: ids,
          }
        )
      )) as {
        data: {
          pairDevices: Application.Device[];
        };
      };

      if (!result.data.pairDevices.length) {
        return rejectWithValue("no devices paired");
      }

      return result.data.pairDevices;
    } catch (err: any) {
      const error = err.errors[0].message;
      return rejectWithValue(error);
    }
  }
);

export const unpairDevices = createAsyncThunk(
  "devices/unpairDevices",
  async (ids: string[]) => {
    const result = (await API.graphql(
      graphqlOperation(
        `
            mutation unpairDevicesMutation($ids: [String]) {
              unpairDevices(ids: $ids) {
                configuration
                id
                info
                name
                status
                tariff_id
                tenant_id
                type
              }
            }

        `,
        {
          ids: ids,
        }
      )
    )) as {
      data: {
        unpairDevices: Application.Device[];
      };
    };
    return result.data.unpairDevices;
  }
);

export const fetchDevices = createAsyncThunk(
  "devices/fetchDevices",
  async (paginationArgs: Application.PaginationArguments) => {
    const { limit = 200, cursor = "" } = paginationArgs;

    const result = (await API.graphql(
      graphqlOperation(
        `
            query allDevices($limit: Int, $cursor: String) {
              devices(cursor: $cursor, limit: $limit) {
                cursor
                items {
                  configuration
                  id
                  info
                  counters
                  name
                  status
                  tariff_id
                  tenant_id
                  deleted_at
                  type
                    tags {
                      device_id
                      id
                      tag_id
                      tenant_id
                    }
                }
              }
            }
    `,
        {
          limit,
          cursor,
        }
      )
    )) as {
      data: {
        devices: {
          cursor?: string;
          items: Application.DeviceWithTags[];
        };
      };
    };
    return result.data.devices.items;
  }
);

export const fetchDevice = createAsyncThunk(
  "devices/fetchDevice",
  async (id: string) => {
    const result = (await API.graphql(
      graphqlOperation(
        `
            query device($id: ID!) {
              device(id: $id) {
                configuration
                id
                info
                name
                status
                counters
                deleted_at
                tariff_id
                tenant_id
                type
                tags {
                  device_id
                  id
                  tag_id
                  tenant_id
                }
              }
            }
        `,
        {
          id,
        }
      )
    )) as {
      data: {
        device: Application.DeviceWithTags;
      };
      errors?: any;
    };
    return result.data.device;
  }
);

export const updateDevice = createAsyncThunk(
  "devices/updateDevice",
  async (device: Application.Device) => {
    const result = (await API.graphql(
      graphqlOperation(
        `
            mutation updateDeviceMutation($id: ID!, $name: String, $tariff_id: String) {
              updateDevice(id: $id, name: $name, tariff_id: $tariff_id) {
                configuration
                id
                info
                name
                status
                tariff_id
                tenant_id
                type
              }
            }
        `,
        {
          id: device.id,
          name: device.name,
          tariff_id: device.tariff_id,
        }
      )
    )) as {
      data: {
        updateDevice: Application.Device;
      };
      errors?: any;
    };
    return result.data.updateDevice;
  }
);

export const resetDeviceUserCounter = createAsyncThunk(
  "devices/resetUserCounter",
  async ({
    deviceId,
    removeCounter = false,
  }: {
    deviceId: string;
    removeCounter?: boolean;
  }) => {
    const result = (await API.graphql(
      graphqlOperation(
        `
            mutation ResetDeviceUserCounterMutation($deviceId: ID!, $removeCounter: Boolean = false) {
                resetUserCounters(deviceId: $deviceId, removeCounter: $removeCounter) {
                    configuration
                    counters
                    deleted_at
                    id
                    name
                    tenant_id
                    type
                }
            }
        `,
        {
          deviceId,
          removeCounter,
        }
      )
    )) as {
      data: {
        resetUserCounters: Pick<
          Application.Device,
          "id" | "counters" | "tenant_id"
        >;
      };
      errors?: any;
    };
    return result.data.resetUserCounters;
  }
);

export const fetchDeviceRules = createAsyncThunk(
  "rules/fetchRules",
  async (device_id: string) => {
    const result = (await API.graphql(
      graphqlOperation(
        `
            query deviceRules($device_id: ID!) {
              deviceRules(device_id: $device_id) {
                type
                id
                device_id
                data
              }
            }
        `,
        {
          device_id: device_id,
        }
      )
    )) as {
      data: {
        deviceRules: Application.DeviceRule<any>[];
      };
    };

    return result.data.deviceRules.map((t) => {
      return {
        ...t,
        data: JSON.parse(t.data),
      };
    });
  }
);

export const setDeviceRules = createAsyncThunk(
  "rules/setRules",
  async (data: { device_id: string; rules: Application.DeviceRule<any>[] }) => {
    const result = (await API.graphql(
      graphqlOperation(
        `
            mutation setDeviceRulesMutation($rules: [DeviceRuleInput], $device_id: ID!) {
              setDeviceRules(device_id: $device_id, rules: $rules) {
                data
                device_id
                id
                type
              }
            }
        `,
        {
          device_id: data.device_id,
          rules: data.rules.map((r) => {
            return {
              id: r.id,
              data: JSON.stringify(r.data),
              type: r.type,
            };
          }),
        }
      )
    )) as {
      data: {
        setDeviceRules: Application.DeviceRule<any>[];
      };
    };

    return result.data.setDeviceRules;
  }
);

// The tenantId must be present in the query response request
// https://blog.purple-technology.com/lessons-learned-aws-appsync-subscriptions/
export const acknowledgeDeviceEvent = createAsyncThunk(
  "deviceEvents/acknowledgeDeviceEvent",
  async (event:  Application.Events.DatabaseEvent<any, any>) => {
    const variables = {
      id: event.id,
    };

    await API.graphql(
      graphqlOperation(
        `
            mutation acknowledgeDeviceEventMutation($id: ID!) {
              acknowledgeDeviceEvent(id: $id) {
                device_id
                mutation_type
                tenant_id
                events {
                  tenant_id
                  device_id
                  id
                  time
                  source
                  type
                  data
                  acknowledged
                }
              }
            }
        `,
        variables
      )
    );
    // Optimistic update
    return event;
  }
);

export const acknowledgeAllDeviceEvents = createAsyncThunk(
  "deviceEvents/acknowledgeAllDeviceEvents",
  async ({ device_id }: { device_id: string }) => {
    const variables = {
      device_id: device_id,
    };

    await API.graphql(
      graphqlOperation(
        `
            mutation acknowledgeAllDeviceEventsMutation($device_id: ID!) {
              acknowledgeAllDeviceEvents(device_id: $device_id) {
                device_id
                mutation_type
                tenant_id
                events {
                  tenant_id
                  device_id
                  id
                  time
                  source
                  type
                  data
                  acknowledged
                }
              }
            }
        `,
        variables
      )
    );
    return {};
  }
);

type DeviceEventUpdateType =
  | "addDeviceEvents"
  | "acknowledgeDeviceEvent"
  | "acknowledgeAllDeviceEvents"
  | "acknowledgeAllTenantDeviceEvents";

export const subscribeToDeviceEvents = ({
  tenant_id,
  onDeviceEventsUpdate,
  onError,
}: {
  tenant_id: string;
  onDeviceEventsUpdate: ({
    mutation_type,
    tenant_id,
    device_id,
    events,
  }: {
    mutation_type: DeviceEventUpdateType;
    tenant_id: string;
    device_id: string;
    events:  Application.Events.DatabaseEvent<any, any>[];
  }) => void;
  onError: (error: any) => void;
}): ZenObservable.Subscription => {
  const variables = {
    tenant_id: tenant_id,
  };

  return (
    API.graphql(
      graphqlOperation(
        `
            subscription deviceEventSubscription($tenant_id: ID!) {
              deviceEventsUpdated(tenant_id: $tenant_id) {
                    tenant_id
                    device_id
                    mutation_type
                    events {
                      tenant_id
                      device_id
                      id
                      source
                      type
                      acknowledged
                      data
                      time
                    }
                  }
            }
        `,
        variables
      )
    ) as Observable<any>
  ).subscribe({
    next: (data: any) => {
      const deviceEventsData = data.value.data.deviceEventsUpdated;

      console.log('eventdata', deviceEventsData);

      onDeviceEventsUpdate({
        mutation_type: deviceEventsData.mutation_type,
        tenant_id: deviceEventsData.tenant_id,
        device_id: deviceEventsData.device_id,
        events: deviceEventsData.events
          ? deviceEventsData.events.map((v: any) => {
              return {
                ...v,
                data: JSON.parse(v.data),
              };
            })
          : [],
      });
    },
    error: (err) => {
      onError(err);
    },
  });
};

export const acknowledgeAllTenantDeviceEvents = createAsyncThunk(
  "deviceEvents/acknowledgeAllTenantDeviceEventsMutation",
  async () => {
    await API.graphql(
      graphqlOperation(`
            mutation acknowledgeAllTenantDeviceEventsMutation {
              acknowledgeAllTenantDeviceEvents {
                tenant_id
                device_id
                mutation_type
                events {
                  id
                  time
                  source
                  type
                  data
                  acknowledged
                }
              }
            }
        `)
    );
    return {};
  }
);

export const fetchDeviceEvents = createAsyncThunk(
  "deviceEvents/fetchEvents",
  async (paginationArgs: Application.PaginationArguments) => {
    const { limit = 1000, cursor = "" } = paginationArgs;

    const result = (await API.graphql(
      graphqlOperation(
        `
            query deviceEventsQuery($limit: Int, $cursor: String) {
              deviceEvents(cursor: $cursor, limit: $limit) {
                cursor
                items {
                  tenant_id
                  device_id
                  id
                  time
                  source
                  type
                  data
                  acknowledged
                }
              }
            }
        `,
        {
          limit,
          cursor,
        }
      )
    )) as {
      data: {
        deviceEvents: {
          nextToken?: string;
          items:  Application.Events.DatabaseEvent<any, any>[];
        };
      };
    };
    return result.data.deviceEvents.items.map((v) => {
      return {
        ...v,
        data: JSON.parse(v.data as string),
      };
    });
  }
);

const devicesSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    deviceUpdated: deviceEntityAdapter.upsertOne,
    onDeviceStatus: (state, action) => {
      deviceStatusEntityAdapter.upsertOne(state.states, action.payload);
    },
    onDeviceConfiguration: (state, action) => {
      deviceConfigurationEntityAdapter.upsertOne(
        state.configurations,
        action.payload
      );
    },
    DeviceRuleAdded: (state, action) => {
      deviceRulesEntityAdapter.upsertOne(state.rules, action.payload);
    },
    deviceRuleRemove: (state, action) => {
      deviceRulesEntityAdapter.removeOne(state.rules, action);
    },
    deviceRuleUpdate: (state, action) => {
      deviceRulesEntityAdapter.upsertOne(state.rules, action);
    },
    onDeviceEventsAdded: (
      state,
      action: PayloadAction< Application.Events.DatabaseEvent<any, any>[]>
    ) => {
      deviceEventsEntityAdapter.upsertMany(state.events, action.payload);
    },
    onDeviceEventAcknowledged: (
      state,
      action: PayloadAction<Application.Events.DatabaseEvent<any, any>[]>
    ) => {
      deviceEventsEntityAdapter.upsertMany(state.events, action.payload);
    },
    onTenantDeviceEventsAcknowledged: (
      state,
      _: PayloadAction<{ tenant_id: string }>
    ) => {
      const { selectAll } = deviceEventsEntityAdapter.getSelectors<
        typeof state
      >((state) => state.events);

      const allDeviceEvemts = selectAll(state);

      deviceEventsEntityAdapter.upsertMany(
        state.events,
        allDeviceEvemts.map((v) => {
          return {
            ...v,
            acknowledged: true,
          };
        })
      );
    },
    onDeviceEventsAcknowledged: (
      state,
      action: PayloadAction<{
        device_id: string;
      }>
    ) => {
      const { device_id } = action.payload;

      const { selectAll } = deviceEventsEntityAdapter.getSelectors<
        typeof state
      >((state) => state.events);

      const deviceEventsUnacknowledgedSelector = createSelector(
        [selectAll, (s: typeof state, deviceId: string) => deviceId],
        (events, deviceId) =>
          events.filter(
            (event) => event.device_id === deviceId && !event.acknowledged
          )
      );

      const deviceEventsUnacknowledged = deviceEventsUnacknowledgedSelector(
        state,
        device_id
      );

      deviceEventsEntityAdapter.upsertMany(
        state.events,
        deviceEventsUnacknowledged.map((v) => {
          return {
            ...v,
            acknowledged: true,
          };
        })
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDevices.pending, (state) => {
      state.fetchDevices.status = "pending";
    });

    builder.addCase(fetchDevices.fulfilled, (state, action) => {
      state.fetchDevices.status = "fulfilled";
      deviceEntityAdapter.setAll(state, action.payload);

      const deviceStates = action.payload.map((device) => {
        return {
          id: device.id,
          ...JSON.parse((device?.status as string) ?? "{}"),
        } as DeviceStatusWithId;
      });

      const deviceConfigurations = action.payload.map((device) => {
        return {
          id: device.id,
          ...JSON.parse((device?.configuration as string) ?? "{}"),
        } as DeviceConfigurationWithId;
      });

      deviceStatusEntityAdapter.upsertMany(state.states, deviceStates);
      deviceConfigurationEntityAdapter.upsertMany(
        state.configurations,
        deviceConfigurations
      );
    });

    builder.addCase(fetchDevices.rejected, (state) => {
      state.fetchDevices.status = "rejected";
      state.fetchDevices.error = "ERROR";
    });

    builder.addCase(fetchDevice.pending, (state) => {
      state.fetchDevice.status = "pending";
    });

    builder.addCase(fetchDevice.fulfilled, (state, action) => {
      state.fetchDevice.status = "fulfilled";
      const device = action.payload;
      if (device && device.id) {
        deviceEntityAdapter.upsertOne(state, device);

        deviceStatusEntityAdapter.upsertOne(state.states, {
          id: device.id,
          ...JSON.parse((device?.status as string) ?? "{}"),
        } as DeviceStatusWithId);

        deviceConfigurationEntityAdapter.upsertOne(state.states, {
          id: device.id,
          ...JSON.parse((device?.configuration as string) ?? "{}"),
        } as DeviceConfigurationWithId);
      }
    });

    builder.addCase(fetchDevice.rejected, (state) => {
      state.fetchDevice.status = "rejected";
      state.fetchDevice.error = "ERROR";
    });

    builder.addCase(pairDevices.pending, (state) => {
      state.pairDevices.status = "pending";
      state.fetchDevice.error = null;
    });

    builder.addCase(pairDevices.fulfilled, (state, action) => {
      state.pairDevices.status = "fulfilled";
      const devices = action.payload;
      if (devices && devices.length) {
        deviceEntityAdapter.upsertMany(state, devices);
      }
    });

    builder.addCase(pairDevices.rejected, (state, action) => {
      state.pairDevices.status = "rejected";
      state.pairDevices.error = action.payload as string;
    });

    builder.addCase(unpairDevices.pending, (state) => {
      state.unpairDevices.status = "pending";
    });

    builder.addCase(unpairDevices.fulfilled, (state, action) => {
      state.unpairDevices.status = "fulfilled";
      const devices = action.payload;
      if (devices && devices.length) {
        deviceEntityAdapter.removeMany(
          state,
          devices.map((device) => device.id)
        );
        deviceStatusEntityAdapter.removeMany(
          state.states,
          devices.map((device) => device.id)
        );
        deviceConfigurationEntityAdapter.removeMany(
          state.configurations,
          devices.map((device) => device.id)
        );
        // Cannot really delete them selectively
        deviceRulesEntityAdapter.removeAll(state.rules);
      }
    });

    builder.addCase(unpairDevices.rejected, (state) => {
      state.unpairDevices.status = "rejected";
    });

    builder.addCase(updateDevice.pending, (state) => {
      state.updateDevice.status = "pending";
    });

    builder.addCase(updateDevice.fulfilled, (state, action) => {
      state.updateDevice.status = "fulfilled";
      const device = action.payload;
      if (device) {
        deviceEntityAdapter.upsertOne(state, device);
      }
    });

    builder.addCase(updateDevice.rejected, (state) => {
      state.updateDevice.status = "rejected";
    });

    builder.addCase(resetDeviceUserCounter.pending, (state) => {
      state.resetDeviceUserCounter.status = "pending";
    });

    builder.addCase(resetDeviceUserCounter.fulfilled, (state, action) => {
      state.resetDeviceUserCounter.status = "fulfilled";
      const device = action.payload;
      if (device) {
        deviceEntityAdapter.updateOne(state, {
          id: device.id,
          changes: {
            counters: device.counters,
          },
        });
      }
    });

    builder.addCase(resetDeviceUserCounter.rejected, (state) => {
      state.resetDeviceUserCounter.status = "rejected";
    });

    builder.addCase(fetchDeviceEvents.pending, (state) => {
      state.events.fetchEvents.status = "pending";
    });

    builder.addCase(fetchDeviceEvents.fulfilled, (state, action) => {
      state.events.fetchEvents.status = "fulfilled";
      deviceEventsEntityAdapter.upsertMany(state.events, action.payload);
    });

    builder.addCase(fetchDeviceEvents.rejected, (state) => {
      state.events.fetchEvents.status = "rejected";
      state.events.fetchEvents.error = "ERROR";
    });

    // Optimistic update
    builder.addCase(acknowledgeDeviceEvent.pending, (state, action) => {
      const violation = action.meta.arg;
      deviceEventsEntityAdapter.upsertOne(state.events, {
        ...violation,
        acknowledged: true,
      });
    });

    builder.addCase(acknowledgeDeviceEvent.fulfilled, (state, action) => {
      deviceEventsEntityAdapter.upsertOne(state.events, {
        ...action.payload,
        acknowledged: true,
      });
    });

    builder.addCase(acknowledgeAllDeviceEvents.pending, (state, action) => {
      const { device_id } = action.meta.arg;

      const { selectAll } = deviceEventsEntityAdapter.getSelectors<
        typeof state
      >((state) => state.events);

      const deviceEventsUnacknowledgedSelector = createSelector(
        [selectAll, (s: typeof state, deviceId: string) => deviceId],
        (events, deviceId) =>
          events.filter(
            (event) => event.device_id === deviceId && !event.acknowledged
          )
      );

      const deviceEventsUnacknowledged = deviceEventsUnacknowledgedSelector(
        state,
        device_id
      );

      deviceEventsEntityAdapter.upsertMany(
        state.events,
        deviceEventsUnacknowledged.map((v) => {
          return {
            ...v,
            acknowledged: true,
          };
        })
      );
    });

    builder.addCase(acknowledgeAllDeviceEvents.rejected, (state, payload) => {
      console.log("error", payload);
    });

    builder.addCase(acknowledgeAllTenantDeviceEvents.pending, (state) => {
      const { selectAll } = deviceEventsEntityAdapter.getSelectors<
        typeof state
      >((state) => state.events);

      const allDeviceEvents = selectAll(state);

      deviceEventsEntityAdapter.upsertMany(
        state.events,
        allDeviceEvents.map((v) => {
          return {
            ...v,
            acknowledged: true,
          };
        })
      );
    });

    builder.addCase(
      acknowledgeAllTenantDeviceEvents.rejected,
      (state, payload) => {
        console.log("error", payload);
      }
    );

    builder.addCase(fetchDeviceRules.pending, (state) => {
      state.rules.fetchDeviceRules.status = "pending";
    });

    builder.addCase(fetchDeviceRules.fulfilled, (state, action) => {
      state.rules.fetchDeviceRules.status = "fulfilled";
      deviceRulesEntityAdapter.upsertMany(state.rules, action.payload);
    });
    builder.addCase(fetchDeviceRules.rejected, (state) => {
      state.rules.fetchDeviceRules.status = "rejected";
      state.rules.fetchDeviceRules.error = "ERROR";
    });
    builder.addCase(setDeviceRules.pending, (state) => {
      state.rules.setDeviceRules.status = "pending";
    });
    builder.addCase(setDeviceRules.fulfilled, (state) => {
      state.rules.setDeviceRules.status = "fulfilled";
    });
    builder.addCase(setDeviceRules.rejected, (state) => {
      state.rules.setDeviceRules.status = "rejected";
    });
  },
});

export const reducer = devicesSlice.reducer;

export const {
  deviceUpdated,
  onDeviceStatus,
  onDeviceConfiguration,
  onDeviceEventsAdded,
  onDeviceEventAcknowledged,
  onDeviceEventsAcknowledged,
  onTenantDeviceEventsAcknowledged,
  DeviceRuleAdded,
  deviceRuleUpdate,
  deviceRuleRemove,
} = devicesSlice.actions;

export const {
  selectAll: selectAllDevices,
  selectById: selectDeviceById,
  selectTotal: selectTotalDevices,
} = deviceEntityAdapter.getSelectors<RootState>((state) => state.devices);

export const selectAllNonDeletedDevices = createSelector(
  [selectAllDevices, (state: RootState) => state],
  (devices) => devices.filter((device) => device.deleted_at == null)
);

export const selectDevicesByTariffId = createSelector(
  [
    selectAllNonDeletedDevices,
    (state: RootState, tariffId: string) => tariffId,
  ],
  (devices, tariffId) =>
    devices.filter((device) => device.tariff_id === tariffId)
);

export const selectNumberOfDevicesByTariffId = createSelector(
  [selectDevicesByTariffId, (state: RootState, tariffId: string) => tariffId],
  (devices) => devices.length
);

export const selectDevicesByDeviceIds = createSelector(
  [
    selectAllNonDeletedDevices,
    (state: RootState, deviceIds: string[]) => deviceIds,
  ],
  (devices, deviceIds) =>
    devices.filter((device) => deviceIds.includes(device.id))
);

export const selectDevicesByNotInDeviceIds = createSelector(
  [
    selectAllNonDeletedDevices,
    (state: RootState, deviceIds: string[]) => deviceIds,
  ],
  (devices, deviceIds) => devices.filter((tag) => !deviceIds.includes(tag.id))
);

function groupBy<T>(list: T[], keyGetter: (v: T) => string) {
  const map = new Map();
  list.forEach((item: any) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export const selectFetchDevicesLoadingState = (state: RootState) =>
  state.devices.fetchDevices;
export const selectPairDevicesLoadingState = (state: RootState) =>
  state.devices.pairDevices;
export const selectUnpairDevicesLoadingState = (state: RootState) =>
  state.devices.unpairDevices;
export const selectUpdateDeviceLoadingState = (state: RootState) =>
  state.devices.updateDevice;
export const resetDeviceUserCounterLoadingState = (state: RootState) =>
  state.devices.resetDeviceUserCounter;

export const {
  selectAll: selectAllDeviceStates,
  selectById: selectDeviceStateByDeviceId,
} = deviceStatusEntityAdapter.getSelectors<RootState>(
  (state) => state.devices.states
);

export const {
  selectById: selectDeviceConfigurationByDeviceId,
  selectAll: selectAllDeviceConfigurations,
} = deviceConfigurationEntityAdapter.getSelectors<RootState>(
  (state) => state.devices.configurations
);

export const { selectAll: selectAllDeviceEvents } =
  deviceEventsEntityAdapter.getSelectors<RootState>(
    (state) => state.devices.events
  );

export const selectAllDevicesOnline = createSelector(
  [
    selectAllDevices,
    selectAllDeviceStates,
    selectAllDeviceConfigurations,
    (state, now: Date) => now,
  ],
  (devices, states, configurations, now) =>
    devices.filter((d) => {
      const state = states.find((s) => s.id === d.id);
      const configuration = configurations.find((c) => c.id === d.id);
      if (state && configuration) {
        const cS = getDeviceConnectionState(
          now,
          new Date(state.time),
          configuration
        );
        return cS.status === "online";
      }

      return false;
    })
);

export const selectDeviceStatesWithEvents = createSelector(
  [selectAllDeviceStates],
  (deviceStates) =>
    deviceStates.filter((state) => state.events && state.events.length)
);

// gen3 change
export const selectTotalMetricByDeviceState = createSelector(
  [
    selectAllDeviceStates,
    (state, metric: keyof Omit<Device.StatusMetrics, "phs">) => metric,
  ],
  (deviceStates, metric) =>
    deviceStates.reduce((sum, state) => state.metrics[metric], 0)
);

export const selectOnlineDevicesByDeviceStateMetric = createSelector(
  [
    selectAllDevices,
    selectAllDeviceStates,
    selectAllDeviceConfigurations,
    (
      state,
      params: {
        now: Date;
        metric: keyof Omit<Device.StatusMetrics, "phs">;
        limit?: number;
      }
    ) => params,
  ],
  (devices, states, configurations, params) => {
    const { now, metric, limit = 5 } = params;

    return devices
      .filter((d) => {
        const state = states.find((s) => s.id === d.id);
        const configuration = configurations.find((c) => c.id === d.id);
        if (state && configuration) {
          const cS = getDeviceConnectionState(
            now,
            new Date(state.time),
            configuration
          );
          return cS.status === "online";
        }

        return false;
      })
      .sort((a, b) => {
        const stateA = states.find((s) => s.id === a.id);
        const stateB = states.find((s) => s.id === b.id);
        return (stateB?.metrics[metric] ?? 0) - (stateA?.metrics[metric] ?? 0);
      })
      .slice(0, limit);
  }
);

export const selectTotalOperatingHoursPluggedByDeviceState = createSelector(
  [selectAllDeviceStates],
  (deviceStates) => deviceStates.reduce((sum, state) => state.metrics.whp, 0)
);

export const selectActiveEvents = createSelector(
  [selectDeviceStatesWithEvents],
  (deviceStatesWithEvents) =>
    deviceStatesWithEvents.map((state) => state.events).flat()
);

export const selectEventsNotAcknowledged = createSelector(
  [selectAllDeviceEvents],
  (events) => events.filter((event) => !event.acknowledged)
);

export const selectEventsNotAcknowledgedTotal = createSelector(
  [selectEventsNotAcknowledged],
  (events) => events.length
);

export const selectEventsGroupedByDevice = createSelector(
  [selectEventsNotAcknowledged],
  (events) =>
    groupBy<Application.Events.DatabaseEvent<any, any>>(events, (event) => event.device_id)
);

export const selectEventsByDevice = createSelector(
  [
    selectEventsNotAcknowledged,
    (state: RootState, deviceId: string) => deviceId,
  ],
  (events, deviceId) => events.filter((event) => event.device_id === deviceId)
);

export const selectDevicesWithActiveOrHistoricEvents = createSelector(
  [selectAllDevices, selectDeviceStatesWithEvents, selectEventsNotAcknowledged],
  (devices, deviceStates, eventsNotAcknowledged) => {
    return devices.filter((d) =>
      deviceStates.find(
        (s) =>
          s.id === d.id ||
          eventsNotAcknowledged.find((e) => e.device_id === d.id)
      )
    );
  }
);

export const { selectAll: selectAllDeviceRules } =
  deviceRulesEntityAdapter.getSelectors<RootState>(
    (state) => state.devices.rules
  );

export const selectDeviceRulesByDeviceId = createSelector(
  [selectAllDeviceRules, (state: RootState, device_id: string) => device_id],
  (rules, device_id) => rules.filter((rule) => rule.device_id == device_id)
);

export const fetchDeviceRulesLoadingState = (state: RootState) =>
  state.devices.rules.fetchDeviceRules;
export const setDeviceRulesLoadingState = (state: RootState) =>
  state.devices.rules.setDeviceRules;
