import { PageHeadline } from "../../components/text/page-headline";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/store";
import { selectAllTags, selectFetchTagsLoadingState } from "./tags-slice";
import { Can } from "../../auth/user-ability-provider";
import { Link as RouterLink } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Button, ButtonGroup } from "../../components/buttons/button-group";
import { PageWidth } from "../../components/containers/page-width";
import { FlexContainer } from "../../components/containers/flex-container";
import { ParentSize } from "@visx/responsive";
import { TagsTable } from "./table/tags-table";

export function TagsListPage() {
  const { t } = useTranslation();

  const tags = useAppSelector((state) => selectAllTags(state));
  const fetchTagsState = useAppSelector((state) =>
    selectFetchTagsLoadingState(state)
  );

  return (
    <PageWidth>
      <FlexContainer>
        <div className="flex flex-row flex-wrap justify-between gap-2">
          <PageHeadline>{t("tags.title", "Groups")}</PageHeadline>

          <ButtonGroup>
            <Can I="create" a="tag">
              <Button>
                <RouterLink
                  className={"flex justify-items-center gap-1 text-sm"}
                  to={"create"}
                >
                  <PlusIcon className="h-5 w-5" />
                  {t("tags.add", "create tag")}
                </RouterLink>
              </Button>
            </Can>
          </ButtonGroup>
        </div>

        {fetchTagsState.status !== "fulfilled" && <span>Loading</span>}

        {fetchTagsState.status == "fulfilled" && (
          <ParentSize>
            {({ width }) => <TagsTable width={width} tags={tags} />}
          </ParentSize>
        )}
      </FlexContainer>
    </PageWidth>
  );
}
