import { Route, Routes, useParams } from "react-router-dom";
import { DeviceDetailPage } from "./device-detail-page";
import { DeviceSettingsPage } from "./settings/device-settings-page";
import React from "react";
import { DeviceDebuggingPage } from "./device-debugging-page";

export function Device() {
  const { deviceId } = useParams();

  return (
    <Routes>
      <Route
        path="/"
        element={<>{deviceId && <DeviceDetailPage deviceId={deviceId} />}</>}
      />

      <Route
        path="/settings"
        element={<>{deviceId && <DeviceSettingsPage deviceId={deviceId} />}</>}
      />

      <Route
        path="/debug"
        element={<>{deviceId && <DeviceDebuggingPage deviceId={deviceId} />}</>}
      />
    </Routes>
  );
}
