import React, { Fragment, HTMLAttributes, useState } from "react";
import { Popover, Tab, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Application } from "@nantis/gridknight-core";
import { classNames } from "../../app/util";

import {
  Calendar,
  DirectionButton,
  getMonthNames,
  SelectionPill,
  YearSelect,
} from "./calendar";

import {
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  setMonth,
  setYear,
  isBefore,
  isSameMonth,
  isAfter,
  addDays,
} from "date-fns";
import { range } from "d3-array";
import {
  getRelativeTimeRange,
  getCurrentTimeRange,
  timeRangeToString,
} from "../time/time-range-utils";

import { CalendarIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import { useTimeRange } from "../../features/analytics/time-range-context";

type TimeSelectionProperties = {
  locale: string;
  today: Date;
  timeRange: Application.TimeRange;
  shownDate: Date;
  setShownDate: (date: Date) => void;
  onTimeRangeSelected: (timerange: Application.TimeRange) => void;
  close: () => void;
  minDate: Date;
  maxDate: Date;
};

export interface TimeRangePickerProps extends HTMLAttributes<HTMLElement> {
  timeZone?: Application.TimeZone;
  minDate?: Date;
  maxDate?: Date;
  isLoading?: boolean;
}

export function TimeRangePicker({
  minDate: min,
  maxDate: max,
  isLoading = false,
}: TimeRangePickerProps) {
  const { timeRange, setTimeRange } = useTimeRange();

  const today = new Date();
  const minDate = min ?? new Date(2022, 0, 1);
  let maxDate = max ?? today;

  // Sanity check
  if (!isAfter(maxDate, minDate)) {
    maxDate = addDays(minDate, 1);
    console.warn(
      "time range picker max date is not after min date, set to min +1d"
    );
  }

  const { t, i18n } = useTranslation();

  const locale = i18n.language;

  const ranges = [
    {
      value: "day",
      label: t("time.range-selector.ranges.day", "Day"),
    },
    {
      value: "month",
      label: t("time.range-selector.ranges.month", "Month"),
    },
    {
      value: "year",
      label: t("time.range-selector.ranges.year", "Year"),
    },
  ];

  // If a timerange of a certain range is selected (year, month, day) we want to show that tab in the range picker
  const selectedTab = ranges.indexOf(
    ranges.find((r) => r.value === timeRange.range) ?? ranges[0]
  );

  const [shownDate, setShownDate] = useState<Date>(timeRange.from);

  const onTimeRangeSelected = (timeRange: Application.TimeRange) => {
    setTimeRange(timeRange);
  };

  const limits = {
    canBackward: minDate ? isBefore(minDate, timeRange.from) : true,
    canForward: maxDate ? isAfter(maxDate, timeRange.to) : true,
  };

  const onBack = () => {
    if (limits.canBackward) {
      setTimeRange(getRelativeTimeRange(timeRange, -1));
    }
  };

  const onForward = () => {
    if (limits.canForward) {
      setTimeRange(getRelativeTimeRange(timeRange, 1));
    }
  };

  const onCurrentTimeRange = () => {
    if (limits.canForward) {
      setTimeRange(
        getRelativeTimeRange(getCurrentTimeRange(timeRange.range), 0)
      );
    }
  };

  const timeSelectionProps: Omit<TimeSelectionProperties, "close"> = {
    minDate,
    maxDate,
    shownDate,
    setShownDate,
    locale: locale,
    onTimeRangeSelected,
    today,
    timeRange,
  };

  return (
    <Popover className="relative w-full">
      <>
        <div className="flex items-stretch justify-between gap-1">
          <DirectionButton
            disabled={!limits.canBackward}
            direction={"back"}
            onClick={onBack}
          />
          <Popover.Button className="w-full rounded border border-gray-400 p-1 focus:outline-none">
            <div className="flex flex-row justify-between">
              <span className="w-full md:hidden">
                {timeRangeToString(timeRange)}
              </span>
              <span className="hidden w-full md:inline">
                {t("time.period", "Period")}: {timeRangeToString(timeRange)}
              </span>
              <span className="mr-2">
                {isLoading ? (
                  <ArrowPathIcon className="h-5 w-5 animate-spin" />
                ) : (
                  <CalendarIcon className="h-5 w-5" />
                )}
              </span>
            </div>
          </Popover.Button>
          <div className={"flew-row flex"}>
            <DirectionButton
              disabled={!limits.canForward}
              direction={"forward"}
              onClick={onForward}
            />
            <DirectionButton
              disabled={!limits.canForward}
              direction={"today"}
              onClick={onCurrentTimeRange}
            />
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel
            static
            className="absolute z-10 flex w-full justify-center"
          >
            {({ close }) => (
              <div className="max-w-md grow rounded-lg shadow-lg">
                <Tab.Group defaultIndex={selectedTab}>
                  <div className="w-full bg-white">
                    <Tab.List className={"flex items-stretch"}>
                      {ranges.map((r) => {
                        return (
                          <Tab
                            key={r.value}
                            className={({ selected }) =>
                              classNames(
                                "h-12 w-full border-b-2 text-sm font-medium leading-5 text-gray-700",
                                "ring-offset-blue-400 ring-white ring-opacity-60",
                                selected ? "border-blue" : "border-gray-400"
                              )
                            }
                          >
                            {r.label}
                          </Tab>
                        );
                      })}
                    </Tab.List>
                    <Tab.Panels>
                      <Tab.Panel className={"p-4"}>
                        <DaysTab close={close} {...timeSelectionProps} />
                      </Tab.Panel>
                      <Tab.Panel className={"p-4"}>
                        <MonthsTab close={close} {...timeSelectionProps} />
                      </Tab.Panel>
                      <Tab.Panel className={"p-4"}>
                        <YearsTab close={close} {...timeSelectionProps} />
                      </Tab.Panel>
                    </Tab.Panels>
                  </div>
                </Tab.Group>
              </div>
            )}
          </Popover.Panel>
        </Transition>
      </>
    </Popover>
  );
}

function DaysTab({
  today,
  timeRange,
  shownDate,
  locale,
  onTimeRangeSelected,
  setShownDate,
  close,
  minDate,
  maxDate,
}: TimeSelectionProperties) {
  const onChangeDay = (day: Date) => {
    const from = startOfDay(day);
    const to = endOfDay(day);

    onTimeRangeSelected({
      from,
      to,
      range: "day",
      raw: {
        from,
        to,
      },
    });

    close();
  };

  return (
    <div>
      <Calendar
        onShownDateChange={setShownDate}
        today={today}
        locale={locale}
        minDate={minDate}
        maxDate={maxDate}
        date={timeRange.from}
        shownDate={shownDate}
        onDateSelected={(item) => onChangeDay(item)}
      />
    </div>
  );
}

function MonthsTab({
  today,
  minDate,
  maxDate,
  shownDate,
  setShownDate,
  locale,
  onTimeRangeSelected,
  close,
}: TimeSelectionProperties) {
  const onSelectYear = (year: number) => {
    const day = setYear(shownDate, year);
    setShownDate(day);
  };

  const onSelectMonth = (month: number) => {
    const m = setMonth(shownDate, month);

    const from = startOfMonth(m);
    const to = endOfMonth(m);

    onTimeRangeSelected({
      range: "month",
      from,
      to,
      raw: {
        from,
        to,
      },
    });

    close();
  };

  const monthNames = getMonthNames(locale, "short");

  return (
    <div className={"flex flex-col"}>
      {/*Selection Year*/}
      <YearSelect
        maxYear={maxDate.getFullYear()}
        minYear={minDate.getFullYear()}
        year={shownDate.getFullYear()}
        onSelectYear={onSelectYear}
      />
      <div className={"mt-4 grid grid-cols-3 gap-1 text-center"}>
        {monthNames.map((month, i) => {
          const mD = new Date(shownDate.getFullYear(), i);
          const isDisabled = !(
            (isAfter(mD, minDate) || isSameMonth(mD, minDate)) &&
            (isBefore(mD, maxDate) || isSameMonth(mD, maxDate))
          );
          return (
            <SelectionPill
              isDisabled={isDisabled}
              isNow={
                i === today.getMonth() &&
                shownDate.getFullYear() === today.getFullYear()
              }
              key={month}
              label={month}
              onSelect={() => {
                onSelectMonth(i);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

function YearsTab({
  minDate,
  today,
  maxDate = today,
  shownDate,
  close,
  onTimeRangeSelected,
}: TimeSelectionProperties) {
  const minYear = minDate ? minDate.getFullYear() : 2020;
  const maxYear = maxDate ? maxDate.getFullYear() : today.getFullYear();

  const availableYears: number[] = range(minYear, maxYear + 1);

  const onSelectYear = (year: number) => {
    const day = setYear(shownDate, year);

    const from = startOfYear(day);
    const to = endOfYear(day);

    onTimeRangeSelected({
      range: "year",
      from,
      to,
      raw: {
        from,
        to,
      },
    });

    close();
  };

  return (
    <div className={"grid grid-cols-3 gap-1 text-center"}>
      {availableYears.map((y) => {
        return (
          <SelectionPill
            isNow={y === today.getFullYear()}
            key={y}
            label={`${y}`}
            onSelect={() => onSelectYear(y)}
          />
        );
      })}
    </div>
  );
}
