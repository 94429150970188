import { Application } from "@nantis/gridknight-core";
import { add } from "date-fns";

export type DeviceConnectionState = {
  status: "offline" | "online";
  secondsSinceLastStatus?: number;
  nextOnline?: Date;
};

const getNextOnline = (
  lastOnline: Date,
  connectionIntervalSeconds: number
): Date => {
  return add(lastOnline, {
    seconds: connectionIntervalSeconds,
  });
};

const thresholdSecondsUntilOffline = 30;

export const getDeviceConnectionState = (
  now: Date,
  lastConnection?: Date,
  config?: Application.DeviceConfiguration
): DeviceConnectionState => {
  if (!lastConnection) {
    return {
      status: "offline",
    };
  }

  const diff = now.getTime() - lastConnection.getTime();
  const connectionIntervalSeconds = config?.connectionInterval ?? 60 * 60;
  // Add a threshold to avoid issues with flickering online offline buttons and to adjust for system runtime
  const status =
    diff > 1000 * (connectionIntervalSeconds + thresholdSecondsUntilOffline)
      ? "offline"
      : "online";

  return {
    status,
    secondsSinceLastStatus: diff / 1000.0,
    // If the device is offline we do not expect the next status
    nextOnline:
      status === "online"
        ? getNextOnline(lastConnection, connectionIntervalSeconds)
        : undefined,
  };
};

export const getAccuracy = (
  cellular: Application.Cellular,
  location: Application.Location
): number => {
  if (cellular.rat === "CAT-M1") {
    return Math.min(location.accuracy, 12000);
  }

  if (cellular.rat === "NB1") {
    return Math.min(location.accuracy, 15000);
  }

  return location.accuracy;
};
