import { setDate, setHours, setMinutes, getMonth, setMonth } from "date-fns";

import React, { PropsWithChildren, useMemo } from "react";
import { AnalyticsDataWithLabelAndColor } from "../../features/analytics/analytics-dashboard-view";
import { formattedValueToString, getValueFormat } from "@nantis/grafana-data";
import {
  Application,
  calculateEnergyCostRateForBaseCurrencyUnitPerKiloWattHour,
  ElectricMetricKey,
  metrics,
  Metric,
} from "@nantis/gridknight-core";
import { useTimeFormatter } from "../time/use-time-formatter";
import { useTranslation } from "react-i18next";
import { encodeDatasetKey } from "../../features/analytics/analytics-slice";
import { RenderTooltipParams } from "@visx/xychart/lib/components/Tooltip";
import { ParentSize } from "@visx/responsive";
import {
  Axis,
  BarGroup,
  BarSeries,
  DataProvider,
  Grid,
  LineSeries,
  Tooltip,
  XYChart,
} from "@visx/xychart";
import { range } from "d3-array";
import { useTheme } from "../../app/theme-provider";

type DataKeyMapEntry = { label: string; color: string };

// The Graph will only return a dataseries key, so we need a way to map between this key and associated data (e.g. label and entity)
export const createKeyMap = (
  timeseries: AnalyticsDataWithLabelAndColor[]
): Record<string, DataKeyMapEntry> => {
  const keyMap: Record<string, DataKeyMapEntry> = {};

  timeseries.forEach((t) => {
    keyMap[encodeDatasetKey(t)] = {
      label: t.label,
      color: t.color,
    };
  });

  return keyMap;
};

export const timeAccessor = (datum: Record<string, any>) => {
  return datum.time as Date;
};

export const keyFormatterGenerator = (
  keyMap: Record<string, DataKeyMapEntry>
): ((key: string) => JSX.Element) => {
  return (key: string): JSX.Element => {
    if (key && keyMap.hasOwnProperty(key)) {
      const { color, label } = keyMap[key];

      return (
        <span>
          <span
            className="mr-2 inline-block h-2 w-2 rounded-full"
            style={{ backgroundColor: color }}
          />
          <span>{label}:</span>
        </span>
      );
    } else {
      return <></>;
    }
  };
};

export function AnalyticsTooltip<Datum extends object>({
  keyFormatter = (v) => <span>{v}</span>,
  valueFormatter = (v) => v.toString(),
  timeFormatter = (v) => v.toString(),
  xAccessor,
  yAccessor,
  secondaryValueFormatter,
}: {
  keyFormatter?: (value: any) => JSX.Element;
  valueFormatter?: (value: any) => string;
  timeFormatter?: (time: number) => string;
  xAccessor: (datum: Datum) => number | Date;
  yAccessor: (datum: Datum) => number | Date;
  secondaryValueFormatter?: (datum: Datum) => string;
}) {
  const tooltipFormat = (
    params: RenderTooltipParams<Datum>
  ): React.ReactNode => {
    // TODO get the associated values
    // cant compare days, but hour in days
    // can also compare months to some extent (need to normalize)
    const nearestDatum = params.tooltipData?.nearestDatum?.datum;
    const datums = params.tooltipData?.datumByKey;

    if (datums != null && nearestDatum) {
      return (
        <div className="text-md space-y-1 leading-6">
          {nearestDatum && (
            <p>{timeFormatter(xAccessor(nearestDatum) as number)}</p>
          )}

          {Object.entries(datums)?.map(([key, value]) => {
            if (!key.startsWith("comparison")) {
              return (
                <p className={"flex items-center"} key={key}>
                  {keyFormatter(key)}
                  <span className="ml-1">
                    <>
                      {valueFormatter(yAccessor(value.datum))}
                      {secondaryValueFormatter
                        ? ` (${secondaryValueFormatter(value.datum)})`
                        : ""}
                    </>
                  </span>
                </p>
              );
            }
          })}
        </div>
      );
    }

    return <></>;
  };

  return (
    <Tooltip<Datum>
      showVerticalCrosshair
      detectBounds={true}
      snapTooltipToDatumX
      snapTooltipToDatumY={false}
      renderTooltip={tooltipFormat}
    />
  );
}

export type AnalyticsGraphProps = {
  measurementKey: ElectricMetricKey;
  currencySymbol: string;
  costKey?: string;
  timeRange: Application.TimeRange;
  timeseries: AnalyticsDataWithLabelAndColor[];
  properties?: string[];
  keyFormatter: (value: any) => JSX.Element;
  decimals?: number;
  includeZero?: boolean;
  shape?: "bar" | "line";
};

export function AnalyticsGraph({
  timeRange,
  timeseries,
  measurementKey,
  costKey,
  currencySymbol,
  keyFormatter,
  decimals = 2,
  includeZero = true,
  shape = "line",
  children,
}: AnalyticsGraphProps & PropsWithChildren) {
  const { t } = useTranslation();
  const timeFormatter = useTimeFormatter(timeRange);
  const { getComparisonColor } = useTheme();

  const currencyFormat = getValueFormat(`currency:${currencySymbol}`);
  const metric: Metric = metrics[measurementKey];
  const measurementFormat = getValueFormat(metric.unit.id);
  const { factor: costFactor } =
    calculateEnergyCostRateForBaseCurrencyUnitPerKiloWattHour(0);

  const valueFormat = (value: number): string => {
    const res = measurementFormat(value, decimals);
    return formattedValueToString(res);
  };

  const yAccessor = (datum: Record<string, any>) => {
    return datum[metric.name] as number;
  };

  const { range, tickFormat, timeAccessor } = useMemo(
    () => getTimeProps({ timeRange, timeFormatter }),
    [timeRange, timeFormatter]
  );

  // Cost formatting
  const secondaryValueFormatter = costKey
    ? (datum: Record<string, any>): string => {
        if (datum && datum.hasOwnProperty(costKey)) {
          // 10ths of a cent
          const costTenthsOfCents = datum[costKey]
            ? Number((datum[costKey] * BigInt(1000)) / BigInt(costFactor)) / 100
            : 0;

          if (costTenthsOfCents === 0) {
            return `${formattedValueToString(currencyFormat(0, 0))}`;
          } else if (costTenthsOfCents < 0.1) {
            return `< ${formattedValueToString(currencyFormat(0.01, 2))}`;
          } else {
            return formattedValueToString(
              currencyFormat(costTenthsOfCents / 10, 2)
            );
          }
        } else {
          return "-";
        }
      }
    : undefined;

  const valueFormatter = (datum: number) => {
    if (datum != null) {
      return valueFormat(datum);
    }
    return "-";
  };

  // Check if no data was passed
  const { isLoading, hasData, hasError } = timeseries.reduce(
    (prev, ts) => {
      return {
        hasData: prev.hasData || ts.currentData.data.length > 0,
        isLoading: prev.isLoading || ts.currentData.status.status === "pending",
        hasError: prev.hasError || ts.currentData.status.status === "rejected",
      };
    },
    {
      hasData: false,
      isLoading: false,
      hasError: false,
    }
  );

  const isEmpty = !hasData;

  if (isLoading && isEmpty) {
    return (
      <div
        className={
          "flex h-full w-full items-center justify-center text-lg text-gray-700"
        }
      >
        {t("analytics.loadingData", "Loading data")}
      </div>
    );
  }

  if (!isLoading && isEmpty) {
    return (
      <div
        className={
          "flex h-full w-full items-center justify-center text-lg text-gray-700"
        }
      >
        {t("analytics.noDataAvailable", "No data available")}
      </div>
    );
  }

  if (!isLoading && isEmpty && hasError) {
    return (
      <div
        className={
          "flex h-full w-full items-center justify-center text-lg text-red"
        }
      >
        {t(
          "analytics.errorLoadingData",
          "There has been an error loading the data."
        )}
      </div>
    );
  }

  return (
    <ParentSize>
      {({ width, height }) => (
        <DataProvider
          xScale={{ type: "band", padding: 0.2, domain: range }}
          yScale={{ type: "linear", zero: includeZero }}
        >
          <XYChart
            margin={{ top: 20, bottom: 30, left: 50, right: 40 }}
            width={width}
            height={height}
          >
            <TimeAxis
              timeRange={timeRange}
              width={width}
              tickFormat={tickFormat}
            />

            <Axis
              key={`value-axis`}
              orientation={"left"}
              tickFormat={valueFormatter}
              numTicks={2}
              strokeWidth={1}
            />

            <AnalyticsTooltip
              yAccessor={yAccessor}
              secondaryValueFormatter={secondaryValueFormatter}
              xAccessor={timeAccessor}
              keyFormatter={keyFormatter}
              timeFormatter={tickFormat}
              valueFormatter={valueFormatter}
            />

            <Grid numTicks={2} columns={false} />

            {shape === "bar" && (
              <BarGroup enableEvents={false}>
                {timeseries.map((value) => {
                  const key = "comparison-" + encodeDatasetKey(value);
                  return (
                    <BarSeries
                      enableEvents={false}
                      dataKey={key}
                      key={key}
                      data={value?.comparisonData?.data ?? []}
                      barPadding={0}
                      colorAccessor={() => getComparisonColor(value.color)}
                      yAccessor={yAccessor as any}
                      xAccessor={timeAccessor as any}
                    />
                  );
                })}
              </BarGroup>
            )}

            {shape === "bar" && (
              <BarGroup>
                {timeseries.map((value) => {
                  const key = encodeDatasetKey(value);
                  return (
                    <BarSeries
                      dataKey={key}
                      key={key}
                      data={value.currentData.data as any}
                      barPadding={0}
                      colorAccessor={() => value.color}
                      yAccessor={yAccessor as any}
                      xAccessor={timeAccessor as any}
                    />
                  );
                })}
              </BarGroup>
            )}

            {shape === "line" && (
              <>
                {timeseries.map((value) => {
                  const key = encodeDatasetKey(value);

                  return (
                    <LineSeries
                      accentHeight={2}
                      dataKey={key}
                      key={key}
                      data={value.currentData.data as any}
                      colorAccessor={() => value.color}
                      yAccessor={yAccessor as any}
                      xAccessor={timeAccessor as any}
                    />
                  );
                })}
              </>
            )}

            {children}
          </XYChart>
        </DataProvider>
      )}
    </ParentSize>
  );
}

/**
 * One tick is added anyway
 * @param tickFormat
 * @param timeRange
 * @param width
 * @constructor
 */
function TimeAxis({
  tickFormat,
  timeRange,
  width,
}: {
  timeRange: Application.TimeRange;
  width: number;
  tickFormat: (value: number) => string;
}) {
  let tickNumber = 12;

  switch (timeRange.range) {
    case "day":
      if (width < 500) {
        tickNumber = 4;
      } else if (width < 800) {
        tickNumber = 8;
      } else if (width < 1000) {
        tickNumber = 12;
      } else if (width < 1400) {
        tickNumber = 16;
      } else {
        tickNumber = 18;
      }

      break;
    case "month":
      if (width < 350) {
        tickNumber = 2;
      } else if (width < 500) {
        tickNumber = 3;
      } else if (width < 800) {
        tickNumber = 6;
      } else if (width < 1000) {
        tickNumber = 10;
      } else if (width < 1400) {
        tickNumber = 12;
      } else {
        tickNumber = 14;
      }

      break;
    case "year":
      if (width < 500) {
        tickNumber = 2;
      } else if (width < 800) {
        tickNumber = 4;
      } else if (width < 1000) {
        tickNumber = 6;
      } else if (width < 1400) {
        tickNumber = 10;
      } else {
        tickNumber = 12;
      }
      break;
    case "custom":
      break;
  }

  return (
    <Axis
      key={`time-axis`}
      tickFormat={tickFormat}
      numTicks={tickNumber}
      strokeWidth={1}
      orientation="bottom"
    />
  );
}

function getTimeProps({
  timeRange,
  timeFormatter,
}: {
  timeRange: Application.TimeRange;
  timeFormatter: (date: Date) => string;
}): {
  range: number[];
  tickFormat: (value: number) => string;
  timeAccessor: (datum: Record<string, any>) => number | Date;
} {
  let timeAccessor: (datum: Record<string, any>) => number | Date = (datum) => {
    return datum.time;
  };

  let tickFormat = (value: number) => {
    return `${value}`;
  };

  let valueRange: number[] = [];

  switch (timeRange.range) {
    case "day":
      valueRange = range(0, 24 * 60, 15);
      timeAccessor = (datum) => {
        const d =
          typeof datum.time === "object"
            ? (datum.time as Date)
            : new Date(datum.time);
        return d.getHours() * 60 + d.getMinutes();
      };
      tickFormat = (value) => {
        const d = setHours(timeRange.to, value / 60);
        const m = setMinutes(d, value % 60);
        return timeFormatter(m);
      };
      break;
    case "month":
      valueRange = range(1, 32);
      timeAccessor = (datum) => {
        return (
          typeof datum.time === "object"
            ? (datum.time as Date)
            : new Date(datum.time)
        ).getDate();
      };
      tickFormat = (value) => {
        const d = setDate(timeRange.to, value);
        return timeFormatter(d);
      };
      break;
    case "year":
      valueRange = range(0, 12);
      timeAccessor = (datum) => {
        const t =
          typeof datum.time === "object"
            ? (datum.time as Date)
            : new Date(datum.time);
        return getMonth(t);
      };
      tickFormat = (value) => {
        const d = setMonth(timeRange.to, value);
        return timeFormatter(d);
      };
      break;
    case "custom":
      break;
  }

  return {
    timeAccessor,
    range: valueRange,
    tickFormat,
  };
}
