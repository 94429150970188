import { classNames } from "../util";
import { PropsWithChildren } from "react";

export type TextProps = PropsWithChildren<{
  variant?: "small" | "normal";
}>;

export function Text({ children, variant = "normal" }: TextProps) {
  const classes = (() => {
    switch (variant) {
      case "small":
        return "text-xs sm:text-sm";
      case "normal":
        return "text-sm sm:text-base";
    }
  })();
  return (
    <p
      className={classNames(classes, "inline-block align-middle text-gray-600")}
    >
      {children}
    </p>
  );
}
