import { DataTable } from "../../../components/ng/data-table";
import { Application } from "@nantis/gridknight-core";
import { useTranslation } from "react-i18next";
import React, { useMemo } from "react";
import { ColumnDef, VisibilityState } from "@tanstack/react-table";
import { Link } from "react-router-dom";
import {
  isVisible,
  ScreenBreakpoints,
} from "../../../components/util/getScreenBreakpoints";
import { DataTableColumnHeader } from "../../../components/table/table-header";
import { useAppSelector } from "../../../app/store";
import { selectNumberOfDevicesByTag } from "../tag-on-device-slice";
import { TagBadge } from "../tagBadge";
export interface TagsTableProps {
  width: number;
  tags: Application.Tag[];
}

export function TagsTable({ tags, width = 200 }: TagsTableProps) {
  const { t } = useTranslation();

  const columnVisibility = useMemo<VisibilityState>(() => {
    const c: Record<string, ScreenBreakpoints["name"] | null> = {
      name: null,
      numberOfDevices: "sm",
      description: null,
    };

    return Object.keys(c).reduce((acc, key) => {
      return {
        [key]: isVisible(width, c[key]),
        ...acc,
      };
    }, {});
  }, [width]);

  const columns = useMemo<ColumnDef<Application.Tag, any>[]>(
    () => [
      {
        accessorKey: "label",
        header: ({ column }) => {
          return (
            <DataTableColumnHeader
              className=""
              column={column}
              title={t("tags.label", "Label")}
            />
          );
        },
        cell: ({ row }) => {
          const tag = row.original;
          return (
            <Link className={"text-sm"} to={`${tag.id}`}>
              <TagBadge key={tag.id} tag={tag} />
            </Link>
          );
        },
      },
      {
        id: "numberOfDevices",
        header: ({ column }) => {
          return (
            <DataTableColumnHeader
              className=""
              column={column}
              title={t(
                "tags.numberOfDeviceInLabel",
                "number of devices in group"
              )}
            />
          );
        },
        cell: ({ row }) => {
          return <NumberOfDevicesWithTag tagId={row.original.id} />;
        },
      },
      {
        accessorKey: "description",
        header: ({ column }) => {
          return (
            <DataTableColumnHeader
              className=""
              column={column}
              title={t("tags.description", "description")}
            />
          );
        },
      },
    ],
    [t]
  );

  return (
    <DataTable
      columnVisibility={columnVisibility}
      columns={columns}
      data={tags}
    />
  );
}

function NumberOfDevicesWithTag({ tagId }: { tagId: string }) {
  const numberOfDevicesWithTag = useAppSelector((state) =>
    selectNumberOfDevicesByTag(state, tagId)
  );

  return <>{numberOfDevicesWithTag}</>;
}
