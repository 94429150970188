import { Application } from "@nantis/gridknight-core";
import i18next from "i18next";
import {
  addDays,
  addMonths,
  addYears,
  endOfDay,
  endOfMonth,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfYear,
} from "date-fns";

export const getCurrentTimeRange = (
  range: Application.TimeRange["range"] = "day"
): Application.TimeRange => {
  const today = new Date();
  const start = startOfDay(today);
  const end = endOfDay(today);

  return {
    range: range,
    from: start,
    to: end,
    raw: {
      from: start,
      to: end,
    },
  };
};
export const getDefaultTimeRange = (): Application.TimeRange => {
  return getCurrentTimeRange("day");
};

function getTimeRangeFromDates(
  from: Date,
  to: Date,
  range: Application.TimeRange["range"]
): Application.TimeRange {
  return {
    from,
    to,
    range: range,
    raw: {
      from,
      to,
    },
  };
}

export function getRelativeTimeRange(
  timeRange: Application.TimeRange,
  increment = 0
): Application.TimeRange {
  switch (timeRange.range) {
    case "day":
      return getTimeRangeFromDates(
        startOfDay(addDays(timeRange.from, increment)),
        endOfDay(addDays(timeRange.to, increment)),
        "day"
      );
    case "month":
      return getTimeRangeFromDates(
        startOfMonth(addMonths(timeRange.from, increment)),
        endOfMonth(addMonths(timeRange.to, increment)),
        "month"
      );
    case "year":
      return getTimeRangeFromDates(
        startOfYear(addYears(timeRange.from, increment)),
        endOfYear(addYears(timeRange.to, increment)),
        "year"
      );
    case "custom":
      break;
  }
  return timeRange;
}

/**
 * Translate a timerange to string
 * @param timeRange
 */
export const timeRangeToString = (timeRange: Application.TimeRange): string => {
  if (timeRange.raw.label) {
    return i18next.t(`time.ranges.${timeRange.raw.label}`, timeRange.raw.label);
  }

  switch (timeRange.range) {
    case "day":
      return i18next.t("time.ranges.day", {
        from: timeRange.from,
        to: timeRange.to,
      });
    case "month":
      return i18next.t("time.ranges.month", {
        from: timeRange.from,
        to: timeRange.to,
      });
    case "year":
      return i18next.t("time.ranges.year", {
        from: timeRange.from,
        to: timeRange.to,
      });
  }

  return i18next.t("time.ranges.custom", {
    from: timeRange.from,
    to: timeRange.to,
  });
};

/**
 * To display a date in the context of a timerange we need to display different values (e.g. date on a month scale)
 * @param timeRange
 * @param locale
 */
export const timeFormatFactory = (
  timeRange: Application.TimeRange,
  locale: string
): ((time: Date | string) => string) => {
  let f: Intl.DateTimeFormat | null = null;

  switch (timeRange.range) {
    case "day":
      f = new Intl.DateTimeFormat(locale, {
        hour: "2-digit",
        minute: "2-digit",
      });
      break;
    case "month":
      f = new Intl.DateTimeFormat(locale, {
        weekday: "short",
        month: "short",
        day: "numeric",
      });
      break;
    case "year":
      f = new Intl.DateTimeFormat(locale, { month: "long" });
      break;
    case "custom":
      const rangeSeconds =
        (timeRange.to.getTime() - timeRange.from.getTime()) / 1000;

      if (rangeSeconds <= 60 * 60 * 24) {
        f = new Intl.DateTimeFormat(locale, {
          hour: "2-digit",
          minute: "2-digit",
        });
      } else {
        f = new Intl.DateTimeFormat(locale, {
          weekday: "short",
          month: "long",
          day: "numeric",
          year: "numeric",
        });
      }
  }

  return (time: Date | string): string => {
    if (f != null) {
      if (typeof time === "object") {
        return f.format(time);
      } else {
        return f.format(new Date(time));
      }
    }
    return "";
  };
};
