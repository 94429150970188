import { HTMLAttributes } from "react";
import { classNames } from "../../app/util";

export function ButtonGroup({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames(
        className,
        "flex flex-grow flex-col justify-end gap-1 rounded-md md:flex-row md:gap-0"
      )}
      {...props}
      role="group"
    >
      {children}
    </div>
  );
}

export function Button({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      type="button"
      className={classNames(
        className,
        "inline-flex items-center rounded-lg border border-blue/50 bg-white py-2 px-4 text-sm text-gray-900 hover:bg-gray-100 hover:text-black focus:z-10 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600 dark:hover:text-white dark:focus:text-white md:rounded-none md:first:rounded-l-md md:last:rounded-r-md"
      )}
      {...props}
    >
      {children}
    </button>
  );
}
