import { useMemo } from "react";
import { Application } from "@nantis/gridknight-core";
import { useTranslation } from "react-i18next";
import { timeFormatFactory } from "./time-range-utils";

export const useTimeFormatter = (
  timeRange: Application.TimeRange
): ((date: Date) => string) => {
  const { i18n } = useTranslation();

  return useMemo((): ((time: Date) => string) => {
    return timeFormatFactory(timeRange, i18n.language);
  }, [timeRange, i18n.language]);
};
