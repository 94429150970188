import React from "react";
import { Route, Routes } from "react-router-dom";
import { DevicesSettingsPage } from "./devices-settings-page";
import { Tariffs } from "../../tariff/tariffs";

export function DevicesSettings() {
  return (
    <Routes>
      <Route path="/" element={<DevicesSettingsPage />} />
      <Route path="/tariffs/*" element={<Tariffs />} />
    </Routes>
  );
}
