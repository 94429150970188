import React from "react";

import { Route, Routes } from "react-router-dom";
import { TenantDashboardPage } from "./tenant-dashboard-page";
import { Devices } from "../devices/devices";

import { ErrorBoundary } from "react-error-boundary";
import { ErrorMessage } from "../common/errors";
import { useAppSelector } from "../../app/store";

import Navigation from "../common/navigation/navigation";
import { selectTenant } from "./tenants-slice";
import { TenantSelectSubscriptionPage } from "./subscriptions/tenant-select-subscription-page";
import { Analytics } from "../analytics/analytics";
import { Tags } from "../tags/tags";
import { Settings } from "../settings/settings";
import { Events } from "../events/events";
import { Documentation } from "../documentation/documentation";

export function ErrorFallback() {
  return <ErrorMessage />;
}

export function Tenant() {
  let tenant = useAppSelector(selectTenant);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <>
        {tenant && tenant.tier == null ? (
          <>
            <Navigation
              showUserLinks={false}
              showNavigationLinks={false}
              showNotifications={false}
            />
            <div className="mt-6">
              <TenantSelectSubscriptionPage currentTier={tenant?.tier} />
            </div>
          </>
        ) : (
          <>
            <Navigation />

            <div className={"w-full flex-grow"}>
              <Routes>
                <Route path="/" element={<TenantDashboardPage />} />
                <Route path="settings/*" element={<Settings />} />
                <Route path="notifications/*" element={<Events />} />
                <Route path="devices/*" element={<Devices />} />
                <Route path="tags/*" element={<Tags />} />
                <Route path="analytics/*" element={<Analytics />} />
                <Route path="docs/*" element={<Documentation />} />
              </Routes>
            </div>
          </>
        )}
      </>
    </ErrorBoundary>
  );
}
