import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  selectAllNonDeletedDevices,
  selectFetchDevicesLoadingState,
} from "./devices-slice";
import { useAppSelector } from "../../app/store";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { PageHeadline } from "../../components/text/page-headline";
import { Card } from "../../components/containers/card";
import { CallToActionPairDevice } from "./pair/cta-pair-device";

import { Can } from "../../auth/user-ability-provider";
import { Button, ButtonGroup } from "../../components/buttons/button-group";
import { PageWidth } from "../../components/containers/page-width";
import { FlexContainer } from "../../components/containers/flex-container";

import { DevicesTable } from "./table/devices-table";
import { ParentSize } from "@visx/responsive";

export function DeviceListPage() {
  const { t } = useTranslation();

  const devices = useAppSelector((state) => selectAllNonDeletedDevices(state));
  const fetchDevicesState = useAppSelector((state) =>
    selectFetchDevicesLoadingState(state)
  );

  if (fetchDevicesState.status !== "fulfilled") {
    return <span>Loading</span>;
  }

  // Show CTA to pair device if there are no devices at all
  if (devices.length === 0) {
    return (
      <PageWidth>
        <FlexContainer>
          <PageHeadline>
            {t("devices.list.titleAllDevices", "All devices")}
          </PageHeadline>
          <Card>
            <CallToActionPairDevice />
          </Card>
        </FlexContainer>
      </PageWidth>
    );
  }

  return (
    <PageWidth>
      <FlexContainer>
        <div className="flex flex-col flex-wrap justify-between gap-2 md:flex-row">
          <PageHeadline>
            {t("devices.list.titleAllDevices", "All devices")}
          </PageHeadline>

          <ButtonGroup>
            <Can I="pair" a="device">
              <Button>
                <RouterLink to={"/devices/pair"}>
                  <div
                    className={"flex justify-items-center gap-1"}
                    title={t("device.pair.title", "pair device")}
                  >
                    <PlusIcon className="h-5 w-5" />
                    {t("device.pair.title", "pair device")}
                  </div>
                </RouterLink>
              </Button>
            </Can>
          </ButtonGroup>
        </div>

        {devices.length > 0 && (
          <ParentSize>
            {({ width }) => <DevicesTable width={width} devices={devices} />}
          </ParentSize>
        )}
      </FlexContainer>
    </PageWidth>
  );
}
