import React, { PropsWithChildren } from "react";
import { classNames } from "../../app/util";

export function Card({
  children,
  style,
  className,
}: PropsWithChildren<{
  style?: any;
  className?: string;
}>) {
  return (
    <div
      style={style}
      className={classNames(
        "w-full rounded bg-white p-2 shadow-md lg:p-4",
        className
      )}
    >
      {children}
    </div>
  );
}
