import React from "react";
import { useTranslation } from "react-i18next";
import { encodeMailLink } from "../../features/tenants/support/encodeMailLink";
import { useAppSelector } from "../../app/store";
import { selectTenant } from "../../features/tenants/tenants-slice";
import { selectCurrentUser } from "../../features/tenants/users/users-slice";

export function CustomerSupportDetails() {
  const { t } = useTranslation();

  const tenant = useAppSelector(selectTenant);
  const currentUser = useAppSelector(selectCurrentUser);

  const recipient = t("support.emailAddress", "support@gridknight.com");

  const emailSubject = t(
    "support.emailSubject",
    "Customer Support Request from {{customerName}}",
    {
      customerName: tenant?.name,
    }
  );

  const emailBody = t(
    "support.emailBodyGeneral",
    "Dear Gridknight Team,\n\nmy customer number is {{customerNumber}} and I have the following request:\n\n\n\nBest regards,\n{{userName}}",
    {
      customerNumber: currentUser?.tenant_id,
      customerName: tenant?.name,
      userName: currentUser?.name,
    }
  );

  const emailLink = encodeMailLink({
    recipient: recipient,
    subject: emailSubject,
    body: emailBody,
  });

  return (
    <>
      <h2 className="text-center text-xl font-extrabold text-gray-900 sm:text-xl">
        {t("support.title", "Customer support")}
      </h2>

      <p className="pt-4 text-center">
        <a href={`tel:${t("support.phoneNumber", "+49 (711) 34 20 200")}`}>
          {t("support.phone", "Telephone")}:{" "}
          {t("support.phoneNumber", "+49 (711) 34 20 200")}
        </a>
      </p>
      <p className="mb-8 pt-4 text-center">
        <a href={emailLink}>
          {t("support.email", "Email:")}:{" "}
          {t("support.emailAddress", "support@gridknight.com")}
        </a>
      </p>
    </>
  );
}
