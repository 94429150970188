import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  SubscriptionPlan,
  SubscriptionSelector,
} from "./subscription-selector";
import { SubscriptionFAQ } from "./subscription-faq";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  setSubscription,
  selectSetSubscriptionState,
  fetchTenant,
} from "../tenants-slice";
import { useLocation, useSearchParams } from "react-router-dom";
import stripeConfig from "../../../stripe-config.json";
import { PageWidth } from "../../../components/containers/page-width";
import type { Application } from "@nantis/gridknight-core";
import { SubscriptionPending } from "./subscription-pending";
import { CustomerSupportDetails } from "../../../components/customer-support/customer-support-details";
import { Can } from "../../../auth/user-ability-provider";

const { tiers: stripeTiers } = stripeConfig as { tiers: SubscriptionPlan[] };

// https://saaswebsites.com/12-best-pricing-pages-including-b2b-saas-pricing-pages/
export function TenantSelectSubscriptionPage({
  currentTier,
}: {
  currentTier: Application.Tenant["tier"];
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const subscriptionLoadingState = useAppSelector(selectSetSubscriptionState);

  const location = useLocation();

  const stripeSessionIdKey = "stripe_session_id";

  let [searchParams] = useSearchParams();
  const stripeSessionId = searchParams.getAll(stripeSessionIdKey);
  const hasReturnedFromCheckout = stripeSessionId.length > 0;

  const onSelectTier = (tier: string | null) => {
    // Undefined could mean any non-existent plan
    if (tier === null || tier === undefined) {
      // TODO show contact form
    } else {
      if (subscriptionLoadingState.status !== "pending") {
        let successUrl = null;
        let cancelUrl = null;

        if (window?.location?.origin) {
          successUrl =
            window.location.origin +
            location.pathname +
            "?stripe_session_id={CHECKOUT_SESSION_ID}";
          cancelUrl = window.location.origin + location.pathname;
        }

        // Init subscription
        dispatch(
          setSubscription({
            tier: tier,
            successUrl: successUrl,
            cancelUrl: cancelUrl,
          })
        ).then(() => {
          dispatch(fetchTenant());
        });
      }
    }
  };

  // Redirect to the session url if possible
  useEffect(() => {
    if (
      subscriptionLoadingState.status === "fulfilled" &&
      subscriptionLoadingState.data?.status === "redirect"
    ) {
      window.location.replace(
        subscriptionLoadingState.data.sessionUrl as string
      );
    }
  }, [dispatch, subscriptionLoadingState]);

  const tiers: SubscriptionPlan[] = stripeTiers;

  const faqs = Array.isArray(
    t("subscriptions.faq.questions", { returnObjects: true })
  )
    ? (t("subscriptions.faq.questions", { returnObjects: true }) as {
        question: string;
        answer: string;
      }[])
    : [];

  if (hasReturnedFromCheckout) {
    return <SubscriptionPending />;
  }

  return (
    <PageWidth>
      <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
        {t("subscriptions.title", "Plans and pricing")}
      </h2>

      <p className="pb-8 pt-4 text-center">
        {t(
          "subscriptions.choosePlan",
          "Please choose a model for your application"
        )}
      </p>

      {/*TODO: Add some kind of calculator*/}

      <Can I="update" a="payment">
        <SubscriptionSelector
          onSelectTier={onSelectTier}
          tiers={tiers}
          currentTier={currentTier}
        />
      </Can>

      <Can not I="update" a="payment">
        {t(
          "tenant.settings.payment.managed",
          "Your account is managed by the software provider. You cannot change your payment settings."
        )}
      </Can>

      <SubscriptionFAQ faqs={faqs} />

      <h2 className="text-center text-xl font-extrabold text-gray-900 sm:text-xl">
        {t("subscriptions.questionsRemaining", "Are there open questions?")}
      </h2>

      <CustomerSupportDetails />
    </PageWidth>
  );
}
