import React from "react";
import { useTranslation } from "react-i18next";
import { PageHeadline } from "../../components/text/page-headline";
import {
  AnalyticsDataLoaderContextProvider,
  Entity,
} from "./analytics-data-loader-context";
import { AnalyticsDashboardView } from "./analytics-dashboard-view";
import { TimeRangeProvider } from "./time-range-context";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { PageWidth } from "../../components/containers/page-width";
import { Application, MetricKey } from "@nantis/gridknight-core";
import { useUserAbility } from "../../auth/user-ability-provider";
import { FeatureNotAvailableBanner } from "../common/feature-not-available-banner";
import { Card } from "../../components/containers/card";
import { FlexContainer } from "../../components/containers/flex-container";
import { decodeDatasetKey, encodeDatasetKey } from "./analytics-slice";

export const entityParamKey = "e";
export const propertyParamKey = "p";

export const analyticDefaultProperties: MetricKey[] = [
  "ea_fwd_t_d",
  "ea_fwd_t_d_cost",
];

export const createAnalyticsParamsWithDefaultProperties = (
  id: string,
  scope: Application.Analytics.TimeseriesScope
): string => {
  const propertyParams: [string, string][] = analyticDefaultProperties.map(
    (p) => {
      return [propertyParamKey, p];
    }
  );

  return (
    "?" +
    createSearchParams([
      [entityParamKey, encodeDatasetKey({ entity: { id: id, scope: scope } })],
      ...propertyParams,
    ])
  );
};

export function AnalyticsDashboardPage() {
  const { t } = useTranslation();
  const ability = useUserAbility();

  let [searchParams, setSearchParams] = useSearchParams();

  const searchParamEntities = searchParams.getAll(entityParamKey);

  const entities = searchParamEntities.map((e) => {
    return decodeDatasetKey(e).entity;
  });

  const searchParamProperties = searchParams.getAll(
    propertyParamKey
  ) as MetricKey[];

  // We build our UI from the context -> entities + properties + time range
  // Get all energy datasets and pass them on -> this needs a generic approach
  const setPropertiesSearchBar = (properties: string[]) => {
    const params: [string, string][] = [];

    properties.forEach((p) => {
      params.push([propertyParamKey, p as string]);
    });

    searchParamEntities.forEach((e) => {
      params.push([entityParamKey, e]);
    });

    setSearchParams(createSearchParams(params), {
      replace: true,
    });
  };

  const setEntitiesSearchBar = (entities: Entity[]) => {
    const params: [string, string][] = [];

    entities.forEach((e) => {
      params.push([
        entityParamKey,
        encodeDatasetKey({
          entity: e,
        }),
      ]);
    });

    searchParamProperties.forEach((p) => {
      params.push([propertyParamKey, p]);
    });

    setSearchParams(createSearchParams(params), {
      replace: true,
    });
  };

  if (!ability.can("use", "historicalData")) {
    return (
      <PageWidth>
        <Card>
          <FeatureNotAvailableBanner />
        </Card>
      </PageWidth>
    );
  }

  return (
    <TimeRangeProvider>
      <AnalyticsDataLoaderContextProvider
        properties={searchParamProperties}
        entities={entities}
        setEntities={setEntitiesSearchBar}
        setProperties={setPropertiesSearchBar}
      >
        <PageWidth>
          <FlexContainer>
            <PageHeadline>{t("analytics.title", "Analytics")}</PageHeadline>

            <AnalyticsDashboardView />
          </FlexContainer>
        </PageWidth>
      </AnalyticsDataLoaderContextProvider>
    </TimeRangeProvider>
  );
}
