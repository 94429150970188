import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { FetchStatus } from "../../models/types";

import { API, graphqlOperation } from "aws-amplify";
import { RootState } from "../../app/store";
import { Application } from "@nantis/gridknight-core";

const tagEntityAdapter = createEntityAdapter<Application.Tag>({
  selectId: (tag) => tag.id,
  sortComparer: (a, b) => b.id.localeCompare(a.id),
});

const initialState = tagEntityAdapter.getInitialState<{
  fetchTags: FetchStatus;
  fetchTag: FetchStatus;
  createTag: FetchStatus;
  updateTag: FetchStatus;
  deleteTag: FetchStatus;
}>({
  fetchTags: {
    status: "idle",
    error: null,
  },
  fetchTag: {
    status: "idle",
    error: null,
  },
  createTag: {
    status: "idle",
    error: null,
  },
  updateTag: {
    status: "idle",
    error: null,
  },
  deleteTag: {
    status: "idle",
    error: null,
  },
});

export const fetchTags = createAsyncThunk(
  "tags/fetchTags",
  async (paginationArgs: Application.PaginationArguments) => {
    const { limit = 100, cursor = "" } = paginationArgs;

    const result = (await API.graphql(
      graphqlOperation(
        `
            query allTags($limit: Int, $cursor: String) {
                tags(cursor: $cursor, limit: $limit) {
                    cursor
                    items {
                        id
                        tenant_id
                        label
                        color
                        description
                    }
                }
            }`,
        {
          limit,
          cursor,
        }
      )
    )) as {
      data: {
        tags: {
          cursor?: string;
          items: Application.Tag[];
        };
      };
    };
    return result.data.tags.items;
  }
);

export const fetchTag = createAsyncThunk(
  "tags/fetchTag",
  async (id: string) => {
    const result = (await API.graphql(
      graphqlOperation(
        `
            query getTag($id: ID = "") {
              tag(id: $id) {
                description
                id
                label
                color
                tenant_id
              }
            }`,
        {
          id,
        }
      )
    )) as {
      data: {
        tag: Application.Tag;
      };
    };
    return result.data.tag;
  }
);

export const createTag = createAsyncThunk(
  "tags/createTag",
  async ({
    label,
    color,
    description = "",
  }: {
    label: string;
    color: string | null;
    description: string | null | undefined;
  }) => {
    const result = (await API.graphql(
      graphqlOperation(
        `
            mutation createTag($description: String, $color: String, $label: String) {
              createTag(description: $description, label: $label, color: $color) {
                tenant_id
                id
                label
                color
                description
              }
            }`,
        {
          label,
          color,
          description,
        }
      )
    )) as {
      data: {
        createTag: Application.Tag;
      };
    };
    return result.data.createTag;
  }
);

export const updateTag = createAsyncThunk(
  "tags/updateTag",
  async ({
    id,
    color,
    label,
    description,
  }: {
    id: string;
    label?: string;
    color?: string | null;
    description?: string | null;
  }) => {
    const result = (await API.graphql(
      graphqlOperation(
        `
            mutation updateTagMutation( $id: ID!, $description: String, $color: String, $label: String) {
              updateTag(id: $id, description: $description, color: $color, label: $label) {
                tenant_id
                id
                label
                color
                description
              }
            }
            `,
        {
          id,
          label,
          color,
          description,
        }
      )
    )) as {
      data: {
        updateTag: Application.Tag;
      };
    };
    return result.data.updateTag;
  }
);

export const deleteTag = createAsyncThunk(
  "tags/deleteTag",
  async (id: string) => {
    const result = (await API.graphql(
      graphqlOperation(
        `
            mutation deleteTagMutation($id: ID!) {
              deleteTag(id: $id) {
                id
                tenant_id
              }
            }
            `,
        {
          id,
        }
      )
    )) as {
      data: {
        deleteTag: {
          id: string;
          tenant_id: string;
        };
      };
    };
    return result.data.deleteTag;
  }
);

const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    tagUpdated: tagEntityAdapter.upsertOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTags.pending, (state) => {
      state.fetchTags.status = "pending";
    });

    builder.addCase(fetchTags.fulfilled, (state, action) => {
      state.fetchTags.status = "fulfilled";
      tagEntityAdapter.setAll(state, action.payload);
    });

    builder.addCase(fetchTags.rejected, (state) => {
      state.fetchTags.status = "rejected";
      state.fetchTags.error = "ERROR";
    });

    builder.addCase(fetchTag.pending, (state) => {
      state.fetchTag.status = "pending";
    });

    builder.addCase(fetchTag.fulfilled, (state, action) => {
      state.fetchTag.status = "fulfilled";
      const tag = action.payload;
      if (tag && tag.id) {
        tagEntityAdapter.upsertOne(state, tag);
      }
    });

    builder.addCase(fetchTag.rejected, (state) => {
      state.fetchTag.status = "rejected";
      state.fetchTag.error = "ERROR";
    });

    builder.addCase(createTag.pending, (state) => {
      state.createTag.status = "pending";
    });

    builder.addCase(createTag.fulfilled, (state, action) => {
      state.createTag.status = "fulfilled";
      const tag = action.payload;
      if (tag && tag.id) {
        tagEntityAdapter.upsertOne(state, tag);
      }
    });

    builder.addCase(createTag.rejected, (state) => {
      state.createTag.status = "rejected";
      state.createTag.error = "ERROR";
    });

    builder.addCase(updateTag.pending, (state) => {
      state.updateTag.status = "pending";
    });

    builder.addCase(updateTag.fulfilled, (state, action) => {
      state.updateTag.status = "fulfilled";
      const tag = action.payload;
      if (tag && tag.id) {
        tagEntityAdapter.upsertOne(state, tag);
      }
    });

    builder.addCase(updateTag.rejected, (state) => {
      state.updateTag.status = "rejected";
      state.updateTag.error = "ERROR";
    });

    builder.addCase(deleteTag.pending, (state) => {
      state.deleteTag.status = "pending";
    });

    builder.addCase(deleteTag.fulfilled, (state, action) => {
      state.deleteTag.status = "fulfilled";
      const tag = action.payload;
      if (tag && tag.id) {
        tagEntityAdapter.removeOne(state, tag.id);
      }
    });

    builder.addCase(deleteTag.rejected, (state) => {
      state.deleteTag.status = "rejected";
      state.deleteTag.error = "ERROR";
    });
  },
});

export const reducer = tagsSlice.reducer;

export const { tagUpdated } = tagsSlice.actions;

export const { selectAll: selectAllTags, selectById: selectTagById } =
  tagEntityAdapter.getSelectors<RootState>((state) => state.tags);

export const selectTagsByTenant = createSelector(
  [selectAllTags, (state: RootState, tenantId: string) => tenantId],
  (tags, tenantId) => tags.filter((tag) => tag.tenant_id === tenantId)
);

export const selectTagByLabel = createSelector(
  [selectAllTags, (state: RootState, label: string) => label],
  (tags, label) =>
    tags.filter((tag) => tag.label.toLowerCase() === label.toLowerCase())
);

export const selectTagsByIds = createSelector(
  [selectAllTags, (state: RootState, ids: string[]) => ids],
  (tags, ids) =>
    tags.filter((tag) =>
      ids.map((id) => id.toLowerCase()).includes(tag.id.toLowerCase())
    )
);

export const selectTagsByNotInIds = createSelector(
  [selectAllTags, (state: RootState, ids: string[]) => ids],
  (tags, ids) =>
    tags.filter(
      (tag) => !ids.map((id) => id.toLowerCase()).includes(tag.id.toLowerCase())
    )
);

export const selectTagByLabelAlreadyExists = createSelector(
  [selectTagByLabel, (state: RootState, label: string) => label],
  (tags) => !!tags.length
);

export const selectFetchTagsLoadingState = (state: RootState) =>
  state.tags.fetchTags;
export const selectFetchTagLoadingState = (state: RootState) =>
  state.tags.fetchTag;
export const selectCreateTagLoadingState = (state: RootState) =>
  state.tags.createTag;
export const selectUpdateTagLoadingState = (state: RootState) =>
  state.tags.updateTag;
export const selectDeleteTagLoadingState = (state: RootState) =>
  state.tags.deleteTag;
