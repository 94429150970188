import React, { ChangeEvent, useState } from "react";
import { Application } from "@nantis/gridknight-core";

export function EnergyPriceInputField({
  rate,
  unit = "kWh",
  currencySymbol,
  onChange,
}: {
  currencySymbol: string;
  unit: string;
  rate: Application.Rate;
  onChange: (rate: number) => void;
}) {
  const [inputValue, setInputValue] = useState<string>(
    rate.originalRate?.toString() ?? ""
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    // Validate the input based on your criteria
    const isValid = /^[0-9]{0,1}(\.[0-9]{0,6})?$/.test(newValue);

    if (isValid) {
      setInputValue(newValue);
      // Convert the string input to a number with 6 decimal places
      const numericValue = parseFloat(newValue);
      onChange(numericValue);
    }
  };

  return (
    <div className="relative rounded-md shadow-sm">
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <span className="text-gray-500 sm:text-sm">{currencySymbol}</span>
      </div>
      <input
        type="number"
        step="0.000001"
        min={0}
        value={inputValue}
        name="activeEnergyPrice"
        id="activeEnergyPrice"
        onChange={handleChange}
        className="block w-full rounded-md border-gray-300 pl-12 pr-14 sm:text-sm"
        placeholder="0.00"
        aria-describedby="price-currency"
      />
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
        <span className="text-gray-500 sm:text-sm" id="price-currency">
          / {unit}
        </span>
      </div>
    </div>
  );
}
