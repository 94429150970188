import React from "react";
import { Routes, Route } from "react-router-dom";
import { TariffCreatePage } from "./tariff-create-page";
import { Tariff } from "./tariff";

export function Tariffs() {
  return (
    <Routes>
      <Route path="/create" element={<TariffCreatePage />} />
      <Route path="/:id/*" element={<Tariff />} />
    </Routes>
  );
}
