import { useTranslation } from "react-i18next";
import React, { Fragment } from "react";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { AnalyticsGraphProps } from "./analytics-graph";
import { useTheme } from "../../app/theme-provider";
import { Tooltip, TooltipContent, TooltipTrigger } from "../help/tooltip";
import { timeRangeToString } from "../time/time-range-utils";

export type AnalyticsGraphLegendProps = AnalyticsGraphProps & {
  showLabel?: boolean;
};

export function AnalyticsGraphLegend({
  showLabel = false,
  ...analyticsGraphProps
}: AnalyticsGraphLegendProps) {
  const { t } = useTranslation();

  const { getComparisonColor } = useTheme();

  const timeseries = analyticsGraphProps.timeseries.map((t) => {
    return {
      timerange: t.currentData.timeRange,
      previousTimerange: t.comparisonData?.timeRange,
      label: t.label,
      color: t.color,
      hasComparisonData: !!t.comparisonData,
      isLoading: t.currentData.status.status === "pending",
    };
  });

  return (
    <div className="flex flex-col items-center justify-center gap-2 md:items-start">
      <div className="flex flex-row gap-2 text-sm">
        {timeseries.map((ts) => {
          return (
            <Fragment key={ts.label}>
              <Tooltip>
                <TooltipTrigger>
                  <LegendItem
                    key={ts.label}
                    isLoading={ts.isLoading}
                    color={ts.color}
                    label={
                      ts.hasComparisonData
                        ? (showLabel ? ts.label + " " : "") +
                          `${t("stats.inCurrentTimerange")}`
                        : ts.label
                    }
                  />
                </TooltipTrigger>
                <TooltipContent>
                  {timeRangeToString(ts.timerange)}
                </TooltipContent>
              </Tooltip>
              {ts.hasComparisonData && (
                <Tooltip>
                  <TooltipTrigger>
                    <LegendItem
                      key={`comparison-${ts.label}`}
                      isLoading={ts.isLoading}
                      color={getComparisonColor(ts.color)}
                      label={`${showLabel ? ts.label + " " : ""}${t(
                        "stats.inPreviousTimerange"
                      )}`}
                    />
                  </TooltipTrigger>
                  <TooltipContent>
                    {ts.previousTimerange
                      ? timeRangeToString(ts.previousTimerange)
                      : ""}
                  </TooltipContent>
                </Tooltip>
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}

function LegendItem({
  label,
  color,
  isLoading,
}: {
  label: string;
  color: string;
  isLoading: boolean;
}) {
  return (
    <span className="flex items-center">
      <span className="mr-2 flex items-center">
        {isLoading ? (
          <ArrowPathIcon
            style={{
              color: color,
              fill: color,
            }}
            className="inline-block h-4 w-4 animate-spin"
          />
        ) : (
          <span
            className="inline-block h-3 w-3 rounded-full"
            style={{
              backgroundColor: color,
            }}
          />
        )}
      </span>
      <span>{label}</span>
    </span>
  );
}
