import React from "react";
import { useTranslation } from "react-i18next";
import { getDeviceConnectionState } from "../../models/device";
import { useTime } from "../../components/time/time-context";
import { useAppSelector } from "../../app/store";
import {
  selectDeviceConfigurationByDeviceId,
  selectDeviceStateByDeviceId,
} from "./devices-slice";
import {
  TooltipContent,
  Tooltip,
  TooltipTrigger,
} from "../../components/help/tooltip";

export function DeviceLastSeenBadge({ deviceId }: { deviceId: string }) {
  const { t } = useTranslation();
  const { now } = useTime();

  const status = useAppSelector((state) =>
    selectDeviceStateByDeviceId(state, deviceId)
  );
  const config = useAppSelector((state) =>
    selectDeviceConfigurationByDeviceId(state, deviceId)
  );

  const lastStatusTime = status?.time ? new Date(status?.time) : undefined;

  const connectionState = getDeviceConnectionState(now, lastStatusTime, config);

  const lastSeenMessage = lastStatusTime
    ? t(
        "device.detail.lastSeen",
        "last seen {{date, date(day: numeric; month: short; year: numeric; hour: 2-digit; minute: 2-digit)}}",
        { date: lastStatusTime }
      )
    : t("device.detail.neverSeen", "This device has never been online");

  return (
    <Tooltip>
      <TooltipTrigger>
        <div className="flex items-center">
          {connectionState.status === "online" && (
            <span className="inline-flex rounded-full bg-green px-2 text-xs leading-5 text-white md:font-semibold">
              {t("device.connectionState.online", "online")}
            </span>
          )}
          {connectionState.status === "offline" && (
            <span className="inline-flex rounded-full bg-red px-2 text-xs leading-5 text-white md:font-semibold">
              {t("device.connectionState.offline", "offline")}
            </span>
          )}
        </div>
      </TooltipTrigger>
      <TooltipContent>{lastSeenMessage}</TooltipContent>
    </Tooltip>
  );
}
