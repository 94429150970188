import { PlugIcon16A, PlugIconBlank } from "../../assets/icons";
import { HTMLAttributes } from "react";
import { Application } from "@nantis/gridknight-core";
import { useTranslation } from "react-i18next";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../components/help/tooltip";

export interface DeviceIconProps extends HTMLAttributes<HTMLElement> {
  type: string | null | undefined;
}

export function DeviceIcon(props: DeviceIconProps) {
  const { t } = useTranslation();

  if (props && props.type) {
    const type = props.type as Application.DeviceType;

    if (type.startsWith("plug-cee-16")) {
      return (
        <Tooltip>
          <TooltipTrigger>
            <PlugIcon16A className="h-[36px] w-[36px]" />
          </TooltipTrigger>
          <TooltipContent>
            {t("device.types.plugCEE16", "CEE plug 16 Ampere")}
          </TooltipContent>
        </Tooltip>
      );
    }

    return (
      <Tooltip>
        <TooltipTrigger>
          <PlugIconBlank className="h-[36px] w-[36px]" />
        </TooltipTrigger>
      </Tooltip>
    );
  }

  return <>-</>;
}
