import React, { PropsWithChildren, useEffect, useState } from "react";
import { urlB64ToUint8Array } from "../../app/util";
import awsConfig from "../../aws-config.json";
const { vapidPublicKey } = awsConfig;

const BrowserContext = React.createContext<{
  notificationPermission: NotificationPermission;
  isSubscribed: boolean;
  subscribeToDeviceNotifications: () => Promise<PushSubscription>;
  unsubscribeToDeviceNotifications: () => Promise<PushSubscription>;
}>({
  isSubscribed: false,
  notificationPermission: "denied",
  subscribeToDeviceNotifications: async () => {
    return Promise.reject("No registration context available");
  },
  unsubscribeToDeviceNotifications: async () => {
    return Promise.reject("No registration context available");
  },
});

export const useBrowser = () => {
  return React.useContext(BrowserContext);
};

export function BrowserContextProvider({ children }: PropsWithChildren) {
  const [pushSubscription, setPushSubscription] =
    useState<PushSubscription | null>();
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);

  const getRegistration = async (): Promise<
    ServiceWorkerRegistration | undefined
  > => {
    if (window.navigator.serviceWorker) {
      return window.navigator.serviceWorker.getRegistration();
    }

    return Promise.reject("No registration available");
  };

  useEffect(() => {
    getRegistration().then((registration) => {
      if (registration != undefined) {
        registration.pushManager.getSubscription().then((subscription) => {
          setPushSubscription(subscription);
          setIsSubscribed(subscription !== null);
        });
      }
    });
  }, []);

  const subscribeToRegistration = async (
    registration: ServiceWorkerRegistration
  ): Promise<PushSubscription> => {
    return registration.pushManager
      .subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlB64ToUint8Array(vapidPublicKey),
      })
      .then((subscription) => {
        setIsSubscribed(true);
        setPushSubscription(subscription);
        return subscription;
      });
  };

  const subscribeToDeviceNotifications =
    async (): Promise<PushSubscription> => {
      return getRegistration()
        .then((registration) => {
          if (registration) {
            return subscribeToRegistration(registration);
          } else {
            return Promise.reject("No registration available");
          }
        })
        .catch((error) => {
          return Promise.reject("No registration available");
        });
    };

  const unsubscribeToDeviceNotifications = async () => {
    if (pushSubscription) {
      await pushSubscription.unsubscribe();
      setIsSubscribed(false);
      return pushSubscription;
    } else {
      return Promise.reject("No push subscription available");
    }
  };

  const notificationPermission =
    "Notification" in window ? window.Notification.permission : "denied";

  return (
    <BrowserContext.Provider
      value={{
        unsubscribeToDeviceNotifications,
        subscribeToDeviceNotifications,
        notificationPermission: notificationPermission,
        isSubscribed: isSubscribed,
      }}
    >
      {children}
    </BrowserContext.Provider>
  );
}
