import React, { ReactElement, useState } from "react";
import { animated, useTransition, to } from "@react-spring/web";

import { scaleBand, scaleLinear } from "d3-scale";
import { range } from "d3-array";

import { Group } from "@visx/group";
import { arc } from "@visx/shape";

import { VisualizationPropsWithOptions } from "../types";
import { useTheme } from "../../app/theme-provider";

export type StackedRadialGaugeOptions = {
  minValue?: number;
  maxValue?: number;
  startAngle?: number;
  endAngle?: number;

  fillFactor?: number;
  radialPadding?: number;
};

interface StackedRadialGaugeProps<Datum>
  extends VisualizationPropsWithOptions<StackedRadialGaugeOptions> {
  data: {
    data: Datum[];
    commonValue: Datum;
  };
  accessor: (d: Datum) => number;
  valueLabelGenerator: (d: Datum) => ReactElement;
}

export function StackedRadialGauge<Datum>({
  data,
  accessor,
  valueLabelGenerator,
  width = 100,
  height = 100,
  margin = { top: 10, right: 10, bottom: 10, left: 10 },
  options,
  animate = true,
  ...props
}: StackedRadialGaugeProps<Datum>) {
  const [showIndex, setShowIndex] = useState<number>(-1);

  const centerLabel =
    showIndex >= 0
      ? valueLabelGenerator(data.data[showIndex])
      : valueLabelGenerator(data.commonValue);

  const { colors } = useTheme();
  const colorPrimaryAccent = colors[1];

  const angle = -(2.5 / 8) * Math.PI * 2;

  const {
    minValue = 0,
    maxValue = 100,
    startAngle = angle,
    endAngle = -angle,
    fillFactor = 0.3,
    radialPadding = 0.2,
  } = options;

  const { top = 0, right = 0, bottom = 0, left = 0 } = margin;

  const availableWidth = width - left - right > 0 ? width - left - right : 0;
  const availableHeight = height - top - bottom > 0 ? height - top - bottom : 0;

  if (availableWidth <= 0 || availableHeight <= 0) {
    return <div>Width or height is less than 0</div>;
  }

  const arcMaxHeight =
    availableHeight / (1 - Math.cos((endAngle - startAngle) / 2));

  const outerRadius = Math.min(arcMaxHeight, availableWidth / 2);
  const innerRadius = outerRadius * (1 - Math.min(fillFactor, 1));

  const centerX = left + availableWidth / 2;
  const centerY = top + outerRadius;

  const rAxis = scaleBand<number>();

  rAxis.domain(range(data.data.length));
  rAxis.range([innerRadius, outerRadius]);
  rAxis.paddingInner(radialPadding);

  const scale = scaleLinear()
    .domain([minValue, maxValue])
    .range([startAngle, endAngle]);

  const animatedArcDatums = data.data.map(
    (value, index): AnimatedArcDatum<Datum> => {
      const outerRadius = rAxis(index) ?? 0;
      const innerRadius = outerRadius - rAxis.bandwidth();

      return {
        index: index,
        data: value,
        innerRadius,
        outerRadius,
        startAngle: scale(0),
        endAngle: scale(accessor(value)),
      };
    }
  );

  const arcGenerator = arc<AnimatedArcDatum<Datum>>({ cornerRadius: 5 });

  return (
    <div {...props} className="chartWrapper" style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          zIndex: 2,
          left: centerX,
          top: centerY,
          height: "auto",
          transform: `translate(-50%, -50%)`,
          lineHeight: "1em",
          fontFeatureSettings: "'zero', 'tnum' 1",
          pointerEvents: "none",
        }}
        className={"font-mono"}
      >
        {centerLabel}
      </div>

      <svg width={width} height={height}>
        <Group top={centerY} left={centerX}>
          <AnimatedArcs<Datum>
            startAngle={startAngle}
            endAngle={endAngle}
            arcPadding={rAxis.padding() * rAxis.bandwidth()}
            getKey={(d) => `arc${data.data.indexOf(d.data)}`}
            path={arcGenerator}
            data={animatedArcDatums}
            animate={animate}
            getColor={() => colorPrimaryAccent}
            onPointerEnter={(d) => setShowIndex(d.index)}
            onPointerLeave={() => setShowIndex(-1)}
          />
        </Group>
      </svg>
    </div>
  );
}

type AnimatedStyles = { startAngle: number; endAngle: number };

interface AnimatedArcDatum<Datum> {
  index: number;
  data: Datum;
  startAngle: number;
  endAngle: number;
  innerRadius: number;
  outerRadius: number;
}

const fromLeaveTransition = ({ startAngle }: AnimatedArcDatum<any>) => ({
  startAngle: startAngle,
  endAngle: startAngle,
});

const enterUpdateTransition = ({
  startAngle,
  endAngle,
}: AnimatedArcDatum<any>) => ({
  startAngle,
  endAngle,
});

type AnimatedArcProps<Datum> = {
  data: AnimatedArcDatum<Datum>[];
  animate?: boolean;
  path: any;
  startAngle: number;
  endAngle: number;
  arcPadding: number;

  getKey: (d: AnimatedArcDatum<Datum>) => string;
  getColor?: (d: AnimatedArcDatum<Datum> | null) => string;
  onPointerEnter: (d: AnimatedArcDatum<Datum>) => void;
  onPointerLeave: () => void;
};

function AnimatedArcs<Datum>({
  path,
  data,
  startAngle,
  endAngle,
  animate,
  getKey,
  arcPadding,
  getColor = () => {
    return "#26c6db";
  },
  onPointerEnter,
  onPointerLeave,
}: AnimatedArcProps<Datum>) {
  const transitions = useTransition<AnimatedArcDatum<Datum>, AnimatedStyles>(
    data,
    {
      keys: getKey,
      from: animate ? fromLeaveTransition : enterUpdateTransition,
      enter: enterUpdateTransition,
      update: enterUpdateTransition,
      leave: animate ? fromLeaveTransition : enterUpdateTransition,
    }
  );

  return (
    <>
      {transitions(
        ({ startAngle: sa, endAngle: ea }, item: AnimatedArcDatum<Datum>) => {
          return (
            <g
              onPointerEnter={() => onPointerEnter(item)}
              onPointerLeave={() => onPointerLeave()}
            >
              {/*Dummy Element for avoiding flicker when hovering*/}
              <path
                d={path({
                  innerRadius: item.innerRadius - arcPadding,
                  outerRadius: item.outerRadius + arcPadding,
                  startAngle,
                  endAngle,
                })}
                fill={"transparent"}
              />

              <path
                d={path({
                  innerRadius: item.innerRadius,
                  outerRadius: item.outerRadius,
                  startAngle,
                  endAngle,
                })}
                fill={"#e7e7e7"}
              />

              <animated.path
                // compute interpolated path d attribute from intermediate angle values
                d={to([sa, ea], (startAngle, endAngle) =>
                  path({
                    innerRadius: item.innerRadius,
                    outerRadius: item.outerRadius,
                    startAngle,
                    endAngle,
                  })
                )}
                fill={getColor(item)}
              />
            </g>
          );
        }
      )}
    </>
  );
}
