import React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/store";
import { selectLoginUserLoadingState } from "../auth-slice";
import { LoadingButton } from "../../components/ng";

export type LoginFormData = {
  email: string;
  password: string;
};

export type LoginFormProps = {
  onLogin: ({ email, password }: LoginFormData) => void;
};

const schema = yup.object().shape({
  password: yup.string().required(),
  email: yup.string().email().required(),
});

export function LoginForm({ onLogin }: LoginFormProps) {
  const { t } = useTranslation();
  const loginLoadingState = useAppSelector((state) =>
    selectLoginUserLoadingState(state)
  );

  const { register, handleSubmit } = useForm<LoginFormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit((data: LoginFormData) => {
    onLogin({
      password: data.password,
      email: data.email,
    });
  });

  return (
    <form onSubmit={onSubmit} className="space-y-6" action="#">
      <div>
        <label htmlFor="companyName" className="block text-sm text-gray-700">
          {t("auth.login.email", "Email address")}
        </label>
        <div className="mt-1">
          <input
            id="email"
            type="text"
            autoComplete="email"
            required
            {...register("email")}
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
          />
        </div>
      </div>

      <div>
        <label htmlFor="password" className="block text-sm text-gray-700">
          {t("auth.login.password", "Password")}
        </label>
        <div className="mt-1">
          <input
            id="password"
            type="password"
            autoComplete="password"
            required
            {...register("password")}
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm sm:text-sm"
          />
        </div>
      </div>

      {loginLoadingState.status === "rejected" &&
        loginLoadingState.error === "emailOrPasswordWrong" && (
          <div className="text-center text-sm">
            <p className="text-red">
              {t(
                "auth.login.errors.wrongUsernameOrPassword",
                "Username or password wrong"
              )}
            </p>
          </div>
        )}

      <LoadingButton
        type="submit"
        variant="success"
        isLoading={loginLoadingState.status === "pending"}
        className={"w-full justify-center text-sm"}
      >
        {t("auth.login.login", "Sign in")}
      </LoadingButton>
    </form>
  );
}
