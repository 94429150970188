import { useBrowser } from "../../components/browser/browser-context";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/ng";
import React, { useState } from "react";

import ConfirmationModal, {
  ConfirmationModalProps,
} from "../../components/modals/confirmation-modal";

export interface WebpushSubscriptionButtonProps {
  onSubscribeToDeviceNotifications?: (subscription: PushSubscription) => void;
  onUnsubscribeToDeviceNotifications?: (subscription: PushSubscription) => void;
}
export function WebpushSubscriptionButton(
  props: WebpushSubscriptionButtonProps
) {
  const { t } = useTranslation();

  // TODO check if the current subscription exists in the backend
  // TODO fix undefined defined input error

  const {
    notificationPermission,
    subscribeToDeviceNotifications,
    unsubscribeToDeviceNotifications,
    isSubscribed,
  } = useBrowser();

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const onCancelSubscriptionDialog = () => {
    setOpenConfirmationModal(false);
  };

  async function onUnsubscribeToDeviceNotifications() {
    if (isSubscribed) {
      unsubscribeToDeviceNotifications().then((subscription) => {
        if (props.onUnsubscribeToDeviceNotifications) {
          props.onUnsubscribeToDeviceNotifications(subscription);
        }
      });
    }
  }

  function askForNotificationPermission() {
    if (notificationPermission === "granted") {
      subscribeToDeviceNotifications().then((subscription) => {
        if (props.onSubscribeToDeviceNotifications) {
          props.onSubscribeToDeviceNotifications(subscription);
        }
      });
    } else {
      setOpenConfirmationModal(true);
    }
  }

  const onConfirmSubscribeToDeviceNotifications = () => {
    setOpenConfirmationModal(false);
    subscribeToDeviceNotifications().then((subscription) => {
      if (props.onSubscribeToDeviceNotifications) {
        props.onSubscribeToDeviceNotifications(subscription);
      }
    });
  };

  const modalProps: ConfirmationModalProps = {
    variant: "info",
    open: openConfirmationModal,
    onConfirm: onConfirmSubscribeToDeviceNotifications,
    onCancel: onCancelSubscriptionDialog,
    title: t(
      "user.settings.notifications.webpush.dialogTitle",
      "Activate push notifications"
    ),
    confirmationButtonText: t(
      "user.settings.notifications.webpush.subscribeAndRequestPermissions",
      "Subscribe and request permissions"
    ),
    description: t(
      "user.settings.notifications.webpush.subscriptionDisclaimer",
      "If you want to receive notifications on this device, you need to give your web browser permission. Your browser will ask you for permission to receive notifications. Please confirm this."
    ),
  };

  return (
    <>
      <ConfirmationModal {...modalProps} />

      {!isSubscribed ? (
        <Button
          variant={"success"}
          disabled={notificationPermission === "denied"}
          type={"button"}
          onClick={() => askForNotificationPermission()}
        >
          {t(
            "user.settings.notifications.webpush.activateOnThisDevice",
            "Activate on this device"
          )}
        </Button>
      ) : (
        <div className={"flex flex-col"}>
          <span className={"text-success"}>
            {t(
              "user.settings.notifications.webpush.activeOnThisDevice",
              "Push notifications are active on this device"
            )}
          </span>
          <span
            className={"cursor-pointer text-sm text-primary underline"}
            onClick={() => onUnsubscribeToDeviceNotifications()}
          >
            {" "}
            {t(
              "user.settings.notifications.webpush.deactivateOnThisDevice",
              "Deactivate on this device"
            )}
          </span>
        </div>
      )}
    </>
  );
}
