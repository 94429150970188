import { Card } from "../../../components/containers/card";
import React from "react";
import { useAppSelector } from "../../../app/store";
import {
  selectDeviceById,
  selectDeviceConfigurationByDeviceId,
  selectDeviceStateByDeviceId,
} from "../devices-slice";
import { PageHeadline } from "../../../components/text/page-headline";
import { DeviceLastSeenBadge } from "../device-last-seen-badge";
import { useTranslation } from "react-i18next";

export function DeviceDebuggingPage({ deviceId }: { deviceId: string }) {
  const { t } = useTranslation();

  const status = useAppSelector((state) =>
    selectDeviceStateByDeviceId(state, deviceId)
  );
  const config = useAppSelector((state) =>
    selectDeviceConfigurationByDeviceId(state, deviceId)
  );

  const device = useAppSelector((state) => selectDeviceById(state, deviceId));
  const deviceInfo = device?.info ? JSON.parse(device.info as string) : null;

  return (
    <div>
      <PageHeadline>
        {device?.name ?? t("device.detail.deviceNoName", { deviceId })}
        {status && <DeviceLastSeenBadge deviceId={deviceId} />}
      </PageHeadline>

      {status && (
        <Card>
          <h1>Status</h1>

          {JSON.stringify(status, null, 2)}
        </Card>
      )}

      {deviceInfo && (
        <Card>
          <h1>Info</h1>

          {JSON.stringify(deviceInfo, null, 2)}
        </Card>
      )}

      {config && (
        <Card>
          <h1>Config</h1>

          {JSON.stringify(config, null, 2)}
        </Card>
      )}
    </div>
  );
}
