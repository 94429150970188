export function encodeMailLink({
  recipient,
  body,
  subject,
}: {
  recipient: string;
  body: string;
  subject: string;
}) {
  return `
        mailto:${recipient}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;
}
