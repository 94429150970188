import React, { useMemo } from "react";

import type { Application } from "@nantis/gridknight-core";
import { getColorFromCSSVariable } from "../util";
import { VisualizationPropsWithOptions } from "../types";
import { scaleBand } from "@visx/scale";
import { Group } from "@visx/group";
import { Bar } from "@visx/shape";

export type ReceptionGraphProps = {
  reception: Application.CellularReceptionQuality;
};

export function CellularReceptionGraph({
  options,
  width = 24,
  height = 24,
  ...props
}: VisualizationPropsWithOptions<ReceptionGraphProps>) {
  const { reception } = options;

  const size = Math.min(height, width);
  const { top = 0, left = 0 } = props.margin ?? {};
  const cellularReceptionValues: string[] = useMemo<
    Application.CellularReceptionQuality[]
  >(() => ["poor", "fair", "good", "excellent"], []);

  const colorGood = getColorFromCSSVariable("--color-status-good") ?? "#10B981";
  const colorBad = getColorFromCSSVariable("--color-status-bad") ?? "#EF4444";
  const colorNeutral =
    getColorFromCSSVariable("--color-status-neutral") ?? "#d0d0d0";

  const getReceptionColor = (
    reception: Application.CellularReceptionQuality
  ): string => {
    switch (reception) {
      case "excellent":
      case "good":
      case "fair":
        return colorGood;
      case "poor":
        return colorBad;
      case "no-reception":
      default:
        return colorNeutral;
    }
  };

  const color = getReceptionColor(reception);

  const yScale = useMemo(() => {
    return scaleBand<string>({
      range: [size, 0],
      domain: cellularReceptionValues,
      round: true,
    });
  }, [size, cellularReceptionValues]);

  const yMax = size;

  const xScale = useMemo(() => {
    return scaleBand<string>({
      range: [0, size],
      round: true,
      domain: cellularReceptionValues,
      padding: 0.4,
    });
  }, [size, cellularReceptionValues]);

  return (
    <div>
      <svg width={width} height={height}>
        <Group top={top + (height - size) / 2} left={left + (width - size) / 2}>
          {yScale &&
            cellularReceptionValues.map((value): JSX.Element => {
              const barWidth = xScale.bandwidth();
              const barHeight = yMax - (yScale(value) ?? 0);
              const barX = xScale(value);
              const barY = yMax - barHeight;
              const active =
                cellularReceptionValues.indexOf(value) <=
                cellularReceptionValues.indexOf(reception);

              return (
                <Bar
                  key={`bar-${value}`}
                  x={barX}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  fill={active ? color : "#d0d0d0"}
                />
              );
            })}
        </Group>
      </svg>
    </div>
  );
}
