import React, { HTMLAttributes } from "react";
import { animated, useSpring } from "@react-spring/web";
import { getValueFormat, formattedValueToString } from "@nantis/grafana-data";
import { classNames } from "../util";

export interface AnimatedNumberProps extends HTMLAttributes<HTMLSpanElement> {
  unit: string;
  decimalPoints: number;
  value?: number;
}

function AnimatedNumber({
  value,
  decimalPoints,
  unit,
  className,
  style,
  ...props
}: AnimatedNumberProps) {
  const spring = useSpring({ number: value, from: { number: 0 } });
  const formatter = getValueFormat(unit);

  if (value === undefined) {
    return <span style={{ fontFeatureSettings: "'zero', 'tnum' 1" }}>-</span>;
  }

  return (
    <animated.span
      style={{ ...style, fontFeatureSettings: "'zero', 'tnum' 1" }}
      className={classNames(className, "whitespace-nowrap font-mono")}
      {...props}
    >
      {spring.number.to((v) =>
        formattedValueToString(formatter(v as number, decimalPoints))
      )}
    </animated.span>
  );
}

export { AnimatedNumber };
