import { DataTable } from "../../../components/ng/data-table";
import React, { useMemo } from "react";
import { ColumnDef, VisibilityState } from "@tanstack/react-table";
import { Application, Device } from "@nantis/gridknight-core";
import { useTranslation } from "react-i18next";
import { DeviceIcon } from "../device-icon";
import { Link } from "react-router-dom";
import { TagBadge } from "../../tags/tagBadge";
import { useAppSelector } from "../../../app/store";
import { selectTagsByIds } from "../../tags/tags-slice";
import { selectTagsOnDeviceByDeviceId } from "../../tags/tag-on-device-slice";
import {
  selectDeviceConfigurationByDeviceId,
  selectDeviceStateByDeviceId,
  selectEventsByDevice,
} from "../devices-slice";
import { DeviceLastSeenBadge } from "../device-last-seen-badge";
import { ExclamationTriangleIcon, SignalIcon } from "@heroicons/react/24/solid";
import { classNames } from "../../../app/util";
import { formattedValueToString, getValueFormat } from "@nantis/grafana-data";
import { DeviceCellularReceptionGraph } from "../device/device-cellular-reception-graph";
import { useTime } from "../../../components/time/time-context";
import { getDeviceConnectionState } from "../../../models/device";
import { CloudIcon } from "../../../assets/icons";
import { DataTableColumnHeader } from "../../../components/table/table-header";
import {
  isVisible,
  ScreenBreakpoints,
} from "../../../components/util/getScreenBreakpoints";

export interface DevicesTableProps {
  width: number;
  devices: Application.Device[];
}

const wattFormat = getValueFormat("watt");

/**
 * Devices Table
 * https://ui.shadcn.com/docs/components/data-table
 * @constructor
 */
export function DevicesTable({ devices, width = 200 }: DevicesTableProps) {
  const { t } = useTranslation();

  const columnVisibility = useMemo<VisibilityState>(() => {
    const c: Record<string, ScreenBreakpoints["name"] | null> = {
      type: null,
      name: null,
      id: "sm",
      status: null,
      tags: "md",
      activePower: "xl",
      reception: null,
      nextOnline: "sm",
      statusReceived: "sm",
    };

    return Object.keys(c).reduce((acc, key) => {
      return {
        [key]: isVisible(width, c[key]),
        ...acc,
      };
    }, {});
  }, [width]);

  const columns = useMemo<ColumnDef<Application.Device, any>[]>(
    () => [
      {
        accessorKey: "type",
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={t("devices.list.table.type", "type")}
          />
        ),
        cell: ({ row }) => {
          return <DeviceIcon type={row.original ? row.original.type : null} />;
        },
        enableSorting: false,
        hidden: true,
      },
      {
        accessorKey: "name",
        header: ({ column }) => {
          return (
            <DataTableColumnHeader
              className=""
              column={column}
              title={t("devices.list.table.name", "name")}
            />
          );
        },
        cell: ({ row }) => {
          const device = row.original;
          const href = `/devices/${device.id}`;
          return (
            <Link className={"flex flex-col gap-1"} to={href}>
              <span>{device.name}</span>
              <span className="text-xs text-gray-600 md:hidden">
                {device.id}
              </span>
            </Link>
          );
        },
      },
      {
        accessorKey: "id",
        id: "id",
        header: ({ column }) => {
          return (
            <DataTableColumnHeader
              column={column}
              title={t("devices.list.table.id", "ID")}
            />
          );
        },
        cell: ({ row }) => {
          const device = row.original;
          const href = `/devices/${device.id}`;
          return <Link to={href}>{device.id}</Link>;
        },
      },
      {
        id: "tags",
        header: () => t("devices.list.table.tags", "tags"),
        cell: ({ row }) => {
          return <DeviceTags deviceId={row.original.id} />;
        },
      },
      {
        id: "activePower",
        header: ({ column }) => {
          return (
            <DataTableColumnHeader
              column={column}
              title={t("devices.list.table.power", "power")}
            />
          );
        },
        cell: ({ row }) => {
          const device = row.original;
          return <ActivePower deviceId={device.id} />;
        },
      },
      {
        id: "status",
        header: () => t("devices.list.table.status", "status"),
        cell: ({ row }) => {
          const device = row.original;

          return (
            <div className={"flex justify-start gap-1"}>
              <DeviceLastSeenBadge deviceId={device.id} />

              <AlertsBadge device={device} />
            </div>
          );
        },
      },
      {
        id: "reception",
        header: () => t("devices.list.table.reception", "reception"),
        cell: ({ row }) => {
          const device = row.original;

          return (
            <div className={"flex h-6 w-6 flex-wrap justify-center"}>
              <DeviceCellularReceptionGraph deviceId={device.id} />
            </div>
          );
        },
      },
      {
        id: "nextOnline",
        header: () => {
          return (
            <>
              <span className="hidden lg:block">
                {t("devices.list.table.nextOnline", "next online")}
              </span>
              <span
                className="block lg:hidden"
                title={t("devices.list.table.nextOnline", "next online")}
              >
                <CloudIcon className="h-8 w-8" />
              </span>
            </>
          );
        },
        cell: ({ row }) => {
          const device = row.original;

          return <NextOnline deviceId={device.id} />;
        },
      },
      {
        id: "statusReceived",
        header: () => (
          <span className="sr-only">
            {t("devices.list.table.ping", "ping")}
          </span>
        ),
        cell: ({ row }) => {
          const device = row.original;
          return (
            <div className={"flex flex-wrap"}>
              <StatusReceivedBadge deviceId={device.id} />
            </div>
          );
        },
      },
    ],
    [t]
  );

  return (
    <DataTable
      columnVisibility={columnVisibility}
      columns={columns}
      data={devices}
    />
  );
}

function StatusReceivedBadge({ deviceId }: { deviceId: string }) {
  const { now } = useTime();

  const status = useAppSelector((state) =>
    selectDeviceStateByDeviceId(state, deviceId)
  );

  const config = useAppSelector((state) =>
    selectDeviceConfigurationByDeviceId(state, deviceId)
  );

  const connectionState = getDeviceConnectionState(
    now,
    status?.time ? new Date(status?.time) : undefined,
    config
  );

  return (
    <div
      className={`inline-block h-6 w-6 rounded-full text-green transition-opacity duration-700 ease-in-out ${
        (connectionState.secondsSinceLastStatus ?? Infinity) <= 5
          ? "opacity-100"
          : "opacity-0"
      }`}
    >
      <SignalIcon />
    </div>
  );
}

function DeviceTags({ deviceId }: { deviceId: string }) {
  const ToDs = useAppSelector((state) =>
    selectTagsOnDeviceByDeviceId(state, deviceId)
  );
  const tagIdsOnDevice = ToDs.map((t) => t.tag_id);

  const tags = useAppSelector((state) =>
    selectTagsByIds(state, tagIdsOnDevice)
  );

  return (
    <div className="flex flex-wrap gap-1">
      {tags &&
        tags.map((tag) => (
          <Link
            onClick={(e) => e.stopPropagation()}
            key={tag.id}
            to={`/tags/${tag.id}`}
          >
            <TagBadge tag={tag} />
          </Link>
        ))}
    </div>
  );
}

function AlertsBadge({ device }: { device: Application.Device }) {
  const { t } = useTranslation();

  const status = useAppSelector((state) =>
    selectDeviceStateByDeviceId(state, device.id)
  );

  const activeEvents: Device.Event<any>[] = status?.events ?? [];

  const historicEvents = useAppSelector((state) =>
    selectEventsByDevice(state, device.id)
  );

  const titleHistoric = t("device.events.summary.alertsHistorical", {
    name: device?.name ?? device?.id,
    count: historicEvents.length,
  });

  const titleActive = t("device.events.summary.alertsActive", {
    name: device?.name ?? device?.id,
    count: activeEvents.length,
  });

  const title = `${activeEvents.length ? titleActive : ""} ${
    historicEvents.length ? titleHistoric : ""
  }`;

  if (historicEvents.length <= 0 && activeEvents.length <= 0) {
    return <></>;
  }

  return (
    <span title={title}>
      <ExclamationTriangleIcon
        className={classNames(
          activeEvents.length ? "fill-red" : "fill-orange",
          "h-5 w-5"
        )}
      />
    </span>
  );
}

function ActivePower({ deviceId }: { deviceId: string }) {
  const status = useAppSelector((state) =>
    selectDeviceStateByDeviceId(state, deviceId)
  );

  return (
    <span>
      {formattedValueToString(wattFormat(status?.metrics?.p_t ?? 0, 1))}
    </span>
  );
}

function NextOnline({ deviceId }: { deviceId: string }) {
  const { now } = useTime();
  const { t } = useTranslation();

  const status = useAppSelector((state) =>
    selectDeviceStateByDeviceId(state, deviceId)
  );

  const config = useAppSelector((state) =>
    selectDeviceConfigurationByDeviceId(state, deviceId)
  );

  const connectionState = getDeviceConnectionState(
    now,
    status?.time ? new Date(status?.time) : undefined,
    config
  );

  return (
    <span>
      {connectionState.nextOnline
        ? t(
            "devices.list.nextOnline",
            "{{date, date(hour: 2-digit; minute: 2-digit)}}",
            { date: connectionState.nextOnline }
          )
        : "-"}
    </span>
  );
}
